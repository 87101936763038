import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormattedMessage } from "react-intl";

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, onCancel } = props;
  
  const handleConfirm = (e) => {
    setOpen(false);
    if (onConfirm) {
      onConfirm()
    }
  }

  const handleCancel = (e) => {
    setOpen(false);
    if (onCancel) {
      onCancel()
    }
  }
  
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleCancel}
          color="secondary"
        >
          <FormattedMessage
              id="confirmDialog.no"
              defaultMessage="Abbrechen"
          />
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          color="primary"
        >
          <FormattedMessage
            id="confirmDialog.yes"
            defaultMessage="Ok"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;