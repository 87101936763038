import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getNavigationValue} from 'components/xstate/NavigationMachine'

export default function Redirect({children}) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const naviValue = useSelector(getNavigationValue)

  useEffect(() => {
    let navigateToBackend = false
    if (window) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code", window.location.search);
      if (code && code !== "") {
        navigateToBackend = true
      }
    }

    if (!navigateToBackend && location?.state?.target && location?.state?.url) {
      if (naviValue !== location.state.url) {
        dispatch({type: location.state.target})
      } else {
        navigate(location.state.url)
      }
    }
  }, []) //eslint-disable-line  react-hooks/exhaustive-deps

  return null
}
