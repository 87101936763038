import DE_AT001 from 'assets/sound/de/AT001.mp3'
import DE_AT002 from 'assets/sound/de/AT002.mp3'
import DE_AT003 from 'assets/sound/de/AT003.mp3'
import DE_AT004 from 'assets/sound/de/AT004.mp3'
import DE_MS001 from 'assets/sound/de/MS001.mp3'
import DE_MS00101 from 'assets/sound/de/MS00101.mp3'
import DE_MS002 from 'assets/sound/de/MS002.mp3'
import DE_H1001a from 'assets/sound/de/H1001a.mp3'
import DE_H1001b from 'assets/sound/de/H1001b.mp3'
import DE_H1001_4 from 'assets/sound/de/H1001.4.mp3'
import DE_H1003_4 from 'assets/sound/de/H1003.4.mp3'
import DE_H1003_6 from 'assets/sound/de/H1003.6.mp3'
import DE_H1005 from 'assets/sound/de/H1005.mp3'
import DE_H1005_3 from 'assets/sound/de/H1005.3.mp3'
import DE_H1005_4 from 'assets/sound/de/H1005.4.mp3'
import DE_H1005_5 from 'assets/sound/de/H1005.5.mp3'
import DE_H1006 from 'assets/sound/de/H1006.mp3'
import DE_H1006_1 from 'assets/sound/de/H1006.1.mp3'
import DE_H1006_2 from 'assets/sound/de/H1006.2.mp3'
import DE_H1006_3 from 'assets/sound/de/H1006.3.mp3'
import DE_H1007 from 'assets/sound/de/H1007.mp3'
import DE_H1007_2 from 'assets/sound/de/H1007.2.mp3'
import DE_H1007_3 from 'assets/sound/de/H1007.3.mp3'
import DE_H1007_4 from 'assets/sound/de/H1007.4.mp3'
import DE_H1008 from 'assets/sound/de/H1008.mp3'
import DE_H1008_2 from 'assets/sound/de/H1008.2.mp3'
import DE_H1009 from 'assets/sound/de/H1009.mp3'
import DE_H1009_2 from 'assets/sound/de/H1009.2.mp3'
import DE_H1015 from 'assets/sound/de/H1015.mp3'
import DE_H1015_1 from 'assets/sound/de/H1015.1.mp3'
import DE_H1015_2 from 'assets/sound/de/H1015.2.mp3'
import DE_H1015_3 from 'assets/sound/de/H1015.3.mp3'
import DE_H1015_4 from 'assets/sound/de/H1015.4.mp3'
import DE_H1015_5 from 'assets/sound/de/H1015.5.mp3'
import DE_H1015_6 from 'assets/sound/de/H1015.6.mp3'
import DE_H1016 from 'assets/sound/de/H1016.mp3'
import DE_H1016_1 from 'assets/sound/de/H1016.1.mp3'
import DE_H1016_2 from 'assets/sound/de/H1016.2.mp3'
import DE_H1016_3 from 'assets/sound/de/H1016.3.mp3'
import DE_H1018 from 'assets/sound/de/H1018.mp3'
import DE_H1018_3 from 'assets/sound/de/H1018.3.mp3'
import DE_H1018_4 from 'assets/sound/de/H1018.4.mp3'

import DE_H4001 from 'assets/sound/de/H4001.mp3'
import DE_H4002_1 from 'assets/sound/de/H4002.1.mp3'
import DE_H4002 from 'assets/sound/de/H4002.mp3'
import DE_H4003_1 from 'assets/sound/de/H4003.1.mp3'
import DE_H4003 from 'assets/sound/de/H4003.mp3'
import DE_H4004 from 'assets/sound/de/H4004.mp3'
import DE_H4005 from 'assets/sound/de/H4005.mp3'
import DE_H4006_1 from 'assets/sound/de/H4006.1.mp3'
import DE_H4006_2 from 'assets/sound/de/H4006.2.mp3'
import DE_H4006 from 'assets/sound/de/H4006.mp3'
import DE_H4007_1 from 'assets/sound/de/H4007.1.mp3'
import DE_H4007_2 from 'assets/sound/de/H4007.2.mp3'
import DE_H4007 from 'assets/sound/de/H4007.mp3'
import DE_H4008_1 from 'assets/sound/de/H4008.1.mp3'
import DE_H4008_2 from 'assets/sound/de/H4008.2.mp3'
import DE_H4008 from 'assets/sound/de/H4008.mp3'
import DE_H4009_1 from 'assets/sound/de/H4009.1.mp3'
import DE_H4009 from 'assets/sound/de/H4009.mp3'
import DE_H4010_1 from 'assets/sound/de/H4010.1.mp3'
import DE_H4010_2 from 'assets/sound/de/H4010.2.mp3'
import DE_H4011_4 from 'assets/sound/de/H4011.4.mp3'

import DE_PL001_2 from 'assets/sound/de/PL001.2.mp3'
import DE_PL001_3 from 'assets/sound/de/PL001.3.mp3'
import DE_PL001 from 'assets/sound/de/PL001.mp3'

import DE_A001 from 'assets/sound/de/A001.mp3'

import DE_CL01_1 from 'assets/sound/de/CL01.1.mp3'
import DE_CL01_2 from 'assets/sound/de/CL01.2.mp3'
import DE_CL01 from 'assets/sound/de/CL01.mp3'
import DE_CL02_1 from 'assets/sound/de/CL02.1.mp3'
import DE_CL02 from 'assets/sound/de/CL02.mp3'
import DE_CL03_1 from 'assets/sound/de/CL03.1.mp3'
import DE_CL03 from 'assets/sound/de/CL03.mp3'
import DE_CL04_1 from 'assets/sound/de/CL04.1.mp3'
import DE_CL04_2 from 'assets/sound/de/CL04.2.mp3'
import DE_CL04 from 'assets/sound/de/CL04.mp3'
import DE_CL05 from 'assets/sound/de/CL05.mp3'
import DE_CL06_1 from 'assets/sound/de/CL06.1.mp3'
import DE_CL06 from 'assets/sound/de/CL06.mp3'
import DE_CL08 from 'assets/sound/de/CL08.mp3'
import DE_CL11_1 from 'assets/sound/de/CL11.1.mp3'
import DE_CL11_2 from 'assets/sound/de/CL11.2.mp3'
import DE_CL11 from 'assets/sound/de/CL11.mp3'
import DE_CL12_1 from 'assets/sound/de/CL12.1.mp3'
import DE_CL12 from 'assets/sound/de/CL12.mp3'
import DE_CL13 from 'assets/sound/de/CL13.mp3'
import DE_CL14 from 'assets/sound/de/CL14.mp3'
import DE_E01 from 'assets/sound/de/E01.mp3'
import DE_E02 from 'assets/sound/de/E02.mp3'
import DE_E03 from 'assets/sound/de/E03.mp3'
import DE_E04 from 'assets/sound/de/E04.mp3'
import DE_E05 from 'assets/sound/de/E05.mp3'
import DE_E06 from 'assets/sound/de/E06.mp3'
import DE_E07 from 'assets/sound/de/E07.mp3'
import DE_E08 from 'assets/sound/de/E08.mp3'
import DE_E09 from 'assets/sound/de/E09.mp3'
import DE_E10 from 'assets/sound/de/E10.mp3'
import DE_E11 from 'assets/sound/de/E11.mp3'
import DE_TL01_2 from 'assets/sound/de/TL01.2.mp3'
import DE_TL01 from 'assets/sound/de/TL01.mp3'
import DE_TL02_1 from 'assets/sound/de/TL02.1.mp3'
import DE_TL02 from 'assets/sound/de/TL02.mp3'
import DE_TL03_1 from 'assets/sound/de/TL03.1.mp3'
import DE_TL04_1 from 'assets/sound/de/TL04.1.mp3'
import DE_TL04 from 'assets/sound/de/TL04.mp3'
import DE_TL05_1 from 'assets/sound/de/TL05.1.mp3'
import DE_TL05 from 'assets/sound/de/TL05.mp3'
import DE_TL06_1 from 'assets/sound/de/TL06.1.mp3'
import DE_TL06 from 'assets/sound/de/TL06.mp3'
import DE_TL07_1 from 'assets/sound/de/TL07.1.mp3'
import DE_TL07 from 'assets/sound/de/TL07.mp3'
import DE_TL08 from 'assets/sound/de/TL08.mp3'
import DE_TL09 from 'assets/sound/de/TL09.mp3'
import DE_TL10 from 'assets/sound/de/TL10.mp3'
import DE_TL11 from 'assets/sound/de/TL11.mp3'
import DE_TL12 from 'assets/sound/de/TL12.mp3'
import DE_TL13_2 from 'assets/sound/de/TL13.2.mp3'
import DE_TL13 from 'assets/sound/de/TL13.mp3'
import DE_TL14 from 'assets/sound/de/TL14.mp3'
import DE_TL15 from 'assets/sound/de/TL15.mp3'
import DE_UK01_1 from 'assets/sound/de/UK01.1.mp3'
import DE_UK01 from 'assets/sound/de/UK01.mp3'
import DE_UK02 from 'assets/sound/de/UK02.mp3'

import DE_LO01 from 'assets/sound/de/LO01.mp3'
import DE_LO02 from 'assets/sound/de/LO02.mp3'
import DE_LO02b from 'assets/sound/de/LO02b.mp3'
import DE_LO03 from 'assets/sound/de/LO03.mp3'
import DE_LO04 from 'assets/sound/de/LO04.mp3'
import DE_LO05 from 'assets/sound/de/LO05.mp3'
import DE_LO06 from 'assets/sound/de/LO06.mp3'
import DE_LO08 from 'assets/sound/de/LO08.mp3'
import DE_LO09 from 'assets/sound/de/LO09.mp3'
import DE_LO10 from 'assets/sound/de/LO10.mp3'
import DE_LO11a from 'assets/sound/de/LO11a.mp3'
import DE_LO12 from 'assets/sound/de/LO12.mp3'
import DE_LO13_1 from 'assets/sound/de/LO13.1.mp3'
import DE_LO13 from 'assets/sound/de/LO13.mp3'
import DE_LO14 from 'assets/sound/de/LO14.mp3'
import DE_LO15 from 'assets/sound/de/LO15.mp3'
import DE_LO16 from 'assets/sound/de/LO16.mp3'

import DE_AG01_1 from 'assets/sound/de/AG01.1.mp3'
import DE_AG01_2 from 'assets/sound/de/AG01.2.mp3'
import DE_AG01 from 'assets/sound/de/AG01.mp3'
import DE_AG02 from 'assets/sound/de/AG02.mp3'
import DE_AG11 from 'assets/sound/de/AG11.mp3'
import DE_AG12 from 'assets/sound/de/AG12.mp3'
import DE_AG13 from 'assets/sound/de/AG13.mp3'
import DE_AG14 from 'assets/sound/de/AG14.mp3'
import DE_AG15 from 'assets/sound/de/AG15.mp3'
import DE_AG16 from 'assets/sound/de/AG16.mp3'
import DE_AG17 from 'assets/sound/de/AG17.mp3'
import DE_AG18 from 'assets/sound/de/AG18.mp3'

import DE_BE01 from 'assets/sound/de/BE01.mp3'
import DE_BE03 from 'assets/sound/de/BE03.mp3'

import DE_SZ01 from 'assets/sound/de/SZ01.mp3'
import DE_SZ02 from 'assets/sound/de/SZ02.mp3'
import DE_SZ04 from 'assets/sound/de/SZ04.mp3'
import DE_SZ04a from 'assets/sound/de/SZ04a.mp3'
import DE_SZ05 from 'assets/sound/de/SZ05.mp3'
import DE_SZ07 from 'assets/sound/de/SZ07.mp3'
import DE_SZ08 from 'assets/sound/de/SZ08.mp3'
import DE_SZ09 from 'assets/sound/de/SZ09.mp3'
import DE_SZ10_1 from 'assets/sound/de/SZ10.01.mp3'
import DE_SZ10 from 'assets/sound/de/SZ10.mp3'

import DE_AG05a from 'assets/sound/de/AG05a.mp3'
import DE_P102_ZU_P104 from 'assets/sound/de/P102 zu P104.mp3'
import DE_P102_1 from 'assets/sound/de/P102.1.mp3'
import DE_P104_ZU_P107 from 'assets/sound/de/P104 zu P107.mp3'
import DE_P104_2 from 'assets/sound/de/P104.2.mp3'
import DE_P104_3 from 'assets/sound/de/P104.3.mp3'
import DE_P104_5 from 'assets/sound/de/P104.5.mp3'
import DE_P107_1 from 'assets/sound/de/P107.1.mp3'
import DE_P203_ZU_P201 from 'assets/sound/de/P203 zu P201.mp3'
import DE_P204_ZU_P203 from 'assets/sound/de/P204 zu P203.mp3'
import DE_P204_1 from 'assets/sound/de/P204.1.mp3'
import DE_P204_3 from 'assets/sound/de/P204.3.mp3'
import DE_P207_ZU_P204 from 'assets/sound/de/P207 zu P204.mp3'
import DE_P207_1 from 'assets/sound/de/P207.1.mp3'
import DE_P301_ZU_P303 from 'assets/sound/de/P301 zu P303.mp3'
import DE_P303_ZU_P305 from 'assets/sound/de/P303 zu P305.mp3'
import DE_P305_ZU_P308 from 'assets/sound/de/P305 zu P308.mp3'
import DE_P305_ZU_P316 from 'assets/sound/de/P305 zu P316.mp3'
import DE_P306_ZU_P314 from 'assets/sound/de/P306 zu P314.mp3'
import DE_P306_0 from 'assets/sound/de/P306.0.mp3'
import DE_P306_1 from 'assets/sound/de/P306.1.mp3'
import DE_P308_1 from 'assets/sound/de/P308.1.mp3'
import DE_P308_2 from 'assets/sound/de/P308.2.mp3'
import DE_P308_3 from 'assets/sound/de/P308.3.mp3'
import DE_P308_4 from 'assets/sound/de/P308.4.mp3'
import DE_P308a from 'assets/sound/de/P308a.mp3'
import DE_P310_ZU_P311 from 'assets/sound/de/P310 zu P311.mp3'
import DE_P314_ZU_P207 from 'assets/sound/de/P314 zu P207.mp3'
import DE_P314_1 from 'assets/sound/de/P314.1.mp3'
import DE_P314_2 from 'assets/sound/de/P314.2.mp3'
import DE_P314_3 from 'assets/sound/de/P314.3.mp3'
import DE_P316_ZU_P102 from 'assets/sound/de/P316 zu P102.mp3'
import DE_P316_1 from 'assets/sound/de/P316.1.mp3'
import DE_P316_2 from 'assets/sound/de/P316.2.mp3'
import DE_P316_3 from 'assets/sound/de/P316.3.mp3'

import EN_CL01_1 from 'assets/sound/en/CL01.1.mp3'
import EN_CL01_2 from 'assets/sound/en/CL01.2.mp3'
import EN_CL01 from 'assets/sound/en/CL01.mp3'
import EN_CL02_1 from 'assets/sound/en/CL02.1.mp3'
import EN_CL02 from 'assets/sound/en/CL02.mp3'
import EN_CL03_1 from 'assets/sound/en/CL03.1.mp3'
import EN_CL03 from 'assets/sound/en/CL03.mp3'
import EN_CL04_1 from 'assets/sound/en/CL04.1.mp3'
import EN_CL04_2 from 'assets/sound/en/CL04.2.mp3'
import EN_CL04 from 'assets/sound/en/CL04.mp3'
import EN_CL05 from 'assets/sound/en/CL05.mp3'
import EN_CL06_1 from 'assets/sound/en/CL06.1.mp3'
import EN_CL06 from 'assets/sound/en/CL06.mp3'
import EN_CL08 from 'assets/sound/en/CL08.mp3'
import EN_CL11_1 from 'assets/sound/en/CL11.1.mp3'
import EN_CL11_2 from 'assets/sound/en/CL11.2.mp3'
import EN_CL11 from 'assets/sound/en/CL11.mp3'
import EN_CL12_1 from 'assets/sound/en/CL12.1.mp3'
import EN_CL12 from 'assets/sound/en/CL12.mp3'
import EN_CL13 from 'assets/sound/en/CL13.mp3'
import EN_CL14 from 'assets/sound/en/CL14.mp3'
import EN_E01 from 'assets/sound/en/E01.mp3'
import EN_E02 from 'assets/sound/en/E02.mp3'
import EN_E03 from 'assets/sound/en/E03.mp3'
import EN_E04 from 'assets/sound/en/E04.mp3'
import EN_E05 from 'assets/sound/en/E05.mp3'
import EN_E06 from 'assets/sound/en/E06.mp3'
import EN_E07 from 'assets/sound/en/E07.mp3'
import EN_E08 from 'assets/sound/en/E08.mp3'
import EN_E09 from 'assets/sound/en/E09.mp3'
import EN_E10 from 'assets/sound/en/E10.mp3'
import EN_E11 from 'assets/sound/en/E11.mp3'
import EN_TL01_2 from 'assets/sound/en/TL01.2.mp3'
import EN_TL01 from 'assets/sound/en/TL01.mp3'
import EN_TL02_1 from 'assets/sound/en/TL02.1.mp3'
import EN_TL02 from 'assets/sound/en/TL02.mp3'
import EN_TL03_1 from 'assets/sound/en/TL03.1.mp3'
import EN_TL04_1 from 'assets/sound/en/TL04.1.mp3'
import EN_TL04 from 'assets/sound/en/TL04.mp3'
import EN_TL05_1 from 'assets/sound/en/TL05.1.mp3'
import EN_TL05 from 'assets/sound/en/TL05.mp3'
import EN_TL06_1 from 'assets/sound/en/TL06.1.mp3'
import EN_TL06 from 'assets/sound/en/TL06.mp3'
import EN_TL07_1 from 'assets/sound/en/TL07.1.mp3'
import EN_TL07 from 'assets/sound/en/TL07.mp3'
import EN_TL08 from 'assets/sound/en/TL08.mp3'
import EN_TL09 from 'assets/sound/en/TL09.mp3'
import EN_TL10 from 'assets/sound/en/TL10.mp3'
import EN_TL11 from 'assets/sound/en/TL11.mp3'
import EN_TL12 from 'assets/sound/en/TL12.mp3'
import EN_TL13_2 from 'assets/sound/en/TL13.2.mp3'
import EN_TL13 from 'assets/sound/en/TL13.mp3'
import EN_TL14 from 'assets/sound/en/TL14.mp3'
import EN_TL15 from 'assets/sound/en/TL15.mp3'
import EN_UK01_1 from 'assets/sound/en/UK01.1.mp3'
import EN_UK01 from 'assets/sound/en/UK01.mp3'
import EN_UK02 from 'assets/sound/en/UK02.mp3'

import EN_AT001 from 'assets/sound/en/AT001.mp3'
import EN_AT002 from 'assets/sound/en/AT002.mp3'
import EN_AT003 from 'assets/sound/en/AT003.mp3'
//import EN_AT004 from 'assets/sound/en/AT004.mp3'
import EN_MS001 from 'assets/sound/en/MS001.mp3'
import EN_MS00101 from 'assets/sound/en/MS00101.mp3'
import EN_MS002 from 'assets/sound/en/MS002.mp3'
import EN_H1001a from 'assets/sound/en/H1001a.mp3'
import EN_H1001b from 'assets/sound/en/H1001b.mp3'
import EN_H1001_4 from 'assets/sound/en/H1001.4.mp3'
import EN_H1003_4 from 'assets/sound/en/H1003.4.mp3'
import EN_H1003_6 from 'assets/sound/en/H1003.6.mp3'
import EN_H1005 from 'assets/sound/en/H1005.mp3'
import EN_H1005_3 from 'assets/sound/en/H1005.3.mp3'
import EN_H1005_4 from 'assets/sound/en/H1005.4.mp3'
import EN_H1005_5 from 'assets/sound/en/H1005.5.mp3'
import EN_H1006 from 'assets/sound/en/H1006.mp3'
import EN_H1006_1 from 'assets/sound/en/H1006.1.mp3'
import EN_H1006_2 from 'assets/sound/en/H1006.2.mp3'
import EN_H1006_3 from 'assets/sound/en/H1006.3.mp3'
import EN_H1007 from 'assets/sound/en/H1007.mp3'
import EN_H1007_2 from 'assets/sound/en/H1007.2.mp3'
import EN_H1007_3 from 'assets/sound/en/H1007.3.mp3'
import EN_H1007_4 from 'assets/sound/en/H1007.4.mp3'
import EN_H1008 from 'assets/sound/en/H1008.mp3'
import EN_H1008_2 from 'assets/sound/en/H1008.2.mp3'
import EN_H1009 from 'assets/sound/en/H1009.mp3'
import EN_H1009_2 from 'assets/sound/en/H1009.2.mp3'
import EN_H1015 from 'assets/sound/en/H1015.mp3'
import EN_H1015_1 from 'assets/sound/en/H1015.1.mp3'
import EN_H1015_2 from 'assets/sound/en/H1015.2.mp3'
import EN_H1015_3 from 'assets/sound/en/H1015.3.mp3'
import EN_H1015_4 from 'assets/sound/en/H1015.4.mp3'
import EN_H1015_5 from 'assets/sound/en/H1015.5.mp3'
import EN_H1015_6 from 'assets/sound/en/H1015.6.mp3'
import EN_H1016 from 'assets/sound/en/H1016.mp3'
import EN_H1016_1 from 'assets/sound/en/H1016.1.mp3'
import EN_H1016_2 from 'assets/sound/en/H1016.2.mp3'
import EN_H1016_3 from 'assets/sound/en/H1016.3.mp3'
import EN_H1018 from 'assets/sound/en/H1018.mp3'
import EN_H1018_3 from 'assets/sound/en/H1018.3.mp3'
import EN_H1018_4 from 'assets/sound/en/H1018.4.mp3'
import EN_H4001 from 'assets/sound/en/H4001.mp3'
import EN_H4002 from 'assets/sound/en/H4002.mp3'
import EN_H4002_1 from 'assets/sound/en/H4002.1.mp3'
import EN_H4003 from 'assets/sound/en/H4003.mp3'
import EN_H4003_1 from 'assets/sound/en/H4003.1.mp3'
import EN_H4004 from 'assets/sound/en/H4004.mp3'
import EN_H4005 from 'assets/sound/en/H4005.mp3'
import EN_H4006 from 'assets/sound/en/H4006.mp3'
import EN_H4006_1 from 'assets/sound/en/H4006.1.mp3'
import EN_H4006_2 from 'assets/sound/en/H4006.2.mp3'
import EN_H4007 from 'assets/sound/en/H4007.mp3'
import EN_H4007_1 from 'assets/sound/en/H4007.1.mp3'
import EN_H4007_2 from 'assets/sound/en/H4007.2.mp3'
import EN_H4008 from 'assets/sound/en/H4008.mp3'
import EN_H4008_1 from 'assets/sound/en/H4008.1.mp3'
import EN_H4008_2 from 'assets/sound/en/H4008.2.mp3'
import EN_H4009 from 'assets/sound/en/H4009.mp3'
import EN_H4009_1 from 'assets/sound/en/H4009.1.mp3'
import EN_H4010_1 from 'assets/sound/en/H4010.1.mp3'
import EN_H4010_2 from 'assets/sound/en/H4010.2.mp3'
import EN_H4011_4 from 'assets/sound/en/H4011.4.mp3'
import EN_PL001 from 'assets/sound/en/PL001.mp3'
import EN_PL001_2 from 'assets/sound/en/PL001.2.mp3'
import EN_PL001_3 from 'assets/sound/en/PL001.3.mp3'
import EN_A001 from 'assets/sound/en/A001.mp3'

import EN_LO01 from 'assets/sound/en/LO01.mp3'
import EN_LO02 from 'assets/sound/en/LO02.mp3'
import EN_LO02b from 'assets/sound/en/LO02b.mp3'
import EN_LO03 from 'assets/sound/en/LO03.mp3'
import EN_LO04 from 'assets/sound/en/LO04.mp3'
import EN_LO05 from 'assets/sound/en/LO05.mp3'
import EN_LO06 from 'assets/sound/en/LO06.mp3'
import EN_LO08 from 'assets/sound/en/LO08.mp3'
import EN_LO09 from 'assets/sound/en/LO09.mp3'
import EN_LO10 from 'assets/sound/en/LO10.mp3'
import EN_LO11a from 'assets/sound/en/LO11a.mp3'
import EN_LO12 from 'assets/sound/en/LO12.mp3'
import EN_LO13_1 from 'assets/sound/en/LO13.1.mp3'
import EN_LO13 from 'assets/sound/en/LO13.mp3'
import EN_LO14 from 'assets/sound/en/LO14.mp3'
import EN_LO15 from 'assets/sound/en/LO15.mp3'
import EN_LO16 from 'assets/sound/en/LO16.mp3'

import EN_AG01_1 from 'assets/sound/en/AG01.1.mp3'
import EN_AG01_2 from 'assets/sound/en/AG01.2.mp3'
import EN_AG01 from 'assets/sound/en/AG01.mp3'
import EN_AG02 from 'assets/sound/en/AG02.mp3'
import EN_AG11 from 'assets/sound/en/AG11.mp3'
import EN_AG12 from 'assets/sound/en/AG12.mp3'
import EN_AG13 from 'assets/sound/en/AG13.mp3'
import EN_AG14 from 'assets/sound/en/AG14.mp3'
import EN_AG15 from 'assets/sound/en/AG15.mp3'
import EN_AG16 from 'assets/sound/en/AG16.mp3'
import EN_AG17 from 'assets/sound/en/AG17.mp3'
import EN_AG18 from 'assets/sound/en/AG18.mp3'

import EN_BE01 from 'assets/sound/en/BE01.mp3'
import EN_BE03 from 'assets/sound/en/BE03.mp3'

import EN_SZ01 from 'assets/sound/en/SZ01.mp3'
import EN_SZ02 from 'assets/sound/en/SZ02.mp3'
import EN_SZ04 from 'assets/sound/en/SZ04.mp3'
import EN_SZ04a from 'assets/sound/en/SZ04a.mp3'
import EN_SZ05 from 'assets/sound/en/SZ05.mp3'
import EN_SZ07 from 'assets/sound/en/SZ07.mp3'
import EN_SZ08 from 'assets/sound/en/SZ08.mp3'
import EN_SZ09 from 'assets/sound/en/SZ09.mp3'
import EN_SZ10_1 from 'assets/sound/en/SZ10.1.mp3'
import EN_SZ10 from 'assets/sound/en/SZ10.mp3'

import EN_AG05a from 'assets/sound/en/AG05a.mp3'
import EN_P102_ZU_P104 from 'assets/sound/en/P102 zu P104.mp3'
import EN_P102_1 from 'assets/sound/en/P102.1.mp3'
import EN_P104_ZU_P107 from 'assets/sound/en/P104 zu P107.mp3'
import EN_P104_2 from 'assets/sound/en/P104.2.mp3'
import EN_P104_3 from 'assets/sound/en/P104.3.mp3'
import EN_P104_5 from 'assets/sound/en/P104.5.mp3'
import EN_P107_1 from 'assets/sound/en/P107.1.mp3'
import EN_P203_ZU_P201 from 'assets/sound/en/P203 zu P201.mp3'
import EN_P204_ZU_P203 from 'assets/sound/en/P204 zu P203.mp3'
import EN_P204_1 from 'assets/sound/en/P204.1.mp3'
import EN_P204_3 from 'assets/sound/en/P204.3.mp3'
import EN_P207_ZU_P204 from 'assets/sound/en/P207 zu P204.mp3'
import EN_P207_1 from 'assets/sound/en/P207.1.mp3'
import EN_P301_ZU_P303 from 'assets/sound/en/P301 zu P303.mp3'
import EN_P303_ZU_P305 from 'assets/sound/en/P303 zu P305.mp3'
import EN_P305_ZU_P308 from 'assets/sound/en/P305 zu P308.mp3'
import EN_P305_ZU_P316 from 'assets/sound/en/P305 zu P316.mp3'
import EN_P306_ZU_P314 from 'assets/sound/en/P306 zu P314.mp3'
import EN_P306_0 from 'assets/sound/en/P306.0.mp3'
import EN_P306_1 from 'assets/sound/en/P306.1.mp3'
import EN_P308_1 from 'assets/sound/en/P308.1.mp3'
import EN_P308_2 from 'assets/sound/en/P308.2.mp3'
import EN_P308_3 from 'assets/sound/en/P308.3.mp3'
import EN_P308_4 from 'assets/sound/en/P308.4.mp3'
import EN_P308a from 'assets/sound/en/P308a.mp3'
import EN_P310_ZU_P311 from 'assets/sound/en/P310 zu P311.mp3'
import EN_P314_ZU_P207 from 'assets/sound/en/P314 zu P207.mp3'
import EN_P314_1 from 'assets/sound/en/P314.1.mp3'
import EN_P314_2 from 'assets/sound/en/P314.2.mp3'
import EN_P314_3 from 'assets/sound/en/P314.3.mp3'
import EN_P316_ZU_P102 from 'assets/sound/en/P316 zu P102.mp3'
import EN_P316_1 from 'assets/sound/en/P316.1.mp3'
import EN_P316_2 from 'assets/sound/en/P316.2.mp3'
import EN_P316_3 from 'assets/sound/en/P316.3.mp3'


export const sounds = {
  de: {
    AT001: DE_AT001,
    AT002: DE_AT002,
    AT003: DE_AT003,
    AT004: DE_AT004,
    MS001: DE_MS001,
    MS00101: DE_MS00101,
    MS002: DE_MS002,
    H1001a: DE_H1001a,
    H1001b: DE_H1001b,
    'H1001.4': DE_H1001_4,
    'H1003.4': DE_H1003_4,
    'H1003.6': DE_H1003_6,
    H1005: DE_H1005,
    'H1005.3': DE_H1005_3,
    'H1005.4': DE_H1005_4,
    'H1005.5': DE_H1005_5,
    H1006: DE_H1006,
    'H1006.1': DE_H1006_1,
    'H1006.2': DE_H1006_2,
    'H1006.3': DE_H1006_3,
    H1007: DE_H1007,
    'H1007.2': DE_H1007_2,
    'H1007.3': DE_H1007_3,
    'H1007.4': DE_H1007_4,
    H1008: DE_H1008,
    'H1008.2': DE_H1008_2,
    H1009: DE_H1009,
    'H1009.2': DE_H1009_2,
    'H1015': DE_H1015,
    'H1015.1': DE_H1015_1,
    'H1015.2': DE_H1015_2,
    'H1015.3': DE_H1015_3,
    'H1015.4': DE_H1015_4,
    'H1015.5': DE_H1015_5,
    'H1015.6': DE_H1015_6,
    H1016: DE_H1016,
    'H1016.1': DE_H1016_1,
    'H1016.2': DE_H1016_2,
    'H1016.3': DE_H1016_3,
    H1018: DE_H1018,
    'H1018.3': DE_H1018_3,
    'H1018.4': DE_H1018_4,
    H4001: DE_H4001,
    H4002: DE_H4002,
    'H4002.1': DE_H4002_1,
    H4003: DE_H4003,
    'H4003.1': DE_H4003_1,
    H4004: DE_H4004,
    H4005: DE_H4005,
    H4006: DE_H4006,
    'H4006.1': DE_H4006_1,
    'H4006.2': DE_H4006_2,
    H4007: DE_H4007,
    'H4007.1': DE_H4007_1,
    'H4007.2': DE_H4007_2,
    H4008: DE_H4008,
    'H4008.1': DE_H4008_1,
    'H4008.2': DE_H4008_2,
    H4009: DE_H4009,
    'H4009.1': DE_H4009_1,
    'H4010.1': DE_H4010_1,
    'H4010.2': DE_H4010_2,
    'H4011.4': DE_H4011_4,
    PL001: DE_PL001,
    'PL001.2': DE_PL001_2,
    'PL001.3': DE_PL001_3,
    A001: DE_A001,
    'CL01.1': DE_CL01_1,
    'CL01.2': DE_CL01_2,
    CL01: DE_CL01,
    'CL02.1': DE_CL02_1,
    CL02: DE_CL02,
    'CL03.1': DE_CL03_1,
    CL03: DE_CL03,
    'CL04.1': DE_CL04_1,
    'CL04.2': DE_CL04_2,
    CL04: DE_CL04,
    CL05: DE_CL05,
    'CL06.1': DE_CL06_1,
    CL06: DE_CL06,
    CL08: DE_CL08,
    'CL11.1': DE_CL11_1,
    'CL11.2': DE_CL11_2,
    CL11: DE_CL11,
    'CL12.1': DE_CL12_1,
    CL12: DE_CL12,
    CL13: DE_CL13,
    CL14: DE_CL14,
    E01: DE_E01,
    E02: DE_E02,
    E03: DE_E03,
    E04: DE_E04,
    E05: DE_E05,
    E06: DE_E06,
    E07: DE_E07,
    E08: DE_E08,
    E09: DE_E09,
    E10: DE_E10,
    E11: DE_E11,
    'TL01.2': DE_TL01_2,
    TL01: DE_TL01,
    'TL02.1': DE_TL02_1,
    TL02: DE_TL02,
    'TL03.1': DE_TL03_1,
    'TL04.1': DE_TL04_1,
    TL04: DE_TL04,
    'TL05.1': DE_TL05_1,
    TL05: DE_TL05,
    'TL06.1': DE_TL06_1,
    TL06: DE_TL06,
    'TL07.1': DE_TL07_1,
    TL07: DE_TL07,
    TL08: DE_TL08,
    TL09: DE_TL09,
    TL10: DE_TL10,
    TL11: DE_TL11,
    TL12: DE_TL12,
    'TL13.2': DE_TL13_2,
    TL13: DE_TL13,
    TL14: DE_TL14,
    TL15: DE_TL15,
    'UK01.1': DE_UK01_1,
    UK01: DE_UK01,
    UK02: DE_UK02,
    LO01: DE_LO01,
    LO02: DE_LO02,
    LO02b: DE_LO02b,
    LO03: DE_LO03,
    LO04: DE_LO04,
    LO05: DE_LO05,
    LO06: DE_LO06,
    LO08: DE_LO08,
    LO09: DE_LO09,
    LO10: DE_LO10,
    LO11a: DE_LO11a,
    LO12: DE_LO12,
    'LO13.1': DE_LO13_1,
    LO13: DE_LO13,
    LO14: DE_LO14,
    LO15: DE_LO15,
    LO16: DE_LO16,
    'AG01.1': DE_AG01_1,
    'AG01_2': DE_AG01_2,
    AG01: DE_AG01,
    AG02: DE_AG02,
    AG11: DE_AG11,
    AG12: DE_AG12,
    AG13: DE_AG13,
    AG14: DE_AG14,
    AG15: DE_AG15,
    AG16: DE_AG16,
    AG17: DE_AG17,
    AG18: DE_AG18,
    BE01: DE_BE01,
    BE03: DE_BE03,
    SZ01: DE_SZ01,
    SZ02: DE_SZ02,
    SZ04: DE_SZ04,
    SZ04a: DE_SZ04a,
    SZ05: DE_SZ05,
    SZ07: DE_SZ07,
    SZ08: DE_SZ08,
    SZ09: DE_SZ09,
    'SZ10_1': DE_SZ10_1,
    SZ10: DE_SZ10,
    AG05a: DE_AG05a,
    'P102 zu P104': DE_P102_ZU_P104,
    'P102.1': DE_P102_1,
    'P104 zu P107': DE_P104_ZU_P107,
    'P104.2': DE_P104_2,
    'P104.3': DE_P104_3,
    'P104.5': DE_P104_5,
    'P107.1': DE_P107_1,
    'P203 zu P201': DE_P203_ZU_P201,
    'P204 zu P203': DE_P204_ZU_P203,
    'P204.1': DE_P204_1,
    'P204.3': DE_P204_3,
    'P207 zu P204': DE_P207_ZU_P204,
    'P207.1': DE_P207_1,
    'P301 zu P303': DE_P301_ZU_P303,
    'P303 zu P305': DE_P303_ZU_P305,
    'P305 zu P308': DE_P305_ZU_P308,
    'P305 zu P316': DE_P305_ZU_P316,
    'P306 zu P314': DE_P306_ZU_P314,
    'P306.0': DE_P306_0,
    'P306.1': DE_P306_1,
    'P308.1': DE_P308_1,
    'P308.2': DE_P308_2,
    'P308.3': DE_P308_3,
    'P308.4': DE_P308_4,
    'P308a': DE_P308a,
    'P310 zu P311': DE_P310_ZU_P311,
    'P314 zu P207': DE_P314_ZU_P207,
    'P314.1': DE_P314_1,
    'P314.2': DE_P314_2,
    'P314.3': DE_P314_3,
    'P316 zu P102': DE_P316_ZU_P102,
    'P316.1': DE_P316_1,
    'P316.2': DE_P316_2,
    'P316.3': DE_P316_3
  },
  en: {
    'CL01.1': EN_CL01_1,
    'CL01.2': EN_CL01_2,
    CL01: EN_CL01,
    'CL02.1': EN_CL02_1,
    CL02: EN_CL02,
    'CL03.1': EN_CL03_1,
    CL03: EN_CL03,
    'CL04.1': EN_CL04_1,
    'CL04.2': EN_CL04_2,
    CL04: EN_CL04,
    CL05: EN_CL05,
    'CL06.1': EN_CL06_1,
    CL06: EN_CL06,
    CL08: EN_CL08,
    'CL11.1': EN_CL11_1,
    'CL11.2': EN_CL11_2,
    CL11: EN_CL11,
    'CL12.1': EN_CL12_1,
    CL12: EN_CL12,
    CL13: EN_CL13,
    CL14: EN_CL14,
    E01: EN_E01,
    E02: EN_E02,
    E03: EN_E03,
    E04: EN_E04,
    E05: EN_E05,
    E06: EN_E06,
    E07: EN_E07,
    E08: EN_E08,
    E09: EN_E09,
    E10: EN_E10,
    E11: EN_E11,
    'TL01.2': EN_TL01_2,
    TL01: EN_TL01,
    'TL02.1': EN_TL02_1,
    TL02: EN_TL02,
    'TL03.1': EN_TL03_1,
    'TL04.1': EN_TL04_1,
    TL04: EN_TL04,
    'TL05.1': EN_TL05_1,
    TL05: EN_TL05,
    'TL06.1': EN_TL06_1,
    TL06: EN_TL06,
    'TL07.1': EN_TL07_1,
    TL07: EN_TL07,
    TL08: EN_TL08,
    TL09: EN_TL09,
    TL10: EN_TL10,
    TL11: EN_TL11,
    TL12: EN_TL12,
    'TL13.2': EN_TL13_2,
    TL13: EN_TL13,
    TL14: EN_TL14,
    TL15: EN_TL15,
    'UK01.1': EN_UK01_1,
    UK01: EN_UK01,
    UK02: EN_UK02,
    AT001: EN_AT001,
    AT002: EN_AT002,
    AT003: EN_AT003,
    //AT004: EN_AT004,
    MS001: EN_MS001,
    MS00101: EN_MS00101,
    MS002: EN_MS002,
    H1001a: EN_H1001a,
    H1001b: EN_H1001b,
    'H1001.4': EN_H1001_4,
    'H1003.4': EN_H1003_4,
    'H1003.6': EN_H1003_6,
    H1005: EN_H1005,
    'H1005.3': EN_H1005_3,
    'H1005.4': EN_H1005_4,
    'H1005.5': EN_H1005_5,
    H1006: EN_H1006,
    'H1006.1': EN_H1006_1,
    'H1006.2': EN_H1006_2,
    'H1006.3': EN_H1006_3,
    'H1007': EN_H1007,
    'H1007.2': EN_H1007_2,
    'H1007.3': EN_H1007_3,
    'H1007.4': EN_H1007_4,
    H1008: EN_H1008,
    'H1008.2': EN_H1008_2,
    H1009: EN_H1009,
    'H1009.2': EN_H1009_2,
    H1015: EN_H1015,
    'H1015.1': EN_H1015_1,
    'H1015.2': EN_H1015_2,
    'H1015.3': EN_H1015_3,
    'H1015.4': EN_H1015_4,
    'H1015.5': EN_H1015_5,
    'H1015.6': EN_H1015_6,
    H1016: EN_H1016,
    'H1016.1': EN_H1016_1,
    'H1016.2': EN_H1016_2,
    'H1016.3': EN_H1016_3,
    H1018: EN_H1018,
    'H1018.3': EN_H1018_3,
    'H1018.4': EN_H1018_4,
    H4001: EN_H4001,
    H4002: EN_H4002,
    'H4002.1': EN_H4002_1,
    H4003: EN_H4003,
    'H4003.1': EN_H4003_1,
    H4004: EN_H4004,
    H4005: EN_H4005,
    H4006: EN_H4006,
    'H4006.1': EN_H4006_1,
    'H4006.2': EN_H4006_2,
    H4007: EN_H4007,
    'H4007.1': EN_H4007_1,
    'H4007.2': EN_H4007_2,
    H4008: EN_H4008,
    'H4008.1': EN_H4008_1,
    'H4008.2': EN_H4008_2,
    H4009: EN_H4009,
    'H4009.1': EN_H4009_1,
    'H4010.1': EN_H4010_1,
    'H4010.2': EN_H4010_2,
    'H4011.4': EN_H4011_4,
    PL001: EN_PL001,
    'PL001.2': EN_PL001_2,
    'PL001.3': EN_PL001_3,
    A001: EN_A001,
    LO01: EN_LO01,
    LO02: EN_LO02,
    LO02b: EN_LO02b,
    LO03: EN_LO03,
    LO04: EN_LO04,
    LO05: EN_LO05,
    LO06: EN_LO06,
    LO08: EN_LO08,
    LO09: EN_LO09,
    LO10: EN_LO10,
    LO11a: EN_LO11a,
    LO12: EN_LO12,
    'LO13.1': EN_LO13_1,
    LO13: EN_LO13,
    LO14: EN_LO14,
    LO15: EN_LO15,
    LO16: EN_LO16,
    'AG01.1': EN_AG01_1,
    'AG01_2': EN_AG01_2,
    AG01: EN_AG01,
    AG02: EN_AG02,
    AG11: EN_AG11,
    AG12: EN_AG12,
    AG13: EN_AG13,
    AG14: EN_AG14,
    AG15: EN_AG15,
    AG16: EN_AG16,
    AG17: EN_AG17,
    AG18: EN_AG18,
    BE01: EN_BE01,
    BE03: EN_BE03,
    SZ01: EN_SZ01,
    SZ02: EN_SZ02,
    SZ04: EN_SZ04,
    SZ04a: EN_SZ04a,
    SZ05: EN_SZ05,
    SZ07: EN_SZ07,
    SZ08: EN_SZ08,
    SZ09: EN_SZ09,
    'SZ10_1': EN_SZ10_1,
    SZ10: EN_SZ10,
    AG05a: EN_AG05a,
    'P102 zu P104': EN_P102_ZU_P104,
    'P102.1': EN_P102_1,
    'P104 zu P107': EN_P104_ZU_P107,
    'P104.2': EN_P104_2,
    'P104.3': EN_P104_3,
    'P104.5': EN_P104_5,
    'P107.1': EN_P107_1,
    'P203 zu P201': EN_P203_ZU_P201,
    'P204 zu P203': EN_P204_ZU_P203,
    'P204.1': EN_P204_1,
    'P204.3': EN_P204_3,
    'P207 zu P204': EN_P207_ZU_P204,
    'P207.1': EN_P207_1,
    'P301 zu P303': EN_P301_ZU_P303,
    'P303 zu P305': EN_P303_ZU_P305,
    'P305 zu P308': EN_P305_ZU_P308,
    'P305 zu P316': EN_P305_ZU_P316,
    'P306 zu P314': EN_P306_ZU_P314,
    'P306.0': EN_P306_0,
    'P306.1': EN_P306_1,
    'P308.1': EN_P308_1,
    'P308.2': EN_P308_2,
    'P308.3': EN_P308_3,
    'P308.4': EN_P308_4,
    'P308a': EN_P308a,
    'P310 zu P311': EN_P310_ZU_P311,
    'P314 zu P207': EN_P314_ZU_P207,
    'P314.1': EN_P314_1,
    'P314.2': EN_P314_2,
    'P314.3': EN_P314_3,
    'P316 zu P102': EN_P316_ZU_P102,
    'P316.1': EN_P316_1,
    'P316.2': EN_P316_2,
    'P316.3': EN_P316_3
  }
}
