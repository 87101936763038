import {useMemo} from 'react'

export default function useIsPoi() {
  const isPoi = useMemo(() => {
    if (window?.blazeIT?.getComponent('Popup Viewer').get('inView')) {
      return true
    } else if (window?.blazeIT?.getComponent('WebFramePopup').get('inView') && window?.blazeIT?.getComponent('WebFramePopup').get('url').includes('.pdf')) {
      return true
    } else {
      return false
    }
  }, [window?.blazeIT?.getComponent('Popup Viewer').get('inView'), window?.blazeIT?.getComponent('WebFramePopup').get('inView')])

  return isPoi
}
