import SvgIcon from '@mui/material/SvgIcon';

export default function FullscreenEnabledIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nav-left-icon-exit-fullscreen" transform="translate(-7.000000, -7.000000)" fillRule="nonzero">
            <g id="Group" transform="translate(10.000000, 10.000000)" fill={props.color}>
                <path d="M28,19.6 L28,26.6 C28,26.97128 27.85244,27.32744 27.58994,27.58994 C27.32744,27.85244 26.97128,28 26.6,28 L19.6,28 L19.6,25.2 L25.2,25.2 L25.2,19.6 L28,19.6 Z M2.8,19.6 L2.8,25.2 L8.4,25.2 L8.4,28 L1.4,28 C1.028692,28 0.672602,27.85244 0.410046,27.58994 C0.147504,27.32744 0,26.97128 0,26.6 L0,19.6 L2.8,19.6 Z M8.4,0 L8.4,2.8 L2.8,2.8 L2.8,8.4 L0,8.4 L0,1.4 C0,1.028692 0.147504,0.672602 0.410046,0.410046 C0.672602,0.147504 1.028692,0 1.4,0 L8.4,0 Z M26.6,0 C26.97128,0 27.32744,0.147504 27.58994,0.410046 C27.85244,0.672602 28,1.028692 28,1.4 L28,8.4 L25.2,8.4 L25.2,2.8 L19.6,2.8 L19.6,0 L26.6,0 Z" id="Shape"></path>
            </g>
            <polygon id="Path" fill="#FFFFFF" points="42 37 36 42 7 13 12 7"></polygon>
            <polygon id="Path" fill={props.color} points="38.7750343 37.0835468 36.8353909 39 9.40466392 11.9164532 11.3443073 10"></polygon>
        </g>
      </g>
    </SvgIcon>
  );
}


FullscreenEnabledIcon.defaultProps = {
  color: '#0F0F0F',
  width: '35px',
  height: '35px',
};