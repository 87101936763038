import {useMemo} from 'react'
export default function useIsProduction() {
  const isProduction = useMemo(() => {
    return (
      window.location.hostname === 'factory-tour.striebelundjohn.com' ||
      window.location.hostname === 'factory-tour-stotz-kontakt.abb.com' ||
      window.location.hostname === 'factory-tour.busch-jaeger.de'
    )
  }, [])

  return isProduction
}
