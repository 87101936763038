import React, {useMemo} from 'react'
import {useAtomValue} from 'jotai/utils'
import {useSelector} from 'react-redux'

import {tourMachineAtom, shouldPlaySoundAtom, shouldPlaySoundPoiAtom} from 'components/jotai'
import {useActor} from '@xstate/react'
import MainAudio from './MainAudio'
import {getSound} from 'components/TourControls/duck'

export default function Sound() {
  const tourService = useAtomValue(tourMachineAtom)
  const shouldPlaySound = useAtomValue(shouldPlaySoundAtom)
  const shouldPlaySoundPoi = useAtomValue(shouldPlaySoundPoiAtom)
  const soundEnabled = useSelector(getSound)
  const [tour] = useActor(tourService)

  return (
    <>
      {soundEnabled && tour.context.soundTitle && shouldPlaySound && <MainAudio sound={tour.context.soundTitle} poi={false} />}
      {soundEnabled && tour.context.soundTitlePoi && shouldPlaySoundPoi && <MainAudio sound={tour.context.soundTitlePoi} poi={true} />}
    </>
  )
}
