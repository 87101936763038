import React, {memo} from 'react'
import {getFormat} from 'components/Caption/components/Time'
import Caption from 'components/Caption/components/Caption'
import {useAtomValue} from 'jotai/utils'
import {audioTimePoiAtom, audioTimeAtom} from 'components/jotai'

function getFormatFromList(captions) {
  if (!captions.length) return 'mm:ss'
  return getFormat(captions[captions.length - 1].end)
}

function shouldUseEnd(end, index, captions) {
  const nextCaption = captions[index + 1]
  if (!nextCaption) {
    return false // The end of the video
  }
  // Captions usually follow the rule where if they
  // are continous, the next caption starts at the next millisecoond
  return end !== nextCaption.start && end + 1 !== nextCaption.start
}

export default memo(function Captions({captions, poi = false}) {
  const format = getFormatFromList(captions)
  const {time} = useAtomValue(poi ? audioTimePoiAtom : audioTimeAtom)

  const caption = captions.find(el => {
    return el.startTime < time * 1000 && el.endTime >= time * 1000
  })

  if (!caption) {
    return null
  }

  const {endTime, ...rest} = caption
  const endToUse = shouldUseEnd(endTime, time, captions) ? endTime : undefined

  return <Caption key={time} endTime={endToUse} {...rest} format={format} />
})
