import React, {useMemo, useState, useEffect, useCallback} from 'react'
import {useAtom} from 'jotai'
import {useAtomValue} from 'jotai/utils'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import {useSpring, animated} from 'react-spring'
import {FormattedMessage} from 'react-intl'
import Tippy from '@tippyjs/react/headless'

import PreviousIcon from 'components/Icons/PreviousIcon'
import NextIcon from 'components/Icons/NextIcon'
import PreviousItemIcon from 'components/Icons/PreviousItemIcon'
import NextItemIcon from 'components/Icons/NextItemIcon'
import PlayIcon from 'components/Icons/PlayIcon'
import PauseIcon from 'components/Icons/PauseIcon'
import StepBackIcon from 'components/Icons/StepBackIcon'
import StepForwardIcon from 'components/Icons/StepForwardIcon'
import {
  dimensionsAtom,
  enableTourNaviAtom,
  isEndVideoAtom,
  isPausedAtom,
  openPopupViewerAtom,
  sidebarAtom,
  tourMachineAtom,
  showPlaybackBarPoiAtom,
  audioControlsAtom,
  audioTimeAtom,
  disableControlsAtom
} from 'components/jotai'
import {useActor} from '@xstate/react'
import Chatbot from 'components/Chatbot'
import {is360Video, isPanorama} from 'components/Navigation/duck'

export default function TourControls() {
  const size = 48
  const sidebarOpen = useAtomValue(sidebarAtom)
  const visible = useAtomValue(enableTourNaviAtom)
  const openPopupViewer = useAtomValue(openPopupViewerAtom)
  const [isPaused, setIsPaused] = useAtom(isPausedAtom)
  const [isEndVideo, setIsEndVideo] = useAtom(isEndVideoAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const showPlaybackBarPoi = useAtomValue(showPlaybackBarPoiAtom)
  const soundControls = useAtomValue(audioControlsAtom)
  const {finished} = useAtomValue(audioTimeAtom)
  const disableControls = useAtomValue(disableControlsAtom)

  const [tour, sendTour] = useActor(tourService)

  const {width, height} = useAtomValue(dimensionsAtom)
  const duration = useMemo(() => {
    return tour?.context?.duration || 0
  }, [tour?.context?.duration])
  const [isVideo, setIsVideo] = useState(false)
  const [isFahrt, setIsFahrt] = useState(false)

  useEffect(() => {
    if (tour.context.szene) {
      const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
        tour.context.szene,
      )
      if (playlistItem) {
        setIsVideo(!isPanorama(playlistItem))
        if (is360Video(playlistItem)) {
          const media = window?.blazeIT?.getCurrentMedia()
          if (media) {
            const tags = media.get('data')?.tags
            if (tags && Array.isArray(tags)) {
              setIsFahrt(tags.find(el => el === 'Fahrt'))
            } else {
              setIsFahrt(true)
            }
          } else {
            setIsFahrt(!isPanorama(playlistItem))
          }
        } else {
          setIsFahrt(!isPanorama(playlistItem))
        }
      } else {
        setIsFahrt(false)
      }
    } else {
      setIsFahrt(false)
    }
  }, [tour.context.szene])

  const handlePrevious = () => {
    sendTour('BACK')
  }

  const handleNext = useCallback(() => {
    sendTour('NEXT')
  }, [sendTour])

  const handleStart = useCallback(() => {
    sendTour('GO_TO_START')
  }, [sendTour])

  const handleEnd = useCallback(() => {
    sendTour('GO_TO_END')
  }, [sendTour])

  const handleStepBack = () => {
    setIsEndVideo(false)
    const currentTime =
      window?.blazeIT?.getCurrentMedia().get('currentTime') * 1000
    window?.blazeIT?.setComponentAttributes(window?.blazeIT?.getCurrentMedia(), {
      currentTime: Math.max(0, currentTime - 10000) / 1000,
    })
    if (!finished) {
      soundControls?.seek(Math.max(0, currentTime - 10000) / 1000)
    }
    if (
      window?.blazeIT?.getComponentAttributes('Main Viewer')?.playbackState ===
      'paused'
    ) {
      setIsPaused(false)
      window?.blazeIT?.getComponent('Main Viewer').play()
      if (!finished) {
        soundControls?.play()
      }
    }
  }

  const handleStepForward = () => {
    const currentTime =
      window?.blazeIT?.getCurrentMedia().get('currentTime') * 1000

    const newTime = Math.min(duration * 1000 - 2000, currentTime + 10000) / 1000
    window?.blazeIT?.setComponentAttributes(window?.blazeIT?.getCurrentMedia(), {
      currentTime: newTime,
    })

    if (newTime < duration) {
      setIsEndVideo(false)
    }

    if (!finished) {
      soundControls?.seek(Math.min(duration * 1000, currentTime + 10000) / 1000)
    }
    if (
      window?.blazeIT?.getComponentAttributes('Main Viewer')?.playbackState ===
      'paused'
    ) {
      setIsPaused(false)
      window?.blazeIT?.getComponent('Main Viewer').play()
      if (!finished) {
        soundControls?.play()
      }
    }
  }

  const handlePause = () => {
    if (isPaused) {
      const currentTime = window?.blazeIT?.getCurrentMedia().get('currentTime')

      if (currentTime < duration) {
        setIsPaused(false)
        window?.blazeIT?.getComponent('Main Viewer').play()
        if (!finished) {
          soundControls?.play()
        }
      }
    } else {
      setIsPaused(true)
      if (
        window?.blazeIT?.getComponentAttributes('Main Viewer')?.playbackState ===
        'playing'
      ) {
        window?.blazeIT?.getComponent('Main Viewer').pause()
      }
      if (!finished) {
        soundControls?.pause()
      }
    }
  }

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'ArrowLeft') {
        handleStart()
      } else if (event.key === 'ArrowRight') {
        handleEnd()
      }
    },
    [handleStart, handleEnd],
  )

  useEffect(() => {
    let iframe = document.getElementById('tourFrame')
    if (iframe) {
      document.addEventListener('keydown', handleKeyPress)
      iframe.contentWindow.document.addEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const items = useMemo(() => {
    let items = {}
    if (visible && !openPopupViewer) {
      items['previous'] = tour?.context?.previousTarget
      items['start'] = isVideo
      items['stepBack'] = isVideo
      items['play/pause'] = !isEndVideo
      items['stepForward'] = isVideo && !isEndVideo
      items['end'] = isVideo && !isEndVideo
      items['next'] = tour?.context?.nextTarget
    }
    return items
  }, [isEndVideo, openPopupViewer, tour, isVideo, visible])

  const previousStyles = useSpring({opacity: items['previous'] && !disableControls ? 1 : 0.2})
  const startStyles = useSpring({opacity: items['start'] ? 1 : 0.2})
  const stepBackStyles = useSpring({opacity: items['stepBack'] ? 1 : 0.2})
  const playStyles = useSpring({opacity: items['play/pause'] ? 1 : 0.2})
  const stepForwardStyles = useSpring({opacity: items['stepForward'] ? 1 : 0.2})
  const endStyles = useSpring({opacity: items['end'] ? 1 : 0.2})
  const nextStyles = useSpring({opacity: items['next'] && !disableControls ? 1 : 0.2})

  if (!window?.blazeIT?.variables?.tourStarted || showPlaybackBarPoi) {
    return null
  }

  const AnimatedGrid = animated(Grid)

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: sidebarOpen ? 88 : 0,
          zIndex: 1200,
          transform: 'translate(0, -50%)',
          pointerEvents: 'none',
        }}
      >
        <AnimatedGrid item style={previousStyles}>
          <Tippy
            render={attrs => (
              <div
                className="box"
                style={{
                  backgroundColor: '#FFF',
                  padding: '2px 4px',
                  display: items['previous'] && !disableControls ? 'block' : 'none',
                }}
                tabIndex="-1"
                {...attrs}
              >
                <FormattedMessage
                  id="tour.controls.previous"
                  defaultMessage="vorige Station"
                />
              </div>
            )}
          >
            <IconButton
              onClick={items['previous'] && !disableControls ? handlePrevious : null}
              edge="start"
              sx={{pointerEvents: items['previous'] && !disableControls ? 'auto' : 'none', cursor: items['previous'] && !disableControls ? 'pointer' : 'not-allowed'}}
            >
              <PreviousItemIcon width={45} height={90} />
            </IconButton>
          </Tippy>
        </AnimatedGrid>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: height > 600 ? 20 : 0,
          right: 10,
          left: sidebarOpen ? 98 : 10,
          zIndex: 1200,
          pointerEvents: 'none',
        }}
      >
        <Grid container sx={{alignItems: 'center'}} direction="row-reverse">
          <Grid
            item
            style={{
              flexBasis: width <= 600 ? '100%' : '90px',
            }}
          >
            <Chatbot />
          </Grid>
          <Grid
            item
            style={{flex: 1, paddingLeft: '10px', paddingRight: '10px'}}
          >
            {isFahrt && (
              <Grid
                container
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {Object.keys(items)
                  .filter(key => key !== 'previous' && key !== 'next')
                  .map(key => {
                    const value = items[key]
                    const styles =
                      key === 'start'
                        ? startStyles
                        : key === 'end'
                        ? endStyles
                        : key === 'stepBack'
                        ? stepBackStyles
                        : key === 'stepForward'
                        ? stepForwardStyles
                        : playStyles
                    return (
                      <AnimatedGrid item style={styles} key={key}>
                        {key === 'start' && (
                          <Tippy
                            render={attrs => (
                              <div
                                className="box"
                                style={{
                                  backgroundColor: '#FFF',
                                  padding: '2px 4px',
                                  display: items['next'] ? 'block' : 'none',
                                }}
                                tabIndex="-1"
                                {...attrs}
                              >
                                <FormattedMessage
                                  id="tour.controls.start"
                                  defaultMessage="zum Anfang"
                                />
                              </div>
                            )}
                          >
                            <IconButton onClick={value ? handleStart : null} sx={{pointerEvents: 'auto'}}>
                              <PreviousIcon width={size} height={size} />
                            </IconButton>
                          </Tippy>
                        )}
                        {key === 'stepBack' && (
                          <Tippy
                            render={attrs => (
                              <div
                                className="box"
                                style={{
                                  backgroundColor: '#FFF',
                                  padding: '2px 4px',
                                  display: items['next'] ? 'block' : 'none',
                                }}
                                tabIndex="-1"
                                {...attrs}
                              >
                                <FormattedMessage
                                  id="tour.controls.backward"
                                  defaultMessage="10 Sek. zurück springen"
                                />
                              </div>
                            )}
                          >
                            <IconButton onClick={value ? handleStepBack : null} sx={{pointerEvents: 'auto'}}>
                              <StepBackIcon
                                width={size * 0.8}
                                height={size * 0.8}
                              />
                            </IconButton>
                          </Tippy>
                        )}
                        {key === 'play/pause' && (
                          <Tippy
                            render={attrs => (
                              <div
                                className="box"
                                style={{
                                  backgroundColor: '#FFF',
                                  padding: '2px 4px',
                                  display: items['next'] ? 'block' : 'none',
                                }}
                                tabIndex="-1"
                                {...attrs}
                              >
                                <FormattedMessage
                                  id="tour.controls.play"
                                  defaultMessage="Anhalten/Wiedergabe"
                                />
                              </div>
                            )}
                          >
                            <IconButton
                              onClick={!isEndVideo ? handlePause : null}
                              sx={{pointerEvents: 'auto'}}
                            >
                              {isPaused && (
                                <PlayIcon
                                  width={size * 1.5}
                                  height={size * 1.5}
                                />
                              )}
                              {!isPaused && (
                                <PauseIcon
                                  width={size * 1.5}
                                  height={size * 1.5}
                                />
                              )}
                            </IconButton>
                          </Tippy>
                        )}
                        {key === 'stepForward' && (
                          <Tippy
                            render={attrs => (
                              <div
                                className="box"
                                style={{
                                  backgroundColor: '#FFF',
                                  padding: '2px 4px',
                                  display: items['next'] ? 'block' : 'none',
                                }}
                                tabIndex="-1"
                                {...attrs}
                              >
                                <FormattedMessage
                                  id="tour.controls.forward"
                                  defaultMessage="10 Sek. vorwärts springen"
                                />
                              </div>
                            )}
                          >
                            <IconButton
                              onClick={value ? handleStepForward : null}
                              sx={{pointerEvents: 'auto'}}
                            >
                              <StepForwardIcon
                                width={size * 0.8}
                                height={size * 0.8}
                              />
                            </IconButton>
                          </Tippy>
                        )}
                        {key === 'end' && (
                          <Tippy
                            render={attrs => (
                              <div
                                className="box"
                                style={{
                                  backgroundColor: '#FFF',
                                  padding: '2px 4px',
                                  display: items['next'] ? 'block' : 'none',
                                }}
                                tabIndex="-1"
                                {...attrs}
                              >
                                <FormattedMessage
                                  id="tour.controls.end"
                                  defaultMessage="zum Ende"
                                />
                              </div>
                            )}
                          >
                            <IconButton onClick={value ? handleEnd : null} sx={{pointerEvents: 'auto'}}>
                              <NextIcon width={size} height={size} />
                            </IconButton>
                          </Tippy>
                        )}
                      </AnimatedGrid>
                    )
                  })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          right: 0,
          zIndex: 1200,
          transform: 'translate(0, -50%)',
          pointerEvents: 'none',
        }}
      >
        <AnimatedGrid item style={nextStyles}>
          <Tippy
            render={attrs => (
              <div
                className="box"
                style={{
                  backgroundColor: '#FFF',
                  padding: '2px 4px',
                  display: items['next'] && !disableControls ? 'block' : 'none',
                }}
                tabIndex="-1"
                {...attrs}
              >
                <FormattedMessage
                  id="tour.controls.next"
                  defaultMessage="nächste Station"
                />
              </div>
            )}
          >
            <IconButton onClick={items['next'] && !disableControls ? handleNext : null} edge="end" sx={{pointerEvents: items['next'] && !disableControls ? 'auto' : 'none', cursor: items['next'] && !disableControls ? 'pointer' : 'not-allowed'}}>
              <NextItemIcon width={45} height={90} />
            </IconButton>
          </Tippy>
        </AnimatedGrid>
      </div>
    </>
  )
}
