import React, {memo, Fragment} from 'react'
import parse from 'html-react-parser'
import Typography from '@mui/material/Typography'

export default memo(function time({text}) {
  return (
    <div className="caption-text">
      {text.split('\n').map((item, index, array) => {
        return (
          <Fragment key={index}>
            <Typography color="white">{parse(item)}</Typography>
          </Fragment>
        )
      })}
    </div>
  )
})
