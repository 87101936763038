import {atom} from 'jotai'

export const sidebarAtom = atom(true)
export const navigationMachineAtom = atom(null)
export const tourMachineAtom = atom(null)
export const soundEnabledAtom = atom(true)
export const subtitleEnabledAtom = atom(false)
export const enableTourNaviAtom = atom(true)
export const stepsChatbotAtom = atom([])
export const tempStepsChatbotAtom = atom([])
export const isTourStartedAtom = atom(false)
export const isPausedAtom = atom(false)
export const isPausedPoiAtom = atom(false)
export const audioIsPlayingAtom = atom(false)
export const audioIsPlayingPoiAtom = atom(false)
export const initialAudioTime = {time: 0, playing: false, paused: true}
export const audioTimeAtom = atom(initialAudioTime)
export const audioTimePoiAtom = atom(initialAudioTime)
export const audioControlsAtom = atom(null)
export const audioControlsPoiAtom = atom(null)
export const isEndVideoAtom = atom(false)
export const isEndVideoPoiAtom = atom(false)
export const dimensionsAtom = atom({width: 0, height: 0})
export const anchorTourAtom = atom(null)

export const iframeDocAtom = atom(null)
export const showPlaybackBarAtom = atom(false)
export const showPlaybackBarPoiAtom = atom(false)
export const openPopupViewerAtom = atom(false)
export const popupViewerSizeAtom = atom({
  width: 80,
  height: 75,
  x: 10,
  y: 12.5,
})

export const showSitePlanAtom = atom(false)
export const currentTimeAtom = atom(0)
export const currentTimePoiAtom = atom(0)
export const windowIsActiveAtom = atom(true)
export const placeOpenAtom = atom(false)
export const languageOpenAtom = atom(false)

export const videoRefAtom = atom(false)
export const globalSoundEnabledAtom = atom(false)
export const shouldPlaySoundAtom = atom(true)
export const shouldPlaySoundPoiAtom = atom(true)
export const individuelleTourUrlAtom = atom(null)
export const disableControlsAtom = atom(false)