import React, {useEffect, useMemo} from 'react'
import {Routes, Route, Navigate, useLocation, useNavigate} from 'react-router-dom'
import { Helmet } from "react-helmet"
import { useDispatch } from 'react-redux'
import Cookies from "js-cookie";
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import ErrorBoundary from 'components/ErrorBoundary'
import Content from './components/Content'
import Videoinfo from 'components/Videoinfo'
import SasbachTimeline from 'components/SasbachTimeline'
import PdfViewer from 'components/PdfViewer'
import {tourMachineAtom, stepsChatbotAtom} from 'components/jotai'
import use3DVista from 'components/Hooks/use3DVista'
import useIsProduction from 'components/Hooks/useIsProduction'
import useWindowActive from 'components/Hooks/useWindowActive'
import useConfig from 'components/Chatbot/useConfig'
import IndividuelleTour from 'components/IndividuelleTour'
import IndividuelleTourOverview from 'components/IndividuelleTourOverview'
import {ssoAuth, fetchUser, invalidateUser} from 'components/user/duck'

function Hook() {
  use3DVista()
  useWindowActive()
  return null
}

export default function Layout() {
  const tourService = useAtomValue(tourMachineAtom)
  const location = useLocation()
  const setSteps = useUpdateAtom(stepsChatbotAtom)
  const defaultStep = useConfig('default')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      if (window) {
        const urlParams = new URLSearchParams(window.location.search);
        
        const code = urlParams.get("code", window.location.search);
        
        if (code && code !== "") {
          await ssoAuth(code).then(async (resp) => {     
            if (resp) {
              dispatch(fetchUser()).then(() => {
                setTimeout(() => {
                  window.location.assign(`${window.location.origin}/#/backend`)
                }, 1000);
              })
              /*toast.success(
                intl.formatMessage({
                  id: `login.success`,
                  defaultMessage: "Anmeldung erfolgreich.",
                })
              );*/
            } else {
              Cookies.remove("access_token");
              Cookies.remove("refresh_token");
              dispatch(invalidateUser());
              /*toast.error(
                intl.formatMessage({
                  id: `login.error`,
                  defaultMessage: "Anmeldung fehlgeschlagen.",
                })
              );*/
            }
          });
        } else {
          setSteps(defaultStep)
        }
      }
    }
    fetch()
  }, [])


  const startPage = useMemo(() => {
    switch (window?.location?.host) {
      case 'factory-tour.striebelundjohn.com':
        return {target: 'SASBACH', url: '/tour/sasbach'}
      case 'factory-tour-stotz-kontakt.abb.com':
        return {target: 'HEIDELBERG', url: '/tour/heidelberg'}
      case 'factory-tour.busch-jaeger.de':
        return {
          target: 'LUEDENSCHEID_EMPFANG',
          url: '/tour/luedenscheid_empfang',
        }
      default:
        return {
          target: 'LUEDENSCHEID_EMPFANG',
          url: '/tour/luedenscheid_empfang',
        }
    }
  }, [])

  useEffect(() => {
    if (
      location.pathname.includes('luedenscheid') &&
      (window?.location?.host === 'factory-tour.striebelundjohn.com' ||
        window?.location?.host === 'factory-tour-stotz-kontakt.abb.com')
    ) {
      window.location.replace(
        'https://factory-tour.busch-jaeger.de/' + window.location.hash,
      )
    }

    if (
      location.pathname.includes('heidelberg') &&
      (window?.location?.host === 'factory-tour.striebelundjohn.com' ||
        window?.location?.host === 'factory-tour.busch-jaeger.de')
    ) {
      window.location.replace(
        'https://factory-tour-stotz-kontakt.abb.com/' + window.location.hash,
      )
    }

    if (
      location.pathname.includes('sasbach') &&
      (window?.location?.host === 'factory-tour.busch-jaeger.de' ||
        window?.location?.host === 'factory-tour-stotz-kontakt.abb.com')
    ) {
      window.location.replace(
        'https://factory-tour.striebelundjohn.com/' + window.location.hash,
      )
    }
  }, [location.pathname])

  const isProduction = useIsProduction()

  return (
    <ErrorBoundary>
      <Helmet>
        {window?.location?.host === 'factory-tour.striebelundjohn.com' && 
          <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="NogRsiE13YOjGf" async></script>
        }
        {window?.location?.host === 'factory-tour-stotz-kontakt.abb.com' && 
          <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="wwO54VxKX4LRiY" async></script>
        }
        {window?.location?.host === 'factory-tour.busch-jaeger.de' && 
          <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="BJB1P5e9a_ue_8" async></script>
        }
      </Helmet>
      {tourService && <Hook />}
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/tour/redirect" state={startPage} />}
        />
        <Route
          path="tour/*"
          element={<Content isProduction={isProduction} />}
        />
        <Route path="sasbachTimeline" element={<SasbachTimeline />} />
        <Route
          path="videoinfo/:layout/:title"
          element={<Videoinfo />}
        />
        <Route
          path="videoinfo/:layout/:title/:subtitle"
          element={<Videoinfo />}
        />
        <Route
          path="videoinfo/:layout/:title/:subtitle/:delay"
          element={<Videoinfo />}
        />
        <Route path="pdfviewer/:filename" element={<PdfViewer />} />
        <Route path="backend" element={<IndividuelleTourOverview />} />
        <Route path="backend/new" element={<IndividuelleTour />} />
        <Route 
          path="backend/:id" 
          element={<IndividuelleTour />}
        />            
      </Routes>
    </ErrorBoundary>
  )
}
