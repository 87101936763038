import React, {useState, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import {useUpdateAtom} from 'jotai/utils'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import useConfig from 'components/Chatbot/useConfig'
import {setOpen} from 'components/TourControls/duck'

import {stepsChatbotAtom} from 'components/jotai'
import {getCalculatedFontSize} from 'components/TourControls/duck'
import ErrorBoundary from 'components/ErrorBoundary'
import {getNavigationContext} from 'components/xstate/NavigationMachine'

export const PlaceBack = ({exit}) => {
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const fontSize = useSelector(getCalculatedFontSize)
  const defaultStep = useConfig('default')
  const exitStep = useConfig('exit')

  const handleBack = () => {
    if (!exit) {
      setStepsChatbot(defaultStep)
    } else {
      setStepsChatbot(exitStep)
    }
  }

  return (
    <Box sx={{background: 'transparent', padding: '19px 29px', width: '100%'}}>
      <Grid container alignItems="center">
        <Grid item xs={6} onClick={handleBack}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} sx={{alignSelf: 'center', textAlign: 'right'}}>
          <Typography style={{fontSize: fontSize}}>
            <FormattedMessage
              id="chatbot.tour.place.header"
              defaultMessage="Standort wechseln"
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export const SelectPlace = ({exit = false}) => {
  const [step, setStep] = useState(1)
  const fontSize = useSelector(getCalculatedFontSize)
  const maxSteps = 2
  const defaultStep = useConfig('default')
  const exitStep = useConfig('exit')

  const {stateKey} = useSelector(getNavigationContext)
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const dispatch = useDispatch()
  
  useEffect(() => {
    setTimeout(() => {
      setStep(cur => {
        if (cur < maxSteps) {
          return cur + 1
        }
        return cur
      })
    }, 500)
  }, [step])

  const handleBack = () => {
    if (!exit) {
      setStepsChatbot(defaultStep)
    } else {
      setStepsChatbot(exitStep)
    }
  }

  const handleOpen = id => {
    handleBack()
    switch (id) {
      case 'heidelberg':
        dispatch({type: 'HEIDELBERG'})
        dispatch(setOpen(false))
        break
      case 'luedenscheid':
        dispatch({type: 'LUEDENSCHEID_EMPFANG'})
        dispatch(setOpen(false))
        break
      case 'sasbach':
        dispatch({type: 'SASBACH'})
        dispatch(setOpen(false))
        break
      default:
        break
    }
  }

  return (
    <ErrorBoundary>
      <div className="rsc-os">
        <Grid
          container
          className="rsc-os-options"
          sx={{padding: '20px'}}
          spacing={2}
        >
          {stateKey !== 'abb' && (
            <Grid
              item
              xs={10}
              className="rsc-os-option-element"
              onClick={() => handleOpen('heidelberg')}
              style={{cursor: 'pointer'}}
            >
              <div className="rsc-os">
                <Typography style={{fontSize: fontSize}}>
                  <FormattedMessage
                    id="chatbot.tour.place.option1"
                    defaultMessage="ABB STOTZ-KONTAKT in Heidelberg"
                  />
                </Typography>
              </div>
            </Grid>
          )}
          {!stateKey.includes('bje') && (
            <Grid
              item
              xs={10}
              className="rsc-os-option-element"
              onClick={() => handleOpen('luedenscheid')}
              style={{cursor: 'pointer'}}
            >
              <div className="rsc-os">
                <Typography style={{fontSize: fontSize}}>
                  <FormattedMessage
                    id="chatbot.tour.place.option2"
                    defaultMessage="Busch-Jaeger in Lüdenscheid"
                  />
                </Typography>
              </div>
            </Grid>
          )}
          {stateKey !== 'striebel' && (
            <Grid
              item
              xs={10}
              className="rsc-os-option-element"
              onClick={() => handleOpen('sasbach')}
              style={{cursor: 'pointer'}}
            >
              <div className="rsc-os">
                <Typography style={{fontSize: fontSize}}>
                  <FormattedMessage
                    id="chatbot.tour.place.option3"
                    defaultMessage="ABB STOTZ-KONTAKT in Sasbach"
                  />
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </ErrorBoundary>
  )
}
