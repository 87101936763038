import SvgIcon from '@mui/material/SvgIcon';

export default function UntertitelEnabledIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 28 23"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="subtitle-off" transform="translate(-10.000000, -13.000000)" stroke={props.color} strokeWidth="2">
            <g id="Group" transform="translate(10.000000, 13.000000)">
                <rect id="Rectangle" x="1" y="1" width="26" height="21" rx="1"></rect>
                <path d="M4,18 L24,18" id="Line"></path>
                <path d="M4,14 L12,14" id="Line"></path>
                <path d="M14,14 L24,14" id="Line"></path>
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}

UntertitelEnabledIcon.defaultProps = {
  color: '#0F0F0F',
  width: '28px',
  height: '23px',
};