import SvgIcon from '@mui/material/SvgIcon';

export default function PlanIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 27 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nav-left-map-icon" transform="translate(-11.000000, -10.000000)" fill={props.color} fillRule="nonzero">
            <g id="Group" transform="translate(11.000000, 10.000000)">
                <path d="M23.035,20 L19.705,20 C19.11,20.74 18.505,21.41 17.931,22 L21.806,22 L23.789,26 L3.21,26 L5.193,22 L8.92,22 C8.345,21.41 7.74,20.74 7.145,20 L3.965,20 L0,28 L27,28 L23.035,20 Z M13.421,0 C8.525,0.044 4.579,4.06 4.579,9 C4.579,16.8 13.421,23.4 13.421,23.4 C13.421,23.4 22.421,16.8 22.421,9 C22.356,4.026 18.351,0.021 13.421,0 Z M13.421,13.15 C11.231,13.15 9.456,11.36 9.456,9.15 C9.456,6.94 11.231,5.15 13.421,5.15 C15.611,5.15 17.385,6.94 17.385,9.15 C17.385,10.21 16.968,11.228 16.225,11.978 C15.8583,12.349 15.4216,12.6435 14.9403,12.8447 C14.459,13.0459 13.9426,13.1496 13.421,13.15 Z" id="Shape"></path>
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}

PlanIcon.defaultProps = {
  color: '#0F0F0F',
  width: '27px',
  height: '28px',
};