import React from 'react'
import {useDispatch} from 'react-redux'
import {FormattedMessage} from 'react-intl'

import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiBox from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {setLanguage, changeVistaLanguage} from 'components/Language/duck'

import ErrorBoundary from 'components/ErrorBoundary'

const Box = styled(MuiBox)({
  background: '#FFF',
  width: '100%',
  padding: '24px 0',
})

export default function Selectlanguage({onClose}) {
  const dispatch = useDispatch()
  return (
    <ErrorBoundary>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          marginLeft: 2,
          display: 'grid',
          width: 200,
          gridColumnGap: 10,
          gridRowGap: 10,
        }}
      >
        <Grid item sx={{background: '#FFF', paddingLeft: '0px !important'}}>
          <Box
            onClick={(e) => {
              dispatch(setLanguage('de'))
              changeVistaLanguage('de')
              onClose(e)
            }}
            sx={{cursor: 'pointer'}}
          >
            <Grid
              container
              direction="column"
              align="center"
            >
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selectlanguage.de.label"
                    defaultMessage={`Deutsch`}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sx={{background: '#FFF', paddingLeft: '0px !important'}}>
          <Box
            onClick={(e) => {
              dispatch(setLanguage('en'))
              changeVistaLanguage('en')
              onClose(e)
            }}
            sx={{cursor: 'pointer'}}
          >
            <Grid
              container
              direction="column"
              align="center"
            >
              <Grid item>
              <Typography>
                <FormattedMessage
                  id="selectlanguage.en.label"
                  defaultMessage={`Englisch`}
                />
              </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
