import React, {useState, useMemo, useEffect} from 'react'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import {useParams} from 'react-router-dom'
import {useMeasure, useDebounce} from 'react-use'
import {
  createUseGesture,
  dragAction,
  pinchAction,
  wheelAction,
} from '@use-gesture/react'
import {useSpring, animated} from 'react-spring'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {CenterIcon} from '../Icons'
import IconButton from '@mui/material/IconButton';

import pdfs from './pdf'

const useGesture = createUseGesture([dragAction, pinchAction, wheelAction])

export default function SinglePage() {
  useEffect(() => {
    const handler = e => {
      e.stopPropagation()
      e.preventDefault()
    }
    /*document.getElementById('pdfviewer').addEventListener(
      'wheel',
      event => {
        const {ctrlKey} = event
        if (ctrlKey) {
          event.preventDefault()
          return
        }
      },
      {passive: false},
    )*/
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])

  const [, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page
  const {filename} = useParams()

  const [pageWidth, setPageWidth] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const [ref, {height, width}] = useMeasure()

  const [scale, setScale] = useState(1)
  const [tmpScale, setTmpScale] = useState(scale)

  useDebounce(
    () => {
      setScale(tmpScale)
    },
    100,
    [tmpScale],
  )

  const initialScale = useMemo(() => {
    if (pageWidth && pageHeight && width && height) {
      const wRatio = width / pageWidth
      const hRatio = height / pageHeight
      return Math.min(wRatio, hRatio)
    }
    return 1
  }, [pageHeight, pageWidth, height, width])

  useEffect(() => {
    setScale(initialScale)
  }, [initialScale])

  const [{x, y}, api] = useSpring(() => ({x: 0, y: 0}))

  const bind = useGesture({
    onDrag: ({down, offset: [x, y]}) => api.start({x, y}),
    /*onWheel: ({ event, offset: [, y], direction: [, dy] }) => {
      api.start({x: 0, y: Math.min(Math.max(0, y), pageHeight * scale - height)})
    },*/
  })

  function onDocumentLoadSuccess(data) {
    setNumPages(data.numPages)
    setPageNumber(1)
  }

  function changeScale(offset) {
    setScale(prevScale => {
      return prevScale + offset
    })
  }

  function zoomOutMap() {
    setScale(initialScale)
    api.start({x: 0, y: 0})
  }

  function zoomOut() {
    changeScale(-0.1)
  }

  function zoomIn() {
    changeScale(0.1)
  }

  const pdf = filename && pdfs ? pdfs[filename] : null

  if (!pdf) {
    return null
  }

  return (
    <div
      style={{
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      id="pdfviewer"
    >
      <div
        style={{flex: 1, margin: '0 auto', position: 'relative', width: '100%'}}
      >
        <div style={{textAlign: 'center'}}>
          <IconButton type="button" disabled={scale >= 2} onClick={zoomIn}>
            <ZoomInIcon />
          </IconButton>
          <IconButton type="button" disabled={scale <= initialScale} onClick={zoomOut}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton type="button" disabled={scale <= initialScale && x === 0 && y === 0} onClick={zoomOutMap}>
            <CenterIcon />
          </IconButton>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          }}
          ref={ref}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflow: 'visible',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: `50%`,
                left: `50%`,
                transform: 'translate(-50%, -50%)',
                cursor: 'grab',
              }}
            >
              <animated.div {...bind()} style={{x, y}}>
                <Document file={`/assets/pdf/${filename}`} onLoadSuccess={onDocumentLoadSuccess}>
                  {' '}
                  <Page
                    size="A4"
                    pageNumber={pageNumber}
                    scale={scale}
                    onLoadSuccess={page => {
                      setPageWidth(page.width)
                      setPageHeight(page.height)
                    }}
                  />
                </Document>
              </animated.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
