import { useState } from "react";
import { useSpring, animated } from "react-spring";

export default function SasbachTimelineElement({ e, i, visible, handleShow }) {
  const fade = i % 2 ? 100 : -100;

  const [animationFinished, setAnimationFinished] = useState(false);

  const styles = useSpring({
    opacity: visible || animationFinished ? 1 : 0,
    transform:
      visible || animationFinished
        ? "translate(0px, 0px)"
        : `translate(${fade}px, 0px)`,
    onRest: () => {
      setAnimationFinished(true);
    },
  });

  const dotStyleLeft = useSpring({
    opacity: visible || animationFinished ? 1 : 0,
    transform:
      visible || animationFinished
        ? "translate(0px, 0px)"
        : `translate(-50px, 0px)`,
    onRest: () => {
      setAnimationFinished(true);
    },
  });
  const dotStylesRight = useSpring({
    opacity: visible || animationFinished ? 1 : 0,
    transform:
      visible || animationFinished
        ? "translate(0px, 0px)"
        : `translate(50px, 0px)`,
    onRest: () => {
      setAnimationFinished(true);
    },
  });

  return (
    <>
      {e.year !== undefined ? (
        <animated.div
          style={i % 2 ? dotStyleLeft : dotStylesRight}
          className={`timeline-dot`}
        >
          {e.year}
        </animated.div>
      ) : null}
      <animated.div className={`timeline-item`} style={styles}>
        <div className={`timeline-content ${i % 2 ? "right" : "left"}`}>
          {e.titel ? <h2>{e.titel}</h2> : null}
          {e.text ? <p>{e.text}</p> : null}
          {e.img ? (
            <img
              style={{ width: "10rem" }}
              className={i % 2 ? "ms-auto" : "ms-auto me-md-0"}
              src={e.img}
              alt={`img-${i}`}
              onClick={handleShow}
            />
          ) : null}
        </div>
      </animated.div>
    </>
  );
}
