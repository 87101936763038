import SvgIcon from '@mui/material/SvgIcon'

export default function ArrowForward(props) {
  return (
    <SvgIcon
      width="4px"
      height="8px"
      viewBox="0 0 9 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="Symbole"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="nav-closed"
          transform="translate(-23.000000, -122.000000)"
          stroke={props.color}
          strokeWidth="2"
        >
          <path
            d="M29,125 L29,134 C29,134.552285 28.5522847,135 28,135 L19,135"
            id="Rectangle"
            transform="translate(24.000000, 130.000000) rotate(-45.000000) translate(-24.000000, -130.000000) "
          ></path>
        </g>
      </g>
    </SvgIcon>
  )
}

ArrowForward.defaultProps = {
  color: '#6E6E6E',
}
