import {createSlice, createDraftSafeSelector} from '@reduxjs/toolkit'
import {getNavigationContext} from 'components/xstate/NavigationMachine'

export const initialState = {
  pages: {
    striebel: [],
    bjeLogistik: [],
    bjeProduktion: [],
    bjeSchulungszentrum: [],
    bjeAussengelaende: [],
    bjeEmpfang: [],
    bjeFree: [],
    abb: [],
  },
}

const visitedSlice = createSlice({
  name: 'visited',
  initialState,
  reducers: {
    resetVisited(state) {
      state.pages = initialState.pages
    },
    addVisited(state, action) {
      if (
        !state.pages[action.payload.id]?.find(c => c === action.payload.szene)
      ) {
        state.pages[action.payload.id]?.push(action.payload.szene)
      }
    },
  },
})

export const {addVisited, resetVisited} = visitedSlice.actions
export default visitedSlice.reducer

export const getVisited = state => state.visited.pages

export const getFilteredVisited = createDraftSafeSelector(
  [getVisited, getNavigationContext],
  (visited, context) => {
    return context?.stateKey && visited[context?.stateKey]
      ? visited[context?.stateKey]
      : []
  },
)

export const shouldPlaySoundOnlyOnce = [
  'E03',
  'E04',
  'E05',
  'E07',
  'E08',
  'E09',
  'E10'
]
