import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit'

export const initialState = {
  fontSize: 'medium',
  sound: true,
  fullSize: false,
  open: false,
  videoInfo: { title: '', subtitle: ''}
}

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setFontSize(state, action) {
      state.fontSize = action.payload
    },
    setSound(state, action) {
      state.sound = action.payload
    },
    setFullSize(state, action) {
      state.fullSize = action.payload
    },
    setOpen(state, action) {
      state.open = action.payload
    },
    setVideoInfo(state, action) {
      state.videoInfo = action.payload
    },
    resetVideoInfo(state) {
      state.videoInfo = initialState.videoInfo
    },
  },
})

export const {
  setFontSize,
  setSound,
  setFullSize,
  setOpen,
  setVideoInfo,
  resetVideoInfo
} = chatbotSlice.actions
export default chatbotSlice.reducer

export const getFontSize = state => state.chatbot.fontSize

export const getCalculatedFontSize = createDraftSafeSelector(
  [getFontSize],
  (fontSize) => {
    switch (fontSize) {
      case 'small':
        return 12
      case 'big':
        return 16
      default:
        return 14
    }
  }
)

export const getSound = state => state.chatbot.sound
export const getFullSize = state => state.chatbot.fullSize
export const getOpen = state => state.chatbot.open
export const getVideoInfo = state => state.chatbot.videoInfo