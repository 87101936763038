import {combineReducers} from 'redux'
import chatbot from 'components/TourControls/duck'
import favorites from 'components/Favorites/duck'
import navigation from 'components/Navigation/duck'
import visited from 'components/visited/duck'
import navigationReducer from 'components/xstate/NavigationMachine'
import user from 'components/user/duck'
import language from 'components/Language/duck'

const RESET_APP = 'abbFactoryTour/rootReducer/RESET_APP'

const reducers = {
  chatbot,
  favorites,
  language,
  navigation,
  visited,
  user,
  navigationState: navigationReducer,
}

const appReducer = combineReducers({
  ...reducers,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined
  }

  return appReducer(state, action)
}

export const resetApp = () => {
  return {
    type: RESET_APP,
  }
}

export default rootReducer
