import React, {useEffect, useMemo, useState} from 'react'
import Helmet from 'react-helmet'
import Cookies from "js-cookie";
import { useParams } from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import { useAtom } from 'jotai'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {useDispatch, useSelector} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {useSearchParams, useNavigate} from 'react-router-dom'
//import CookieConsent from 'react-cookie-consent'
import Grid from '@mui/material/Grid'
//import {withStyles} from '@mui/styles'

import ErrorBoundary from 'components/ErrorBoundary'
import PlaybackBar from 'components/PlaybackBar'
import PlaybackBarPoi from 'components/PlaybackBarPoi'
import TourControls from 'components/TourControls'
import ConnectionInfo from 'components/ConnectionInfo'
import SitePlan from 'components/SitePlan'
import {
  setNavigation,
} from 'components/Navigation/duck'
import {
  iframeDocAtom,
  sidebarAtom,
  tourMachineAtom,
  isTourStartedAtom,
  individuelleTourUrlAtom ,
} from 'components/jotai'
import { getIsLoggedIn, invalidateUser} from 'components/user/duck'
import IconAbb from 'assets/img/faviconAbb.ico'
import IconBje from 'assets/img/faviconBje.ico'
import IconStriebel from 'assets/img/faviconStriebel.ico'
import {sidebarWitdh} from 'components/utils'
import { getNavigationContext } from 'components/xstate/NavigationMachine'
import useNavigateToPlaylistItem from 'components/Hooks/useNavigateToPlaylistItem'
import { getFirstPlaylistItem } from 'components/Navigation/duck';

export default function TourView() {
  const brand = 'bje'
  const dispatch = useDispatch()
  const drawerOpen = useAtomValue(sidebarAtom)
  const setIFrameDoc = useUpdateAtom(iframeDocAtom)
  const { id } = useParams()
  const individuelleTourUrl = useAtomValue(individuelleTourUrlAtom)
  const {navigateToPlaylistItem} = useNavigateToPlaylistItem()

  const playlistItemsProps = useQuery({
    queryKey: ['playlistItems'],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/playlistItems`,
      }).then((res) => {
        return res?.data || []
      }),
      select: (data) => data.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {})
  })
  const mappedPlaylistItems = useMemo(() => {
    return playlistItemsProps.data || {}
  }, [playlistItemsProps.data])

  const individuelleTourProps = useQuery({
    queryKey: ['individuelleTour', id],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/individuelleTour/${id}`,
      }).then((res) => {
        if (!!res?.data) {
          return {
            ...res?.data,
            tour: JSON.parse(res.data.tour)
          }
        }
        return null
      })
  })

  const individuellePlaylistItems = useMemo(() => {
    return individuelleTourProps.data?.tour || []
  }, [individuelleTourProps.data])

  const individuelleEnabled =  !individuelleTourProps.isError && individuelleTourProps.data?.enabled
  const isLoading = individuelleTourProps.isLoading || playlistItemsProps.isLoading

  const tourService = useAtomValue(tourMachineAtom)
  const setTourIsStarted = useUpdateAtom(isTourStartedAtom)
  const [searchParams] = useSearchParams()
  const naviContext = useSelector(getNavigationContext)
  const firstPlaylistItem = useSelector(getFirstPlaylistItem)

  useEffect(() => {
    dispatch({type: 'INDIVIDUELL'})
    dispatch(
      setNavigation({
        id: id,
        pages: individuellePlaylistItems?.reduce((acc, id) => {
          const item = mappedPlaylistItems[id]
          if (item) {
            acc.push({
              label: item.id,
              subtitle: item.name,
              tourId: item.tourId,
              thumbnailUrl: item.thumbnailUrl,
            })
          }
          return acc
        }, []) || []
      })
    )
  }, [id, individuellePlaylistItems, mappedPlaylistItems])

  useEffect(() => {
    if (firstPlaylistItem) {
      navigateToPlaylistItem(firstPlaylistItem.label)
    }
  }, [firstPlaylistItem])

  const title = useMemo(() => {
    return 'Individuelle Tour'
  }, [])

  useEffect(() => {
    const url2 = window.location.origin

    let myDynamicManifest = {
      short_name: 'Factory Tour',
      name: 'Factory Tour',
      display: 'standalone',
      orientation: 'any',
      background_color: '#ffffff',
      theme_color: '#FFF',
      scope: `${url2}/`,
      start_url: `${url2}/`,
      icons: [
        {
          src: `${url2}/logoBje192.jpg`,
          type: 'image/png',
          sizes: '192x192',
        },
        {
          src: `${url2}/logoBje512.jpg`,
          type: 'image/png',
          sizes: '512x512',
        },
      ],
    }

    let link = document.getElementById('manifest')
    if (!link) {
      link = document.createElement('link')
      link.rel = 'manifest'
      link.id = 'manifest'
    }
    const stringManifest = JSON.stringify(myDynamicManifest)
    link.setAttribute(
      'href',
      'data:application/json;charset=utf-8,' +
        encodeURIComponent(stringManifest),
    )
    document.head.appendChild(link)

    window.onload = function() {
      let frameElement = document.getElementById('tourFrame')
      if (frameElement) {
        let doc = frameElement.contentDocument

        if (doc) {
          setIFrameDoc(doc)
          doc
            .getElementById('preloadContainer')
            ?.setAttribute('style', 'opacity:1;cursor:pointer;')
        }
      }
    }

    return () => {
      setTourIsStarted(false)
    }
  }, [brand, setIFrameDoc, setTourIsStarted])

  const yaw = searchParams.get('yaw')
  const pitch = searchParams.get('pitch')

  const params = useMemo(() => {
    let str = ``

    if (yaw) {
      if (str.length > 0) {
        str = `${str}&yaw=${yaw}`
      } else {
        str = `?yaw=${yaw}`
      }
    }

    if (pitch) {
      if (str.length > 0) {
        str = `${str}&pitch=${pitch}`
      } else {
        str = `?pitch=${pitch}`
      }
    }

    return str
  }, [yaw, pitch])

  const renderEl = useMemo(() => !!tourService, [tourService])

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title}</title>
        <link
          id="favicon"
          rel="icon"
          href={IconBje}
        />
        <link
          rel="apple-touch-icon"
          href={`${window.location.origin}/logoBje192.jpg`}
        />
      </Helmet>
      <Grid container sx={{width: '100vw', height: '100vh', }}>
        {isLoading &&
          <Grid item xs={12} sx={{padding: 4, marginLeft: drawerOpen ? `${sidebarWitdh}px` : 0}}>
            <FormattedMessage
              id="tour.loading"
              defaultMessage="Die Tourdaten werden geladen..."
            />
          </Grid>
        }
        {!isLoading && !individuelleEnabled &&
          <Grid item xs={12} sx={{padding: 4, marginLeft: drawerOpen ? `${sidebarWitdh}px` : 0}}>
            <FormattedMessage
              id="tour.not.found"
              defaultMessage="Die Tour mit dieser ID konnte nicht gefunden werden oder ist derzeit deaktiviert."
            />
          </Grid>
        }
        {!isLoading && individuelleEnabled && individuellePlaylistItems.length > 0 && Object.keys(mappedPlaylistItems).length > 0 &&
          <Grid item xs={12}>
            <div style={{position: 'relative', height: '100%'}}>
              <iframe
                id="tourFrame"
                title={title}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1000,
                }}
                src={`${individuelleTourUrl}${params}`}
                name="tourFrame"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
              />
            </div>
          </Grid>
        }
        {renderEl && <PlaybackBar />}
        {renderEl && <PlaybackBarPoi />}
        {renderEl && <TourControls />}
        {/*renderEl && <SitePlan tour={'individuell'} />*/}
        <ConnectionInfo />
      </Grid>
    </ErrorBoundary>
  )

  /*
  
  

  

  

 

  

  

  

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title}</title>
        <link
          id="favicon"
          rel="icon"
          href={IconBje}
        />
        <link
          rel="apple-touch-icon"
          href={`${window.location.origin}/logoBje192.jpg`}
        />
      </Helmet>
      <Grid container sx={{width: '100vw', height: '100vh', }}>
      {isFetching &&
          <Grid item xs={12} sx={{padding: 4, marginLeft: drawerOpen ? `${sidebarWitdh}px` : 0}}>
            <FormattedMessage
              id="tour.loading"
              defaultMessage="Die Tourdaten werden geladen..."
            />
          </Grid>
        }
        {!isFetching && !individuelleEnabled &&
          <Grid item xs={12} sx={{padding: 4, marginLeft: drawerOpen ? `${sidebarWitdh}px` : 0}}>
            <FormattedMessage
              id="tour.not.found"
              defaultMessage="Die Tour mit dieser ID konnte nicht gefunden werden oder ist derzeit deaktiviert."
            />
          </Grid>
        }
        {!isFetching && individuelleEnabled && individuellePlaylistItems.length > 0 && Object.keys(mappedPlaylistItems).length > 0 &&
          <Grid item xs={12}>
            <div style={{position: 'relative', height: '100%'}}>
              <iframe
                id="tourFrame"
                title={title}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1000,
                }}
                src={`${individuelleTourUrl}${params}`}
                name="tourFrame"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
              />
            </div>
          </Grid>
        }
      </Grid>
      
    </ErrorBoundary>
  )*/
}
