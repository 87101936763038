import SvgIcon from '@mui/material/SvgIcon';

export default function BjeIconSmall(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 13 32"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
        <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="nav-left-icon-LD" transform="translate(-18.000000, -8.000000)" fill={props.color}>
              <path d="M29.4255732,22.2494349 C30.2951311,22.2494349 31,22.9741245 31,23.8686064 C31,24.762809 30.2951311,25.4877778 29.4255732,25.4877778 L19.5574089,25.4877778 C18.6884,25.4877778 17.9835311,24.762809 17.9835311,23.8686064 C17.9835311,22.9741245 18.6884,22.2494349 19.5574089,22.2494349 L29.4255732,22.2494349 Z M26.0615261,17.9889865 C27.2865377,17.3863353 28.1733879,16.0863623 28.1733879,14.6101739 C28.1733879,12.5704312 26.4935602,10.9501427 24.4887463,10.9501427 C22.4842067,10.9501427 20.9130738,12.5704312 20.9130738,14.6101739 C20.9130738,16.0821734 21.8205101,17.3173571 22.9154174,17.9889865 C24.6932351,18.9211691 23.3998775,21.5208357 21.6020227,20.5135312 C19.4972974,19.4257987 17.9917656,17.1874995 17.9917656,14.6101739 C17.9917656,10.9593584 20.9004476,8 24.4884718,8 C28.0767704,8 30.9854525,10.9593584 30.9854525,14.6101739 C30.9854525,17.2321817 29.4840378,19.4986866 27.3084963,20.5660328 C25.8092775,21.4878826 24.1599172,19.0775568 26.0615261,17.9889865 L26.0615261,17.9889865 Z M19.1130231,31.9795439 C18.3763143,30.6418704 18.074659,30.4151082 17.9868249,29.4496932 C17.9876483,28.2402018 18.9502555,27.2806514 20.1390355,27.2806514 L22.4326042,27.2806514 C23.2316203,27.2806514 23.8785735,27.9391554 23.8785735,28.7526508 C23.8785735,29.5639121 23.2316203,30.2017506 22.4326042,30.2017506 L21.4000042,30.2017506 L24.4003632,35.4664316 L27.5750179,30.2017506 L26.541869,30.2017506 C25.7434019,30.2017506 25.0961741,29.5639121 25.0961741,28.7526508 C25.0961741,27.9391554 25.7434019,27.2806514 26.541869,27.2806514 L28.8357122,27.2806514 C30.0247667,27.2806514 30.9876483,28.2402018 30.9876483,29.4496932 C30.9876483,29.8227897 30.8954225,30.1590233 30.7332038,30.4673305 L25.570204,39.4171765 C25.0736667,40.2007907 23.8969638,40.2426802 23.324944,39.3878538 C22.0738567,37.1713371 20.2427896,34.0301779 19.1130231,31.9795439 L19.1130231,31.9795439 Z" id="Fill-13"></path>
          </g>
      </g>
    </SvgIcon>
  );
}

BjeIconSmall.defaultProps = {
  color: '#00ACEC',
  width: '13px',
  height: '32px',
};