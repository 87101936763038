import React from 'react'
import {useDispatch} from 'react-redux'
import {
  setSound,
} from 'components/TourControls/duck'
import {setOpen} from 'components/TourControls/duck'
import Grid from '@mui/material/Grid'
import {FormattedMessage} from 'react-intl'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {
  audioControlsAtom,
  stepsChatbotAtom,
  globalSoundEnabledAtom,
} from 'components/jotai'
import useConfig from 'components/Chatbot/useConfig'

export default function Header() {
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const defaultStep = useConfig('default')
  const dispatch = useDispatch()
  const soundControls = useAtomValue(audioControlsAtom)
  const setGlobalSoundEnabledAtom = useUpdateAtom(globalSoundEnabledAtom)
  
  const handleEnableSound = event => {
    dispatch(setSound(true))
    soundControls?.play()
    dispatch(setOpen(false))
    setStepsChatbot(defaultStep)
    setGlobalSoundEnabledAtom(true)
  }
  
  const handleDisableSound = event => {
    dispatch(setSound(false))
    dispatch(setOpen(false))
    setStepsChatbot(defaultStep)
    setGlobalSoundEnabledAtom(true)
  }

  return (
    <div className="rsc-os" style={{width: '100%'}}>
      <Grid
        container
        className="rsc-os-options"
        sx={{padding: '20px'}}
        spacing={2}
      >
        <Grid
          item
          xs={10}
          className="rsc-os-option-element"
          sx={{cursor: 'pointer', textAlign: 'center'}}
          onClick={handleEnableSound}
        >
          <div className="rsc-os">
            <FormattedMessage
              id="chatbot.sound.enable.on.label"
              defaultMessage={`ja`}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={10}
          className="rsc-os-option-element"
          sx={{cursor: 'pointer', textAlign: 'center'}}
          onClick={handleDisableSound}
        >
          <div className="rsc-os">
            <FormattedMessage
              id="chatbot.sound.enable.off.label"
              defaultMessage={`nein`}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
