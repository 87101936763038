import React, {useMemo, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import useStationMessages from 'components/Hooks/useStationMessages'

import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiBox from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MuiPaper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'

import {getTour} from 'components/Navigation/duck'
import ErrorBoundary from 'components/ErrorBoundary'
import {
  CloseIcon,
  //FavoriteDisabledIcon,
  //FavoriteEnabledIcon,
} from 'components/Icons'
import CheckIcon from '@mui/icons-material/Check'

import ScrollContainer from 'components/ScrollContainer'
import {addFavorite, removeFavorite} from 'components/Favorites/duck'
import {useAtomValue} from 'jotai/utils'
import {dimensionsAtom, tourMachineAtom, disableControlsAtom} from 'components/jotai'
import {getNavigationValue} from 'components/xstate/NavigationMachine'

import {useActor} from '@xstate/react'
import {getFilteredVisited} from 'components/visited/duck'
import useNavigateToPlaylistItem from 'components/Hooks/useNavigateToPlaylistItem'

const Box = styled(MuiBox)({
  background: '#FFF',
  minWidth: '150px',
  padding: '24px 0 24px 10px',
})

const FirstTitle = styled(MuiPaper)(({theme}) => ({
  position: 'relative',
  background: 'transparent',
  boxShadow: 'none',
  paddingBottom: 6,
}))

const Title = styled(MuiPaper)(({theme}) => ({
  position: 'relative',
  background: 'transparent',
  boxShadow: 'none',
  paddingBottom: 6,
  '&::before': {
    content: '""',
    width: '1px',
    height: '64px',
    borderLeft: ' 3px solid #D8D8D8',
    position: 'absolute',
    top: '-32px',
    left: '50%',
    zIndex: -1,
  },
}))

const Paper = styled(MuiPaper)(({theme}) => ({
  background: '#FFF',
  borderRadius: '9.73px',
  boxShadow: '0 2px 12px 0 rgba(0,0,0,0.12)',
  position: 'relative',
  '&::before': {
    content: '""',
    width: '1px',
    height: '32px',
    borderLeft: ' 3px solid #D8D8D8',
    position: 'absolute',
    top: '-32px',
    left: '50%',
    zIndex: -1,
  },
}))

const FirstPaper = styled(MuiPaper)(({theme}) => ({
  background: '#FFF',
  borderRadius: '9.73px',
  boxShadow: '0 2px 12px 0 rgba(0,0,0,0.12)',
  position: 'relative',
}))

const Image = styled('img')(({theme}) => ({
  maxWidth: '100%',
}))

const RedLine = styled('span')(({theme}) => ({
  color: theme.palette.primary.main,
}))

export default function TourOverview({onClose, open}) {
  const {width} = useAtomValue(dimensionsAtom)

  const tourService = useAtomValue(tourMachineAtom)
  const disableControls = useAtomValue(disableControlsAtom)

  const naviValue = useSelector(getNavigationValue)
  const [tour] = useActor(tourService)
  const {navigateToPlaylistItem} = useNavigateToPlaylistItem()

  useEffect(() => {
    if (open) {
      if (tour?.context?.szene) {
        const el = document.getElementById(tour?.context?.szene)
        if (el) {
          el.scrollIntoView()
        }
      }
    }
  }, [open, tour?.context?.szene])

  const place = useMemo(() => {
    if (naviValue?.includes('individuell')) {
      return 'Individuell'
    } else if (naviValue?.includes('sasbach')) {
      return 'Sasbach'
    } else if (naviValue?.includes('luedenscheid')) {
      return 'Lüdenscheid'
    } else {
      return 'Heidelberg'
    }
  }, [naviValue])

  const subPlace = useMemo(() => {
    if (place === 'Lüdenscheid') {
      if (naviValue?.includes('logistik')) {
        return 'Logistik'
      } else if (naviValue?.includes('produktion')) {
        return 'Produktion'
      } else if (naviValue?.includes('schulungszentrum')) {
        return 'Schulungszentrum'
      } else if (naviValue?.includes('aussengelaende')) {
        return 'Aussengelaende'
      } else if (naviValue?.includes('empfang')) {
        return 'Empfang'
      } else {
        return 'Freie'
      }
    }
    return ''
  }, [naviValue, place])

  const tourKey = useMemo(() => {
    if (place === 'Sasbach') {
      return 'striebel'
    } else if (place === 'Lüdenscheid') {
      if (subPlace === 'Logistik') {
        return 'bjeLogistik'
      } else if (subPlace === 'Produktion') {
        return 'bjeProduktion'
      } else if (subPlace === 'Schulungszentrum') {
        return 'bjeSchulungszentrum'
      } else if (subPlace === 'Aussengelaende') {
        return 'bjeAussengelaende'
      } else if (subPlace === 'Empfang') {
        return 'bjeEmpfang'
      } else {
        return 'bjeFree'
      }
    } else {
      return 'abb'
    }
  }, [place, subPlace])

  const tourData = useSelector(getTour)
  const visited = useSelector(getFilteredVisited)

  const dispatch = useDispatch()
  const intl = useIntl()

  const keys = Object.keys(tourData)
  //let index = 0

  const messages = useStationMessages()

  const handleNavigate = type => {
    dispatch({type})
  }

  return (
    <ErrorBoundary>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          height: '100vh',
          marginLeft: 0,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            height: '100%',
            background: 'transparent',
            width: width > 1200 ? '480px' : width > 800 ? '400px' : 'auto',
            padding: width > 800 ? '10px 30px' : '10px',
          }}
        >
          <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <Grid container direction="column" sx={{height: '100%'}}>
              <Grid item>
                <Grid container>
                  <Grid item xs={10}>
                    {place === 'Lüdenscheid' && (
                      <ul
                        style={{
                          listStyleType: 'none',
                          fontSize: '0.8rem',
                          paddingLeft: 0,
                        }}
                      >
                        {subPlace !== 'Empfang' && (
                          <>
                            <li
                              style={{
                                display: 'inline-block',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleNavigate('LUEDENSCHEID_EMPFANG')
                              }
                            >
                              {intl.formatMessage(messages['Empfang'])}
                            </li>
                          </>
                        )}
                        {subPlace !== 'Produktion' && (
                          <>
                            {subPlace !== 'Empfang' && (
                              <span style={{paddingLeft: 5, paddingRight: 5}}>
                                |
                              </span>
                            )}
                            <li
                              style={{
                                display: 'inline-block',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleNavigate('LUEDENSCHEID_PRODUKTION')
                              }
                            >
                              {intl.formatMessage(messages['Produktion'])}
                            </li>
                          </>
                        )}

                        {subPlace !== 'Logistik' && (
                          <>
                            <span style={{paddingLeft: 5, paddingRight: 5}}>
                              |
                            </span>
                            <li
                              style={{
                                display: 'inline-block',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleNavigate('LUEDENSCHEID_LOGISTIK')
                              }
                            >
                              {intl.formatMessage(messages['Logistik'])}
                            </li>
                          </>
                        )}
                        {subPlace !== 'Schulungszentrum' && (
                          <>
                            <span style={{paddingLeft: 5, paddingRight: 5}}>
                              |
                            </span>
                            <li
                              style={{
                                display: 'inline-block',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleNavigate('LUEDENSCHEID_SCHULUNGSZENTRUM')
                              }
                            >
                              {intl.formatMessage(messages['Schulungszentrum'])}
                            </li>
                          </>
                        )}
                        {subPlace !== 'Aussengelaende' && (
                          <>
                            <span style={{paddingLeft: 5, paddingRight: 5}}>
                              |
                            </span>
                            <li
                              style={{
                                display: 'inline-block',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleNavigate('LUEDENSCHEID_AUSSENGELAENDE')
                              }
                            >
                              {intl.formatMessage(messages['Aussengelaende'])}
                            </li>
                          </>
                        )}
                      </ul>
                    )}
                  </Grid>
                  <Grid item xs={2} align="right">
                    <IconButton onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: '38.92px',
                    fontWeight: 'bold',
                    letterSpacing: 0,
                    lineHeight: '42px',
                  }}
                >
                  {messages[`${place}${subPlace || ''}`]
                    ? intl.formatMessage(messages[`${place}${subPlace || ''}`])
                    : ''}
                  <br />
                  <RedLine>-</RedLine>
                </Typography>
              </Grid>
              {/*keys.length > 1 && (
                <Grid item>
                  <ul
                    style={{
                      listStyleType: 'none',
                      fontSize: '0.8rem',
                      paddingLeft: 0,
                    }}
                  >
                    {keys.map((key, index) => {
                      return (
                        <>
                          {index > 0 && (
                            <span style={{paddingLeft: 5, paddingRight: 5}}>
                              |
                            </span>
                          )}
                          <li
                            style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (tourData[key][0]?.label) {
                                const el = document.getElementById(
                                  tourData[key][0]?.label,
                                )
                                if (el) {
                                  el.scrollIntoView()
                                }
                              }
                            }}
                          >
                            {intl.formatMessage(
                              messages[`${place}${subPlace}${key}`],
                            )}
                          </li>
                        </>
                      )
                    })}
                  </ul>
                </Grid>
              )*/}
              <Grid item sx={{flex: 1, position: 'relative'}}>
                <ScrollContainer>
                  <Grid container spacing={4} sx={{marginBottom: 10}}>
                    {keys.map((key, index) => {
                      const TitleComponent = index === 0 ? FirstTitle : Title

                      return (
                        <Grid item xs={12} key={key}>
                          <Grid container spacing={4}>
                            {keys.length > 1 && (
                              <Grid
                                item
                                xs={12}
                                id={`tour-${index}`}
                                key={`tour-${index}`}
                                style={{
                                  position: 'sticky',
                                  top: -32,
                                  zIndex: 1000,
                                  background: 'rgb(240,240,240)',
                                  marginBottom: 16,
                                }}
                              >
                                <TitleComponent>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      fontFamily: 'Averta-Bold',
                                      background: 'rgb(240,240,240)',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {messages[`${place}${subPlace}${key}`]
                                      ? intl.formatMessage(
                                          messages[`${place}${subPlace}${key}`],
                                        )
                                      : ''}
                                  </Typography>
                                </TitleComponent>
                              </Grid>
                            )}
                            {tourData[key].map((el, subIndex) => {
                              const PaperComponent =
                                index === 0 ? FirstPaper : Paper
                              const isActive = el.label === tour?.context?.szene
                              const isVisited = visited?.find(
                                c => c === el.label,
                              )
                              const splits = el.subtitle?.split('##')
                              const label =
                                Array.isArray(splits) && splits.length > 0
                                  ? splits[0]
                                  : el.label

                              /*const toggleFavorite = e => {
                                e.stopPropagation()
                                e.preventDefault()
                                if (el?.label) {
                                  if (el.isFavorite) {
                                    dispatch(
                                      removeFavorite({
                                        id: tourKey,
                                        label: el.label,
                                      }),
                                    )
                                  } else {
                                    dispatch(
                                      addFavorite({
                                        id: tourKey,
                                        label: el.label,
                                      }),
                                    )
                                  }
                                }
                              }*/

                              const navigateToPanorama = e => {
                                e.stopPropagation()
                                e.preventDefault()
                                if (!disableControls) {
                                  navigateToPlaylistItem(el.label)
                                  onClose()
                                }
                              }

                              index++

                              return (
                                <Grid
                                  item
                                  xs={12}
                                  id={el.label}
                                  key={`tour-${index}`}
                                  style={{
                                    paddingTop:
                                      keys.length > 1 && subIndex === 0
                                        ? 0
                                        : 32,
                                  }}
                                >
                                  <PaperComponent
                                    sx={{
                                      padding: isActive
                                        ? '15px 10px'
                                        : '17px 12px',
                                      border: isActive ? '2px solid' : 'none',
                                      borderColor: 'primary.main',
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={1}
                                      alignItems="center"
                                      onClick={navigateToPanorama}
                                      sx={{cursor: !disableControls ? 'pointer' : 'not-allowed'}}
                                    >
                                      <Grid item xs={3}>
                                        <Image src={el?.thumbnailUrl} />
                                      </Grid>
                                      <Grid item xs={7}>
                                        <Typography
                                          sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={1} align="middle">
                                        {isVisited && <CheckIcon />}
                                      </Grid>
                                      {/*<Grid
                                        item
                                        xs={1}
                                        align="middle"
                                        onClick={toggleFavorite}
                                      >
                                        {el?.isFavorite && (
                                          <FavoriteEnabledIcon />
                                        )}
                                        {!el?.isFavorite && (
                                          <FavoriteDisabledIcon />
                                        )}
                                      </Grid>*/}
                                    </Grid>
                                  </PaperComponent>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </ScrollContainer>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Grid>
    </ErrorBoundary>
  )
}
