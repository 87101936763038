import {useState, useEffect} from 'react'
import {useNetworkState, usePrevious} from 'react-use'
import {FormattedMessage} from 'react-intl'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Fade from '@mui/material/Fade'

import {CloseIcon} from 'components/Icons'

export default function ConnectionInfo() {
  const {downlink} = useNetworkState()
  const prevDownlink = usePrevious(downlink)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (downlink < 2 && (!prevDownlink || prevDownlink > 2 || downlink === 0)) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [downlink])

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Fade in={show}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 5000,
          right: 5,
          top: 5,
          backgroundColor: 'primary.main',
          padding: '10px',
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{textAlign: 'right'}}>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon color="#FFF" width="8px" height="8px" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{color: '#fff'}}>
              <FormattedMessage
                id="connection.info"
                defaultMessage="Langsame Internetverbindung"
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
