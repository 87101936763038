import {useMemo} from 'react'
import {
  useDraggable,
} from '@dnd-kit/core';
import {CSS} from "@dnd-kit/utilities"
import {v4} from 'uuid'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Draggable = ({
  enabled,
  element,
  playlistItems,
  setOpenPanorama,
  setOpen360Video,
  setOpenVideo,
  scroll,
  handleDelete,
  handleAddLast
}) => {
  const {attributes, listeners, transition, setNodeRef, transform} = useDraggable({
    id: `drag_${element.id}`,
    data: element
  })

  const downloadFile = (playlistItem) => {
    const link = document.createElement('a')
    if (playlistItem.type === 'showPdf') {
      link.href = `${window.location.origin}/assets/pdf/${playlistItem.url}.pdf`
      link.setAttribute('download', `${playlistItem.url}.pdf`)
    } else {
      const splits = playlistItem.url.split('/')
      link.href = playlistItem.url
      link.setAttribute('download', splits[splits.length - 1])
    }
    document.body.appendChild(link)
    link.click()
  }

  const handleView = (playlistItem) => {
    if (playlistItem.type === 'panorama') {
      setOpenPanorama(playlistItem.url)
    } else if (playlistItem.type === '360video') {
      setOpen360Video(playlistItem.url)
    } else if (playlistItem.type === 'video') {
      setOpenVideo(playlistItem.url)
    }
  }

  /*const Container = useMemo(({children}) => {
    if (enabled) {
      return (
        <Card 
          {...attributes} 
          {...listeners} 
          ref={setNodeRef} 
          style={{transform: CSS.Translate.toString(transform), transition, maxWidth: 500}}
        >
          {children}
        </Card>
        )
    } else {
      return (
        <Card
          style={{transform: transition, maxWidth: 500}}
        >
          {children}
        </Card>
      )
    }
    
  }, [enabled, setNodeRef])*/

  const props = enabled ? {...attributes, ...listeners} : {}

  return (
    <Card 
      {...props} 
      ref={enabled ? setNodeRef : null} 
      style={{
        maxWidth: 500,
        cursor: 'pointer'
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (enabled) {
          handleAddLast(`${element.id}##${v4()}`)
        }
      }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item item xs={12} xl={4}>
              {['video', '360video', 'panorama'].includes(element.type) && <img src={element.thumbnailUrl} style={{maxWidth: '100%'}} />}
              {element.type === 'showPdf' && <PictureAsPdfIcon sx={{ fontSize: 40 }} />}
          </Grid>
          <Grid item xs={12} xl={6} align="left">
            {element.name}
          </Grid>
          <Grid item xs={6} xl={1} align="center">
            <IconButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                downloadFile(element)
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} xl={1} align="center">
            <IconButton
              size="small"
              href={element.type === 'showPdf'
                ? `${window.location.origin}/#/pdfviewer/${element.url}`
                : `${window.location.origin}/#/tour/preview/${element.id}`
              }
              target="_blank"
            >
              <PreviewIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Draggable