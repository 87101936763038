import React, {useState} from 'react'

import {useAtom} from 'jotai'
import {useUpdateAtom} from 'jotai/utils'

import {styled} from '@mui/material/styles'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MuiInputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'
import {FormattedMessage} from 'react-intl'

import ErrorBoundary from 'components/ErrorBoundary'
import {useDispatch, useSelector} from 'react-redux'
import {
  getFontSize,
  getSound,
  setFontSize,
  setSound,
} from 'components/TourControls/duck'
import {stepsChatbotAtom, tempStepsChatbotAtom} from 'components/jotai'

const Select = styled(MuiSelect)(({theme}) => ({
  border: 'none',
  '& fieldset': {
    border: 'none',
  },
}))

const InputLabel = styled(MuiInputLabel)(({theme}) => ({
  transform: 'none',
  webkkitTransform: 'none',
  display: 'contents',
}))

export default function Settings() {
  const dispatch = useDispatch()
  const size = useSelector(getFontSize)
  const sound = useSelector(getSound)
  const [tempSteps, setTempSteps] = useAtom(tempStepsChatbotAtom)
  const setSteps = useUpdateAtom(stepsChatbotAtom)

  const [currentSize, setCurrentSize] = useState(size)
  const [currentSound, setCurrentSound] = useState(sound)

  const handleChangeSize = event => {
    setCurrentSize(event.target.value)
  }

  const handleChangeSound = event => {
    setCurrentSound(event.target.value)
  }

  const handleAccept = event => {
    dispatch(setFontSize(currentSize))
    dispatch(setSound(currentSound))
    setSteps(tempSteps)
    setTempSteps([])
  }

  return (
    <ErrorBoundary>
      <Grid container spacing={2} sx={{padding: '0 16px'}}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '22px',
              lineHeight: '24px',
            }}
          >
            <FormattedMessage
              id="chatbot.settings.title"
              defaultMessage={`Einstellungen`}
            />
            <br />-
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <FormattedMessage
              id="chatbot.settings.description"
              defaultMessage={`Diese Einstellungen können Sie für Laura, Ihren digitalen Tourguide, vornehmen.`}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <InputLabel id="chatbot-settings-size-label">
                  <FormattedMessage
                    id="chatbot.settings.size.label"
                    defaultMessage={`Schriftgröße`}
                  />
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="chatbot-settings-size-label"
                  id="chatbot-settings-size"
                  value={currentSize}
                  label="Schriftgröße"
                  onChange={handleChangeSize}
                >
                  <MenuItem value={'small'}>
                    <FormattedMessage
                      id="chatbot.settings.size.small.label"
                      defaultMessage={`klein`}
                    />
                  </MenuItem>
                  <MenuItem value={'medium'}>
                    <FormattedMessage
                      id="chatbot.settings.size.medium.label"
                      defaultMessage={`mittel`}
                    />
                  </MenuItem>
                  <MenuItem value={'big'}>
                    <FormattedMessage
                      id="chatbot.settings.size.big.label"
                      defaultMessage={`groß`}
                    />
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <InputLabel id="chatbot-settings-sound-label">
                  <FormattedMessage
                    id="chatbot.settings.sound.label"
                    defaultMessage={`Sprachausgabe`}
                  />
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="chatbot-settings-sound-label"
                  id="chatbot-settings-sound"
                  value={currentSound}
                  label="Sprachausgabe"
                  onChange={handleChangeSound}
                >
                  <MenuItem value={true}>
                    <FormattedMessage
                      id="chatbot.settings.sound.on.label"
                      defaultMessage={`ein`}
                    />
                  </MenuItem>
                  <MenuItem value={false}>
                    <FormattedMessage
                      id="chatbot.settings.sound.off.label"
                      defaultMessage={`aus`}
                    />
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12} align="right">
          <Button variant="outlined" color="secondary" onClick={handleAccept}>
            <FormattedMessage
              id="chatbot.settings.accept.label"
              defaultMessage={`Ok`}
            />
          </Button>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
