import {
  useDroppable,
} from '@dnd-kit/core';
import {
  verticalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import Grid from '@mui/material/Grid'
import SortableItem from './sortableItem'

const Droppable = ({
  id,
  items,
  playlistItems,
  setOpenPanorama,
  setOpen360Video,
  setOpenVideo,
  isPublicDragging,
  handleDelete
}) => {
  const { setNodeRef, isOver } = useDroppable({ id });

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div
        ref={setNodeRef}
        style={{width: '100%', height: '100%', border: `2px dashed ${isOver ? 'red' : 'black'}`}}
      >
        <Grid container spacing={0} sx={{maxWidth: 700, margin: '0px auto'}}>
          {items.map((id, index) => {
            const splits = id.split('##')
            const playlistItem = playlistItems[splits[0]]
            return (
              <SortableItem
                index={index}
                id={id}
                element={playlistItem}
                playlistItems={playlistItems}
                setOpenPanorama={setOpenPanorama}
                setOpen360Video={setOpen360Video}
                setOpenVideo={setOpenVideo}
                isPublicDragging={isPublicDragging}
                handleDelete={handleDelete}
              />
            )
          })}
        </Grid>
      </div>
    </SortableContext>
  )
};

export default Droppable