import {getMediaData} from 'components/Navigation/duck'
import {createMachine, assign} from 'xstate'
import {isPanorama, is360Video} from 'components/Navigation/duck'
const tourStriebelMachine = createMachine({
  id: 'tourStriebel',
  initial: 'szene',
  context: {
    szene: undefined,
    filename: undefined,
    duration: 0,
    timeslots: [],
    szenePoi: undefined,
    filenamePoi: undefined,
    durationPoi: 0,
    timeslotsPoi: [],
    soundTitle: null,
    soundTitlePoi: null,
    subtitle: null,
    subtitlePoi: null,
    nextTarget: undefined,
    previousTarget: undefined,
    seekAudio: false,
    seekAudioPoi: false,
    visited: null,
  },
  states: {
    szene: {
      on: {
        TOUR_LOADED: {
          actions: ['tourStart'],
        },
        TOUR_NAVIGATION_LOADED: {
          actions: ['tourNavigationLoaded'],
        },
        ENTER: {
          actions: [
            'setVisited',
            assign({
              visited: (context, _) => {
                return context?.szene
              },
              szene: (context, e) => {
                return getMediaData(
                  window?.blazeIT?.getCurrentPlayListItem(),
                  'label',
                )
              },
              filename: (_, e) => {
                const subtitle = getMediaData(
                  window?.blazeIT?.getCurrentPlayListItem(),
                  'data',
                )?.subtitle
                const split = subtitle?.split('##')
                return Array.isArray(split) ? split[1] : subtitle
              },
              videoFile: (_, e) => {
                const playlistItem = window?.blazeIT?.getCurrentPlayListItem()
                if (playlistItem) {
                  if (!isPanorama(playlistItem) && !is360Video(playlistItem)) {
                    const media = window?.blazeIT?.getCurrentMedia()
                    if (media) {
                      return media
                        ?.get('video')
                        ?.get('levels')[0]
                        ?.get('url')
                    }
                  }
                }
                return null
              },
              timeslots: (_, e) => {
                const playlistItem = window?.blazeIT?.getCurrentPlayListItem()
                if (playlistItem) {
                  if (!isPanorama(playlistItem) && !is360Video(playlistItem)) {
                    return (
                      window?.blazeIT
                        ?.getHotspots()
                        ?.filter(el => {
                          const label = el.get('data')?.label
                          if (label?.includes('Timeslot')) {
                            const media = window?.blazeIT?.getCurrentMedia()
                            if (media) {
                              if (label.includes(media.get('label'))) {
                                return true
                              }
                            }
                          }
                          return false
                        })
                        .map((el, index) => {
                          if (el.get('items')?.length > 0) {
                            const positions = el
                              .getx('items')[0]
                              ?.get('playbackPositions')
                            if (positions?.length > 1) {
                              const splits = el.get('data')?.label?.split('##')
                              return {
                                position: positions[1]?.get('timestamp'),
                                label:
                                  Array.isArray(splits) && splits.length > 2
                                    ? splits[2]
                                    : `timeSlot${index}`,
                              }
                            }
                          }
                          return null
                        })
                        .filter((el, pos, self) => {
                          return el && self.indexOf(el) === pos
                        }) || []
                    )
                  }
                }
                return []
              },
            }),
            'enter',
            'controlChatbot',
            'controlButtons',
            'calcluateNavigation',
            'calculateSoundAndCaptions'
          ],
        },
        ENTER_DELAYED: {
          actions: [
            assign({
              duration: (_, e) => {
                if (e?.duration) {
                  return e.duration
                }

                const playlistItem = window?.blazeIT?.getCurrentPlayListItem()
                if (playlistItem) {
                  if (!isPanorama(playlistItem)) {
                    const media = window?.blazeIT?.getCurrentMedia()
                    if (media) {
                      if (is360Video(playlistItem)) {
                        const overlays =
                          window?.blazeIT?.getComponentAttributes(media)
                            .overlays || []

                        if (overlays && Array.isArray(overlays)) {
                          const stopHotspot = overlays.find(el => {
                            if (
                              el &&
                              el.get('class')?.includes('HotspotPanorama')
                            ) {
                              let attributes = window?.blazeIT?.getComponentAttributes(
                                el,
                              )
                              return (
                                attributes?.data?.label === 'Stop360Video' ||
                                attributes?.data?.label === 'Stop'
                              )
                            }
                            return false
                          })

                          if (stopHotspot) {
                            let attributes = window?.blazeIT?.getComponentAttributes(
                              stopHotspot,
                            )
                            if (
                              attributes?.items &&
                              Array.isArray(attributes.items)
                            ) {
                              for (
                                let i = 0;
                                i < attributes.items.length;
                                i++
                              ) {
                                const hotSpots =
                                  attributes.items[i].get(
                                    'playbackPositions',
                                  ) || []

                                const key = hotSpots?.find(keyFrame => {
                                  return keyFrame.get('opacity') === 1
                                })

                                if (key) {
                                  return key.get('timestamp')
                                }
                              }
                            }
                          }
                        }
                      }

                      return media.get('duration')
                    }
                  }
                }
                return 0
              },
            }),
            'backBegin',
          ],
        },
        ENTER_POI: {
          actions: [
            assign({
              szenePoi: (context, e) => {
                if (e?.name) {
                  return e.name
                }

                const item = window?.blazeIT
                  ?.getPlayListItems()
                  .find(
                    el =>
                      el.get('media').get('state') === 'playing' &&
                      getMediaData(el, 'label') !== context.szene,
                  )

                return item ? getMediaData(item, 'label') : null
              },
              filenamePoi: (context, e) => {
                if (e?.filename) {
                  return e.filename
                }

                const item = window?.blazeIT
                  ?.getPlayListItems()
                  .find(
                    el =>
                      el.get('media').get('state') === 'playing' &&
                      getMediaData(el, 'label') !== context.szene,
                  )

                if (item) {
                  const subtitle = getMediaData(item, 'data')?.subtitle
                  const split = subtitle?.split('##')
                  return Array.isArray(split) ? split[1] : subtitle
                }

                return null
              },
              videoFilePoi: (context, e) => {
                const playlistItem = window?.blazeIT
                  ?.getPlayListItems()
                  .find(
                    el =>
                      el.get('media').get('state') === 'playing' &&
                      getMediaData(el, 'label') !== context.szene,
                  )

                if (playlistItem) {
                  if (!isPanorama(playlistItem) && !is360Video(playlistItem)) {
                    const media = playlistItem?.get('media')
                    if (media) {
                      return media
                        ?.get('video')
                        ?.get('levels')[0]
                        ?.get('url')
                    }
                  }
                }
                return null
              },
              timeslotsPoi: (context, e) => {
                const playlistItem = window?.blazeIT
                  ?.getPlayListItems()
                  .find(
                    el =>
                      el.get('media').get('state') === 'playing' &&
                      getMediaData(el, 'label') !== context.szene,
                  )
                if (playlistItem) {
                  if (!isPanorama(playlistItem) && !is360Video(playlistItem)) {
                    return (
                      window?.blazeIT
                        ?.getHotspots()
                        ?.filter(el => {
                          const label = el.get('data')?.label
                          if (label?.includes('Timeslot')) {
                            const media = window?.blazeIT?.getCurrentMedia()
                            if (media) {
                              if (label.includes(media.get('label'))) {
                                return true
                              }
                            }
                          }
                          return false
                        })
                        .map((el, index) => {
                          if (el.get('items')?.length > 0) {
                            const positions = el
                              .get('items')[0]
                              ?.get('playbackPositions')
                            if (positions?.length > 1) {
                              const splits = el.get('data')?.label?.split('##')
                              return {
                                position: positions[1]?.get('timestamp'),
                                label:
                                  Array.isArray(splits) && splits.length > 2
                                    ? splits[2]
                                    : `timeSlot${index}`,
                              }
                            }
                          }
                          return null
                        })
                        .filter((el, pos, self) => {
                          return el && self.indexOf(el) === pos
                        }) || []
                    )
                  }
                }
                return []
              },
            }),
            'enterPoi',
            'controlChatbot',
            'calculatePoiSoundAndCaptions'
          ],
        },
        ENTER_POI_DELAYED: {
          actions: [
            assign({
              durationPoi: (context, e) => {
                if (e?.duration) {
                  return e.duration
                }

                const playlistItem = window?.blazeIT
                  ?.getPlayListItems()
                  .find(
                    el =>
                      el.get('media').get('state') === 'playing' &&
                      getMediaData(el, 'label') !== context.szene,
                  )

                if (playlistItem) {
                  if (!isPanorama(playlistItem)) {
                    const media = playlistItem.get('media')
                    if (media) {
                      if (is360Video(playlistItem)) {
                        const overlays =
                          window?.blazeIT?.getComponentAttributes(media)
                            .overlays || []

                        if (overlays && Array.isArray(overlays)) {
                          const stopHotspot = overlays.find(el => {
                            if (
                              el &&
                              el.get('class')?.includes('HotspotPanorama')
                            ) {
                              let attributes = window?.blazeIT?.getComponentAttributes(
                                el,
                              )
                              return (
                                attributes?.data?.label === 'Stop360VideoPoi' ||
                                attributes?.data?.label === 'Stop'
                              )
                            }
                            return false
                          })
                          if (stopHotspot) {
                            let attributes = window?.blazeIT?.getComponentAttributes(
                              stopHotspot,
                            )
                            if (
                              attributes?.items &&
                              Array.isArray(attributes.items)
                            ) {
                              for (
                                let i = 0;
                                i < attributes.items.length;
                                i++
                              ) {
                                const hotSpots =
                                  attributes.items[i].get(
                                    'playbackPositions',
                                  ) || []
                                const key = hotSpots?.find(keyFrame => {
                                  return keyFrame.get('opacity') === 1
                                })

                                if (key) {
                                  return key.get('timestamp')
                                }
                              }
                            }
                          }
                        }
                      }

                      return media.get('duration')
                    }
                  }
                }
                return 0
              },
            }),
          ],
        },
        OPEN_POI: {
          actions: ['openPoi'],
        },
        CLOSE_POI: {
          actions: [
            'closePoi',
            assign({
              szenePoi: (_, e) => {
                return undefined
              },
              filenamePoi: (_, e) => {
                return undefined
              },
              timeSlotsPoi: (_, e) => {
                return 0
              },
              durationPoi: (_, e) => {
                return 0
              },
              soundTitlePoi: (_, e) => {
                return null
              },
              subtitlePoi: (_, e) => {
                return null
              },
            }),
            'controlChatbot',
          ],
        },
        LEAVE: {
          actions: ['end', 'navigate'],
        },
        LEAVE_POI: {
          actions: ['endPoi'],
        },
        NEXT: {
          actions: ['next'],
        },
        BACK: {
          actions: ['back'],
        },
        GO_TO_START: {
          actions: ['goToStart'],
        },
        GO_TO_END: {
          actions: ['goToEnd'],
        },
        EXIT: {
          actions: ['exit'],
        },
        ENTER_FULLSCREEN_POI: {
          actions: ['enterFullscreenPoi'],
        },
        EXIT_FULLSCREEN_POI: {
          actions: ['exitFullscreenPoi'],
        },
        SET_SOUNDTITLE_POI: {
          actions: [
            assign({
              szenePoi: (context, e) => {
                if (e?.soundTitlePoi) {
                  return e.soundTitlePoi
                }
                return context.soundTitlePoi
              },
            }),
            'enterPoi',
            'calculatePoiSoundAndCaptions'
          ]
        },
        CHANGE_LANGUAGE: {
          actions: [
            'calculateSoundAndCaptions',
            'calculatePoiSoundAndCaptions',
            assign({
              seekAudio: (context, e) => {
                return context.soundTitle ? true : false
              },
              seekAudioPoi: (context, e) => {
                return context.soundTitlePoi ? true : false
              },
            }),
          ]
        },
        SEEK_AUDIO: {
          actions: [
            assign({
              seekAudio: (context, e) => {
                return false
              },
            }),
            'seekAudio'
          ]
        },
        SEEK_AUDIO_POI: {
          actions: [
            assign({
              seekAudioPoi: (context, e) => {
                return false
              },
            }),
            'seekAudioPoi'
          ]
        }
      },
      entry: ['navigate'],
    },
  },
})

export default tourStriebelMachine
