import {FormattedMessage} from 'react-intl'
import Typography from '@mui/material/Typography'
import {useSelector} from 'react-redux'
import {getCalculatedFontSize} from 'components/TourControls/duck'

const Message = ({message}) => {
  const fontSize = useSelector(getCalculatedFontSize)
  return (
    <div className="rsc-ts rsc-ts-bot" style={{width: '100%'}}>
      <div className="rsc-ts-bubble">
        <Typography style={{fontSize: fontSize}}>
          <FormattedMessage
            id={message.id}
            defaultMessage={message.defaultMessage}
          />
        </Typography>
      </div>
    </div>
  )
}

export default Message