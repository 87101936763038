import React, {useState, useEffect, useMemo} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import {useUpdateAtom, useAtomValue} from 'jotai/utils'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {getCalculatedFontSize} from 'components/TourControls/duck'
import ErrorBoundary from 'components/ErrorBoundary'
import {getNavigationContext} from 'components/xstate/NavigationMachine'
import {getFilteredNavgigation} from 'components/Navigation/duck'
import {getFilteredVisited} from 'components/visited/duck'

import {setOpen} from 'components/TourControls/duck'
import useConfig from 'components/Chatbot/useConfig'
import {stepsChatbotAtom, tourMachineAtom} from 'components/jotai'
import useStationMessages from 'components/Hooks/useStationMessages'
import {useActor} from '@xstate/react'

export const TourBack = ({exit}) => {
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const fontSize = useSelector(getCalculatedFontSize)
  const defaultStep = useConfig('default')

  const handleBack = () => {
    setStepsChatbot(defaultStep)
  }

  return (
    <Box sx={{background: 'transparent', padding: '19px 29px', width: '100%'}}>
      <Grid container alignItems="center">
        <Grid item xs={6} onClick={handleBack}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} sx={{alignSelf: 'center', textAlign: 'right'}}>
          <Typography style={{fontSize: fontSize}}>
            <FormattedMessage
              id="chatbot.tour.tour.header"
              defaultMessage="Tour wechseln"
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export const SelectTour = ({exit = false, empfang = false}) => {
  const fontSize = useSelector(getCalculatedFontSize)

  const {stateKey} = useSelector(getNavigationContext)
  const navigation = useSelector(getFilteredNavgigation)
  const visited = useSelector(getFilteredVisited)
  const dispatch = useDispatch()
  const messages = useStationMessages()
  const intl = useIntl()
  const tourService = useAtomValue(tourMachineAtom)

  const [tour] = useActor(tourService)

  const handleOpen = id => {
    dispatch(setOpen(false))
    switch (id) {
      case 'Empfang':
        dispatch({type: 'LUEDENSCHEID_EMPFANG'})
        break
      case 'Logistik':
        dispatch({type: 'LUEDENSCHEID_LOGISTIK'})
        break
      case 'Produktion':
        dispatch({type: 'LUEDENSCHEID_PRODUKTION'})
        break
      case 'Schulungszentrum':
        dispatch({type: 'LUEDENSCHEID_SCHULUNGSZENTRUM'})
        break
      case 'Aussengelaende':
        dispatch({type: 'LUEDENSCHEID_AUSSENGELAENDE'})
        break
      default:
        break
    }
  }

  const handleNavigatePanorama = label => {
    const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(label)
    if (playlistItem) {
      dispatch(setOpen(false))
      window?.blazeIT?.navigateToPlayListItem(playlistItem)
    }
  }

  const place = useMemo(() => {
    if (stateKey === 'abb') {
      return 'Heidelberg'
    } else if (stateKey === 'striebel') {
      return 'Sasbach'
    } else if (stateKey.includes('bje')) {
      return 'Lüdenscheid'
    }
    return ''
  }, [stateKey])

  const luedenscheidTouren = useMemo(() => {
    if (stateKey.includes('bje')) {
      return [
        'Empfang',
        'Produktion',
        'Logistik',
        'Schulungszentrum',
        'Aussengelaende',
      ].filter(el => `bje${el}` !== stateKey)
    }

    return []
  }, [stateKey])

  return (
    <ErrorBoundary>
      <div className="rsc-os">
        <Grid
          container
          className="rsc-os-options"
          sx={{padding: '20px'}}
          spacing={2}
        >
          {(stateKey === 'abb' || stateKey === 'striebel') && (
            <>
              {Object.keys(navigation).map(key => {
                if (
                  navigation[key].find(el => el.label === tour?.context?.szene)
                ) {
                  return null
                }

                const isVisited = visited.reduce((value, item) => {
                  if (!value) {
                    return navigation[key].find(el => el.label === item) ? true : false
                  }
                  return value
                }, false)

                return (
                  <Grid
                    item
                    xs={10}
                    className="rsc-os-option-element"
                    sx={{cursor: 'pointer'}}
                    onClick={() =>
                      handleNavigatePanorama(navigation[key][0]?.label)
                    }
                  >
                    <div className="rsc-os">
                      <Grid container>
                        <Grid item xs={11}>
                          <Typography style={{fontSize: fontSize}}>
                            {messages[`${place}${key}`]
                              ? intl.formatMessage(messages[`${place}${key}`])
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} align="middle">
                          {isVisited && <CheckIcon />}
                        </Grid>
                      </Grid>
                      
                    </div>
                  </Grid>
                )
              })}
            </>
          )}
          {stateKey.includes('bje') && (
            <>
              {luedenscheidTouren.map((key, index) => {
                return (
                  <Grid
                    item
                    xs={10}
                    className="rsc-os-option-element"
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleOpen(key)}
                  >
                    <div className="rsc-os">
                      <Typography style={{fontSize: fontSize}}>
                        {messages[key]
                          ? intl.formatMessage(messages[key])
                          : ''}
                      </Typography>
                    </div>
                  </Grid>
                )
              })}
            </>
          )}
        </Grid>
      </div>
    </ErrorBoundary>
  )
}

export const PlaceOption = () => {
  const placeStep = useConfig('placeExit')
  const fontSize = useSelector(getCalculatedFontSize)

  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const handleChangePlace = () => {
    setStepsChatbot(placeStep)
  }
  return (
    <Grid
      item
      xs={10}
      className="rsc-os-option-element"
      sx={{cursor: 'pointer'}}
      onClick={handleChangePlace}
    >
      <div className="rsc-os">
        <Typography style={{fontSize: fontSize}}>
          <FormattedMessage
            id="chatbot.tour.exit.option1"
            defaultMessage="Standort wechseln"
          />
        </Typography>
      </div>
    </Grid>
  )
}
