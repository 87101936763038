import {createSlice, createDraftSafeSelector} from '@reduxjs/toolkit'
import {getFilteredFavorites} from 'components/Favorites/duck'
import {getFilteredVisited} from 'components/visited/duck'
import {getNavigationContext} from 'components/xstate/NavigationMachine'

export const initialState = {
  pages: {
    striebel: [],
    bjeLogistik: [],
    bjeProduktion: [],
    bjeSchulungszentrum: [],
    bjeFree: [],
    bjeAussengelaende: [],
    bjeEmpfang: [],
    abb: [],
  },
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation(state, action) {
      state.pages[action.payload.id] = action.payload.pages
    },
  },
})

export const {setNavigation} = navigationSlice.actions
export default navigationSlice.reducer

export const getNavigation = state => state.navigation.pages

export const getFilteredNavgigation = createDraftSafeSelector(
  [getNavigation, getNavigationContext],
  (navigation, context) => {
    let nav =
      context?.stateKey && navigation[context?.stateKey]
        ? navigation[context.stateKey]
        : []

      return nav
  },
)

export const getFlattenedNavigation = createDraftSafeSelector(
  [getFilteredNavgigation],
  navigation => {
    if (Array.isArray(navigation)) {
      return navigation
    } else {
      return Object.keys(navigation).reduce((acc, id) => {
        if (navigation && navigation[id] && Array.isArray(navigation[id])) {
          return [...acc, ...navigation[id]]
        }
        return acc
      }, [])
    }
  },
)

export const getFirstPlaylistItem = createDraftSafeSelector(
  [getFlattenedNavigation],
  navigation => {
    if (navigation && Array.isArray(navigation) && navigation.length > 0) {
      return navigation[0]
    }
    return null
  },
)

export const getTour = createDraftSafeSelector(
  [getFilteredNavgigation, getFilteredFavorites],
  (navigation, favorites) => {
    return Object.keys(navigation).reduce((acc, id) => {
      acc[id] = (navigation && navigation[id] && Array.isArray(navigation[id]) ? navigation[id] : [navigation[id]]).map((el, index) => {
        return {
          ...el,
          isFavorite: Boolean(favorites.find(el2 => el2 === el?.label)),
        }
      })
      return acc
    }, {})
  },
)

export const getVisitedTour = createDraftSafeSelector(
  [getTour, getFilteredVisited],
  (tour, visited) => {
    let tmpTour = JSON.parse(JSON.stringify(tour))
    tmpTour = Object.keys(tmpTour).reduce((acc, id) => {
      acc[id] = tmpTour[id].map((el, index) => {
        return visited.find(el2 => el2 === el.label) ? el : null
      })
      return acc
    }, {})
    return tmpTour
  },
)

export const vistaProperty = 'GA'
export const getMediaData = (el, property) => {
  return el?.get('media')?.get(property)
}
export const isPanorama = item => {
  return item?.get('class')?.includes('Panorama')
}

export const isVideo = item => {
  return item?.get('class')?.includes('VideoPlayListItem')
}

export const is360Video = item => {
  return item?.get('class')?.includes('Video360PlayListItem')
}
