import React, {useEffect} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {useUpdateAtom, useAtomValue} from 'jotai/utils'
import {useMeasure} from 'react-use'
import {useDispatch} from 'react-redux'
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton'

import ErrorBoundary from 'components/ErrorBoundary'

import TourView from 'components/TourView'
//import StartPage from 'components/StartPage'
import Redirect from 'components/Redirect'
import Caption from 'components/Caption'
import Sound from 'components/Sound'
import Sidebar from 'components/Layout/components/Sidebar'
import IndividuelleTourView from 'components/IndividuelleTourView'
import IndividuelleTourPreview from 'components/IndividuelleTourPreview'
import {dimensionsAtom, tourMachineAtom} from 'components/jotai'


export default function Content({children, isProduction}) {
  const [ref, {height, width}] = useMeasure()
  const setDimensions = useUpdateAtom(dimensionsAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const dispatch = useDispatch()

  useEffect(() => {
    setDimensions({height, width})
  }, [height, width, setDimensions])

  useEffect(() => {
    setDimensions({height, width})
  }, [height, width, setDimensions])

  //Disable overlaying iFrame which will be used locally
  useEffect(() => {
    setTimeout(() => {
      let iframes = document.getElementsByTagName('iframe')
      for (let i = 0; i < iframes.length; i++) {
        if (iframes[i].style['zIndex'] === '2147483647') {
          document.getElementsByTagName('iframe')[i].style['display'] = 'none'
        }
      }
    }, 3000)
  }, [])

  const process = {
    env: {
      REACT_APP_SSOCLIENTID: "plansoft-dev",
      REACT_APP_SSOCLIENTSECRET: "yX77A7PqkuaJ_NFVEUb\$uQDa",
      REACT_APP_SSOURL: "https://my-staging.busch-jaeger.de/sso/",
      REACT_APP_MYBJEPANEL: "https://my-staging.busch-jaeger.de/api/panel/js",
      REACT_APP_MYBUILDINGSAPI: "https://stage.api.eu.mybuildings.abb.com/"
    }
  }

  return (
    <ErrorBoundary>
      <div
        ref={ref}
        style={{height: '100vh', width: '100vw', overflow: 'hidden'}}
      >
        {/*<div style={{position: 'fixed', top: 10, right: 10, zIndex: 4000}}>
          {isLoggedIn && (
            <IconButton onClick={handleLogout}><LogoutIcon /></IconButton>
          )}
          {!isLoggedIn && (
            <IconButton href={`${process.env.REACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.REACT_APP_SSOCLIENTID}&popup=1&redirect_uri=${window.location.origin}`}><LoginIcon /></IconButton>
          )}
        </div>*/}
        <div id="content">
          <Routes>
            {/*<Route path="start" element={<StartPage />} />*/}
            <Route
              path="sasbach"
              element={<TourView brand="striebel" tour="striebel" />}
            />
            <Route path="luedenscheid" element={ <Navigate to="/tour/luedenscheid_empfang" /> }/>
            <Route
              path="luedenscheid_logistik"
              element={
                <TourView brand="bje" tour="bjeLogistik" />
              }
            />
            <Route
              path="luedenscheid_produktion"
              element={
                <TourView brand="bje" tour="bjeProduktion" />
              }
            />
            <Route
              path="luedenscheid_free"
              element={
                isProduction ? null : <TourView brand="bje" tour="bjeFree" />
              }
            />
            <Route
              path="luedenscheid_aussengelaende"
              element={
                <TourView brand="bje" tour="bjeAussengelaende" />
              }
            />
            <Route
              path="luedenscheid_empfang"
              element={
                <TourView brand="bje" tour="bjeEmpfang" />
              }
            />
            <Route
              path="luedenscheid_schulungszentrum"
              element={
                <TourView brand="bje" tour="bjeSchulungszentrum" />
              }
            />
            <Route
              path="heidelberg"
              element={<TourView brand="abb" tour="abb" />}
            />
            <Route path="redirect" element={<Redirect />} />
            <Route 
              path="individuelleTour/:id" 
              element={<IndividuelleTourView />}
            />
            <Route 
              path="preview/:id" 
              element={<IndividuelleTourPreview />}
            />
          </Routes>
          <Sidebar />
          {tourService && <Caption />}
          {tourService && <Sound />}
        </div>
      </div>
    </ErrorBoundary>
  )
}
