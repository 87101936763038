import SvgIcon from '@mui/material/SvgIcon'

export default function SettingsIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g
        id="Symbole"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="lauramenu" fill="#0F0F0F" fillRule="nonzero">
          <g id="Group">
            <path
              d="M20,11.4285714 L20,8.57142857 L17.6071429,8.05714286 C17.4228571,7.33121429 17.1341429,6.63582857 16.75,5.99285714 L18.0785714,3.93571429 L16.0642857,1.92142857 L14.0071429,3.25 C13.3641429,2.86585714 12.6687857,2.57712857 11.9428571,2.39285714 L11.4285714,0 L8.57142857,0 L8.05714286,2.39285714 C7.33121429,2.57712857 6.63582857,2.86585714 5.99285714,3.25 L3.93571429,1.92142857 L1.92142857,3.93571429 L3.25,5.99285714 C2.86585714,6.63582857 2.57712857,7.33121429 2.39285714,8.05714286 L0,8.57142857 L0,11.4285714 L2.39285714,11.9428571 C2.57712857,12.6687857 2.86585714,13.3641429 3.25,14.0071429 L1.92142857,16.0642857 L3.93571429,18.0785714 L5.99285714,16.75 C6.63571429,17.1342857 7.33142857,17.4228571 8.05714286,17.6071429 L8.57142857,20 L11.4285714,20 L11.9428571,17.6071429 C12.6687857,17.4228571 13.3641429,17.1341429 14.0071429,16.75 L16.0642857,18.0785714 L18.0785714,16.0642857 L16.75,14.0071429 C17.1342857,13.3642857 17.4228571,12.6685714 17.6071429,11.9428571 L20,11.4285714 Z M10,13.5714286 C9.05278571,13.5714286 8.14435714,13.1951429 7.47464286,12.5253571 C6.80484286,11.8556429 6.42857143,10.9472143 6.42857143,10 C6.42857143,9.05278571 6.80484286,8.14435714 7.47464286,7.47464286 C8.14435714,6.80484286 9.05278571,6.42857143 10,6.42857143 C10.9472143,6.42857143 11.8556429,6.80484286 12.5253571,7.47464286 C13.1951429,8.14435714 13.5714286,9.05278571 13.5714286,10 C13.5714286,10.9472143 13.1951429,11.8556429 12.5253571,12.5253571 C11.8556429,13.1951429 10.9472143,13.5714286 10,13.5714286 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

SettingsIcon.defaultProps = {
  color: '#0F0F0F',
  width: '28px',
  height: '20px',
}
