import SvgIcon from '@mui/material/SvgIcon';

export default function UntertitelDisabledIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="subtitle-off" transform="translate(-7.000000, -7.000000)">
              <g id="Group" transform="translate(10.000000, 13.000000)" stroke={props.color} strokeWidth="2">
                  <rect id="Rectangle" x="1" y="1" width="26" height="21" rx="1"></rect>
                  <path d="M4,18 L24,18" id="Line"></path>
                  <path d="M4,14 L12,14" id="Line"></path>
                  <path d="M14,14 L24,14" id="Line"></path>
              </g>
              <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="42 37 36 42 7 13 12 7"></polygon>
              <polygon id="Path" fill={props.color} fillRule="nonzero" points="38.7750343 37.0835468 36.8353909 39 9.40466392 11.9164532 11.3443073 10"></polygon>
          </g>
      </g>
    </SvgIcon>
  );
}

UntertitelDisabledIcon.defaultProps = {
  color: '#0F0F0F',
  width: '35px',
  height: '35px',
};