import SvgIcon from '@mui/material/SvgIcon'

export default function ResizeIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g
        id="Symbole"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="lauramenu"
          transform="translate(-37.000000, -2.000000)"
          stroke="#000000"
          strokeWidth="1.75"
        >
          <g
            id="Group"
            transform="translate(45.000000, 10.000000) scale(-1, 1) translate(-45.000000, -10.000000) translate(38.000000, 3.000000)"
          >
            <polyline id="Rectangle" points="14 5.25 14 14 5.25 14"></polyline>
            <polyline
              id="Rectangle"
              transform="translate(4.375000, 4.375000) rotate(-180.000000) translate(-4.375000, -4.375000) "
              points="8.75 8.8817842e-16 8.75 8.75 0 8.75"
            ></polyline>
            <path d="M0.4375,0.4375 L13.125,13.125" id="Line"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

ResizeIcon.defaultProps = {
  color: '#000000',
  width: '16px',
  height: '16px',
}
