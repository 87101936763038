import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Dialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { FormattedMessage } from "react-intl";

const ShareDialog = (props) => {
  const { open, url, handleClose } = props;
  
  const handleCopy = (e) => {
    navigator.clipboard.writeText(url)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="confirm-dialog">
        <FormattedMessage
          id="shareDialog.title"
          defaultMessage="Tour teilen"
        />
      </DialogTitle>
      <DialogContent>
        <TextField
          disabled
          value={url}
          margin="dense"
          id="url"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleCopy}
          color="primary"
        >
          <FormattedMessage
            id="shareDialog.copy"
            defaultMessage="Kopieren"
          />
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
        >
          <FormattedMessage
            id="shareDialog.cancel"
            defaultMessage="Abbrechen"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;