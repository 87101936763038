import {useEffect, useState, useMemo} from 'react';
import Cookies from "js-cookie";
import axios from 'axios'
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai/utils'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import IosShareIcon from '@mui/icons-material/IosShare';
import { sidebarAtom } from 'components/jotai';
import {sidebarWitdh} from 'components/utils'
import useMessages from 'components/IndividuelleTour/useMessages'
import { getIsLoggedIn, invalidateUser } from 'components/user/duck';
import { getLanguage } from 'components/Language/duck'
import ConfirmDialog from 'components/ConfirmDialog'
import ShareDialog from 'components/ShareDialog'
import Tippy from '@tippyjs/react/headless'

const IndividuelleTourOverview = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [confirmOpen, setConfirmOpen] = useState(null)
  const [shareUrl, setShareUrl] = useState(null)
  const drawerOpen = useAtomValue(sidebarAtom)
  const [individuelleTours, setIndividuelleTours] = useState([])
  const messages = useMessages()
  const intl = useIntl()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const language = useSelector(getLanguage)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isLoggedIn && window) {
      window.location.href = `${process.env.REACT_APP_SSOURL}authorize?response_type=code&client_id=${process.env.REACT_APP_SSOCLIENTID}&popup=1&redirect_uri=${window.location.origin}`;
    }
  }, [isLoggedIn])

  const playlistItemsProps = useQuery({
    queryKey: ['playlistItems'],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/playlistItems`,
      }).then((res) => {
        return res?.data || []
      }),
      select: (data) => data.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {})
  })

  const defaultData = useMemo(() => {
    return [...Array(5)].map(() => {
      return {
        name: (Math.random() + 1).toString(36).substring(14),
        createdAt: new Date(),
        updatedAt: new Date(),
        tour: "[]",
        enabled: true,
      }
    })
  }, [])

  const individuelleToursProps = useQuery({
    queryKey: ['individuelleTours'],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/individuelleTours`,
      }).then((res) => {
        return res?.data || []
      }),
      select: (data) => data.map(el => {
        return {...el, tour: JSON.parse(el.tour)}
      }),
    enabled: isLoggedIn,
    placeholderData: defaultData
  })

  const data = individuelleToursProps.data ?? defaultData

  const deleteMutation = useMutation({
    mutationFn: (id) => {
      return axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/api/individuelleTour/${id}`,
      })
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ['individuelleTours'] })
      const previousTours = queryClient.getQueryData(['individuelleTours'])
      queryClient.setQueryData(['individuelleTours'], (old) => old.filter(tour => tour.id !== id))
      return { previousTours }
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(['individuelleTours'], context.previousTours)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['individuelleTours'] })
    },
  })

  const enabledMutation = useMutation({
    mutationFn: ({id, value}) => {
      return axios({
        method: 'GET',
        url: value
          ? `${process.env.REACT_APP_API_URL}/api/individuelleTour/${id}/enable`
          : `${process.env.REACT_APP_API_URL}/api/individuelleTour/${id}/disable`,
      })
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: ['individuelleTours'] })
      const previousTours = queryClient.getQueryData(['individuelleTours'])
      /*queryClient.setQueryData(['individuelleTours'], (old) => old.map(tour => {
        if (tour.id === variables.id) {
          return {
            ...tour,
            enabled: variables.val 
          }
        } else {
          return tour
        }
      }))*/
      return { previousTours }
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(['individuelleTours'], context.previousTours)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['individuelleTours'] })
    },
  })

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor(row => row.name, {
      id: "name",
      cell: info => info.getValue(),
      header: () => intl.formatMessage(messages.name),
      size: 'auto'
    }),
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      cell: info => dayjs(info?.getValue())?.format('DD.MM.YYYY'),
      header: () => intl.formatMessage(messages.createdAt),
      maxSize: 100
    }),
    columnHelper.accessor(row => row.updatedAt, {
      id: 'updatedAt',
      header: () => intl.formatMessage(messages.updatedAt),
      cell: info => dayjs(info?.getValue())?.format('DD.MM.YYYY'),
      maxSize: 100
    }),
    columnHelper.accessor(row => row.tour, {
      id: 'tour',
      header: () => intl.formatMessage(messages.countItems),
      cell: info => <div style={{textAlign: 'right'}}>{info.getValue()?.length || 0}</div>,
      maxSize: 120
    }),
    columnHelper.accessor(row => row.tour, {
      id: 'place',
      header: intl.formatMessage(messages.places),
      cell: info => {
        return info.getValue()?.reduce((acc, id) => {
          const item = playlistItemsProps.data[id]
          if (item) {
            const place = item.tourId.includes('bje')
              ? 'Lüdenscheid'
              : item.tourId === 'striebel'
                ? 'Sasbach'
                : 'Heidelberg'

            if (!acc.includes(place)) {
              if (acc === "") {
                acc = place
              } else {
                acc = `${acc}, ${place}`
              }
            }
          }
          return acc
        }, "") || ""
      },
      size: 'auto'
    }),
    columnHelper.accessor(row => row, {
      id: "control",
      header: () => null,
      cell: info => {
        const handleEdit = (e) => {
          e.stopPropagation();
          e.preventDefault();
          navigate(`/backend/${info.getValue()?.id}`)
        }

        const handleDelete = (e) => {
          e.stopPropagation();
          e.preventDefault();
          setConfirmOpen(info.getValue()?.id)
        }

        const handleChangeVisibility = (e) => {
          e.stopPropagation();
          e.preventDefault();
          enabledMutation.mutate({
            id: info.getValue()?.id,
            value: !info.getValue()?.enabled
          })
        }

        const handleShare = (e) => {
          e.stopPropagation();
          e.preventDefault();
          const url = `${window.origin}/#/tour/individuelleTour/${info.getValue()?.id}`
          let shareData = {
            title: "Factory-Tour Individuelle Tour",
            text: info.getValue()?.name,
            url: url,
          };

          if (!navigator.canShare) {
            setShareUrl(url)
          } else if (navigator.canShare(shareData)) {
            navigator.share(shareData)
          } else {
            setShareUrl(url)
          }
        }

        return (
          <Grid container>
            <Grid item>
              <Tippy
                render={attrs => (
                  <div
                    className="box"
                    style={{
                      backgroundColor: '#FFF',
                      padding: '2px 4px',
                      display: 'block',
                    }}
                    tabIndex="-1"
                    {...attrs}
                  >
                    {intl.formatMessage(info.getValue()?.enabled ? messages.tooltipDisable : messages.tooltipEnable)}
                  </div>
                )}
              >
                <IconButton onClick={handleChangeVisibility}>
                  {info.getValue()?.enabled
                    ? <LocationOnIcon />
                    : <LocationOffIcon />
                  }
                </IconButton>
              </Tippy>
            </Grid>
            <Grid item>
              <Tippy
                render={attrs => (
                  <div
                    className="box"
                    style={{
                      backgroundColor: '#FFF',
                      padding: '2px 4px',
                      display: 'block',
                    }}
                    tabIndex="-1"
                    {...attrs}
                  >
                    {intl.formatMessage(messages.tooltipView)}
                  </div>
                )}
              >
                <IconButton
                  href={`${window.location.origin}/#/tour/individuelleTour/${info.getValue()?.id}`}
                  target="_blank"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tippy>
            </Grid>
            <Grid item>
              <Tippy
                render={attrs => (
                  <div
                    className="box"
                    style={{
                      backgroundColor: '#FFF',
                      padding: '2px 4px',
                      display: 'block',
                    }}
                    tabIndex="-1"
                    {...attrs}
                  >
                    {intl.formatMessage(messages.tooltipEdit)}
                  </div>
                )}
              >
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              </Tippy>
            </Grid>
            <Grid item>
              <Tippy
                render={attrs => (
                  <div
                    className="box"
                    style={{
                      backgroundColor: '#FFF',
                      padding: '2px 4px',
                      display: 'block',
                    }}
                    tabIndex="-1"
                    {...attrs}
                  >
                    {intl.formatMessage(messages.tooltipShare)}
                  </div>
                )}
              >
                <IconButton onClick={handleShare}>
                  <IosShareIcon />
                </IconButton>
              </Tippy>
            </Grid>
            <Grid item>
              <Tippy
                render={attrs => (
                  <div
                    className="box"
                    style={{
                      backgroundColor: '#FFF',
                      padding: '2px 4px',
                      display: 'block',
                    }}
                    tabIndex="-1"
                    {...attrs}
                  >
                    {intl.formatMessage(messages.tooltipDelete)}
                  </div>
                )}
              >
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tippy>
            </Grid>
          </Grid>
        )
      },
      size: 'auto'
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleNew = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate('/backend/new')
  }

  const handleDeleteTour = () => {
    deleteMutation.mutate(confirmOpen)
    setConfirmOpen(null)
  }

  const handleCloseDialog = () => {
    setConfirmOpen(null)
  }

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    dispatch(invalidateUser());
    const host = window.location.host
    window.location.href = `${process.env.REACT_APP_MYABB_URL}/${language}/user/sso-logout?sso_logout_uri=&sso_host=${host}&client_id=${process.env.REACT_APP_SSOCLIENTID}&redirect_uri=${window.location.origin}`
  }

  return (
    <>
      <Grid container style={{height: '100vh', filter: !isLoggedIn || individuelleToursProps?.isFetching || playlistItemsProps?.isFetching ? 'blur(10px)' : 'none'}}>
        <Grid item xs={12} sx={{ padding: '32px', background: "#FEFEFE"}}>
          <Box flexDirection="column" p={0} display="flex" style={{height: '100%'}} >
            <Box p={1} flexGrow={0}>
              <Grid container>
                <Grid item xs={12} sx={{textAlign: 'right'}}>
                  {isLoggedIn && 
                    <Button
                      variant="text"
                      onClick={handleLogout}
                      startIcon={<LogoutIcon />}
                    >
                      <Typography>
                        {intl.formatMessage(messages.logout)}
                      </Typography>
                    </Button>
                  }
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h2">{intl.formatMessage(messages.title)}</Typography>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'right', alignSelf: 'center'}}>
                  <Button variant="contained" color="primary" onClick={handleNew}>
                    <Typography>
                      {intl.formatMessage(messages.new)}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box p={1} flexGrow={1} style={{position: 'relative'}}>
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  overflowY: 'auto'
                }}
              >
                <Grid container spacing={2}>
                  {
                    data?.length === 0 && 
                      <Grid item xs={12}>
                        <Typography>
                          {intl.formatMessage(messages.noElement)}
                        </Typography>
                      </Grid>
                  }
                  {data?.length > 0 &&
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            {table.getHeaderGroups().map(headerGroup => (
                              <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                  <TableCell key={header.id} style={{ width: header.getSize() }}>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableHead>
                          <TableBody>
                            {table.getRowModel().rows.map(row => (
                              <TableRow key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                  <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  }
                </Grid>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isLoggedIn && (individuelleToursProps?.isFetching || playlistItemsProps?.isFetching) &&
        <Grid container sx={{height: '100vh', alignContent: 'center', position: 'absolute', top: 0, left: 0}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <span>{intl.formatMessage(messages.loading)}</span>
          </Grid>
        </Grid>
      }
      <ConfirmDialog
        title={intl.formatMessage(messages.deleteConfirmTitle)}
        open={confirmOpen}
        setOpen={() => null}
        onConfirm={handleDeleteTour}
        onCancel={handleCloseDialog}
      >
        {intl.formatMessage(messages.deleteConfirmText)}
      </ConfirmDialog>
      <ShareDialog
        url={shareUrl}
        open={!!shareUrl}
        handleClose={() => setShareUrl(null)}
      />
    </>
  )
}

export default IndividuelleTourOverview