import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAtom} from 'jotai'
import {useUpdateAtom, useAtomValue} from 'jotai/utils'
import {assign} from 'xstate'
import axios from 'axios'
import useIsIndividualTour from 'components/IndividuelleTour/useIsIndividualTour'
import useNavigateToPlaylistItem from 'components/Hooks/useNavigateToPlaylistItem'
import {
  getFlattenedNavigation,
  setNavigation,
  getMediaData,
  isPanorama,
  is360Video,
  isVideo
} from 'components/Navigation/duck'
import {
  enableTourNaviAtom,
  isEndVideoAtom,
  isEndVideoPoiAtom,
  isPausedAtom,
  isPausedPoiAtom,
  tourMachineAtom,
  popupViewerSizeAtom,
  showPlaybackBarPoiAtom,
  stepsChatbotAtom,
  audioControlsAtom,
  audioTimeAtom,
  audioTimePoiAtom,
  audioControlsPoiAtom,
  shouldPlaySoundAtom,
  shouldPlaySoundPoiAtom,
  initialAudioTime,
  disableControlsAtom,
  soundEnabledAtom,
  subtitleEnabledAtom
} from 'components/jotai'
import {useMachine} from '@xstate/react'
import tourMachine from 'components/xstate/TourMachine'
import {setOpen} from 'components/TourControls/duck'
import {addVisited, vi} from 'components/visited/duck'
import {} from 'components/Navigation/duck'
import {
  getNavigationValue,
  getNavigationContext,
} from 'components/xstate/NavigationMachine'
import useConfig from 'components/Chatbot/useConfig'
import {getFilteredVisited, shouldPlaySoundOnlyOnce} from 'components/visited/duck'
import {getLanguage, changeVistaLanguage} from 'components/Language/duck'
import {sounds} from 'components/Sound/sound'
import {subtitles} from 'components/Caption/subtitles'

export default function MachineProvider({children}) {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch()
  const naviValue = useSelector(getNavigationValue)
  const naviContext = useSelector(getNavigationContext)
  const defaultStep = useConfig('default')
  const empfangStep = useConfig('empfang')
  const playSoundStep = useConfig('shouldPlaySound')
  const exitStep = useConfig('exit')
  const navigation = useSelector(getFlattenedNavigation)
  const updateEnableTourNavi = useUpdateAtom(enableTourNaviAtom)
  const setTourMachine = useUpdateAtom(tourMachineAtom)
  const setIsPaused = useUpdateAtom(isPausedAtom)
  const setIsPausedPoi = useUpdateAtom(isPausedPoiAtom)
  const setIsEndVideo = useUpdateAtom(isEndVideoAtom)
  const setIsEndVideoPoi = useUpdateAtom(isEndVideoPoiAtom)
  const setPopupViewerSize = useUpdateAtom(popupViewerSizeAtom)
  const setShowPlaybackBarPoi = useUpdateAtom(showPlaybackBarPoiAtom)
  const setShouldPlaySound = useUpdateAtom(shouldPlaySoundAtom)
  const setShouldPlaySoundPoi = useUpdateAtom(shouldPlaySoundPoiAtom)
  const setAudioTime = useUpdateAtom(audioTimeAtom)
  const setAudioTimePoi = useUpdateAtom(audioTimeAtom)
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const [disableControls, setDisableControls] = useAtom(disableControlsAtom)
  const soundControls = useAtomValue(audioControlsAtom)
  const soundControlsPoi = useAtomValue(audioControlsPoiAtom)
  const soundEnabled = useAtomValue(soundEnabledAtom)
  const untertitelEnabled = useAtomValue(subtitleEnabledAtom)

  const {finished, time} = useAtomValue(audioTimeAtom)
  const {diration: durationPoi, time: timePoi} = useAtomValue(audioTimePoiAtom)
  const visited = useSelector(getFilteredVisited)
  const language = useSelector(getLanguage)
  const isIndividualTour = useIsIndividualTour()
  const {navigateToPlaylistItem} = useNavigateToPlaylistItem()

  const goToEnd = context => {
    const playlistItem = window?.blazeIT?.getCurrentPlayListItem()
    if (playlistItem) {
      setIsEndVideo(false)
      const dur = context?.duration || 0

      window?.blazeIT?.setComponentAttributes(window?.blazeIT?.getCurrentMedia(), {
        currentTime: Math.max(0, dur - 2),
      })
      window?.blazeIT?.getComponent('Main Viewer').play()
      if (!finished) {
        soundControls?.seek(Math.max(0, dur - 2))
      }
    }
  }

  useEffect(() => {
    if (naviValue !== 'home' && naviValue !== 'individuell') {
      if (location?.pathname !== naviValue) {
        navigate(naviValue)
      }
    }
  }, [naviValue]) //eslint-disable-line  react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.pathname !== '/')
      if (location?.pathname !== naviValue) {
        let splits = location?.pathname.split('/') || []

        let target = splits[splits.length - 1]
        if (target === '') {
          target = splits[splits.length - 2]
        }

        dispatch({
          type: target.toUpperCase(),
        })
      }
  }, []) //eslint-disable-line  react-hooks/exhaustive-deps

  const [, sendTourMachine, tourService] = useMachine(tourMachine, {
    actions: {
      tourStart: () => {
        if (window?.blazeIT) {
          window.blazeIT.variables.tourStarted = true
          window.blazeIT.variables.crossingFoyer = true
          window.blazeIT.variables.backBegin = false
          window?.parent?.tour?.set('mute', !soundEnabled)
          window?.blazeIT?.getComponentByName('Main Viewer')?.set('subtitlesEnabled', untertitelEnabled)
          window?.blazeIT?.getComponentByName('Popup Viewer')?.set('subtitlesEnabled', untertitelEnabled)
        }
        
        changeVistaLanguage(language)
        setTimeout(() => {
          sendTourMachine('TOUR_NAVIGATION_LOADED')
        }, 2500)

        if (isIndividualTour) {
          window?.blazeIT?.getHotspots().forEach(hotspot => {
            const data = hotspot.get('data')
            if (data?.label?.toLowerCase().includes('goto') || data?.label?.toLowerCase().includes('exit')) {
              window?.blazeIT?.hideHotspot(hotspot)
            }
          })
        }


      },
      tourNavigationLoaded: context => {
        if (window?.blazeIT && !isIndividualTour) {
          dispatch(
            setNavigation({
              id: naviContext?.stateKey,
              pages: window?.blazeIT?.getPlayListItems().reduce((acc, item) => {
                const splits = getMediaData(item, 'data')?.subtitle?.split('##')
                const id =
                  Array.isArray(splits) && splits.length > 2 ? splits[2] : '1'
                if (!acc[id]) {
                  acc[id] = []
                }

                const label = getMediaData(item, 'label')

                if (!label?.includes('_POI_') && !label?.includes('.')) {
                  acc[id].push({
                    label: label,
                    subtitle:
                      Array.isArray(splits) && splits.length > 0
                        ? splits[0]
                        : getMediaData(item, 'data')?.subtitle,
                    thumbnailUrl: getMediaData(item, 'thumbnailUrl'),
                  })
                }
                return acc
              }, {}),
            }),
          )

          const mappedData = window?.blazeIT?.getPlayListItems().map((item, index) => {
            const splits = getMediaData(item, 'data')?.subtitle?.split('##')

            const videoFileExtension = isPanorama(item) 
              ? '_hd_t.jpg'
              : isVideo(item) || is360Video(item) 
                ? '_de.mp4'
                : ''
            
            return {
              id: getMediaData(item, 'label'),
              name: Array.isArray(splits) && splits.length > 0
              ? splits[0]
              : getMediaData(item, 'data')?.subtitle,
              url: `${getMediaData(item, 'baseDirectoy')}media/${getMediaData(item, 'id')}${videoFileExtension}`,
              thumbnailUrl: getMediaData(item, 'thumbnailUrl'),
              pois: JSON.stringify(getMediaData(item, 'overlays')?.filter(el => {
                return el.get('data')?.label.includes('POI_')
              }).map(el => el.get('data').label.replace('POI_', "")) || []),
              tourId: naviContext?.stateKey,
              type:  isPanorama(item) ? 'panorama' : is360Video(item) ? '360video' : isVideo(item) ? 'video' : '',
              index: index
            }
          })
          
          const hotspotData = window?.blazeIT?.getHotspots().filter(el => {
            const label = el.get('data')?.label || ''
            const id = label.replace('POI_', '')
            return label.includes('POI') && !mappedData.find(el2 => el2.id === id)
          }).map((el, index) => {
            const handlerDefString = el.get('areas')[0]?.Xc?.click[0]?.handlerDef
            if (handlerDefString) {
              const splits = handlerDefString.split('}')
              const label = el.get('data')?.label || ''
              const id = label.replace('POI_', '')
              const handlerDef = JSON.parse((`${splits[0]}}`).replace("try{eval('window.parent.postMessage(", "").replaceAll("\\'", '"').replace('type', '"type"').replace('value', '"value"'))
              return {
                id: id,
                name: el.get('areas')[0].get('toolTip') || id,
                url: handlerDef?.value || "",
                thumbnailUrl: "",
                pois: "[]",
                tourId: naviContext?.stateKey,
                type:  handlerDef?.type || "",
                index: mappedData.length + index
              }
            }  
            return null
          }).filter(el => el)

          axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/playlistItems`,
            data: [...mappedData, ...hotspotData]
          })
        }
      },
      enter: (context, event, actionMeta) => {
        setDisableControls(false)
        window?.blazeIT?.hideComponent('WebFrame')
        
        if (isIndividualTour && context?.szene && context?.previousTarget) {
          if (!navigation.find(el => {
            if (el?.label) {
              return el.label === context.szene
            }
            return false
          })) {
            navigateToPlaylistItem(context.previousTarget)
          }
        }
        
        switch (context?.szene) {
          case 'AT002':
          case 'MS001':
          case 'H1001':
          case 'H4001':
          case 'PL001':
          case 'A001':
          case 'AG01':
          case 'BE03':
          case '301':
          case 'LO02':
          case 'AG05a':
          case 'SZ01':
          case 'E02':
          case 'CL01':
            if (window?.blazeIT?.getComponent('360Info')) {
              window?.blazeIT?.showComponent('360Info')
              setTimeout(() => {
                window?.blazeIT?.hideComponent('360Info')
              }, 3000)
            }
            break
          case 'E12':
            window?.blazeIT?.showComponent('WebFrame')
            window?.blazeIT
              .getComponent('WebFrame')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Rainer John',
                )}/${encodeURI('Produkt Marketing Specialist')}`,
              )
            break
          case 'SZ03':
            window?.blazeIT?.showComponent('WebFrame')
            const webFrame = window?.blazeIT?.getComponent('WebFrame')
            if (webFrame) {
              webFrame
              .set(
                'url',
                `${window.origin}/#/videoinfo/bje/${encodeURI(
                  'Lucas Bergmann',
                )}/${encodeURI('Infrastruktur Management')}`,
              )

              const frame = document.getElementById('tourFrame')
              if (frame) {
                const frameWidth = frame.getBoundingClientRect()?.width
                const frameHeight = frame.getBoundingClientRect()?.height
                if (frameWidth && frameHeight) {
                  const newWidth = (Math.min(frameWidth, 466) / frameWidth) * 100
                  const newHeight = (300 / frameHeight) * 100
                  webFrame.set('width', `${newWidth}%`)
                  webFrame.set('height', `${newHeight}%`)
                  webFrame.set('left', `0%`)
                  webFrame.set('top', `${100-newHeight}%`)
                }
              }
            }
            break
          default:
            break
        }

        if (isPanorama(window?.blazeIT?.getCurrentPlayListItem())) {
          setIsEndVideo(true)
        } else {
          setIsEndVideo(false)
        }

        const isVisited = visited?.find(
          c => c === context?.szene,
        )

        const playSoundOunce = shouldPlaySoundOnlyOnce.find(c => c === context?.szene)

        if (isVisited && playSoundOunce) {
          setShouldPlaySound(false)
          setAudioTime(initialAudioTime)
        } else {
          setShouldPlaySound(true)
        }

        setTimeout(() => {
          if (!finished) {
            soundControls?.play()
          }
        }, 2000)
      },
      enterPoi: (context, event, actionMeta) => {
        setIsEndVideoPoi(false)
        setIsPausedPoi(false)
        setIsPaused(true)
        if (
          window?.blazeIT?.getComponentAttributes('Main Viewer')
            ?.playbackState === 'playing'
        ) {
          window?.blazeIT?.getComponent('Main Viewer')?.pause()
        }
        if (!finished) {
          soundControls?.pause()
        }
        if (context?.szenePoi) {
          const playListItem = window?.blazeIT?.getPlayListItemByMediaName(
            context.szenePoi,
          )
          if (playListItem) {
            if (playListItem.get('media')?.get('class') === 'Video') {
              const width = playListItem
                .get('media')
                ?.get('video')
                .get('width')
              const height = playListItem
                ?.get('media')
                ?.get('video')
                .get('height')

              if (width && height) {
                const frame = document.getElementById('tourFrame')
                if (frame) {
                  const frameWidth = frame.getBoundingClientRect()?.width
                  const frameHeight = frame.getBoundingClientRect()?.height
                  if (frameWidth && frameHeight) {
                    const webFrameWidth = frameWidth * 0.8
                    const webFrameHeight = webFrameWidth * (height / width)

                    if (webFrameHeight > frameHeight * 0.8) {
                      webFrameHeight = frameHeight * 0.8
                      webFrameWidth = webFrameHeight * (width / height)
                    }

                    const popupViewer = window?.blazeIT?.getComponent(
                      'Popup Viewer',
                    )

                    if (popupViewer) {
                      const newWidth = (webFrameWidth / frameWidth) * 100
                      const newHeight = (webFrameHeight / frameHeight) * 100
                      const newX = (100 - newWidth) / 2
                      const newY = (100 - newHeight) / 2

                      popupViewer.set('width', `${newWidth}%`)
                      popupViewer.set('height', `${newHeight}%`)
                      popupViewer.set('left', `${newX}%`)
                      popupViewer.set('bottom', `${newY}%`)

                      setPopupViewerSize({
                        width: parseFloat(
                          popupViewer.get('width').replace('%', ''),
                        ),
                        height: parseFloat(
                          popupViewer.get('height').replace('%', ''),
                        ),
                        x: parseFloat(popupViewer.get('left').replace('%', '')),
                        y: parseFloat(
                          popupViewer.get('bottom').replace('%', ''),
                        ),
                      })

                      const webFramePopup = window?.blazeIT?.getComponent(
                        'WebFramePopup',
                      )
                      if (webFramePopup) {
                        webFramePopup.set('width', `${newWidth}%`)
                        webFramePopup.set('height', `${newHeight}%`)
                        webFramePopup.set('left', `${newX}%`)
                        webFramePopup.set('bottom', `${newY}%`)
                      }

                      const playbackBarPoi = window?.blazeIT?.getComponent(
                        'PlaybackBarPoi',
                      )
                      if (playbackBarPoi) {
                        playbackBarPoi.set('width', `${newWidth}%`)
                        playbackBarPoi.set('left', `${newX}%`)
                        playbackBarPoi.set('bottom', `${newY}%`)
                      }

                      const closePopup = window?.blazeIT?.getComponent(
                        'ClosePopup',
                      )
                      if (closePopup) {
                        closePopup.set('right', `${newX + 1}%`)
                        closePopup.set('top', `${newY + 1}%`)
                      }

                      const enterFullscreenPopup = window?.blazeIT?.getComponent(
                        'EnterFullscreenPopup',
                      )
                      if (enterFullscreenPopup) {
                        enterFullscreenPopup.set('right', `${newX + 3}%`)
                        enterFullscreenPopup.set('top', `${newY + 1}%`)
                      }

                      const exitFullscreenPopup = window?.blazeIT?.getComponent(
                        'ExitFullscreenPopup',
                      )
                      if (exitFullscreenPopup) {
                        exitFullscreenPopup.set('right', `${newX + 3}%`)
                        exitFullscreenPopup.set('top', `${newY + 1}%`)
                      }
                    }
                  }
                }
              }
            }
          }

          const isVisited = visited?.find(
            c => c === context?.szenePoi,
          )
          
          const playSoundOunce = shouldPlaySoundOnlyOnce.find(c => c === context?.szenePoi)
          
          if (isVisited && playSoundOunce) {
            setShouldPlaySoundPoi(false)
            setAudioTimePoi(initialAudioTime)
          } else {
            setShouldPlaySoundPoi(true)
          }
        }

        switch (context?.szenePoi) {
          case 'AT002.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Corell Göbel',
                )}/${encodeURI('Local product group manager (LPG) Germany (ELSP)')}`,
              )
            break
          case 'MS001.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Isabell Luft',
                )}/${encodeURI('Marketing & Communication Manager')}`,
              )
            break
          case 'MS002.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Kurt Friedrich',
                )}/${encodeURI('Schulungsleiter')}`,
              )
            break
          /*case 'H1001.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Sascha Pollmann',
                )}/${encodeURI('Produktionsleiter MCB Montage')}`,
              )
            break
          case 'H1001.2':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Larissa Schahn',
                )}/${encodeURI('Fertigungsteamleiterin MCB Montage')}`,
              )
            break*/
          case 'H1005.2':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Patrick Kölbl',
                )}/${encodeURI('Maschinenfacharbeiter Montagelinie 2')}`,
              )
            break
          case 'H1006.4':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Christian Schmitt',
                )}/${encodeURI('Maschinenfacharbeiter Montagelinie 2')}`,
              )
            break
          case 'H1015.7':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Thekie Haile',
                )}/${encodeURI('Maschinenfacharbeiter Prüflinie Desto 4')}`,
              )
            break
          case 'H1016.4':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Tanja Kief',
                )}/${encodeURI(
                  'Maschinenfacharbeiterin Finalisierung Nautilus',
                )}`,
              )
            break
          case 'PL001.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/abb/${encodeURI(
                  'Klaus Kling',
                )}/${encodeURI('Leiter Hochleistungsprüffeld')}`,
              )
            break
          case 'LO02.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/bje/${encodeURI(
                  'Jörg Siemers',
                )}/${encodeURI('Logistikleiter')}`,
              )
            break
          case 'P305.3':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/bje/${encodeURI(
                  'Timo Brix',
                )}/${encodeURI('Ausbildungsleiter')}`,
              )
            break
          case 'P207.0':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/bje/${encodeURI(
                  'Max Henke',
                )}/${encodeURI('Schichtleiter Steckdosenproduktion')}`,
              )
            break
          case 'P204.2':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/bje/${encodeURI(
                  'Anne Schaub',
                )}/${encodeURI(
                  'Maschinenanlagenführende Steckdosenproduktion',
                )}`,
              )
            break
          case 'P104.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/bje/${encodeURI(
                  'Jaqueline Wiesner',
                )}/${encodeURI('Busch-free@home® Fertigung')}`,
              )
            break
          case 'E04.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set('url', `${window.origin}/#/sasbachTimeline`)
            break
          case 'CL08.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Winfried Zimmer',
                )}/${encodeURI('Schichtführer ComfortLine')}`,
              )
            break
          case 'CL08.2':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Sascha Götter',
                )}/${encodeURI('Bereichsleiter Endmontage')}`,
              )
            break
          case 'CL12.2':
          case 'TL13.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Jürgen Lorenz',
                )}/${encodeURI('Leiter Logistik')}`,
              )
            break
          case 'TL01.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Yavuz Eraslan',
                )}/${encodeURI('Maschinenführer TwinLine Fertigung')}`,
              )
            break
          case 'TL04.2':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Sascha Glaser',
                )}/${encodeURI('Vorarbeiter Blechbearbeitung')}`,
              )
            break
          case 'TL05.2':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Sascha Glaser',
                )}/${encodeURI('Vorarbeiter Blechbearbeitung')}`,
              )
            break
          case 'TL10.1':
          case 'TL12.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Detlef Kähler',
                )}/${encodeURI('Leiter TwinLine und CombiLine Montage')}`,
              )
            break
          case 'TL11.1':
            window?.blazeIT?.showComponent('WebFramePopup')
            window?.blazeIT
              .getComponent('WebFramePopup')
              .set(
                'url',
                `${window.origin}/#/videoinfo/sasbach/${encodeURI(
                  'Stefan Burkart',
                )}/${encodeURI('Technischer Vertriebsinnendienst')}`,
              )
            break
          default:
            break
        }
      },
      openPoi: (context, event, actionMeta) => {
        setIsPaused(true)
        if (
          window?.blazeIT?.getComponentAttributes('Main Viewer')
            ?.playbackState === 'playing'
        ) {
          window?.blazeIT?.getComponent('Main Viewer').pause()
        }
        if (!finished) {
          soundControls?.pause()
        }
      },
      closePoi: (context, event, actionMeta) => {
        if (context?.szenePoi && !isIndividualTour) {
          dispatch(
            addVisited({id: naviContext?.stateKey, szene: context.szenePoi}),
          )
        }
        
        const popupViewer = window?.blazeIT?.getComponent('Popup Viewer')
        if (popupViewer) {
          const newWidth = 80
          const newHeight = 75
          const newX = 10
          const newY = 12.5

          setPopupViewerSize({
            width: newWidth,
            height: newHeight,
            x: newX,
            y: newY,
          })

          popupViewer.set('width', `${newWidth}%`)
          popupViewer.set('height', `${newHeight}%`)
          popupViewer.set('left', `${newX}%`)
          popupViewer.set('bottom', `${newY}%`)

          const webFramePopup = window?.blazeIT?.getComponent('WebFramePopup')
          if (webFramePopup) {
            webFramePopup.set('width', `${newWidth}%`)
            webFramePopup.set('height', `${newHeight}%`)
            webFramePopup.set('left', `${newX}%`)
            webFramePopup.set('bottom', `${newY}%`)
          }

          const playbackBarPoi = window?.blazeIT?.getComponent('PlaybackBarPoi')
          if (playbackBarPoi) {
            playbackBarPoi.set('width', `${newWidth}%`)
            playbackBarPoi.set('left', `${newX}%`)
            playbackBarPoi.set('bottom', `${newY}%`)
          }

          const closePopup = window?.blazeIT?.getComponent('ClosePopup')
          if (closePopup) {
            closePopup.set('right', `${newX + 1}%`)
            closePopup.set('top', `${newY + 1}%`)
          }

          const enterFullscreenPopup = window?.blazeIT?.getComponent(
            'EnterFullscreenPopup',
          )
          if (enterFullscreenPopup) {
            enterFullscreenPopup.set('right', `${newX + 3}%`)
            enterFullscreenPopup.set('top', `${newY + 1}%`)
          }

          const exitFullscreenPopup = window?.blazeIT?.getComponent(
            'ExitFullscreenPopup',
          )
          if (exitFullscreenPopup) {
            exitFullscreenPopup.set('right', `${newX + 3}%`)
            exitFullscreenPopup.set('top', `${newY + 1}%`)
          }
        }

        setShowPlaybackBarPoi(false)

        const playlistItem = window?.blazeIT?.getCurrentPlayListItem()
        if (playlistItem) {
          let dur = 0
          if (is360Video(playlistItem)) {
            const media = window?.blazeIT?.getCurrentMedia()

            if (media) {
              const overlays =
                window?.blazeIT?.getComponentAttributes(media).overlays || []

              if (overlays && Array.isArray(overlays)) {
                overlays.forEach(el => {
                  if (el && el.get('class')?.includes('HotspotPanorama')) {
                    let attributes = window?.blazeIT?.getComponentAttributes(el)
                    if (attributes?.data?.label === 'Stop360Video') {
                      if (
                        attributes?.items &&
                        Array.isArray(attributes.items)
                      ) {
                        attributes.items.forEach(item => {
                          const hotSpots = item.get('playbackPositions') || []
                          const key = hotSpots?.find(keyFrame => {
                            return keyFrame.get('opacity') === 1
                          })

                          if (key) {
                            dur = key.get('timestamp')
                          }
                        })
                      }
                    }
                  }
                })
              }
            }
          } else {
            dur = playlistItem.get('media')?.get('duration') || 0
          }

          const currentTime = window?.blazeIT
            .getCurrentMedia()
            .get('currentTime')


          if (currentTime < dur || isPanorama(playlistItem)) {
            if (!isPanorama(playlistItem)) {
              window?.blazeIT?.getComponent('Main Viewer').play()
            }
            if (!finished) {
              soundControls?.play()
            }
          }
          setIsPaused(false)
        }

        switch (actionMeta?.state?.history?.context?.szenePoi) {
          //case 'Zählerschränke ComfortLine (9_POI_1)':
          //  setIsPaused(false)
          //  window?.blazeIT?.getComponent('Main Viewer').play()
          //  if (!finished) {
          //  soundControls?.play()
          //}
          //  break
          default:
            break
        }
      },
      controlChatbot: (context, event, actionMeta) => {
        if (context?.szenePoi) {
          const isVisited = visited?.find(
            c => c === context?.szenePoi,
          )

          const playSoundOunce = shouldPlaySoundOnlyOnce.find(c => c === context?.szenePoi)

          switch (context?.szenePoi) {
            default:
              dispatch(setOpen(false))
              if (isVisited && playSoundOunce && context?.soundTitlePoi) {
                dispatch(setOpen(true))
                setStepsChatbot(playSoundStep)
              } else {
                setStepsChatbot(defaultStep)
              }
          }
        } else {
          const isVisited = visited?.find(
            c => c === context?.szene,
          )

          const playSoundOunce = shouldPlaySoundOnlyOnce.find(c => c === context?.szene)

          switch (context?.szene) {
            case 'E03':
            case 'AT004':
            case 'BE03':
              dispatch(setOpen(true))
              if (!isIndividualTour) {
                setStepsChatbot(empfangStep)
              } else {
                dispatch(setOpen(false))
                if (isVisited && playSoundOunce && context?.soundTitle) {
                  dispatch(setOpen(true))
                  setStepsChatbot(playSoundStep)
                } else {
                  setStepsChatbot(defaultStep)
                }  
              }
              break
            default:
              dispatch(setOpen(false))
              if (isVisited && playSoundOunce && context?.soundTitle) {
                dispatch(setOpen(true))
                setStepsChatbot(playSoundStep)
              } else {
                setStepsChatbot(defaultStep)
              }
          }
        }
      },
      controlButtons: (context, event, actionMeta) => {
        const disable = []
        if (disable.find(el => el === context?.szene)) {
          updateEnableTourNavi(false)
        } else {
          updateEnableTourNavi(true)
        }
        setIsPaused(false)
      },
      next: context => {
        if (isIndividualTour) {
          navigateToPlaylistItem(context?.nextTarget)
        } else {
          const handleDefaultNext = () => {
            const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
              context?.nextTarget,
            )
            if (playlistItem) {
              window?.blazeIT?.navigateToPlayListItem(playlistItem)
            }
          }
  
          switch (context?.szene) {
            /*case 'Zählerschränke ComfortLine (3)':
              window?.blazeIT?.triggerHotspotAction(
                `NEXT: ${context.szene}`,
                'click',
              )
              break*/
            default:
              handleDefaultNext()
          }
        }
      },
      calcluateNavigation: assign({
        nextTarget: (context, event, actionMeta) => {
          const index = navigation?.findIndex(el => {
            if (el?.label && context?.szene) {
              return el?.label === context?.szene
            }
            return false
          })
          let nextTarget = navigation[index + 1]?.label
          
          switch (context?.szene) {
            /*case 'Empfangsbereich (2a)':
              nextTarget =
                window?.blazeIT?.variables?.nextTarget ||
                'Zählerschränke ComfortLine (1)'
              break*/
            default:
              break
          }
          return nextTarget
        },
        previousTarget: (context, event, actionMeta) => {
          const index = navigation?.findIndex(el => {
            if (el?.label && context?.szene) {
              return el?.label === context?.szene
            }
            return false
          })

          let previousTarget = navigation[index - 1]?.label

          switch (context?.szene) {
            default:
              break
          }

          if (!previousTarget && isIndividualTour && index < 0) {
            if (context?.visited && navigation?.find(el => {
              if (el?.label) {
                return el?.label === context?.visited
              }
              return false
            })) {
              return context.visited
            } else if (context?.previousTarget && navigation?.find(el => {
              if (el?.label) {
                return el?.label === context?.previousTarget
              }
              return false
            })) {
              return context.previousTarget
            }
          }

          return previousTarget
        },
      }),
      back: context => {
        if (isIndividualTour) {
          navigateToPlaylistItem(context?.previousTarget)
        } else {
          const handleDefaultBack = () => {
            const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
              context?.previousTarget,
            )
            if (playlistItem) {
              window?.blazeIT?.navigateToPlayListItem(playlistItem)
            }
          }
          switch (context?.szene) {
            default:
              handleDefaultBack()
          }
        }
      },
      end: () => {
        setIsEndVideo(true)
      },
      endPoi: () => {
        let time = 1000        
        if (timePoi > 0 && durationPoi > timePoi) {
          //if (durationPoi - timePoi <= 3) {
            time = Math.max((durationPoi - timePoi) * 1000, time)
          //}
        }

        setIsEndVideoPoi(true)
        setTimeout(() => {
          window?.blazeIT?.triggerComponentAction('ClosePopup', 'click')
        }, time)
      },
      navigate: (context, event, actionMeta) => {
        if (!isIndividualTour) {
          let playlistItem = null
          switch (context?.szene) {
            case 'AT001':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('AT002')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'AT002':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('AT003')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'BE01':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('BE02')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'BE02':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('BE03')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'LO01':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('LO02')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'LO02b':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('LO03')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'LO03':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('LO04')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'LO14':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('LO15')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'E01':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('E02')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'E02':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('E03')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'E11':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('E12')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'E12':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('CL01')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'CL05':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('CL06')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'SZ01':
              playlistItem = window?.blazeIT?.getPlayListItemByMediaName('SZ02')
              if (playlistItem) {
                window?.blazeIT?.navigateToPlayListItem(playlistItem)
              }
              break
            case 'SZ03':
                if (context?.previousTarget === 'SZ07') {
                  playlistItem = window?.blazeIT?.getPlayListItemByMediaName('SZ07')
                } else {
                  playlistItem = window?.blazeIT?.getPlayListItemByMediaName('SZ04') 
                }
                if (playlistItem) {
                  window?.blazeIT?.navigateToPlayListItem(playlistItem)
                }
                break
            default:
          }
        }
      },
      backBegin: (context, event, actionMeta) => {
        if (window?.blazeIT?.variables.backBegin) {
          goToEnd(context)
        }
        if (window?.blazeIT) {
          window.blazeIT.variables.backBegin = false
        }
      },
      goToStart: (context, event, actionMeta) => {
        window?.blazeIT?.setComponentAttributes(
          window?.blazeIT?.getCurrentMedia(),
          {
            currentTime: 0,
          },
        )
        setIsEndVideo(false)
        window?.blazeIT?.getComponent('Main Viewer').play()
        if (!finished) {
          soundControls?.seek(0)
          soundControls?.play()
        }
        setTimeout(() => {
          setIsPaused(true)
          if (
            window?.blazeIT?.getComponentAttributes('Main Viewer')
              ?.playbackState === 'playing'
          ) {
            window?.blazeIT?.getComponent('Main Viewer').pause()
          }
          if (!finished) {
            soundControls?.pause()
          }
        }, 500)
      },
      goToEnd: (context, event, actionMeta) => {
        goToEnd(context)
      },
      exit: (context, event, actionMeta) => {
        dispatch(setOpen(true))
        if(!isIndividualTour) {
          setStepsChatbot(exitStep)
        }
      },
      enterFullscreenPoi: (context, event, actionMeta) => {
        const popupViewer = window?.blazeIT?.getComponent('Popup Viewer')

        if (popupViewer) {
          setPopupViewerSize({
            width: 100,
            height: 100,
            x: 0,
            y: 0,
          })

          popupViewer.set('width', '100%')
          popupViewer.set('height', '100%')
          popupViewer.set('left', '0%')
          popupViewer.set('bottom', '0%')

          const webFramePopup = window?.blazeIT?.getComponent('WebFramePopup')

          if (webFramePopup) {
            webFramePopup.set('width', '100%')
            webFramePopup.set('height', '100%')
            webFramePopup.set('left', '0%')
            webFramePopup.set('bottom', '0%')
          }

          const playbackBarPoi = window?.blazeIT?.getComponent('PlaybackBarPoi')
          if (playbackBarPoi) {
            playbackBarPoi.set('width', '100%')
            playbackBarPoi.set('left', '0%')
            playbackBarPoi.set('bottom', '0%')
          }

          const closePopup = window?.blazeIT?.getComponent('ClosePopup')
          if (closePopup) {
            closePopup.set('right', '1%')
            closePopup.set('top', '1%')
          }

          const enterFullscreenPopup = window?.blazeIT?.getComponent(
            'EnterFullscreenPopup',
          )
          if (enterFullscreenPopup) {
            enterFullscreenPopup.set('right', '6%')
            enterFullscreenPopup.set('top', '1%')
          }

          const exitFullscreenPopup = window?.blazeIT?.getComponent(
            'ExitFullscreenPopup',
          )
          if (exitFullscreenPopup) {
            exitFullscreenPopup.set('right', '6%')
            exitFullscreenPopup.set('top', '1%')
          }
        }
      },
      exitFullscreenPoi: (context, event, actionMeta) => {
        let newWidth = 80
        let newHeight = 75
        let newX = (100 - newWidth) / 2
        let newY = (100 - newHeight) / 2

        if (context?.szenePoi) {
          const playListItem = window?.blazeIT?.getPlayListItemByMediaName(
            context.szenePoi,
          )
          if (playListItem) {
            if (playListItem.get('media')?.get('class') === 'Video') {
              const width = playListItem
                .get('media')
                ?.get('video')
                .get('width')
              const height = playListItem
                ?.get('media')
                ?.get('video')
                .get('height')

              if (width && height) {
                const frame = document.getElementById('tourFrame')
                if (frame) {
                  const frameWidth = frame.getBoundingClientRect()?.width
                  const frameHeight = frame.getBoundingClientRect()?.height
                  if (frameWidth && frameHeight) {
                    const webFrameWidth = frameWidth * 0.8
                    const webFrameHeight = Math.min(webFrameWidth * (height / width), frameHeight * 0.8)

                    if (webFrameHeight > frameHeight * 0.8) {
                      webFrameHeight = frameHeight * 0.8
                      webFrameWidth = webFrameHeight * (width / height)
                    }

                    newWidth = (webFrameWidth / frameWidth) * 100
                    newHeight = (webFrameHeight / frameHeight) * 100
                    newX = (100 - newWidth) / 2
                    newY = (100 - newHeight) / 2
                  }
                }
              }
            }
          }
        }

        const popupViewer = window?.blazeIT?.getComponent('Popup Viewer')

        if (popupViewer) {
          popupViewer.set('width', `${newWidth}%`)
          popupViewer.set('height', `${newHeight}%`)
          popupViewer.set('left', `${newX}%`)
          popupViewer.set('bottom', `${newY}%`)

          setPopupViewerSize({
            width: parseFloat(popupViewer.get('width').replace('%', '')),
            height: parseFloat(popupViewer.get('height').replace('%', '')),
            x: parseFloat(popupViewer.get('left').replace('%', '')),
            y: parseFloat(popupViewer.get('bottom').replace('%', '')),
          })

          const webFramePopup = window?.blazeIT?.getComponent('WebFramePopup')
          if (webFramePopup) {
            webFramePopup.set('width', `${newWidth}%`)
            webFramePopup.set('height', `${newHeight}%`)
            webFramePopup.set('left', `${newX}%`)
            webFramePopup.set('bottom', `${newY}%`)
          }

          const playbackBarPoi = window?.blazeIT?.getComponent('PlaybackBarPoi')
          if (playbackBarPoi) {
            playbackBarPoi.set('width', `${newWidth}%`)
            playbackBarPoi.set('left', `${newX}%`)
            playbackBarPoi.set('bottom', `${newY}%`)
          }

          const closePopup = window?.blazeIT?.getComponent('ClosePopup')
          if (closePopup) {
            closePopup.set('right', `${newX + 1}%`)
            closePopup.set('top', `${newY + 1}%`)
          }

          const enterFullscreenPopup = window?.blazeIT?.getComponent(
            'EnterFullscreenPopup',
          )
          if (enterFullscreenPopup) {
            enterFullscreenPopup.set('right', `${newX + 3}%`)
            enterFullscreenPopup.set('top', `${newY + 1}%`)
          }

          const exitFullscreenPopup = window?.blazeIT?.getComponent(
            'ExitFullscreenPopup',
          )
          if (exitFullscreenPopup) {
            exitFullscreenPopup.set('right', `${newX + 3}%`)
            exitFullscreenPopup.set('top', `${newY + 1}%`)
          }
        }
      },
      calculateSoundAndCaptions: assign({
        soundTitle: (context, e) => {
          return sounds[language][context.szene]
        },
        subtitle: (context, e) => {
          return subtitles[language][context.szene]
        
        },
      }),
      calculatePoiSoundAndCaptions: assign({
        soundTitlePoi: (context, e) => {
          return sounds[language][e?.soundTitlePoi || context.szenePoi]
        },
        subtitlePoi: (context, e) => {
          return subtitles[language][e?.soundTitlePoi || context.szenePoi]
        }
      }),
      seekAudio: (context, event, actionMeta) => {
        if (context.soundTitle && !finished) {
          const currentTime = window?.blazeIT?.getCurrentMedia()?.get('currentTime') || 0
          soundControls?.seek(currentTime)
        }
      },
      seekAudioPoi: (context, event, actionMeta) => {
        if (context.soundTitlePoi) {
          const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(context.szenePoi)
          if (playlistItem) {
            const currentTime = playlistItem.get('media')?.get('currentTime') || 0
            soundControlsPoi?.seek(currentTime)          
          }
          
        }
        soundControls?.pause()
      },
      setVisited: (context, event, actionMeta) => {
        if (context?.visited && isIndividualTour) {
          dispatch(
            addVisited({id: naviContext?.stateKey, szene: context.visited}),
          )
        }
      }
    },
  })

  useEffect(() => {
    setTourMachine(tourService)
  }, [tourService, setTourMachine])

  useEffect(() => {
    setTimeout(() => {
      sendTourMachine('TOUR_NAVIGATION_LOADED')
    }, 2500)
    sendTourMachine('CHANGE_LANGUAGE')
  }, [language, sendTourMachine])

  return children
}
