import {createStore, compose, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {persistReducer, persistStore, createMigrate} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import reducer from '../rootReducer'
import * as Sentry from '@sentry/react'
import createSentryMiddleware from 'redux-sentry-middleware'
import migrations from './migrations'

// #region createStore : enhancer
const enhancer = compose(
  applyMiddleware(
    thunkMiddleware,
    createSentryMiddleware(Sentry, {
      // Optionally pass some options here.
    }),
  ),
)
// #endregion

// #region persisted reducer
const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, {debug: false}),
  blacklist: ['chatbot'],
}

const persistedReducer = persistReducer(persistConfig, reducer)
// #endregion

// #region store initialization
export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState, enhancer)
  const persistor = persistStore(store)
  return {store, persistor}
}
// #endregion
