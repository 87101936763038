import Cookies from "js-cookie";
import axios from "axios";

import {createSlice, createDraftSafeSelector, createAsyncThunk} from '@reduxjs/toolkit'

export const initialState = {
  isInvalidated: true,
  isFetching: false,
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  gender_iso5218: 1,
  country: "",
  roles: "",
  loggedInAt: null,
}

export const fetchUser = createAsyncThunk(
  "team/playerListLoading",
  () => axios
    .get(process.env.REACT_APP_MYBUILDINGSAPI + "user")
    .then((response) => response.data)
    .catch((error) => error)
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    invalidateUser(state) {
      state = {...initialState}
    },
  },
  extraReducers: {
    [fetchUser.pending.type]: (state, action) => {
      state.isFetching = true
      state.isInvalidated = false
    },
    [fetchUser.fulfilled.type]: (state, action) => {
      state.isFetching = false
      state.isInvalidated = false
      state.username = action.payload.username
      state.realname = action.payload.realname
      state.firstname = action.payload.firstname
      state.lastname = action.payload.lastname
      state.email = action.payload.email
      state.gender_iso5218= action.payload.gender_iso5218
      state.country = action.payload.country
      state.roles = action.payload.roles
      state.loggedInAt = Date.now ? Date.now() : new Date().getTime()
    },
    [fetchUser.rejected.type]: (state, action) => {
      state.isFetching = false
    },
  },
})

export const {requestUser, invalidateUser, fetchUserFailure, writeUserData} = userSlice.actions
export default userSlice.reducer

export const shouldFetchUserData = (state) => {
  return (
    (!state.username && !state.email && !state.isFetching) ||
    Cookies.get("access_token") === ""
  );
};

export const fetchUserDataIfNeeded = () => {
  return (dispatch, getState) => {
    if (Cookies.get("access_token") === undefined) {
      dispatch(invalidateUser());
    } else if (shouldFetchUserData(getState().user)) {
      return dispatch(fetchUserData());
    }
  };
};

//Async action creators
export const fetchUserData = () => {
  return (dispatch, getState) => {
    dispatch(requestUser());
    let apiUrl = process.env.REACT_APP_MYBUILDINGSAPI + "user"; //eslint-disable-line no-undef
    let xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        dispatch(writeUserData(JSON.parse(this.responseText)));
      } else {
        dispatch(fetchUserFailure());
      }
    });
    xhr.open("GET", apiUrl);
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + Cookies.get("access_token")
    );
    xhr.send();
  };
};

//Selectors
export const getUser = (state) => state.user;
export const getUserName = (state) => state.user.username;

export const getIsLoggedIn = (state) => {
  return (
    state.user.username !== "" && Cookies.get("access_token") !== undefined
  );
};

export const getIsUserFetching = (state) => {
  return state.user.isFetching;
};

export const ssoAuth = (code) => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    var data = new FormData();
    
    
    
    data.append("client_id", process.env.REACT_APP_SSOCLIENTID);
    data.append("client_secret", process.env.REACT_APP_SSOCLIENTSECRET);
    data.append("redirect_uri", window.location.origin)
    data.append("grant_type", "authorization_code");
    data.append("code", code);
    xhr.open("POST", process.env.REACT_APP_SSOURL + "token", true);
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const response = JSON.parse(xhr.responseText);
          Cookies.set("access_token", response["access_token"], {
            expires: 30,
          });
          Cookies.set("refresh_token", response["refresh_token"], {
            expires: 90,
          });
          resolve(true);
        } else {
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          resolve(false);
        }
      }
    });
    xhr.send(data);
  });
};

export const ssoRefresh = () => {
  let xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    const refresh_token = Cookies.get("refresh_token");
    if (refresh_token) {
      var data = new FormData();
      data.append("client_id", process.env.REACT_APP_SSOCLIENTID);
      data.append("client_secret", process.env.REACT_APP_SSOCLIENTSECRET);
      data.append("redirect_uri", window.location.origin)
      data.append("grant_type", "refresh_token");
      data.append("refresh_token", refresh_token);
      xhr.open("POST", process.env.REACT_APP_SSOURL + "token", true);
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            const response = JSON.parse(xhr.responseText);
            Cookies.set("access_token", response["access_token"], {
              expires: 30,
            });
            Cookies.set("refresh_token", response["refresh_token"], {
              expires: 90,
            });
            //window.location.reload(true)
            resolve(true)
          } else {
            Cookies.remove("access_token");
            Cookies.remove("refresh_token");
            resolve(false);
          }
        }
      });
      xhr.send(data);
    } else {
      resolve(false);
    }
  });
};

axios.interceptors.request.use(
  function (config) {
    const token = Cookies.get("access_token");

    if (token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    try {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401) {
        ssoRefresh().then((resp) => {
          if (resp) {
            return originalRequest;
          }
        });
      }
      return Promise.reject(error);
    } catch (e) {
      console.log("Auth. error");
      return Promise.reject(error);
    }
  }
);
