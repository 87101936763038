import SvgIcon from '@mui/material/SvgIcon';
import { omit } from 'ramda'

export default function StriebelIconSmall(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 16 23"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...omit(['secondaryColor'], props)}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nav-left-icon-HD" transform="translate(-16.000000, -13.000000)">
            <g id="Group" transform="translate(16.000000, 13.000000)">
                <polyline id="Path-3" fill={props.color} points="0 9 0 0 16 0 16 5 6 5 6 9"></polyline>
                <polyline id="Path-3" fill={props.color} transform="translate(8.000000, 18.500000) scale(1, -1) translate(-8.000000, -18.500000) " points="0 23 0 14 16 14 16 19 6 19 6 23"></polyline>
                <polygon id="Path-7" fill={props.secondaryColor} points="6 9 6 14 13 14 13 9"></polygon>
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}


StriebelIconSmall.defaultProps = {
  color: '#046927',
  secondaryColor: '#C3010C',
  width: '16px',
  height: '23px',
};