import {createSlice, createDraftSafeSelector} from '@reduxjs/toolkit'
import {getNavigationContext} from 'components/xstate/NavigationMachine'

export const initialState = {
  elements: {
    striebel: [],
    bjeLogistik: [],
    bjeProduktion: [],
    bjeSchulungszentrum: [],
    bjeAussengelaende: [],
    bjeEmpfang: [],
    bjeFree: [],
    abb: [],
  },
}

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addFavorite(state, action) {
      if (
        !state.elements[action.payload.id].find(
          el => el === action.payload.label,
        )
      ) {
        state.elements[action.payload.id].push(action.payload.label)
      }
    },
    removeFavorite(state, action) {
      state.elements[action.payload.id] = state.elements[
        action.payload.id
      ].filter(el => el !== action.payload.label)
    },
  },
})

export const {addFavorite, removeFavorite} = favoritesSlice.actions
export default favoritesSlice.reducer

export const getFavorites = state => state.favorites.elements

export const getFilteredFavorites = createDraftSafeSelector(
  [getFavorites, getNavigationContext],
  (favorites, context) => {
    return context?.stateKey && favorites[context?.stateKey]
      ? favorites[context?.stateKey]
      : []
  },
)
