import {useMemo} from 'react'

export default function TooltipContent({id}) {
  const item = useMemo(() => {
    const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(id)

    if (playlistItem) {
      const subtitle = playlistItem.get('media')?.get('data')?.subtitle
      if (subtitle) {
        const splits = subtitle.split('##')
        if (Array.isArray(splits) && splits.length > 1) {
          return {
            label: splits[0],
            thumbnail: playlistItem.get('media')?.get('thumbnailUrl'),
          }
        }
      }
    }

    return {
      label: id,
    }
  }, [id])

  return (
    <div
      style={{
        background: '#FFF',
        padding: 5,
        webkitBoxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
        boxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
      }}
    >
      {item.thumbnail && (
        <div>
          <img src={item.thumbnail} style={{maxWidth: 300}} alt={item?.label} />
        </div>
      )}
      <span>{item?.label}</span>
    </div>
  )
}
