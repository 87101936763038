import SvgIcon from '@mui/material/SvgIcon'

export default function NextIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <defs>
        <clipPath id="a">
          <path d="M762 0v484H0V0h762Z" />
        </clipPath>
        <clipPath id="c">
          <path d="M16.5 0C25.613 0 33 7.387 33 16.5S25.613 33 16.5 33 0 25.613 0 16.5 7.387 0 16.5 0Z" />
        </clipPath>
        <clipPath id="d">
          <path d="m2.936 0 1.503 1.945A10.44 10.44 0 0 1 10.5.01C16.295.01 21 4.716 21 10.51 21 16.306 16.295 21 10.5 21S0 16.306 0 10.51c0-.906.116-1.785.332-2.624H2.15a8.76 8.76 0 0 0-.401 2.625c0 4.83 3.92 8.75 8.75 8.75s8.75-3.92 8.75-8.75-3.92-8.75-8.75-8.75a8.7 8.7 0 0 0-4.99 1.571L7 5.261H1.242L2.936 0Z" />
        </clipPath>
        <clipPath id="e">
          <path d="M2.959 0v6.865H1.797V1.387l-1.24.927L0 1.455 2.017 0h.942Z" />
        </clipPath>
        <clipPath id="f">
          <path d="M2.637 0c.387 0 .743.079 1.067.237.324.158.602.384.835.679.232.294.414.663.544 1.105.13.443.195.94.195 1.49 0 .693-.103 1.303-.31 1.83-.206.528-.51.94-.913 1.236-.402.296-.875.444-1.418.444-.388 0-.742-.078-1.065-.236a2.396 2.396 0 0 1-.832-.679C.507 5.811.326 5.442.195 4.998A5.28 5.28 0 0 1 0 3.51c0-.69.104-1.3.313-1.829.208-.529.512-.941.913-1.238.4-.296.87-.444 1.41-.444Zm.005 1.074c-.456 0-.814.215-1.075.645-.26.43-.39 1.027-.39 1.792 0 .755.132 1.35.395 1.784.264.435.62.652 1.07.652.459 0 .817-.215 1.074-.644.257-.43.386-1.027.386-1.792 0-.765-.131-1.363-.394-1.792-.262-.43-.617-.645-1.066-.645Z" />
        </clipPath>
        <filter
          id="b"
          x="-18.919%"
          y="-16.216%"
          filterUnits="userSpaceOnUse"
          width="127.027%"
          height="127.027%"
        >
          <feGaussianBlur stdDeviation={1} in="SourceAlpha" result="i2" />
          <feOffset dy={1} in="i2" result="i3" />
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.3 0"
            in="i3"
          />
        </filter>
      </defs>
      <g clipPath="url(#a)" transform="translate(-281 -382)">
        <g opacity="50%">
          <path
            d="M16.5 0C25.613 0 33 7.387 33 16.5S25.613 33 16.5 33 0 25.613 0 16.5 7.387 0 16.5 0Z"
            filter="url(#b)"
            transform="matrix(-1 0 0 1 316 383)"
          />
          <g clipPath="url(#c)" transform="matrix(-1 0 0 1 316 383)">
            <path fill="#FFF" d="M0 0h33v33H0V0z" />
          </g>
        </g>
        <g clipPath="url(#d)" transform="translate(289 389)">
          <path d="M0 0h21v21H0V0z" />
        </g>
        <g clipPath="url(#e)" transform="translate(295.02 396.135)">
          <path d="M0 0h2.959v6.865H0V0z" />
        </g>
        <g clipPath="url(#f)" transform="translate(299.355 396.057)">
          <path d="M0 0h5.278v7.021H0V0z" />
        </g>
      </g>
    </SvgIcon>
  )
}

NextIcon.defaultProps = {
  color: '#000000',
  width: '68px',
  height: '68px',
}
