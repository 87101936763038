import {
  createTheme
} from '@mui/material/styles';
import ABBvoiceWLt from 'assets/fonts/ABBvoice_W_Lt.woff'
import ABBvoiceWBd from 'assets/fonts/ABBvoice_W_Bd.woff'
import ABBvoiceWMd from 'assets/fonts/ABBvoice_W_Md.woff'
import ABBvoiceWRg from 'assets/fonts/ABBvoice_W_Rg.woff'

const abbRed = '#ff000f'
const abbRedDark = '#bb2924'

export const abbTheme = createTheme({
  abb: {
    red: abbRed,
    black: '#000000',
    white: '#ffffff',
    grey01: '#262626',
    grey02: '#6e6e6e',
    grey03: '#a9a9a9',
    grey04: '#d2d2d2',
    grey05: '#f0f0f0',
    grey06: '#fafafa',
    blue: '#004c97',
    ok: '#007a33',
    warning: '#ffd100',
  },
  palette: {
    primary: {
      main: abbRed,
    },
    secondary: {
      main: '#ffffff',
    },
    text: {
      primary: '#000'
    }
  },
  typography: {
    fontFamily: 'ABBvoice',
    h1: {
      '&:before': {
        content: '""',
        width: 48,
        height: 6,
        display: 'block',
        position: 'relative',
        marginBottom: 20,
        marginLeft: 0,
        marginRight: 'auto',
        backgroundColor: abbRed,
      },
    },
    h2: {
      '&:before': {
        content: '""',
        width: 48,
        height: 6,
        display: 'block',
        position: 'relative',
        marginBottom: 20,
        marginLeft: 0,
        marginRight: 'auto',
        backgroundColor: abbRed,
      }
    },
  },
  zIndex: {
    modal: 6000,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: ABBvoice;
            src: local('ABBvoice'), local('ABBvoice-Regular'), url(${ABBvoiceWRg}) format('woff');
            font-weight: normal;
        }
        @font-face {
            font-family: ABBvoice-Bold;
            src: local('ABBvoice-Bold'), url(${ABBvoiceWBd}) format('woff');
            font-weight: bold;
        }
        @font-face {
            font-family: ABBvoice-Semibold;
            src: local('ABBvoice-Semibold'), url(${ABBvoiceWMd}) format('woff');
            font-weight: normal;
        }
        @font-face {
            font-family: ABBvoice-Light;
            src: local('ABBvoice-Light'), url(${ABBvoiceWLt}) format('woff');
            font-weight: normal;
        }
        @font-face {
            font-family: ABBvoice-Light;
            src: local('ABBvoice-Light'), url(${ABBvoiceWLt}) format('woff');
            font-style: normal;
            font-weight: 100;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          padding: 0,
          textTransform: 'none',
        },
        text: {
          textTransform: 'unset',
        },
        contained: {
          background: '#ffffff',
          padding: '10px 15px',
        },
        containedPrimary: {
          color: '#FFFFFF',
          background: abbRed,
          boxShadow: 'none',
          borderRadius: 24,
          border: `2px solid ${abbRed}`,
          '&:hover': {
            backgroundColor: abbRedDark,
            border: `2px solid ${abbRedDark}`,
            color: '#ffffff',
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          boxShadow: 'none',
          borderRadius: 24,
          border: '2px solid #dbdbdb',
          '&:hover': {
            backgroundColor: '#ebebeb',
            boxShadow: 'none',
          },
        },
        outlined: {
          background: abbRed,
          border: `2px solid ${abbRed}`,
          borderRadius: 24,
          color: '#000',
          padding: '10px 15px',
          '&:hover': {
            border: `2px solid ${abbRedDark}`,
            background: abbRedDark,
            color: '#ffffff',
          },
        },
        outlinedSecondary: {
          border: '2px solid #dbdbdb',
          background: '#fff',
          borderRadius: 24,
          color: '#000',
          fontWeight: 'normal',
          '&:hover': {
            color: '#000',
            background: '#ebebeb !important',
            border: '2px solid #dbdbdb',
          },
        }
      }
    },
  },
});

export default abbTheme;