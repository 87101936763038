import SvgIcon from '@mui/material/SvgIcon';

export default function AbbIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 39 14"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="nav-left-icon-SB" transform="translate(-5.000000, -17.000000)" fill="#6E6E6E" fillRule="nonzero">
              <g id="abb-logo" transform="translate(5.147059, 17.000000)">
                  <g id="ABB-Copy" fill="none">
                      <g id="Layer_1" fill={props.color}>
                          <path d="M7.8,0 L9.78421053,0 L12.3157895,6.69473684 L7.8,6.69473684 L7.8,0 Z M7.18421053,6.69473684 L7.18421053,0 L5.26842105,0 L2.73684211,6.69473684 L7.18421053,6.69473684 Z M7.18421053,7.2 L2.53157895,7.2 L0,13.8947368 L3.76315789,13.8947368 L4.78947368,11.1157895 L7.18421053,11.1157895 L7.18421053,7.2 Z M12.5210526,7.2 L7.8,7.2 L7.8,11.1157895 L10.1947368,11.1157895 L11.2210526,13.8947368 L15.0526316,13.8947368 L12.5210526,7.2 Z M20.9368421,7.2 L20.9368421,13.8947368 L22.1684211,13.8947368 C24.6315789,13.8947368 26.6842105,12 26.6842105,9.72631579 C26.6842105,8.77894737 26.3421053,7.89473684 25.7947368,7.2 L20.9368421,7.2 Z M20.3210526,6.69473684 L20.3210526,0 L16.0789474,0 L16.0789474,6.69473684 L20.3210526,6.69473684 Z M20.9368421,6.69473684 L25.1789474,6.69473684 C24.7684211,6.31578947 24.2894737,6.06315789 23.7421053,5.87368421 C24.7684211,5.36842105 25.4526316,4.29473684 25.4526316,3.15789474 C25.4526316,1.45263158 23.9473684,0.0631578947 22.0315789,0.0631578947 L20.8,0.0631578947 L20.8,6.69473684 L20.9368421,6.69473684 Z M20.3210526,7.2 L16.0789474,7.2 L16.0789474,13.8947368 L20.3210526,13.8947368 L20.3210526,7.2 Z M32.6368421,7.2 L32.6368421,13.8947368 L33.8684211,13.8947368 C36.3315789,13.8947368 38.3842105,12 38.3842105,9.72631579 C38.3842105,8.77894737 38.0421053,7.89473684 37.4947368,7.2 L32.6368421,7.2 Z M32.0894737,6.69473684 L32.0894737,0 L27.8473684,0 L27.8473684,6.69473684 L32.0894737,6.69473684 Z M32.6368421,6.69473684 L36.9473684,6.69473684 C36.5368421,6.31578947 36.0578947,6.06315789 35.5105263,5.87368421 C36.5368421,5.36842105 37.2210526,4.29473684 37.2210526,3.15789474 C37.2210526,1.45263158 35.7157895,0.0631578947 33.8,0.0631578947 L32.5684211,0.0631578947 L32.5684211,6.69473684 L32.6368421,6.69473684 Z M32.0894737,7.2 L27.8473684,7.2 L27.8473684,13.8947368 L32.0894737,13.8947368 L32.0894737,7.2 Z" id="Shape"></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </SvgIcon>
  );
}

AbbIcon.defaultProps = {
  color: '#FF000F',
  width: '39px',
  height: '14px',
};