import {createTheme} from '@mui/material/styles'

import AvertaBold from 'assets/fonts/Averta-Bold.woff'
import AvertaExtraBold from 'assets/fonts/Averta-Extra-Bold.woff'
import AvertaLight from 'assets/fonts/Averta-Light.woff'
import AvertaRegular from 'assets/fonts/Averta-Regular.woff'
import AvertaSemibold from 'assets/fonts/Averta-Semibold.woff'
import BuschJaegerIcons from 'assets/fonts/busch-jaeger-icons.ttf'

const bjeBlue = '#00acec'
const bjeBlueDark = '#006f9e'

export const bjeTheme = createTheme({
  palette: {
    primary: {
      main: bjeBlue,
    },
    secondary: {
      main: '#F2F2F2',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#28a745',
    },
    common: {
      blue: '#007bff',
      indigo: '#6610f2',
      purple: '#6f42c1',
      pink: '#e83e8c',
      red: '#dc3545',
      orange: '#fd7e14',
      yellow: '#ffc107',
      green: '#28a745',
      teal: '#20c997',
      cyan: '#17a2b8',
      white: '#fff',
      gray: '#6c757d',
      grayDark: '#343a40',
      primary: '#009FE3',
      secondary: '#F2F2F2',
      success: '#28a745',
      info: '#17a2b8',
      warning: '#ffc107',
      danger: '#dc3545',
      light: '#f8f9fa',
      dark: '#343a40',
    },
  },
  typography: {
    fontFamily: 'Averta',
    h1: {
      fontFamily: 'Averta-Bold',
      '&:after': {
        color: bjeBlue,
        display: 'block',
        content: '"\\2014"',
        fontSize: 'inherit',
      },
    },
    h2: {
      fontFamily: 'Averta-Bold',
      '&:after': {
        color: bjeBlue,
        display: 'block',
        content: '"\\2014"',
        fontSize: 'inherit',
      },
    },
    h5: {
      fontFamily: 'Averta-Light',
      color: '#000000',
      '&:after': {
        color: bjeBlue,
        display: 'block',
        content: '"\\2014"',
        fontSize: 'inherit',
      },
    },
    h6: {
      fontFamily: 'Averta-Light',
      fontSize: '1rem',
      lineHeight: 1.25,
      color: '#000000',
    },
    body1: {
      fontFamily: 'Averta',
      color: '#000000',
    },
    body2: {
      fontFamily: 'Averta',
      color: '#000000',
    },
  },
  zIndex: {
    modal: 6000,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
              font-family: Averta;
              src: local('Averta'), local('Averta-Regular'), url(${AvertaRegular}) format('woff');
              font-weight: normal;
          }
          @font-face {
              font-family: Averta-Bold;
              src: local('Averta-Bold'), url(${AvertaBold}) format('woff');
              font-weight: bold;
          }
          @font-face {
              font-family: Averta-Extra-Bold;
              src: local('Averta-Extra-Bold'), url(${AvertaExtraBold}) format('woff');
              font-weight: bolder;
          }
          @font-face {
              font-family: Averta-Semibold;
              src: local('Averta-Semibold'), url(${AvertaSemibold}) format('woff');
              font-weight: normal;
          }
          @font-face {
              font-family: Averta-Light;
              src: local('Averta-Light'), url(${AvertaLight}) format('woff');
              font-weight: normal;
          }
          @font-face {
              font-family: icon-font;
              src: local('icon-fomt'), url(${BuschJaegerIcons}) format('ttf');
              font-weight: normal;
          }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          padding: 0,
          textTransform: 'none',
        },
        text: {
          textTransform: 'unset',
        },
        contained: {
          background: '#ffffff',
          padding: '10px 15px',
        },
        containedPrimary: {
          color: '#FFFFFF',
          background: bjeBlue,
          boxShadow: 'none',
          borderRadius: 24,
          border: `2px solid ${bjeBlue}`,
          '&:hover': {
            backgroundColor: bjeBlueDark,
            border: `2px solid ${bjeBlueDark}`,
            color: '#ffffff',
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          boxShadow: 'none',
          borderRadius: 24,
          border: '2px solid #dbdbdb',
          '&:hover': {
            backgroundColor: '#ebebeb',
            boxShadow: 'none',
          },
        },
        outlined: {
          background: bjeBlue,
          border: `2px solid ${bjeBlue}`,
          borderRadius: 24,
          color: '#000',
          padding: '10px 15px',
          '&:hover': {
            border: `2px solid ${bjeBlueDark}`,
            background: bjeBlueDark,
            color: '#ffffff',
          },
        },
        outlinedSecondary: {
          border: '2px solid #dbdbdb',
          background: '#fff',
          borderRadius: 24,
          color: '#000',
          fontWeight: 'normal',
          '&:hover': {
            color: '#000',
            background: '#ebebeb !important',
            border: '2px solid #dbdbdb',
          },
        },
      },
    },
  },
})

export default bjeTheme
