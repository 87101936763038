import {
  useQuery,
} from '@tanstack/react-query'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { isUUID } from 'components/utils'
import { getNavigationContext } from 'components/xstate/NavigationMachine'

const useIsIndividualTour = () => {
  const naviContext = useSelector(getNavigationContext)
  const { isLoading, error, data } = useQuery({
    queryKey: ['playlistItems'],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/playlistItems`,
      }).then((res) => {
        return res?.data || []
      })
  })

  return isUUID(naviContext?.stateKey) || !!data?.find(el => el.id === naviContext?.stateKey)
}

export default useIsIndividualTour