const migrations = {
  0: state => {
    return state
  },
  2: state => {
    if (!state?.language?.lang) {
      state.language.lang = 'de'
    }
    return state
  }
}

export default migrations
