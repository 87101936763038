import React, {useState} from 'react'
import {useSpring, animated, easings} from 'react-spring'
import {useParams} from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ErrorBoundary from 'components/ErrorBoundary'
import {useUnmount} from 'react-use'
import {resetVideoInfo} from 'components/TourControls/duck'
import {useDispatch} from 'react-redux'

export default function VideoInfo() {
  const {layout, title, subtitle, delay} = useParams()
  const [toogleBubble, setToogleBubble] = useState(false)
  const [toogleText, setToogleText] = useState(false)
  const dispatch = useDispatch()

  useUnmount(() => dispatch(resetVideoInfo()))
  const staticProps = {
    position: 'absolute',
    bottom: 40,
    width: '80%',
    maxWidth: '336px',
    height: '80px',
    background:
      layout === 'abb'
        ? '#ff000f'
        : layout === 'bje'
        ? 'rgba(0, 159, 227, .78)'
        : 'rgba(0,134,57, .78)',
    boxShadow: '0 0 50px 25px rgba(0,0,0,0.40)',
    borderTopRightRadius: 90,
    borderBottomRightRadius: 90,
    color: '#FFF',
    padding: '18px 32px',
  }

  const propsBubble = useSpring({
    from: {
      ...staticProps,
      left: -450,
    },
    to: {
      ...staticProps,
      left: 0,
    },
    reverse: toogleBubble,
    delay: toogleText ? 0 : parseInt(delay) ? parseInt(delay) : 0,
    config: {
      duration: 1500,
      easing: easings.easeInQuart,
    },
    onRest: () => {
      if (toogleBubble && window?.parent?.parent) {
        const media = window.parent.parent.blazeIT?.getCurrentMedia()
        if (media) {
          if (media.get('class') === "Video360") {
            window.parent.parent.postMessage({type: 'finishVideoInfo'}, '*')
          }
        }
      }

      setTimeout(() => {
        if (!toogleBubble) {
          setToogleBubble(value => !value)
        }
      }, 3000)
    },
  })

  const propsText = useSpring({
    from: {
      height: '100%',
      opacity: 0,
    },
    to: {
      height: '100%',
      opacity: 1,
    },
    reverse: toogleText,
    delay: toogleText ? 0 : parseInt(delay) ? parseInt(delay) + 1200 : 1200,
    config: {
      duration: 800,
    },
    onRest: () => {
      setTimeout(() => {
        if (!toogleText) {
          setToogleText(value => !value)
        }
      }, 2500)
    },
  })

  return (
    <ErrorBoundary>
      <div
        style={{
          width: '100%',
          height: '100vh',
          position: 'relative',
          pointerEvents: 'none',
        }}
      >
        <animated.div style={propsBubble}>
          <animated.div style={propsText}>
            <div style={{height: '100%', position: 'relative'}}>
              <Grid
                container
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{fontSize: '22px', fontWeight: 'bold'}}
                    id="videoInfoTitle"
                  >
                    {title}
                  </Typography>
                </Grid>
                {subtitle && <Grid item xs={12}>
                  <Typography sx={{fontSize: '16px'}} id="videoInfoSubtitle">
                    {subtitle}
                  </Typography>
                </Grid>}
              </Grid>
            </div>
          </animated.div>
        </animated.div>
      </div>
    </ErrorBoundary>
  )
}
