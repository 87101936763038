import React from 'react'

//Redux
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {ToastContainer} from 'react-toastify'
import {HashRouter} from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import configureStore from './redux/store/configureStore'

import QThemeProvider from './components/QThemeProvider'
import QIntlProvider from './components/QIntlProvider'
import MachineProvider from './components/xstate/MachineProvider'

import Layout from './components/Layout'

const queryClient = new QueryClient()

export function App() {
  const {store, persistor} = configureStore({})
  const ThemeProvider = /*navigationService ?*/ QThemeProvider /*: Container*/

  return (
    <div className="app">
      <ToastContainer toastClassName="toast-container" autoClose={3000} />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <QIntlProvider>
              <QueryClientProvider client={queryClient}>
                <HashRouter>
                  <MachineProvider>
                    <Layout />
                  </MachineProvider>
                </HashRouter>
              </QueryClientProvider>
            </QIntlProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  )
}
