import { createSlice } from '@reduxjs/toolkit'

export const initialState = {lang: 'de'}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.lang = action.payload
    },
  },
})

export const {
  setLanguage
} = languageSlice.actions
export default languageSlice.reducer

export const getLanguage = state => state.language.lang

export const changeVistaLanguage = (language) => {
  if (window?.tour?.setLocale) {
    window.tour.setLocale(language)
  }
}