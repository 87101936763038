import React from 'react'
import {useSelector} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'
import {sasbachTheme} from './themes/sasbach'
import {bjeTheme} from './themes/bje'
import {abbTheme} from './themes/abb'
import {getNavigationValue} from 'components/xstate/NavigationMachine'

export default function QThemeProvider({children}) {
  const naviValue = useSelector(getNavigationValue)

  const theme = naviValue?.includes('sasbach') || window.location.host.includes('factory-tour.striebelundjohn.com')
    ? sasbachTheme
    : naviValue?.includes('luedenscheid') || window.location.host.includes('factory-tour.busch-jaeger.de')
    ? bjeTheme
    : abbTheme

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
