import SvgIcon from '@mui/material/SvgIcon';

export default function SoundEnabledIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 30 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nav-left-sound-on-icon" transform="translate(-9.000000, -10.000000)" fill={props.color} fillRule="nonzero">
            <g id="Group" transform="translate(9.000000, 12.000000)">
              <path d="M15.0044477,0 L15.0044477,24 L12.8609552,24 L5.35873134,17.4545455 L2.14349254,17.4545455 C1.57500616,17.4545455 1.02979705,17.2246909 0.627813959,16.8154909 C0.225831943,16.4062909 0,15.8513455 0,15.2727273 L0,8.72727273 C0,8.14862182 0.225831943,7.59366545 0.627813959,7.18449818 C1.02979705,6.77532 1.57500616,6.54545455 2.14349254,6.54545455 L5.35873134,6.54545455 L12.8609552,0 L15.0044477,0 Z M26.0755867,2.49818182 C27.3180621,3.73645091 28.3051405,5.21472 28.9789473,6.84632727 C29.6528614,8.47794545 30,10.2300655 30,12 C30,13.7698909 29.6528614,15.5221091 28.9789473,17.1536727 C28.3051405,18.7852364 27.3180621,20.2635273 26.0755867,21.5018182 L24.5644245,19.9527273 C25.6046614,18.9165818 26.4310849,17.6793818 26.9953593,16.3136727 C27.5596337,14.9480727 27.8502913,13.4814545 27.8502913,12 C27.8502913,10.5185127 27.5596337,9.05193818 26.9953593,7.68629455 C26.4310849,6.32066182 25.6046614,5.08346182 24.5644245,4.04727273 L26.0755867,2.49818182 Z M22.1958652,6.86181818 C25.3039294,10.0363636 25.3039294,13.9636364 22.1958652,17.1381818 L20.6739855,15.5890909 C22.9568051,13.2763636 22.9568051,10.7236364 20.6739855,8.41090909 L22.1958652,6.86181818 Z" id="Shape"></path>
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}

SoundEnabledIcon.defaultProps = {
  color: '#0F0F0F',
  width: '30px',
  height: '29px',
};
