import React from 'react'
import {styled} from '@mui/material/styles'

const ScrollContainerComponent = styled('div')(({theme}) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'scroll',
}))

export default function ScrollContainer({children}) {
  return <ScrollContainerComponent>{children}</ScrollContainerComponent>
}
