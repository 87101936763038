import React, {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import {useUpdateAtom, useAtomValue} from 'jotai/utils'
import {setOpen} from 'components/TourControls/duck'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {
  shouldPlaySoundAtom,
  shouldPlaySoundPoiAtom,
  stepsChatbotAtom
} from 'components/jotai'
import {getCalculatedFontSize} from 'components/TourControls/duck'
import ErrorBoundary from 'components/ErrorBoundary'
import useConfig from 'components/Chatbot/useConfig'
import useIsIndividualTour from 'components/IndividuelleTour/useIsIndividualTour'

export const InitialBack = ({exit}) => {
  const dispatch = useDispatch()
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const shouldPlaySound = useAtomValue(shouldPlaySoundAtom)
  const shouldPlaySoundPoi = useAtomValue(shouldPlaySoundPoiAtom)

  const fontSize = useSelector(getCalculatedFontSize)
  const playSoundStep = useConfig('shouldPlaySound')

  const handleBack = () => {
    dispatch(setOpen(true))
    setStepsChatbot(playSoundStep)
  }

  if (shouldPlaySound && shouldPlaySoundPoi) {
    return null
  }

  return (
    <Box sx={{background: 'transparent', padding: '19px 29px', width: '100%'}}>
      <Grid container alignItems="center">
        <Grid item xs={6} onClick={handleBack}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} sx={{alignSelf: 'center', textAlign: 'right'}}>
          
        </Grid>
      </Grid>
    </Box>
  )
}


export const Initial = () => {
  const fontSize = useSelector(getCalculatedFontSize)

  const selectTourStep = useConfig('tour')
  const selectPlaceStep = useConfig('place')
  const selectWhereStep = useConfig('where')
  const selectHighlightsStep = useConfig('highlights')
  const isIndividualTour = useIsIndividualTour()

  useEffect(() => {

  }, [])

  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)

  const handleOpen = id => {
    switch (id) {
      case 'tour':
        setStepsChatbot(selectTourStep)
        break
      case 'place':
        setStepsChatbot(selectPlaceStep)
        break
      case 'where':
        setStepsChatbot(selectWhereStep)
        break
      case 'highlights':
        setStepsChatbot(selectHighlightsStep)
        break
      default:
        break
    }
  }

  return (
    <ErrorBoundary>
      <div className="rsc-os">
        <Grid
          container
          className="rsc-os-options"
          sx={{padding: '20px'}}
          spacing={2}
        >
          {!isIndividualTour &&
            <Grid
              item
              xs={10}
              className="rsc-os-option-element"
              onClick={() => handleOpen('tour')}
              style={{cursor: 'pointer'}}
            >
              <div className="rsc-os">
                <Typography style={{fontSize: fontSize}}>
                  <FormattedMessage
                    id="chatbot.tour.exit.option0"
                    defaultMessage="Tour wechseln"
                  />
                </Typography>
              </div>
            </Grid>
          }
          <Grid
            item
            xs={10}
            className="rsc-os-option-element"
            onClick={() => handleOpen('place')}
            style={{cursor: 'pointer'}}
          >
            <Typography style={{fontSize: fontSize}}>
              <FormattedMessage
                id="chatbot.tour.exit.option1"
                defaultMessage="Standort wechseln"
              />
            </Typography>
          </Grid>
          {!isIndividualTour &&
            <Grid
              item
              xs={10}
              className="rsc-os-option-element"
              onClick={() => handleOpen('where')}
              style={{cursor: 'pointer'}}
            >
              <Typography style={{fontSize: fontSize}}>
                <FormattedMessage
                  id="chatbot.tour.exit.option2"
                  defaultMessage="Wo bin ich?"
                />
              </Typography>
            </Grid>
          }
          <Grid
            item
            xs={10}
            className="rsc-os-option-element"
            onClick={() => handleOpen('highlights')}
            style={{cursor: 'pointer'}}
          >
            <Typography style={{fontSize: fontSize}}>
              <FormattedMessage
                id="chatbot.tour.exit.option3"
                defaultMessage="Was gibt es hier zu entdecken?"
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </ErrorBoundary>
  )
}
