import React from 'react'
import ReactDOM from 'react-dom'
import 'style/index.css'

import 'style/index.css'
//Toastify
import 'react-toastify/dist/ReactToastify.css'
import {App} from './App'

const container = document.getElementById('root')
ReactDOM.render(<App />, container)
