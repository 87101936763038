import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useUpdateAtom} from 'jotai/utils'
import useIsIndividualTour from 'components/IndividuelleTour/useIsIndividualTour'
import {getFlattenedNavigation,} from 'components/Navigation/duck'
import {individuelleTourUrlAtom, disableControlsAtom} from 'components/jotai'

export default function useNavigateToPlaylistItem() {
  const isIndividualTour = useIsIndividualTour()
  const navigation = useSelector(getFlattenedNavigation)
  const setIndividuelleTourUrl = useUpdateAtom(individuelleTourUrlAtom)
  const setDisableControls = useUpdateAtom(disableControlsAtom)

  const navigateToPlaylistItem = useCallback((label, items) => {
    const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(label)
    if (playlistItem) {
      window?.blazeIT?.hideComponent('Popup Viewer')
      window?.blazeIT?.navigateToPlayListItem(playlistItem)
      setDisableControls(true)
    } else if (isIndividualTour) {
      let item = navigation.find(nav => nav.label === label)
      if (!item && items) {
        item = items[label]
      }
      if (item) {
        let url = ""
        switch(item?.tourId) {
          case 'striebel':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/sasbach/index.htm'
              : `${window.location.origin}/sasbach/index.htm`
            break
          case 'bjeLogistik':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/luedenscheid/logistik/index.htm'
              : `${window.location.origin}/luedenscheid_logistik/index.htm`
            break
          case 'bjeProduktion':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/luedenscheid/production/index.htm'
              : `${window.location.origin}/luedenscheid_produktion/index.htm`
            break
          case 'bjeSchulungszentrum':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/luedenscheid/schulungszentrum/index.htm'
              : `${window.location.origin}/luedenscheid_schulungszentrum/index.htm`
            break
          case 'bjeAussengelaende':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/luedenscheid/aussengelaende/index.htm'
              : `${window.location.origin}/luedenscheid_aussengelaende/index.htm`
            break
          case 'bjeEmpfang':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/luedenscheid/empfang/index.htm'
              : `${window.location.origin}/luedenscheid_empfang/index.htm`
            break
          case 'bjeFree':
            url = window.location.hostname.includes('localhost')
              ? 'http://localhost:3000/tour-data/luedenscheid/free/index.htm'
              : `${window.location.origin}/luedenscheid_free/index.htm`
            break
          case 'abb':
            url = window.location.hostname.includes('localhost')
            ? 'http://localhost:3000/tour-data/heidelberg/index.htm'
            : `${window.location.origin}/heidelberg/index.htm`
            break
          default:
        }
        setDisableControls(true)
        setIndividuelleTourUrl(`${url}?media=${item.label}`)
      }
    }
  }, [isIndividualTour, navigation, setIndividuelleTourUrl, setDisableControls])


  return { navigateToPlaylistItem }
}
