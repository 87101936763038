import SvgIcon from '@mui/material/SvgIcon'

export default function NextIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 45 90"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <defs>
        <clipPath id="nextItem_a">
          <path d="M1440 0v1024H0V0h1440Z" />
        </clipPath>
        <clipPath id="nextItem_b">
          <path d="M45 0v90C20.147 90 0 69.853 0 45S20.147 0 45 0Z" />
        </clipPath>
        <clipPath id="nextItem_c">
          <path d="M8.5 0 17 8.514 15.512 10 8.5 3l-7.013 7L0 8.514 8.5 0Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#nextItem_a)" transform="translate(-1395 -467)">
        <g clipPath="url(#nextItem_b)" transform="translate(1395 467)">
          <path fill="#FFF" opacity="75%" d="M0 0h45v90H0V0z" />
        </g>
        <g
          clipPath="url(#nextItem_c)"
          transform="matrix(0 -1 -1 0 1427.5 520.5)"
        >
          <path d="M0 0h17v10H0V0z" />
        </g>
        <path
          stroke="#000"
          strokeWidth={1.422}
          fill="none"
          strokeMiterlimit={10}
          d="m1427.5 512-8.514-8.5-1.486 1.488 7 7.012-7 7.013 1.486 1.487 8.514-8.5z"
        />
      </g>
    </SvgIcon>
  )
}

NextIcon.defaultProps = {
  color: '#000000',
  width: '68px',
  height: '68px',
}
