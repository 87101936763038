import React, {useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch} from 'react-redux'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import ErrorBoundary from 'components/ErrorBoundary'

import playIcon from 'assets/img/play.svg'

import {setOpen} from 'components/TourControls/duck'

export default function Settings({id}) {
  const dispatch = useDispatch()

  const handleOpen = () => {
    if (playlistItem.get('media')?.get('label')) {
      window?.blazeIT?.triggerHotspotAction(
        `POI_${playlistItem.get('media').get('label')}`,
      )
      dispatch(setOpen(false))
    }
  }

  const playlistItem = useMemo(() => {
    return window?.blazeIT?.getPlayListItemByMediaName(id)
  }, [id])

  const label = useMemo(() => {
    if (playlistItem) {
      const splits = playlistItem.get('media')?.get('data')?.subtitle?.split('##')
      const label = Array.isArray(splits) && splits.length > 0
        ? splits[0]
        : playlistItem.get('media')?.get('label')
      return label
    }
    return ''
  }, [playlistItem])

  if (!playlistItem) {
    return null
  }

  return (
    <ErrorBoundary>
      <Grid container>
        <Grid item xs={12} onClick={handleOpen} style={{cursor: 'pointer'}}>
          <div className="rsc-ts-bubble">
            <Typography>
              <FormattedMessage
                id={`chatbot.video.${playlistItem.get('media')?.get('label')}`}
                defaultMessage={label}
              />
            </Typography>
            <div style={{position: 'relative'}}>
              <img
                src={playlistItem.get('media')?.get('thumbnailUrl')}
                style={{width: '100%'}}
                alt={playlistItem.get('media')?.get('label')}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <img src={playIcon} height="50%" width="auto" alt="play" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
