import SvgIcon from '@mui/material/SvgIcon'

export default function PlayPureIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 68 68"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <defs>
        <rect id="path-1" x="0" y="0" width="60" height="60" rx="30"></rect>
        <filter
          x="-11.7%"
          y="-8.3%"
          width="123.3%"
          height="123.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="designvorgaben-fuer-umsetzung"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="laura-next" transform="translate(-282.000000, -932.000000)">
          <g id="Group-4-Copy-2" transform="translate(286.000000, 934.000000)">
            <polygon
              id="Triangle"
              fill={props.color}
              transform="translate(33.000000, 30.500000) rotate(89.000000) translate(-33.000000, -30.500000) "
              points="33 23 43 38 23 38"
            ></polygon>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

PlayPureIcon.defaultProps = {
  color: '#000000',
  width: '68px',
  height: '68px',
}
