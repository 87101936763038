import { defineMessages } from "react-intl";

const useMessages = () => {
  const messages = defineMessages({
    title: {
      id: 'tour.individual.title',
      defaultMessage: 'Individuelle Touren',
    },
    defaultName: {
      id: 'tour.individual.name.default',
      defaultMessage: 'Individuelle Tour',
    },
    fachkunde: {
      id: 'tour.individual.fachkunde',
      defaultMessage: 'Fachkunde',
    },
    endkunde: {
      id: 'tour.individual.endkunde',
      defaultMessage: 'Endkunde',
    },
    overview: {
      id: 'tour.individual.overview',
      defaultMessage: 'Sammlung',
    },
    showUsed: {
      id: 'tour.individual.show.used',
      defaultMessage: 'verwendete ausblenden',
    },
    save: {
      id: 'tour.individual.save',
      defaultMessage: 'Änderungen speichern',
    },
    create: {
      id: 'tour.individual.create',
      defaultMessage: 'Tour speichern',
    },
    view: {
      id: 'tour.individual.view',
      defaultMessage: 'Tour anzeigen',
    },
    share: {
      id: 'tour.individual.share',
      defaultMessage: 'Tour teilen',
    },
    new: {
      id: 'tour.individual.new',
      defaultMessage: 'Neue Tour anlegen',
    },
    noElement: {
      id: 'tour.individual.no.element',
      defaultMessage: 'Es sind keine gespeicherten Touren vorhanden',
    },
    alreadyUsed: {
      id: 'tour.individual.already.used',
      defaultMessage: 'Bereits verwendet',
    },
    name: {
      id: 'tour.individual.table.head.name',
      defaultMessage: 'Name',
    },
    createdAt: {
      id: 'tour.individual.table.head.createdAt',
      defaultMessage: 'erstellt am',
    },
    updatedAt: {
      id: 'tour.individual.table.head.updatedAt',
      defaultMessage: 'geändert am',
    },
    countItems: {
      id: 'tour.individual.table.head.countItems',
      defaultMessage: 'Anzahl Stationen',
    },
    places: {
      id: 'tour.individual.table.head.places',
      defaultMessage: 'Orte',
    },
    tooltipView: {
      id: 'tour.individual.table.head.tooltipView',
      defaultMessage: 'Tour anzeigen',
    },
    tooltipEdit: {
      id: 'tour.individual.table.head.tooltipEdit',
      defaultMessage: 'Tour bearbeiten',
    },
    tooltipShare: {
      id: 'tour.individual.table.head.tooltipShare',
      defaultMessage: 'Tour teilen',
    },
    tooltipDelete: {
      id: 'tour.individual.table.head.tooltipDelete',
      defaultMessage: 'Tour löschen',
    },
    tooltipEnable: {
      id: 'tour.individual.table.head.tooltipEnable',
      defaultMessage: 'Tour aktivieren',
    },
    tooltipDisable: {
      id: 'tour.individual.table.head.tooltipDisable',
      defaultMessage: 'Tour deaktivieren',
    },
    login: {
      id: 'tour.individual.table.head.login',
      defaultMessage: 'Anmelden',
    },
    logout: {
      id: 'tour.individual.table.head.logout',
      defaultMessage: 'Abmelden',
    },
    loading: {
      id: 'tour.individual.table.head.loading',
      defaultMessage: 'Laden...',
    },
    deleteConfirmTitle: {
      id: 'tour.individual.table.head.deleteConfirmTitle',
      defaultMessage: 'Tour löschen',
    },
    deleteConfirmText: {
      id: 'tour.individual.table.head.deleteConfirmText',
      defaultMessage: 'Möchten Sie die Tour wirklich löschen?',
    },
    back: {
      id: 'tour.back',
      defaultMessage: 'zurück',
    },
  })

  return messages
}

export default useMessages