import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {useAtom} from 'jotai'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {
  tourMachineAtom,
  shouldPlaySoundAtom,
  shouldPlaySoundPoiAtom,
  isEndVideoAtom,
  audioControlsAtom,
  audioControlsPoiAtom,
  stepsChatbotAtom,
} from 'components/jotai'
import {useActor} from '@xstate/react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import useIsPoi from 'components/Hooks/useIsPoi'
import {setOpen} from 'components/TourControls/duck'
import useConfig from 'components/Chatbot/useConfig'
import {getCalculatedFontSize} from 'components/TourControls/duck'

export const PlaySoundNext = () => {
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const defaultStep = useConfig('default')
  const handleForward = () => {
    setStepsChatbot(defaultStep)
  }
  return (
    <Box sx={{background: 'transparent', padding: '19px 29px', width: '100%'}}>
      <Grid container alignItems="center">
        <Grid item xs={12} sx={{textAlign: 'right'}} onClick={handleForward}>
          <IconButton>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export const PlaySound = () => {
  const [shouldPlaySound, setShouldPlaySound] = useAtom(shouldPlaySoundAtom)
  const [shouldPlaySoundPoi, setShouldPlaySoundPoi] = useAtom(shouldPlaySoundPoiAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const setIsEndVideo = useUpdateAtom(isEndVideoAtom)
  const soundControls = useAtomValue(audioControlsAtom)
  const soundControlsPoi = useAtomValue(audioControlsPoiAtom)
  const [tour,] = useActor(tourService)
  const isPoi = useIsPoi()
  const dispatch = useDispatch()
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const defaultStep = useConfig('default')
  const fontSize = useSelector(getCalculatedFontSize)
  
  const handleClose = () => {
    dispatch(setOpen(false))
    setStepsChatbot(defaultStep)
  }

  const handlePlaySound = event => {
    if (isPoi) {
      const popupViewer = window?.blazeIT?.getComponent('Popup Viewer')
      setShouldPlaySoundPoi(true)
      const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
        tour.context.szenePoi,
      )
      if (playlistItem) {
        const media = playlistItem.get('media')
        if (media) {
          window?.blazeIT?.setComponentAttributes(
            media,
            {
              currentTime: 0,
            },
          )
          if (popupViewer) {
            popupViewer.play()
          }
          soundControlsPoi?.seek(0)
          soundControlsPoi?.play()
        }
      }
    } else {
      setShouldPlaySound(true)
      window?.blazeIT?.setComponentAttributes(
        window?.blazeIT?.getCurrentMedia(),
        {
          currentTime: 0,
        },
      )
      setIsEndVideo(false)
      window?.blazeIT?.getComponent('Main Viewer').play()
      soundControls?.seek(0)
      soundControls?.play()
    }
    handleClose()
  }

  if (shouldPlaySound && shouldPlaySoundPoi) {
    return null
  }

  return (
    <div className="rsc-os" style={{width: '100%'}}>
      <Grid
        container
        className="rsc-os-options"
        sx={{padding: '20px'}}
        spacing={2}
      >
        <Grid
          item
          xs={10}
          className="rsc-os-option-element"
          sx={{cursor: 'pointer', textAlign: 'center'}}
          onClick={handlePlaySound}
        >
          <div className="rsc-os">
            <Typography style={{fontSize: fontSize}}>
              <FormattedMessage
                id="chatbot.sound.play.yes"
                defaultMessage={`Ja`}
              />
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={10}
          className="rsc-os-option-element"
          sx={{cursor: 'pointer', textAlign: 'center'}}
          onClick={handleClose}
        >
          <div className="rsc-os">
            <Typography style={{fontSize: fontSize}}>
              <FormattedMessage
                id="chatbot.sound.play.no"
                defaultMessage={`Nein`}
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
