import {Initial, InitialBack} from 'components/Chatbot/components/Initial'
import {
  TourBack,
  SelectTour,
  PlaceOption,
} from 'components/Chatbot/components/SelectTour'
import {PlaceBack, SelectPlace} from 'components/Chatbot/components/SelectPlace'
import {WhereBack, SelectWhere} from 'components/Chatbot/components/SelectWhere'
import {
  HighlightsBack,
  SelectHighlights,
} from 'components/Chatbot/components/SelectHighlights'
import StartTour from 'components/Chatbot/components/StartTour'
import Message from 'components/Chatbot/components/Message'
import {PlaySound, PlaySoundNext} from 'components/Chatbot/components/PlaySound'
import {useIntl, defineMessages} from 'react-intl'
import {useSelector} from 'react-redux'
import {
  getNavigationContext,
} from 'components/xstate/NavigationMachine'

const useConfig = id => {
  const messages = defineMessages({
    initialTitle: {
      id: 'chatbot.tour.initial.title',
      defaultMessage: 'Was möchten Sie tun?',
    },
    placeTitle: {
      id: 'chatbot.tour.place.title',
      defaultMessage: 'Zu welchem Standort möchten Sie wechseln?',
    },
    tourTitle: {
      id: 'chatbot.tour.tour.title',
      defaultMessage: 'Welche Tour möchten Sie sich ansehen?',
    },
    whereMessage: {
      id: 'chatbot.tour.where.message',
      defaultMessage: 'Ich habe ihre Position im Plan markiert.',
    },
    highlightsMessage: {
      id: 'chatbot.tour.highlights.message',
      defaultMessage: 'Schauen Sie mal in die Liste der Stationen',
    },
    enableSoundMessage: {
      id: 'chatbot.tour.sound.message',
      defaultMessage: 'Möchten Sie die Sprachausgabe aktivieren?',
    },
    playSoundMessage: {
      id: 'chatbot.sound.play.message',
      defaultMessage: 'Möchten Sie meine Erläuterungen zu dieser Station noch einmal hören?',
    },
  })

  const naviContext = useSelector(getNavigationContext)
  const intl = useIntl()


  switch (id) {
    case 'enableGlobalSounding':
      return [
        {
          id: 'StartTour',
          component: <Message message={messages.enableSoundMessage} />,
          trigger: 'StartTour2',
        },
        {
          id: 'StartTour2',
          component: <StartTour />,
          end: true,
        }
      ]

    case 'place':
    case 'placeExit':
      return [
        {
          id: '1',
          component: <PlaceBack />,
          trigger: '2',
        },
        {
          id: '2',
          component: <Message message={messages.placeTitle} />,
          trigger: '3',
        },
        {
          id: '3',
          component: <SelectPlace exit={id === 'placeExit'} />,
          end: true,
        },
      ]
    case 'tour':
    case 'exit':
    case 'empfang':
      let tourStep = []

      if (id === 'tour') {
        tourStep = [
          ...tourStep,
          {
            id: '1',
            component: <TourBack />,
            trigger: '2',
          },
        ]
      }

      tourStep = [
        ...tourStep,
        {
          id: '2',
          component: <Message message={messages.tourTitle} />,
          trigger: '3',
        },
      ]

      if (id === 'exit') {
        tourStep = [
          ...tourStep,
          {
            id: '3',
            component: <SelectTour />,
            trigger: '4',
          },
          {
            id: '4',
            component: <PlaceOption />,
            end: true,
          },
        ]
      } else {
        tourStep = [
          ...tourStep,
          {
            id: '3',
            component: <SelectTour />,
            end: true,
          },
        ]
      }

      return tourStep
    case 'where':
      return [
        {
          id: '1',
          component: <WhereBack />,
          trigger: '2',
        },
        {
          id: '2',
          component: <SelectWhere />,
          trigger: '3',
        },
        {
          id: '3',
          component: <Message message={messages.whereMessage} />,
          end: true,
        },
      ]
    case 'highlights':
      return [
        {
          id: '1',
          component: <HighlightsBack />,
          trigger: '2',
        },
        {
          id: '2',
          component: <SelectHighlights />,
          trigger: '3',
        },
        {
          id: '3',
          component: <Message message={messages.highlightsMessage} />,
          end: true,
        },
      ]
    case 'shouldPlaySound':
      return [
        {
          id: '1',
          component: <PlaySoundNext />,
          trigger: '2',
        },
        {
          id: '2',
          component: <Message message={messages.playSoundMessage} />,
          trigger: '3',
        },
        {
          id: '3',
          component: <PlaySound />,
          end: true,
        },
      ]
    default:
      return [
        {
          id: '1',
          component: <InitialBack />,
          trigger: '2',
        },
        {
          id: '2',
          component: <Message message={messages.initialTitle} />,
          trigger: '3',
        },
        {
          id: '3',
          component: <Initial />,
          end: true,
        },
      ]
  }
}

export default useConfig
