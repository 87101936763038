import React, {useState, useMemo, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useSpring, animated} from 'react-spring'
import {FormattedMessage, defineMessages, useIntl} from 'react-intl'

import {useAtom} from 'jotai'
import {useAtomValue} from 'jotai/utils'
import {useClickAway} from 'react-use'

import {
  dimensionsAtom,
  sidebarAtom,
  soundEnabledAtom,
  subtitleEnabledAtom,
  showSitePlanAtom,
  isTourStartedAtom,
  tourMachineAtom,
  placeOpenAtom,
  languageOpenAtom,
  anchorTourAtom,
} from 'components/jotai'
import {getNavigationValue} from 'components/xstate/NavigationMachine'

import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import MuiIconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import {
  ArrowForward,
  ArrowBackward,
  BjeIconSmall,
  AbbIcon,
  FullScreenDisabledIcon,
  FullScreenEnabledIcon,
  LanguageIcon,
  PlanIcon,
  SoundDisabledIcon,
  SoundEnabledIcon,
  StandorteIcon,
  StationenIcon,
  StriebelIconSmall,
  UntertitelDisabledIcon,
  UntertitelEnabledIcon,
} from 'components/Icons'

import ScrollContainer from 'components/ScrollContainer'
import SelectTour from 'components/SelectTour'
import SelectLanguage from 'components/Language'
import TourOverview from 'components/TourOverview'

import ErrorBoundary from 'components/ErrorBoundary'
import {sidebarWitdh} from 'components/utils'
import { getLanguage } from 'components/Language/duck'
import useIsIndividualTour from 'components/IndividuelleTour/useIsIndividualTour'

const IconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '125px',
  transform: 'translate(50%, 50%)',
  background: '#FFF !important',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.30)',
  '& .MuiSvgIcon-root': {
    width: '0.5em',
    height: '0.5em',
  },
})

const Label = styled(Typography)({
  fontSize: '12px',
  lineHeight: '13px',
  color: '#6e6e6e',
})

export default function Sidebar() {
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useAtom(sidebarAtom)
  const [showSitePlan, setShowSitePlan] = useAtom(showSitePlanAtom)
  const isTourStarted = useAtomValue(isTourStartedAtom)
  const naviValue = useSelector(getNavigationValue)
  const tourService = useAtomValue(tourMachineAtom)
  const isIndividualTour = useIsIndividualTour()

  const [openPlace, setOpenPlace] = useAtom(placeOpenAtom)
  const [openLanguage, setOpenLanguage] = useAtom(languageOpenAtom)
  const [anchorTour, setAnchorTour] = useAtom(anchorTourAtom)
  const [soundEnabled, setSoundEnabled] = useAtom(soundEnabledAtom)
  const [untertitelEnabled, setUntertitelEnabled] = useAtom(subtitleEnabledAtom)
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false)
  const language = useSelector(getLanguage)

  const {height} = useAtomValue(dimensionsAtom)
  const sidebarStyles = useSpring({
    position: 'fixed',
    top: 0,
    left: drawerOpen ? 0 : sidebarWitdh * -1,
    opacity: drawerOpen ? 1 : 0,
    zIndex: 2000,
  })
  const collapseInStyles = useSpring({
    opacity: drawerOpen ? 0 : 1,
    left: drawerOpen ? 60 : -20,
    zIndex: 2000,
    borderRadius: '0 50% 50% 0',
    cursor: 'pointer',
  })
  const collapseOutStyles = useSpring({opacity: drawerOpen ? 1 : 0})
  const tourOverviewStyles = useSpring({
    left: Boolean(anchorTour) ? (drawerOpen ? sidebarWitdh : 0) : -500,
    opacity: Boolean(anchorTour) ? 1 : 0,
    position: 'fixed',
    top: '0',
    zIndex: 1900,
    background: '#F0F0F0',
  })

  const padding = useMemo(() => {
    return height > 700 ? 50 : height > 500 ? 20 : 10
  }, [height])

  const iconSize = useMemo(() => {
    return height > 1000 ? 28 : height > 700 ? 24 : 20
  }, [height])

  const spacingElements = useMemo(() => {
    return height > 1000 ? 4 : height > 700 ? 2 : 1
  }, [height])

  const spacingLabel = useMemo(() => {
    return height > 1000 ? 1 : 0
  }, [height])

  const toggleDrawer = () => {
    setDrawerOpen(open => !open)
  }

  const intl = useIntl()

  const messages = defineMessages({
    impressumAbb: {
      id: 'impressum.abb.link',
      defaultMessage: 'https://new.abb.com/provider-information',
    },
    datenschutzAbb: {
      id: 'datenschutz.abb.link',
      defaultMessage: `https://new.abb.com/privacy-policy/de`,
    },
    kontaktAbb: {
      id: 'kontakt.abb.link',
      defaultMessage:
        'https://new.abb.com/de/ueber-uns/gesellschaften/abb-stotz-kontakt/weitere-informationen-und-kontakt/kontakt',
    },
    impressumBje: {
      id: 'impressum.bje.link',
      defaultMessage: 'https://www.busch-jaeger.de/impressum',
    },
    datenschutzBje: {
      id: 'datenschutz.bje.link',
      defaultMessage: `https://www.busch-jaeger.de/datenschutz`,
    },
    kontaktBje: {
      id: 'kontakt.bje.link',
      defaultMessage: 'https://www.busch-jaeger.de/kontakt',
    },
    impressumStriebel: {
      id: 'impressum.striebel.link',
      defaultMessage: 'https://www.striebelundjohn.com/impressum',
    },
    datenschutzStriebel: {
      id: 'datenschutz.striebel.link',
      defaultMessage: `https://www.striebelundjohn.com/abb-striebel-und-john-datenschutzhinweise`,
    },
    kontaktStriebel: {
      id: 'kontakt.striebel.link',
      defaultMessage:
        'https://www.striebelundjohn.com/unternehmen/kontakt/support',
    },
  })

  const handleLogoClick = target => {
    if (naviValue === target) {
      window.location.reload()
    } else {
      dispatch({type: target})
    }
  }

  const handleOpenPlace = event => {
    setOpenPlace(!openPlace)
  }

  const handleClosePlace = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpenPlace(false)
  }

  const handleOpenLanguage = event => {
    setOpenLanguage(!openLanguage)
  }

  const handleCloseLanguage = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpenLanguage(false)
  }


  const handleOpenTour = event => {
    setAnchorTour(anchorTour ? null : true)
  }

  const handleCloseTour = () => {
    setAnchorTour(null)
  }

  const handlePlan = () => {
    setShowSitePlan(!showSitePlan)
  }

  const tourOverviewRef = React.useRef(null)
  useClickAway(tourOverviewRef, e => {
    if (!e.path?.find(el => el.id === 'stationsContainer')) {
      handleCloseTour(e)
    }
  })

  const placeIconRef = useRef(null)
  const placeRef = useRef(null)
  useClickAway(placeRef, e => {
    if (!e.path?.find(el => el.id === 'placeContainer')) {
      handleClosePlace()
    }
  })

  const languageIconRef = useRef(null)
  const languageRef = useRef(null)
  useClickAway(languageRef, e => {
    if (!e.path?.find(el => el.id === 'languageContainer')) {
      handleCloseLanguage()
    }
  })

  const isBje = useMemo(() => {
    return (
      naviValue?.toLowerCase()?.includes('luedenscheid') ||
      window.origin === 'factory-tour.busch-jaeger.de'
    )
  }, [naviValue])

  const isAbb = useMemo(() => {
    return (
      naviValue?.toLowerCase()?.includes('heidelberg') ||
      window.origin === 'factory-tour-stotz-kontakt.abb.com'
    )
  }, [naviValue])

  const isStriebel = useMemo(() => {
    return (
      naviValue?.toLowerCase()?.includes('sasbach') ||
      window.origin === 'actory-tour.striebelundjohn.com'
    )
  }, [naviValue])

  const color = '#6e6e6e'
  const disabledColor = '#cecece'

  const AnimatedIcon = animated(IconButton)
  const AnimatedCard = animated(Card)

  const cookieSettings = `<a href="#" onClick="UC_UI.showSecondLayer();"><p style="
  margin: 0px;
  font-family: ${isBje ? 'Averta' : 'ABBvoice'};
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: rgb(110, 110, 110);
">${language === 'en' ? 'Cookie-Settings': 'Cookie-Einstellungen'}</p></a>`

  return (
    <ErrorBoundary>
      <AnimatedIcon style={collapseInStyles} onClick={toggleDrawer}>
        <ArrowForward color="#6E6E6E" />
      </AnimatedIcon>
      <animated.div style={sidebarStyles}>
        <Box
          sx={{
            width: sidebarWitdh,
            height: '100vh',
            bgcolor: '#FFF',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.30)',
            position: 'relative',
          }}
        >
          <IconButton
            style={{
              ...collapseOutStyles,
              position: 'absolute',
              right: 0,
              zIndex: 2001,
              cursor: 'pointer',
            }}
            onClick={toggleDrawer}
          >
            <ArrowBackward color="#6E6E6E" />
          </IconButton>
          <ScrollContainer>
            <Grid
              container
              direction="column"
              sx={{
                height: '100%',
                paddingTop: `${padding}px`,
                paddingBottom: `${padding + 30}px`,
                justifyContent: 'space-between',
              }}
            >
              <Grid item align="center" id="logo">
                {(isAbb || (!isBje && !isStriebel)) && (
                  <AbbIcon
                    width={55}
                    height={21}
                    onClick={() => handleLogoClick('HEIDELBERG')}
                    style={{cursor: 'pointer'}}
                  />
                )}
                {isBje && (
                  <BjeIconSmall
                    width={18}
                    height={44}
                    onClick={() => handleLogoClick('LUEDENSCHEID_LOGISTIK')}
                    style={{cursor: 'pointer'}}
                  />
                )}
                {isStriebel && (
                  <StriebelIconSmall
                    width={height > 1000 ? 29 : 23.3}
                    height={height > 1000 ? 40 : 36}
                    onClick={() => handleLogoClick('SASBACH')}
                    style={{cursor: 'pointer'}}
                  />
                )}
              </Grid>
              <Grid item>
                <Grid
                  id="stations"
                  container
                  spacing={spacingElements}
                  direction="column"
                >
                  {!isIndividualTour && 
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        align="center"
                        spacing={spacingLabel}
                        sx={{cursor: 'pointer'}}
                        id="planIcon"
                        onClick={
                          window?.blazeIT?.variables?.tourStarted
                            ? handlePlan
                            : null
                        }
                      >
                        <Grid item>
                          <PlanIcon
                            color={isTourStarted ? color : disabledColor}
                            height={iconSize}
                          />
                        </Grid>
                        {height > 420 && (
                          <Grid item>
                            <Label>
                              <FormattedMessage
                                id="sidebar.plan.label"
                                defaultMessage={`Plan`}
                              />
                            </Label>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  }
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      align="center"
                      sx={{cursor: 'pointer'}}
                      spacing={spacingLabel}
                      id="stationsContainer"
                      onClick={
                        window?.blazeIT?.variables?.tourStarted
                          ? handleOpenTour
                          : null
                      }
                    >
                      <Grid item>
                        <StationenIcon
                          color={isTourStarted ? color : disabledColor}
                          height={iconSize}
                        />
                      </Grid>
                      {height > 420 && (
                        <Grid item>
                          <Label>
                            <FormattedMessage
                              id="sidebar.stationen.label"
                              defaultMessage={`Stationen`}
                            />
                          </Label>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid id="controls" item>
                <Grid container spacing={spacingElements} direction="column">
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      align="center"
                      onClick={() => {
                        if (window?.blazeIT?.variables?.tourStarted) {
                          window?.parent?.tour?.set('mute', soundEnabled)
                          setSoundEnabled(!soundEnabled)
                        }
                      }}
                      spacing={spacingLabel}
                      sx={{cursor: 'pointer'}}
                    >
                      <Grid item>
                        {soundEnabled && (
                          <SoundEnabledIcon
                            color={isTourStarted ? color : disabledColor}
                            height={iconSize}
                          />
                        )}
                        {!soundEnabled && (
                          <SoundDisabledIcon
                            color={isTourStarted ? color : disabledColor}
                            height={iconSize}
                          />
                        )}
                      </Grid>
                      {height > 420 && (
                        <Grid item>
                          <Label>
                            <FormattedMessage
                              id="sidebar.sound.label"
                              defaultMessage={`Sound`}
                            />
                          </Label>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      align="center"
                      sx={{cursor: 'pointer'}}
                      spacing={spacingLabel}
                      onClick={() => {
                        if (window?.blazeIT?.variables?.tourStarted) {
                          setUntertitelEnabled(!untertitelEnabled)
                          window?.blazeIT
                            ?.getComponentByName('Main Viewer')
                            ?.set('subtitlesEnabled', !untertitelEnabled)
                          window?.blazeIT
                            ?.getComponent('Popup Viewer')
                            ?.set('subtitlesEnabled', !untertitelEnabled)
                        }
                      }}
                    >
                      <Grid item>
                        {untertitelEnabled && (
                          <UntertitelEnabledIcon
                            color={isTourStarted ? color : disabledColor}
                            height={iconSize}
                          />
                        )}
                        {!untertitelEnabled && (
                          <UntertitelDisabledIcon
                            color={isTourStarted ? color : disabledColor}
                            height={iconSize}
                          />
                        )}
                      </Grid>
                      {height > 420 && (
                        <Grid item>
                          <Label>
                            <FormattedMessage
                              id="sidebar.untertitel.label"
                              defaultMessage={`Untertitel`}
                            />
                          </Label>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      id="languageContainer"
                      container
                      direction="column"
                      align="center"
                      sx={{cursor: 'pointer'}}
                      onClick={handleOpenLanguage}
                      spacing={spacingLabel}
                      ref={languageIconRef}
                    >
                      <Grid item>
                        <LanguageIcon color={color} height={iconSize} />
                      </Grid>
                      {height > 420 && (
                        <Grid item>
                          <Label>
                            <FormattedMessage
                              id="sidebar.language.label"
                              defaultMessage={`Sprache`}
                            />
                          </Label>
                        </Grid>
                      )}
                    </Grid>
                    <Popover
                      ref={languageRef}
                      id={'languagePopover'}
                      open={openLanguage}
                      anchorEl={() => {
                        return document.getElementById('languageContainer')
                      }}
                      onClose={handleCloseLanguage}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      PaperProps={{
                        sx: {
                          background: 'none',
                          boxShadow: 'none',
                          overflow: 'visible',
                        },
                      }}
                      sx={{
                        left: 0,
                      }}
                    >
                      <SelectLanguage onClose={handleCloseLanguage} />
                    </Popover>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      align="center"
                      sx={{cursor: 'pointer'}}
                      spacing={spacingLabel}
                      onClick={() => {
                        if (fullScreenEnabled) {
                          if (document.exitFullscreen) {
                            document.exitFullscreen()
                          } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen()
                          }
                        } else {
                          if (document.documentElement.requestFullscreen) {
                            document.documentElement.requestFullscreen()
                          } else if (
                            document.documentElement.msRequestFullscreen
                          ) {
                            // for IE11 (remove June 15, 2022)
                            document.documentElement.msRequestFullscreen()
                          } else if (
                            document.documentElement.webkitRequestFullscreen
                          ) {
                            // iOS Safari
                            document.documentElement.webkitRequestFullscreen()
                          }
                        }
                        setFullScreenEnabled(!fullScreenEnabled)
                      }}
                    >
                      <Grid item>
                        {fullScreenEnabled && (
                          <FullScreenEnabledIcon
                            color={color}
                            height={iconSize}
                          />
                        )}
                        {!fullScreenEnabled && (
                          <FullScreenDisabledIcon
                            color={color}
                            height={iconSize}
                          />
                        )}
                      </Grid>
                      {height > 420 && (
                        <Grid item>
                          <Label>
                            <FormattedMessage
                              id="sidebar.fullscreen.label"
                              defaultMessage={`Fullscreen`}
                            />
                          </Label>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  id="places"
                  spacing={spacingElements}
                  direction="column"
                >
                  {!isIndividualTour && 
                    <Grid item>
                      <Grid
                        id="placeContainer"
                        container
                        direction="column"
                        align="center"
                        sx={{cursor: 'pointer'}}
                        onClick={handleOpenPlace}
                        spacing={spacingLabel}
                        ref={placeIconRef}
                      >
                        <Grid item>
                          <StandorteIcon color={color} height={iconSize} />
                        </Grid>
                        {height > 420 && (
                          <Grid item>
                            <Label>
                              <FormattedMessage
                                id="sidebar.standorte.label"
                                defaultMessage={`Standorte`}
                              />
                            </Label>
                          </Grid>
                        )}
                      </Grid>
                      <Popover
                        ref={placeRef}
                        id={'placePopover'}
                        open={openPlace}
                        anchorEl={() => {
                          return document.getElementById('placeContainer')
                        }}
                        onClose={handleClosePlace}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          sx: {
                            background: 'none',
                            boxShadow: 'none',
                            overflow: 'visible',
                          },
                        }}
                        sx={{
                          left: 0,
                        }}
                      >
                        <SelectTour onClose={handleClosePlace} />
                      </Popover>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid id="links" item>
                <Grid
                  container
                  spacing={height > 1000 ? 2 : height > 500 ? 1 : 0}
                  direction="column"
                  align="center"
                >
                  <Grid item>
                    <a
                      href={intl.formatMessage(
                        messages[
                          naviValue?.toLowerCase()?.includes('sasbach')
                            ? 'impressumStriebel'
                            : naviValue?.toLowerCase()?.includes('luedenscheid')
                            ? 'impressumBje'
                            : 'impressumAbb'
                        ],
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Label>
                        <FormattedMessage
                          id="sidebar.impressum.label"
                          defaultMessage={`Impressum`}
                        />
                      </Label>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href={intl.formatMessage(
                        messages[
                          naviValue?.toLowerCase()?.includes('sasbach')
                            ? 'datenschutzStriebel'
                            : naviValue?.toLowerCase()?.includes('luedenscheid')
                            ? 'datenschutzBje'
                            : 'datenschutzAbb'
                        ],
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Label>
                        <FormattedMessage
                          id="sidebar.datenschutz.label"
                          defaultMessage={`Datenschutz`}
                        />
                      </Label>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href={intl.formatMessage(
                        messages[
                          naviValue?.toLowerCase()?.includes('sasbach')
                            ? 'kontaktStriebel'
                            : naviValue?.toLowerCase()?.includes('luedenscheid')
                            ? 'kontaktBje'
                            : 'kontaktAbb'
                        ],
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Label>
                        <FormattedMessage
                          id="sidebar.kontakt.label"
                          defaultMessage={`Kontakt`}
                        />
                      </Label>
                    </a>
                  </Grid>
                  <Grid item dangerouslySetInnerHTML={{__html: cookieSettings}} />
                </Grid>
              </Grid>
            </Grid>
          </ScrollContainer>
        </Box>
      </animated.div>
      <AnimatedCard style={tourOverviewStyles} ref={tourOverviewRef}>
        <CardContent>
          {tourService && <TourOverview onClose={handleCloseTour} />}
        </CardContent>
      </AnimatedCard>
    </ErrorBoundary>
  )
}