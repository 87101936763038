import React from 'react'
import { IntlProvider } from 'react-intl'
import {useSelector} from 'react-redux'
import {getLanguage} from 'components/Language/duck'

import messages_de from 'assets/translations/de.json'
import messages_en from 'assets/translations/en.json'

const messages = {
    'de': messages_de,
    'en': messages_en,
}

const formats = {
    number: {
        de: {
            style: 'currency',
            currency: 'EUR',
        },
        en: {
            style: 'currency',
            currency: 'EUR',
        },
    },
}

export default function QIntlProvider({ children }) {
    const language = useSelector(getLanguage)
    return (
        <IntlProvider
            locale={language}
            messages={messages[language]}
            formats={formats}
            defaultFormats={formats}
            onError={err => {
                if (err.code === 'MISSING_TRANSLATION') {
                    console.warn('Missing translation', err.message)
                    return
                }
                throw err
            }}
        >
            {children}
        </IntlProvider>
    )
}
