import SvgIcon from '@mui/material/SvgIcon'

export default function ArrowBackward(props) {
  return (
    <SvgIcon
      width="4px"
      height="8px"
      viewBox="0 0 9 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="Symbole"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="nav-settings"
          transform="translate(-144.000000, -122.000000)"
          stroke={props.color}
          strokeWidth="2"
        >
          <path
            d="M157,125 L157,134 C157,134.552285 156.552285,135 156,135 L147,135"
            id="Rectangle"
            transform="translate(152.000000, 130.000000) rotate(-225.000000) translate(-152.000000, -130.000000) "
          ></path>
        </g>
      </g>
    </SvgIcon>
  )
}

ArrowBackward.defaultProps = {
  color: '#6E6E6E',
}
