import SvgIcon from '@mui/material/SvgIcon';

export default function StandorteIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 28 22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nav-left-factory-icon" transform="translate(-10.000000, -13.000000)" fill={props.color}  fillRule="nonzero">
            <g id="Group" transform="translate(10.000000, 13.000000)">
                <path d="M23,0 L23,9 L20,9 L20,4 L10,9 L10,4 L0,9 L0,19.05 C0.0131177,19.837 0.33497,20.5872 0.896163,21.1391 C1.45736,21.6909 2.21294,22.0001 3,22 L25.1,22 C25.7121,21.9901 26.3065,21.7932 26.8034,21.4357 C27.3004,21.0782 27.676,20.5772 27.88,20 L28,20 L28,0 L23,0 Z M9,18 L5,18 L5,14 L9,14 L9,18 Z M16,18 L12,18 L12,14 L16,14 L16,18 Z M23,18 L19,18 L19,14 L23,14 L23,18 Z" id="Shape"></path>
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}

StandorteIcon.defaultProps = {
  color: '#0F0F0F',
  width: '28px',
  height: '22px',
};
