import React from 'react'
import {useDispatch} from 'react-redux'
import {FormattedMessage} from 'react-intl'

import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiBox from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import {BjeIconSmall, AbbIcon, StriebelIconSmall} from 'components/Icons'

import ErrorBoundary from 'components/ErrorBoundary'

const Box = styled(MuiBox)({
  background: '#FFF',
  width: '100%',
  padding: '24px 0',
})

export default function SelectTour({onClose}) {
  const dispatch = useDispatch()
  return (
    <ErrorBoundary>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          marginLeft: 2,
          display: 'grid',
          width: 200,
          gridColumnGap: 10,
          gridRowGap: 10,
        }}
      >
        <Grid item sx={{background: '#FFF', paddingLeft: '0px !important'}}>
          <Box>
            <Grid
              container
              direction="column"
              align="center"
              onClick={(e) => {
                onClose(e)
                dispatch({type: 'HEIDELBERG'})
              }}
              sx={{cursor: 'pointer'}}
            >
              <Grid item>
                <IconButton>
                  <AbbIcon width={55} height={21} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selecttour.abb.name.label"
                    defaultMessage={`ABB STOTZ-KONTAKT`}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selecttour.abb.place.label"
                    defaultMessage={`Heidelberg`}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sx={{background: '#FFF', paddingLeft: '0px !important'}}>
          <Box>
            <Grid
              container
              direction="column"
              align="center"
              onClick={(e) => {
                onClose(e)
                dispatch({type: 'LUEDENSCHEID_EMPFANG'})
              }}
              sx={{cursor: 'pointer'}}
            >
              <Grid item>
                <IconButton>
                  <BjeIconSmall width={18} height={44} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selecttour.bje.name.label"
                    defaultMessage={`Busch-Jaeger`}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selecttour.bje.place.label"
                    defaultMessage={`Lüdenscheid`}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sx={{background: '#FFF', paddingLeft: '0px !important'}}>
          <Box>
            <Grid
              container
              direction="column"
              align="center"
              onClick={(e) => {
                onClose(e)
                dispatch({type: 'SASBACH'})
              }}
              sx={{cursor: 'pointer'}}
            >
              <Grid item>
                <IconButton>
                  <StriebelIconSmall width={29} height={40} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selecttour.striebel.name.label"
                    defaultMessage={`Striebel & John`}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <FormattedMessage
                    id="selecttour.striebel.place.label"
                    defaultMessage={`Sasbach`}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
