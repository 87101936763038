import React, {useMemo} from 'react'

import {useActor} from '@xstate/react'

import {useAtomValue} from 'jotai/utils'
import {
  dimensionsAtom,
  tourMachineAtom,
  subtitleEnabledAtom,
  popupViewerSizeAtom,
  audioTimePoiAtom,
  audioTimeAtom
} from 'components/jotai'
import ErrorBoundary from 'components/ErrorBoundary'
import Captions from 'components/Caption/components/List'
import {subtitles} from 'components/Caption/subtitles'

export default function Layout() {
  const untertitelEnabled = useAtomValue(subtitleEnabledAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const [tour] = useActor(tourService)
  const {height} = useAtomValue(dimensionsAtom)
  const popupViewerSize = useAtomValue(popupViewerSizeAtom)
  const {playing} = useAtomValue(audioTimeAtom)
  const {playing: playingPoi} = useAtomValue(audioTimePoiAtom)
  
  return (
    <ErrorBoundary>
      <>
        { tour.context.subtitle && !tour.context.subtitlePoi && playing && (
          <div
            style={{
              position: 'absolute',
              bottom: height > 600 ? 100 : 70,
              left: '50%',
              background: 'rgb(0,0,0, .7)',
              padding: height > 600 ? 10 : 5,
              borderRadius: 5,
              color: '#FFF',
              transform: 'translate(-50%, 0)',
              zIndex: 1200,
              display: untertitelEnabled ? 'block' : 'none',
            }}
          >
            <Captions captions={ tour.context.subtitle} poi={false} />
          </div>
        )}
        {tour.context.subtitlePoi && playingPoi && (
          <div
            style={{
              position: 'absolute',
              bottom: `${popupViewerSize.y}%`,
              marginBottom: 48,
              left: '50%',
              background: 'rgb(0,0,0, .7)',
              padding: height > 600 ? 10 : 5,
              borderRadius: 5,
              color: '#FFF',
              transform: 'translate(-50%, 0)',
              zIndex: 1200,
              display: untertitelEnabled ? 'block' : 'none',
            }}
          >
            <Captions captions={tour.context.subtitlePoi} poi={true} />
          </div>
        )}
      </>
    </ErrorBoundary>
  )
}
