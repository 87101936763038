import React, {useEffect, useMemo} from 'react'
import Helmet from 'react-helmet'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
//import {FormattedMessage, useIntl, defineMessages} from 'react-intl'
import {useSpring, animated} from 'react-spring'
import {useSearchParams} from 'react-router-dom'
//import CookieConsent from 'react-cookie-consent'
import Grid from '@mui/material/Grid'
//import {withStyles} from '@mui/styles'

import ErrorBoundary from 'components/ErrorBoundary'
import PlaybackBar from 'components/PlaybackBar'
import PlaybackBarPoi from 'components/PlaybackBarPoi'
import TourControls from 'components/TourControls'
import ConnectionInfo from 'components/ConnectionInfo'
import SitePlan from 'components/SitePlan'
import {
  iframeDocAtom,
  sidebarAtom,
  tourMachineAtom,
  isTourStartedAtom,
} from 'components/jotai'
import {sidebarWitdh} from 'components/utils'
import IconAbb from 'assets/img/faviconAbb.ico'
import IconBje from 'assets/img/faviconBje.ico'
import IconStriebel from 'assets/img/faviconStriebel.ico'

/*const useStyles = theme => ({
  cookieBanner: {
    zIndex: '6100 !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    '& a': {
      color: '#FFF',
      marginLeft: theme.spacing(1),
    },
  },
  cookieBannerContent: {
    marginRight: '70px !important',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: '15px !important',
    },
  },
  cookieButtonWrapper: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      width: '100%',
      display: 'block',
      textAlign: 'center',
      margin: '15px',
    },
  },
  cookieButton: {
    border: 'none',
    background: 'transparent',
  },
})*/

export default function TourView({brand = 'bje', tour = 'bjeEmpfang', classes}) {
  const drawerOpen = useAtomValue(sidebarAtom)
  const setIFrameDoc = useUpdateAtom(iframeDocAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const setTourIsStarted = useUpdateAtom(isTourStartedAtom)
  const [searchParams] = useSearchParams()
  /*const intl = useIntl()

  const messages = defineMessages({
    domainBje: {
      id: 'cookie.bje.domain',
      defaultMessage: 'https://www.busch-jaeger.de/datenschutz',
    },
    domainAbb: {
      id: 'cookie.abb.domain',
      defaultMessage: 'https://new.abb.com/privacy-policy/de',
    },
    domainStriebel: {
      id: 'cookie.striebel.domain',
      defaultMessage:
        'https://www.striebelundjohn.com/abb-striebel-und-john-datenschutzhinweise',
    },
  })*/

  const url = useMemo(() => {
    switch (tour) {
      case 'abb':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/heidelberg/index.htm'
          : window.location.hostname.includes('192.168.178.8')
            ? 'http://192.168.178.8:3000/tour-data/heidelberg/index.htm'
            : `${window.location.origin}/heidelberg/index.htm`
      case 'abb2':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/heidelberg_6/index.htm'
          : `${window.location.origin}/heidelberg_6/index.htm`
      case 'striebel':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/sasbach/index.htm'
          : `${window.location.origin}/sasbach/index.htm`
      case 'bjeLogistik':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/luedenscheid/logistik/index.htm'
          : `${window.location.origin}/luedenscheid_logistik/index.htm`
      case 'bjeProduktion':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/luedenscheid/production/index.htm'
          : `${window.location.origin}/luedenscheid_produktion/index.htm`
      case 'bjeFree':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/luedenscheid/free/index.htm'
          : `${window.location.origin}/luedenscheid_free/index.htm`
      case 'bjeAussengelaende':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/luedenscheid/aussengelaende/index.htm'
          : `${window.location.origin}/luedenscheid_aussengelaende/index.htm`
      case 'bjeSchulungszentrum':
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/luedenscheid/schulungszentrum/index.htm'
          : `${window.location.origin}/luedenscheid_schulungszentrum/index.htm`
      case 'bjeEmpfang':
      default:
        return window.location.hostname.includes('localhost')
          ? 'http://localhost:3000/tour-data/luedenscheid/empfang/index.htm'
          : `${window.location.origin}/luedenscheid_empfang/index.htm`
    }
  }, [tour])

  const title = useMemo(() => {
    switch (tour) {
      case 'abb':
        return 'ABB - Heidelberg'
      case 'striebel':
        return 'Striebel & John'
      case 'bjeLogistik':
        return 'Busch-Jaeger - Lüdenscheid - Logistik'
      case 'bjeProduktion':
        return 'Busch-Jaeger - Lüdenscheid - Produktion'
      case 'bjeFree':
        return 'Busch-Jaeger - Lüdenscheid - Produktion'
      case 'bjeAussengelaende':
        return 'Busch-Jaeger - Lüdenscheid - Außengelände'
      case 'bjeSchulungszentrum':
        return 'Busch-Jaeger - Lüdenscheid - Schulungszentrum'
      case 'bjeEmpfang':
      default:
        return 'Busch-Jaeger - Lüdenscheid - Empfang'
    }
  }, [tour])

  useEffect(() => {
    const url2 = window.location.origin

    let myDynamicManifest = {
      short_name: 'Factory Tour',
      name: 'Factory Tour',
      display: 'standalone',
      orientation: 'any',
      background_color: '#ffffff',
      theme_color: '#FFF',
      scope: `${url2}/`,
      start_url: `${url2}/`,
      icons: [
        {
          src:
            brand === 'abb'
              ? `${url2}/logoAbb192.jpg`
              : brand === 'striebel'
              ? `${url2}/logoStriebel192.jpg`
              : `${url2}/logoBje192.jpg`,
          type: 'image/png',
          sizes: '192x192',
        },
        {
          src:
            brand === 'abb'
              ? `${url2}/logoAbb512.jpg`
              : brand === 'striebel'
              ? `${url2}/logoStriebel512.jpg`
              : `${url2}/logoBje512.jpg`,
          type: 'image/png',
          sizes: '512x512',
        },
      ],
    }

    let link = document.getElementById('manifest')
    if (!link) {
      link = document.createElement('link')
      link.rel = 'manifest'
      link.id = 'manifest'
    }
    const stringManifest = JSON.stringify(myDynamicManifest)
    link.setAttribute(
      'href',
      'data:application/json;charset=utf-8,' +
        encodeURIComponent(stringManifest),
    )
    document.head.appendChild(link)

    window.onload = function() {
      let frameElement = document.getElementById('tourFrame')
      if (frameElement) {
        let doc = frameElement.contentDocument

        if (doc) {
          setIFrameDoc(doc)
          doc
            .getElementById('preloadContainer')
            ?.setAttribute('style', 'opacity:1;cursor:pointer;')
        }
      }
    }

    return () => {
      setTourIsStarted(false)
    }
  }, [brand, setIFrameDoc, setTourIsStarted])

  const media = searchParams.get('media')
  const yaw = searchParams.get('yaw')
  const pitch = searchParams.get('pitch')

  const params = useMemo(() => {
    let str = ``

    if (media) {
      if (str.length > 0) {
        str = `${str}&media=${encodeURI(media)}`
      } else {
        str = `?media=${encodeURI(media)}`
      }
    }

    if (yaw) {
      if (str.length > 0) {
        str = `${str}&yaw=${yaw}`
      } else {
        str = `?yaw=${yaw}`
      }
    }

    if (pitch) {
      if (str.length > 0) {
        str = `${str}&pitch=${pitch}`
      } else {
        str = `?pitch=${pitch}`
      }
    }

    return str
  }, [media, yaw, pitch])

  const renderEl = useMemo(() => !!tourService, [tourService])

  const gridStyles = useSpring({
    position: 'relative',
    height: '100%',
    marginLeft: drawerOpen ? sidebarWitdh : 0,
  })

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title}</title>
        <link
          id="favicon"
          rel="icon"
          href={
            brand === 'abb'
              ? IconAbb
              : brand === 'striebel'
              ? IconStriebel
              : IconBje
          }
        />
        <link
          rel="apple-touch-icon"
          href={
            brand === 'abb'
              ? `${window.location.origin}/logoAbb192.jpg`
              : brand === 'striebel'
              ? `${window.location.origin}/logoStriebel192.jpg`
              : `${window.location.origin}/logoBje192.jpg`
          }
        />
      </Helmet>
      <Grid container sx={{width: '100vw', height: '100vh'}}>
        <Grid item xs={12}>
          <animated.div style={gridStyles}>
            <iframe
              id="tourFrame"
              title={title}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1000,
              }}
              src={`${url}${params}`}
              name="tourFrame"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
            />
          </animated.div>
        </Grid>
      </Grid>
      {renderEl && <PlaybackBar />}
      {renderEl && <PlaybackBarPoi />}
      {renderEl && <TourControls />}
      {renderEl && <SitePlan tour={tour} />}
      <ConnectionInfo />
      {/*<CookieConsent
        location="bottom"
        disableButtonStyles={true}
        buttonText={
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 36 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
            >
              <path
                d="M18,18 L33,3 L18,18 L3,3 L18,18 Z M18,18 L33,33 L18,18 L3,33 L18,18 Z"
                id="Combined-Shape"
                stroke="#FFF"
                stroke-width="5"
              ></path>
            </g>
          </svg>
        }
        containerClasses={classes.cookieBanner}
        contentClasses={classes.cookieBannerContent}
        buttonClasses={classes.cookieButton}
        buttonWrapperClasses={classes.cookieButtonWrapper}
        cookieName="FactoryTourCookieConsent"
      >
        {brand === 'bje' && (
          <FormattedMessage
            id="cookie.bje.info"
            defaultMessage={`Busch-Jaeger verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Mit der Nutzung unserer Website erklären Sie sich damit einverstanden, dass wir Cookies verwenden. Weitere Informationen zu Cookies und unserem Datenschutz finden`}
          />
        )}
        {brand === 'striebel' && (
          <FormattedMessage
            id="cookie.striebel.info"
            defaultMessage={`Striebel & John verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Mit der Nutzung unserer Website erklären Sie sich damit einverstanden, dass wir Cookies verwenden. Weitere Informationen zu Cookies und unserem Datenschutz finden`}
          />
        )}
        {brand === 'abb' && (
          <FormattedMessage
            id="cookie.abb.info"
            defaultMessage={`ABB-STOTZ-KONTAKT verwendet Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Mit der Nutzung unserer Website erklären Sie sich damit einverstanden, dass wir Cookies verwenden. Weitere Informationen zu Cookies und unserem Datenschutz finden`}
          />
        )}

        <a
          href={intl.formatMessage(
            brand === 'abb'
              ? messages.domainAbb
              : brand === 'striebel'
              ? messages.domainStriebel
              : messages.domainBje,
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="cookie.more.info" defaultMessage={`Sie hier`} />
        </a>
      </CookieConsent>*/}
    </ErrorBoundary>
  )
}

/*
export default withStyles(theme => {
  return useStyles(theme)
})(TourView)
*/
