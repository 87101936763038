import SvgIcon from '@mui/material/SvgIcon'

export default function CenterIcon(props) {
  return (
    <SvgIcon
      width="8px"
      height="8px"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
      enableBackground: "new 0 0 28 28",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path d="M1 9V6.3h5.3V1H9v6.7c0 .3-.1.7-.4.9-.2.3-.6.4-.9.4H1zM9 27H6.3v-5.3H1V19h6.7c.4 0 .7.1.9.4.3.2.4.6.4.9V27zM19 1h2.7v5.3H27V9h-6.7c-.4 0-.7-.1-.9-.4-.3-.2-.4-.6-.4-.9V1zM27 19v2.7h-5.3V27H19v-6.7c0-.4.1-.7.4-.9.3-.3.6-.4.9-.4H27zM16.6 18h-5.2c-.8 0-1.4-.6-1.4-1.4v-5.2c0-.8.6-1.4 1.4-1.4h5.2c.8 0 1.4.6 1.4 1.4v5.2c0 .8-.6 1.4-1.4 1.4z" />
    </SvgIcon>
  )
}

CenterIcon.defaultProps = {
  color: '#6E6E6E',
}
