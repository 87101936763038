import {CSS} from "@dnd-kit/utilities"
import {
  useSortable,
} from "@dnd-kit/sortable";
import {styled} from '@mui/material/styles'
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Card = styled(MuiCard)(({theme}) => {
  return {
    position: 'relative',
    overflow: 'visible !important',
    marginBottom: '32px',
    '&:before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      height: theme.spacing(4),
      background: '#D2D2D2',
      width: 3,
      left: 'calc(50% - 2px)',
      top: `${parseInt(theme.spacing(4).replace('px', '')) * - 1}px`,
    },
    '&.firstCard': {
      '&:before': {
        display: 'none',
      }
    }
  }
})

const Card2 = styled(MuiCard)(({theme}) => {
  return {
    position: 'relative',
    overflow: 'visible !important',
    marginBottom: '32px',
  }
})

const SubCard = styled(MuiCard)(({theme}) => {
  return {
    position: 'relative',
    overflow: 'visible !important',
    marginBottom: '32px',
    '&:before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      height: theme.spacing(4),
      background: '#D2D2D2',
      width: 3,
      left: 'calc(50% - 18px)',
      top: `${parseInt(theme.spacing(4).replace('px', '')) * - 1}px`,
    },
    '&.firstCard': {
      '&:before': {
        display: 'none',
      }
    }
  }
})

const SubCard2 = styled(MuiCard)(({theme}) => {
  return {
    position: 'relative',
    overflow: 'visible !important',
    marginBottom: '32px',
  }
})

const SortableItem = ({
  index,
  id,
  element,
  playlistItems,
  setOpenPanorama,
  setOpen360Video,
  setOpenVideo,
  isPublicDragging,
  handleDelete
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isOver } =
    useSortable({ 
      id: id,
      data: element
    });


  const pois = element?.pois ? JSON.parse(element.pois) : []

  const downloadFile = (playlistItem) => {
    const link = document.createElement('a')
    if (playlistItem.type === 'showPdf') {
      link.href = `${window.location.origin}/assets/pdf/${playlistItem.url}`
      link.setAttribute('download', `${playlistItem.url}.pdf`)
    } else {
      const splits = playlistItem.url.split('/')
      link.href = playlistItem.url
      link.setAttribute('download', splits[splits.length - 1])
    }
    document.body.appendChild(link)
    link.click()
  }

  const handleView = (playlistItem) => {
    if (playlistItem.type === 'panorama') {
      setOpenPanorama(playlistItem.url)
    } else if (playlistItem.type === '360video') {
      setOpen360Video(playlistItem.url)
    } else if (playlistItem.type === 'video') {
      setOpenVideo(playlistItem.url)
    }
  }

  const CardComponent = isPublicDragging ? Card2 : Card
  const SubCardComponent = isPublicDragging ? SubCard2 : SubCard

  if (!element) {
    return null
  }

  return (
    <Grid
      item xs={12}
      align="right"
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      key={id}
      className={index === 0 ? 'firstCard' : ''}
      style={{transform: CSS.Translate.toString(transform), transition, borderTop: isOver ? '5px solid red' : 'none'}}
    >
      <Grid container spacing={2}  alignItems="center">
        <Grid item xs={2} align="right">
          {String(index + 1).padStart(2, '0')}
        </Grid>
        <Grid item xs={10}>
            <CardComponent 
              className={index === 0 ? 'firstCard' : ''}
              style={{width: '100%'}}
            >
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} lg={4}>
                  {['video', '360video', 'panorama'].includes(element.type) && <img src={element.thumbnailUrl} style={{maxWidth: '100%'}} />}
                  {element.type === 'showPdf' && <PictureAsPdfIcon sx={{ fontSize: 40 }} />}
                </Grid>
                <Grid item xs={12} lg={5} align="left">
                  {element.name}
                </Grid>
                <Grid item xs={4} lg={1} align="center">
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      downloadFile(element)
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4} lg={1} align="center">
                  <IconButton
                    href={element.type === 'showPdf'
                      ? `${window.location.origin}/#/pdfviewer/${element.url}`
                      : `${window.location.origin}/#/tour/preview/${element.id}`
                    }
                    target="_blank"
                  >
                    <PreviewIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4} lg={1}>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDelete(element?.id)
                    }}  
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </CardComponent>
        </Grid>
        {Array.isArray(pois) && pois.map((poiId, poiIndex) => {
            const poi = playlistItems[poiId]
            if (!poi) {
              return null
            }

            return (
              <>
                <Grid item xs={3} align="right">
                  {`${String(index + 1).padStart(2, '0')}.${String(poiIndex + 1).padStart(2, '0')}`}
                </Grid>
                <Grid item xs={9} style={{paddingTop: 0}}>
                  <SubCardComponent 
                    ref={setNodeRef}
                    {...attributes}
                    {...listeners}
                    key={element.id}
                    style={{width: '100%'}}
                  >
                    <CardContent>
                      {<Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} lg={4}>
                          {['video', '360video', 'panorama'].includes(poi.type) && <img src={poi.thumbnailUrl} style={{maxWidth: '100%'}} />}
                          {poi.type === 'showPdf' && <PictureAsPdfIcon sx={{ fontSize: 40 }} />}
                        </Grid>
                        <Grid item xs={12} lg={6} align="left">
                          {poi.name}
                        </Grid>
                        <Grid item xs={6} lg={1}>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              downloadFile(poi)
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={6} lg={1}>
                          <IconButton
                            href={
                              poi.type === 'showPdf'
                                ? `${window.location.origin}/#/pdfviewer/${poi.url}`
                                : `${window.location.origin}/#/tour/preview/${poi.id}`
                              }
                            target="_blank"
                          >
                            <PreviewIcon />
                          </IconButton>
                        </Grid>
                      </Grid>}
                    </CardContent>
                  </SubCardComponent>
                </Grid>
              </>
            )
          })}
      </Grid>
    </Grid>
  )
}

export default SortableItem