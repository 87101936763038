import SvgIcon from '@mui/material/SvgIcon';

export default function SoundDisabledIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 30 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g id="Symbole" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nav-left-sound-off-icon" transform="translate(-9.000000, -10.000000)" fill={props.color} fillRule="nonzero">
            <g id="Group" transform="translate(9.000000, 10.000000)">
                <path d="M29.7750343,27.0835468 L27.8353909,29 L0.404663923,1.9164532 L2.34430727,0 L29.7750343,27.0835468 Z M3.78600823,9.08249832 L15.0891632,20.2425538 L15.0891632,26.6894211 L12.345679,26.6894211 L5.48696845,19.9175017 L2.74348422,19.9175017 C2.01587106,19.9175017 1.31805213,19.632133 0.803552812,19.124239 C0.289039781,18.6162096 0,17.9272346 0,17.2087339 L0,11.7912661 C0,11.0728467 0.289039781,10.3838581 0.803552812,9.87586932 C1.31805213,9.36788055 2.01587106,9.08249832 2.74348422,9.08249832 L3.78600823,9.08249832 Z M27.037037,2.70335023 C30.9876543,6.61751966 30.9876543,16.9649448 27.037037,20.8791142 L25.0891632,18.9558891 C28.0109739,16.0845953 28.0109739,7.49786918 25.0891632,4.62657535 L27.037037,2.70335023 Z M22.9218107,6.76650188 C24.1806584,8.14493962 24.8775034,9.93516424 24.8775034,11.7912661 C24.8775034,13.6473815 24.1806584,15.4376061 22.9218107,16.8159626 L20.9739369,14.8927375 C21.7182442,14.0264735 22.1268861,12.9272556 22.1268861,11.7912661 C22.1268861,10.6552089 21.7182442,9.55605865 20.9739369,8.689727 L22.9218107,6.76650188 Z M15.0891632,2.31057891 L15.0891632,8.75744619 L10.4526749,4.17962867 L12.345679,2.31057891 L15.0891632,2.31057891 Z" id="Shape"></path>
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}

SoundDisabledIcon.defaultProps = {
  color: '#0F0F0F',
  width: '30px',
  height: '29px',
};
