import {useEffect, useCallback} from 'react'
import {useUpdateAtom} from 'jotai/utils'
import {windowIsActiveAtom} from 'components/jotai'

export default function use3DVista() {
  const setWindowIsActive = useUpdateAtom(windowIsActiveAtom)

  const handleActivity = useCallback(
    forcedFlag => {
      if (typeof forcedFlag === 'boolean') {
        return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false)
      }

      return document.hidden
        ? setWindowIsActive(false)
        : setWindowIsActive(true)
    },
    [setWindowIsActive],
  )

  useEffect(() => {
    document.addEventListener('visibilitychange', handleActivity)

    return () => {
      document.removeEventListener('visibilitychange', handleActivity)
    }
  }, [handleActivity])
}
