import SvgIcon from '@mui/material/SvgIcon'

export default function PauseIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 68 68"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <defs>
        <rect id="path-1" x="0" y="0" width="60" height="60" rx="30"></rect>
        <filter
          x="-11.7%"
          y="-8.3%"
          width="123.3%"
          height="123.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="designvorgaben-fuer-umsetzung"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="laura-next" transform="translate(-362.000000, -932.000000)">
          <g id="Group-4-Copy-3" transform="translate(366.000000, 934.000000)">
            <g id="Group-3" opacity="0.5">
              <g id="Rectangle">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
            </g>
            <rect
              id="Rectangle"
              fill="#000000"
              x="24"
              y="20"
              width="5"
              height="21"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="32"
              y="20"
              width="5"
              height="21"
            ></rect>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

PauseIcon.defaultProps = {
  color: '#000000',
  width: '68px',
  height: '68px',
}
