import Sasbach from './Sasbach'
import BjeFree from './BjeFree'
import BjeEmpfang from './BjeEmpfang'
import BjeAussengelaende from './BjeAussengelaende'
import BjeLogistik from './BjeLogistik'
import BjeProduktion from './BjeProduktion'
import BjeSchulungszentrum from './BjeSchulungszentrum'
import Heidelberg from './Heidelberg'

export default function SitePlan({tour}) {
  switch (tour) {
    case 'abb':
      return <Heidelberg />
    case 'striebel':
      return <Sasbach />
    case 'bjeLogistik':
      return <BjeLogistik />
    case 'bjeProduktion':
      return <BjeProduktion />
    case 'bjeFree':
      return <BjeFree />
    case 'bjeAussengelaende':
      return <BjeAussengelaende />
    case 'bjeSchulungszentrum':
      return <BjeSchulungszentrum />
    case 'bjeEmpfang':
      return <BjeEmpfang />
    default:
      return null
  }
}
