import {defineMessages} from 'react-intl'

export default function useStationMessages() {
  const messages = defineMessages({
    Individuell: {
      id: 'tour.overview.individuell',
      defaultMessage: 'Ihre Stationen',
    },
    Sasbach: {
      id: 'tour.overview.sasbach',
      defaultMessage: 'Ihre Stationen in Sasbach',
    },
    Sasbach1: {
      id: 'tour.overview.sasbach.1',
      defaultMessage: 'Empfang',
    },
    Sasbach2: {
      id: 'tour.overview.sasbach.2',
      defaultMessage: 'Zählerschränke ComfortLine',
    },
    Sasbach3: {
      id: 'tour.overview.sasbach.3',
      defaultMessage: 'TwinLine Schrankserie',
    },
    Sasbach4: {
      id: 'tour.overview.sasbach.4',
      defaultMessage: 'Stromkreisverteiler UK600',
    },
    Sasbach5: {
      id: 'tour.overview.sasbach.5',
      defaultMessage: '',
    },
    Sasbach6: {
      id: 'tour.overview.sasbach.6',
      defaultMessage: '',
    },
    Sasbach7: {
      id: 'tour.overview.sasbach.7',
      defaultMessage: '',
    },
    Logistik: {
      id: 'tour.overview.logistik',
      defaultMessage: 'Logistik',
    },
    LüdenscheidLogistik: {
      id: 'tour.overview.luedenscheid.logistik',
      defaultMessage: 'Stationen Logistik',
    },
    LüdenscheidLogistik1: {
      id: 'tour.overview.luedenscheid.logistik.1',
      defaultMessage: 'Intro Logistik',
    },
    LüdenscheidLogistik2: {
      id: 'tour.overview.luedenscheid.logistik.2',
      defaultMessage: 'Einlagerung Material',
    },
    LüdenscheidLogistik3: {
      id: 'tour.overview.luedenscheid.logistik.3',
      defaultMessage: 'Materialversorgung Produktion',
    },
    LüdenscheidLogistik4: {
      id: 'tour.overview.luedenscheid.logistik.4',
      defaultMessage: 'Einlagerung Steckdosen',
    },
    LüdenscheidLogistik5: {
      id: 'tour.overview.luedenscheid.logistik.5',
      defaultMessage: 'Kommissionierung Einzelversandstücke',
    },
    LüdenscheidLogistik6: {
      id: 'tour.overview.luedenscheid.logistik.6',
      defaultMessage: 'Kommissionierung Paletten',
    },
    LüdenscheidLogistik7: {
      id: 'tour.overview.luedenscheid.logistik.7',
      defaultMessage: 'Kommissionierung Paletten',
    },
    Produktion: {
      id: 'tour.overview.produktion',
      defaultMessage: 'Produktion',
    },
    LüdenscheidProduktion: {
      id: 'tour.overview.luedenscheid.produktion',
      defaultMessage: 'Stationen Produktion',
    },
    LüdenscheidProduktion1: {
      id: 'tour.overview.luedenscheid.produktion.1',
      defaultMessage: 'Intro Produktion',
    },
    LüdenscheidProduktion2: {
      id: 'tour.overview.luedenscheid.produktion.2',
      defaultMessage: 'Schalterproduktion',
    },
    LüdenscheidProduktion3: {
      id: 'tour.overview.luedenscheid.produktion.3',
      defaultMessage: 'Steckdosenproduktion',
    },
    LüdenscheidProduktion4: {
      id: 'tour.overview.luedenscheid.produktion.4',
      defaultMessage: 'Tacteo',
    },
    LüdenscheidProduktion5: {
      id: 'tour.overview.luedenscheid.produktion.5',
      defaultMessage: 'Busch-free@home®',
    },
    LüdenscheidProduktion6: {
      id: 'tour.overview.luedenscheid.produktion.6',
      defaultMessage: '',
    },
    LüdenscheidProduktion7: {
      id: 'tour.overview.luedenscheid.produktion.7',
      defaultMessage: '',
    },
    Schulungszentrum: {
      id: 'tour.overview.schulungszentrum',
      defaultMessage: 'Schulungszentrum',
    },
    LüdenscheidSchulungszentrum: {
      id: 'tour.overview.luedenscheid.schulungszentrum',
      defaultMessage: 'Stationen Schulungszentrum',
    },
    LüdenscheidSchulungszentrum1: {
      id: 'tour.overview.luedenscheid.schulungszentrum.1',
      defaultMessage: ' ',
    },
    LüdenscheidSchulungszentrum2: {
      id: 'tour.overview.luedenscheid.schulungszentrum.2',
      defaultMessage: ' ',
    },
    LüdenscheidSchulungszentrum3: {
      id: 'tour.overview.luedenscheid.schulungszentrum.3',
      defaultMessage: ' ',
    },
    LüdenscheidSchulungszentrum4: {
      id: 'tour.overview.luedenscheid.schulungszentrum.4',
      defaultMessage: ' ',
    },
    LüdenscheidSchulungszentrum5: {
      id: 'tour.overview.luedenscheid.schulungszentrum.5',
      defaultMessage: '',
    },
    LüdenscheidSchulungszentrum6: {
      id: 'tour.overview.luedenscheid.schulungszentrum.6',
      defaultMessage: '',
    },
    LüdenscheidSchulungszentrum7: {
      id: 'tour.overview.luedenscheid.schulungszentrum.7',
      defaultMessage: '',
    },
    LüdenscheidFreie: {
      id: 'tour.overview.luedenscheid.free',
      defaultMessage: 'Stationen Produktion',
    },
    Aussengelaende: {
      id: 'tour.overview.aussengelaende',
      defaultMessage: 'Außengelände',
    },
    LüdenscheidAussengelaende: {
      id: 'tour.overview.luedenscheid.aussengelaende',
      defaultMessage: 'Stationen Außengelände',
    },
    LüdenscheidAussengelaende1: {
      id: 'tour.overview.luedenscheid.aussengelaende.1',
      defaultMessage: '',
    },
    LüdenscheidAussengelaende2: {
      id: 'tour.overview.luedenscheid.aussengelaende.2',
      defaultMessage: '',
    },
    LüdenscheidAussengelaende3: {
      id: 'tour.overview.luedenscheid.aussengelaende.3',
      defaultMessage: '',
    },
    LüdenscheidAussengelaende4: {
      id: 'tour.overview.luedenscheid.aussengelaende.4',
      defaultMessage: '',
    },
    LüdenscheidAussengelaende5: {
      id: 'tour.overview.luedenscheid.aussengelaende.5',
      defaultMessage: '',
    },
    LüdenscheidAussengelaende6: {
      id: 'tour.overview.luedenscheid.aussengelaende.6',
      defaultMessage: '',
    },
    LüdenscheidAussengelaende7: {
      id: 'tour.overview.luedenscheid.aussengelaende.7',
      defaultMessage: '',
    },
    Empfang: {
      id: 'tour.overview.empfang',
      defaultMessage: 'Empfang',
    },
    LüdenscheidEmpfang: {
      id: 'tour.overview.luedenscheid.empfang',
      defaultMessage: 'Stationen Empfang',
    },
    LüdenscheidAussengelaendeEmpfang1: {
      id: 'tour.overview.luedenscheid.empfang.1',
      defaultMessage: '',
    },
    LüdenscheidAussengelaendeEmpfang2: {
      id: 'tour.overview.luedenscheid.empfang.2',
      defaultMessage: '',
    },
    LüdenscheidAussengelaendeEmpfang3: {
      id: 'tour.overview.luedenscheid.empfang.3',
      defaultMessage: '',
    },
    LüdenscheidAussengelaendeEmpfang4: {
      id: 'tour.overview.luedenscheid.empfang.4',
      defaultMessage: '',
    },
    LüdenscheidAussengelaendeEmpfang5: {
      id: 'tour.overview.luedenscheid.empfang.5',
      defaultMessage: '',
    },
    LüdenscheidAussengelaendeEmpfang6: {
      id: 'tour.overview.luedenscheid.eEmpfang.6',
      defaultMessage: '',
    },
    LüdenscheidEmpfang7: {
      id: 'tour.overview.luedenscheid.empfang.7',
      defaultMessage: '',
    },
    Heidelberg: {
      id: 'tour.overview.heidelberg',
      defaultMessage: 'Ihre Stationen in Heidelberg',
    },
    Heidelberg1: {
      id: 'tour.overview.heidelberg.1',
      defaultMessage: 'Atrium',
    },
    Heidelberg2: {
      id: 'tour.overview.heidelberg.2',
      defaultMessage: 'Museum / Schulungszentrum',
    },
    Heidelberg3: {
      id: 'tour.overview.heidelberg.3',
      defaultMessage: 'Produktion Sicherungsautomat',
    },
    Heidelberg4: {
      id: 'tour.overview.heidelberg.4',
      defaultMessage: 'Produktion Industrieschaltgeräte',
    },
    Heidelberg5: {
      id: 'tour.overview.heidelberg.5',
      defaultMessage: 'Prüflabor',
    },
    Heidelberg6: {
      id: 'tour.overview.heidelberg.6',
      defaultMessage: 'Außenanlagen',
    },
    Heidelberg7: {
      id: 'tour.overview.heidelberg.7',
      defaultMessage: '',
    },
  })

  return messages
}
