import {useCallback, useEffect} from 'react'
import {useActor} from '@xstate/react'
import {useDispatch} from 'react-redux'

import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {
  showPlaybackBarAtom,
  showPlaybackBarPoiAtom,
  tourMachineAtom,
  stepsChatbotAtom,
  isTourStartedAtom,
} from 'components/jotai'
import ShowVideo from 'components/Chatbot/components/ShowVideo'
import {setOpen} from 'components/TourControls/duck'
import {
  getMediaData,
} from 'components/Navigation/duck'
import useIsIndividualTour from 'components/IndividuelleTour/useIsIndividualTour'

export default function use3DVista() {
  const dispatch = useDispatch()
  const tourService = useAtomValue(tourMachineAtom)
  const [, sendTour] = useActor(tourService)

  const isIndividualTour = useIsIndividualTour()
  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const setShowPlaybackBar = useUpdateAtom(showPlaybackBarAtom)
  const setShowPlaybackPoiBar = useUpdateAtom(showPlaybackBarPoiAtom)
  const setTourIsStarted = useUpdateAtom(isTourStartedAtom)
  
  const calculateEvents = useCallback(
    event => {
      if (sendTour) {
        if (typeof event.data === 'string') {
          if (event.data === 'tourLoaded') {
            sendTour('TOUR_LOADED')
            setTourIsStarted(true)
          }
        } else {
          let playlistItem
          let playingItems
          switch (event.data?.type) {
            case 'navigationMachine':
              let message = event.data?.message
              message = message.replace('LUEDENSCHEID2_', 'LUEDENSCHEID_')
              dispatch({type: message})
              break
            case 'tourStriebelEvent':
              switch (event.data?.value) {
                case 'ENTER':
                  sendTour(event.data?.value)
                  playlistItem = window?.blazeIT?.getCurrentPlayListItem()
                  if (playlistItem) {
                    sendTour({type: 'ENTER_DELAYED'}, {delay: 500})
                  }
                  break
                case 'ENTER_POI':
                  playingItems = window?.blazeIT
                  ?.getPlayListItems()
                  .filter(
                    el =>
                      el.get('media').get('state') === 'playing'
                  ) || []

                  if (isIndividualTour && playingItems.length === 1
                    && getMediaData(playingItems[0], 'label') === getMediaData(window?.blazeIT?.getCurrentPlayListItem(), 'label')
                  ) {
                    sendTour('ENTER')
                    playlistItem = window?.blazeIT?.getCurrentPlayListItem()
                    if (playlistItem) {
                      sendTour({type: 'ENTER_DELAYED'}, {delay: 500})
                    }
                  } else {
                    sendTour({
                      type: event.data?.value,
                      name: event.data?.name,
                      filename: event.data?.filename,
                    })
                    playlistItem = window?.blazeIT
                      ?.getPlayListItems()
                      .find(el => el.get('media').get('state') === 'playing')
                    if (playlistItem) {
                      sendTour({type: 'ENTER_POI_DELAYED'}, {delay: 500})
                    }
                  }
                  break
                case 'BACK_BEGIN':
                  if (window?.blazeIT) {
                    window.blazeIT.variables.backBegin = true
                  }
                  break
                case 'LEAVE_POI':
                  playingItems = window?.blazeIT
                  ?.getPlayListItems()
                  .filter(
                    el =>
                      el.get('media').get('state') === 'playing'
                  ) || []

                  if (isIndividualTour && playingItems.length === 1
                    && getMediaData(playingItems[0], 'label') === getMediaData(window?.blazeIT?.getCurrentPlayListItem(), 'label')
                  ) {
                    sendTour({type: 'LEAVE'})
                  } else {
                    sendTour({type: event.data?.value})
                  }
                  break
                default:
                  sendTour({type: event.data?.value})
                  break
              }
              break
            case 'showPlaybackbarEvent':
              setShowPlaybackBar(event?.data?.value)
              break
            case 'showPlaybackbarPoiEvent':
              setShowPlaybackPoiBar(event?.data?.value)
              break
            case 'showPopupViewEvent':
              if (event?.data?.value === false) {
                sendTour('CLOSE_POI')
              } else {
                sendTour('OPEN_POI')
              }
              break
            case 'showPopupWebEvent':
              const url = window?.blazeIT
              .getComponent('WebFramePopup')
              .get('url')
              if (url?.includes('.pdf')) {
                if (event?.data?.value === false) {
                  sendTour('CLOSE_POI')
                } else {
                  sendTour('OPEN_POI')
                }
              }
              break
            case 'showVideoInfoEvent':
              if (event?.data?.value) {
                const {layout, title, subtitle, delay} = event?.data?.value
                window?.blazeIT?.showComponent('WebFramePopup')
                window?.blazeIT
                  .getComponent('WebFramePopup')
                  .set(
                    'url',
                    `${window.origin}/#/videoinfo/${layout}/${encodeURI(
                      title,
                    )}/${encodeURI(subtitle)}${delay ? encodeURI(delay) : ''}`,
                  )
              }
              break
            case 'showChatbotEvent':
              if (event?.data?.subType === 'close') {
                dispatch(setOpen(false))
              } else {
                dispatch(setOpen(true))
                if (event?.data?.subType === 'showVideo') {
                  setStepsChatbot([
                    {
                      id: 'ShowVideo',
                      component: <ShowVideo id={event?.data?.playListItem} />,
                      end: true,
                    },
                  ])
                } else if (event?.data?.subType === 'steps') {
                  setStepsChatbot([
                    {
                      id: 'ChatbotEvent',
                      steps: event?.data?.steps,
                      end: true,
                    },
                  ])
                } else if (event?.data?.subType === 'component') {
                  switch (event?.data?.component) {
                  }
                }
              }
              break
            case 'showPdf':
              if (event?.data?.value) {
                window?.blazeIT?.showComponent('WebFramePopup')
                window?.blazeIT
                  .getComponent('WebFramePopup')
                  .set(
                    'url',
                    `${window.origin}/#/pdfViewer/${encodeURI(
                      event.data.value,
                    )}`,
                  )

                let newWidth = 80
                let newHeight = 75
                let newX = (100 - newWidth) / 2
                let newY = (100 - newHeight) / 2

                const webFramePopup = window?.blazeIT?.getComponent(
                  'WebFramePopup',
                )
                if (webFramePopup) {
                  webFramePopup.set('width', `${newWidth}%`)
                  webFramePopup.set('height', `${newHeight}%`)
                  webFramePopup.set('left', `${newX}%`)
                  webFramePopup.set('bottom', `${newY}%`)
                }

                const playbackBarPoi = window?.blazeIT?.getComponent(
                  'PlaybackBarPoi',
                )
                if (playbackBarPoi) {
                  playbackBarPoi.set('width', `${newWidth}%`)
                  playbackBarPoi.set('left', `${newX}%`)
                  playbackBarPoi.set('bottom', `${newY}%`)
                }

                const closePopup = window?.blazeIT?.getComponent('ClosePopup')
                if (closePopup) {
                  closePopup.set('right', `${newX + 1}%`)
                  closePopup.set('top', `${newY + 1}%`)
                }

                const enterFullscreenPopup = window?.blazeIT?.getComponent(
                  'EnterFullscreenPopup',
                )
                if (enterFullscreenPopup) {
                  enterFullscreenPopup.set('right', `${newX + 3}%`)
                  enterFullscreenPopup.set('top', `${newY + 1}%`)
                }

                const exitFullscreenPopup = window?.blazeIT?.getComponent(
                  'ExitFullscreenPopup',
                )
                if (exitFullscreenPopup) {
                  exitFullscreenPopup.set('right', `${newX + 3}%`)
                  exitFullscreenPopup.set('top', `${newY + 1}%`)
                }
              }
              break
            case 'keyPressEvent':
              switch (event?.data?.value) {
                case 'ArrowLeft':
                  sendTour('GO_TO_START')
                  break
                case 'ArrowRight':
                  sendTour('GO_TO_END')
                  break
                default:
                  break
              }
              break
            case 'setSoundTitleEvent':
              if (event?.data?.value) {
                sendTour({type: 'SET_SOUNDTITLE_POI', soundTitlePoi: event?.data?.value})
              }
              
              break
            case 'finishVideoInfo':
              const webFrame = window?.blazeIT?.getComponent('WebFrame')
              if (webFrame) {
                webFrame.set(
                  'url',
                  '',
                )
                webFrame.set('width', '100%')
                webFrame.set('height', '100%')
                webFrame.set('left', '0%')
                webFrame.set('top', '0%')
                window?.blazeIT?.hideComponent('WebFrame')
              }
              break
            default:
              break
          }
        }
      }
    },
    [
      dispatch,
      sendTour,
      setShowPlaybackBar,
      setShowPlaybackPoiBar,
      setStepsChatbot,
      setTourIsStarted,
    ],
  )

  useEffect(() => {
    window.addEventListener('message', calculateEvents)
    return () => {
      window.removeEventListener('message', calculateEvents)
    }
  }, [calculateEvents])
}
