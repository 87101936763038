import SvgIcon from '@mui/material/SvgIcon'

export default function CloseIcon(props) {
  return (
    <SvgIcon
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{
        width: props.width,
        height: props.height,
      }}
    >
      <g
        id="Symbole"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="lauramenu"
          transform="translate(-71.000000, -2.000000)"
          stroke={props.color}
          strokeWidth="1.75"
        >
          <g id="Group-3" transform="translate(72.000000, 3.000000)">
            <path d="M0,0 L14,14" id="Line-3"></path>
            <path d="M14,0 L0,14" id="Line-3"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

CloseIcon.defaultProps = {
  color: '#000000',
  width: '16px',
  height: '16px',
}
