export const setMediaByName = (tour, name) => {  
  if(!tour) {
    return;
  }        
  tour.setMediaByName(name);
}

export const sidebarWitdh = 88

export const isUUID = ( uuid ) => {
  let s = "" + uuid;

  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (s === null) {
    return false;
  }
  return true;
}