import React from 'react'
import {FormattedMessage} from 'react-intl'
import {withStyles} from '@mui/styles'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

const useStyles = theme => ({
  root: {
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      expanded: false,
    }
    this.handleExpandClick = this.handleExpandClick.bind(this)
  }

  static getDerivedStateFromError(error) {
    if (error?.message.includes('blazeIT')) {
      location.reload() // eslint-disable-line no-restricted-globals
    }
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error, errorInfo) {}

  handleExpandClick() {
    this.setState({expanded: !this.state.expanded})
  }

  render() {
    const {classes} = this.props
    if (this.state.error?.message?.includes('blazeIT')) {
      return null
    }

    if (this.state.hasError) {
      return (
        <Alert
          className={classes.root}
          severity="error"
          action={
            <IconButton
              className={`${classes.expand} ${
                this.state.expanded ? classes.expandOpen : ''
              }`}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <AlertTitle>
            <FormattedMessage
              id="errorboundary.title"
              defaultMessage={`Es ist ein Fehler aufgetreten.`}
            />
          </AlertTitle>
          {this.state.error.message}
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            {this.state.error.stack}
          </Collapse>
        </Alert>
      )
    }

    return this.props.children
  }
}

export default withStyles(theme => {
  return useStyles(theme)
})(ErrorBoundary)
