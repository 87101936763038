import React, {useEffect, useMemo} from 'react'
import {useAtom} from 'jotai'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {useAudio, usePrevious, useDebounce} from 'react-use'
import {useActor} from '@xstate/react'
import {pick, equals} from 'ramda'
import {
  soundEnabledAtom,
  windowIsActiveAtom,
  audioTimeAtom,
  audioTimePoiAtom,
  audioControlsAtom,
  audioControlsPoiAtom,
  isPausedAtom,
  isPausedPoiAtom,
  tourMachineAtom,
} from 'components/jotai'

export default function Sound({sound, poi = false}) {
  const soundEnabled = useAtomValue(soundEnabledAtom)
  const isPaused = useAtomValue(poi ? isPausedPoiAtom : isPausedAtom)
  const windowIsActive = useAtomValue(windowIsActiveAtom)
  const prevWindowIsActive = usePrevious(windowIsActive)
  const tourService = useAtomValue(tourMachineAtom)
  const [tour, sendTour] = useActor(tourService)

  const [audioTime, setAudioTime] = useAtom(poi ? audioTimePoiAtom : audioTimeAtom)

  const setAudioControls = useUpdateAtom(
    poi ? audioControlsPoiAtom : audioControlsAtom,
  )

  const [audio, state, controls] = useAudio({
    src: sound,
    autoPlay: true,
    loop: false,
    onProgress: () => {
      if (poi && tour?.context?.seekAudioPoi) {
        sendTour('SEEK_AUDIO_POI')
      } else if (tour?.context?.seekAudio) {
        sendTour('SEEK_AUDIO')
      }
      setAudioControls(controls)
    },
  })
  
  

  useEffect(() => {
    if (soundEnabled) {
      controls.unmute()
    } else {
      controls.mute()
    }
  }, [soundEnabled, controls])

  useDebounce(
    () => {
      setAudioTime({
        ...state,
        finished: state.duration > 0 && state.time === state.duration,
      })
    },
    250,
    [state]
  );

  useEffect(() => {
    if (prevWindowIsActive !== undefined) {
      if (windowIsActive !== prevWindowIsActive) {
        if (windowIsActive) {
          if (!isPaused) {
            controls.play()
          }
        } else {
          controls.pause()
        }
      }
    }
  }, [windowIsActive, prevWindowIsActive, controls, isPaused])

  if (!sound || !audio) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '120px',
        left: '50%',
        background: '#000',
        color: '#FFF',
        transform: 'translate(-50%, 0)',
        zIndex: 1200,
      }}
    >
      {audio}
    </div>
  )
}
