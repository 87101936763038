import React, {useEffect, useState, useMemo} from 'react'
import {useAtomValue} from 'jotai/utils'
import {useAtom} from 'jotai'

import {styled} from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import {
  audioControlsPoiAtom,
  showPlaybackBarPoiAtom,
  sidebarAtom,
  tourMachineAtom,
  popupViewerSizeAtom,
  isEndVideoPoiAtom,
  isPausedPoiAtom,
  currentTimePoiAtom,
} from 'components/jotai'
import {useActor} from '@xstate/react'
import ReactPlayer from 'react-player'

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'

import PlayPureIcon from 'components/Icons/PlayPureIcon'
import PausePureIcon from 'components/Icons/PausePureIcon'

import {isPanorama} from 'components/Navigation/duck'

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 28,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))

export default function PlaybackBarPoi() {
  const size = 28
  const showPlaybackBarPoi = useAtomValue(showPlaybackBarPoiAtom)

  const soundControls = useAtomValue(audioControlsPoiAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const [tour, sendTour] = useActor(tourService)
  const [isVideo, setIsVideo] = useState(false)
  const duration = tour?.context?.durationPoi
    ? tour.context.durationPoi * 1000
    : 0
  const [currentTime, setCurrentTime] = useAtom(currentTimePoiAtom)
  const drawerOpen = useAtomValue(sidebarAtom)
  const popupViewerSize = useAtomValue(popupViewerSizeAtom)

  const [isPaused, setIsPaused] = useAtom(isPausedPoiAtom)
  const [isEndVideo, setIsEndVideo] = useAtom(isEndVideoPoiAtom)
  const [loop, setLoop] = useState()

  useEffect(() => {
    if (tour.context.szenePoi) {
      clearTimeout(loop)
      const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
        tour.context.szenePoi,
      )
      if (playlistItem) {
        setIsVideo(!isPanorama(playlistItem))
        setCurrentTime(0)
        if (!isPanorama(playlistItem)) {
          const media = playlistItem.get('media')
          if (media) {
            setLoop(
              setInterval(() => {
                setCurrentTime(media.get('currentTime') * 1000)
              }, 200),
            )
          }
        }
      } else {
        setIsVideo(false)
        setCurrentTime(0)
      }
    }
    return () => clearTimeout(loop)
  }, [tour.context.szenePoi, setCurrentTime]) // eslint-disable-line react-hooks/exhaustive-deps

  const pos = useMemo(() => {
    const drawerSize = drawerOpen ? 88 : 0
    const width = window.innerWidth - drawerSize
    const bottom = popupViewerSize?.y || 0
    const left = popupViewerSize?.x || 0

    return {
      x: drawerSize + width * (left / 100),
      y: bottom,
      width: width - ((2 * left) / 100) * width,
    }
  }, [popupViewerSize, drawerOpen])

  const gridStyles = {
    position: 'absolute',
    bottom: `${pos.y}%`,
    left: pos.x,
    width: pos.width,
    zIndex: 1100,
    webkitBoxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
  }

  const handleClick = e => {
    if (duration > 0) {
      setIsEndVideo(false)
      const newTime = Math.min(
        (e.pageX /
          document.getElementById('playbackBar')?.getBoundingClientRect()
            ?.width) *
          duration,
        duration - 200,
      )

      const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
        tour.context.szenePoi,
      )

      if (playlistItem) {
        window?.blazeIT?.setComponentAttributes(playlistItem.get('media'), {
          currentTime: newTime / 1000,
        })
      }
      window?.blazeIT?.getComponent('Popup Viewer')?.play()
      setIsPaused(false)
      soundControls?.seek(newTime / 1000)
    }
  }

  const handlePause = () => {
    if (isPaused) {
      setIsPaused(false)
      window?.blazeIT?.getComponent('Popup Viewer').play()
      soundControls?.play()
    } else {
      setIsPaused(true)
      window?.blazeIT?.getComponent('Popup Viewer').pause()
      soundControls?.pause()
    }
  }

  /*const handleMouseEnter = () => {
    setShowPlaybackBarPoi(true)
  }

  const handleMouseLeave = () => {
    setShowPlaybackBarPoi(false)
  }*/

  const onDuration = e => {
    sendTour({type: 'ENTER_POI_DELAYED', duration: e})
  }

  return (
    <>
      {isVideo && showPlaybackBarPoi && (
        <div style={gridStyles}>
          <Grid
            container
            onMouseEnter={null /*handleMouseEnter*/}
            onMouseLeave={null /*handleMouseLeave*/}
            alignItems="flex-end"
            style={{background: '#FFF'}}
          >
            <Grid item style={{textAlign: 'center'}}>
              {!isEndVideo && (
                <IconButton
                  onClick={!isEndVideo ? handlePause : null}
                  size="small"
                  sx={{padding: 0}}
                >
                  {isPaused && <PlayPureIcon width={size} height={size} />}
                  {!isPaused && <PausePureIcon width={size} height={size} />}
                </IconButton>
              )}
            </Grid>
            <Grid item sx={{position: 'relative', flex: 1}}>
              <>
                <div onClick={handleClick} id="playbackBar">
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (Math.min(currentTime, duration) /
                        Math.max(1, duration)) *
                      100
                    }
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 5,
                    color: '#000',
                    lineHeight: '28px',
                  }}
                >
                  {`${Math.floor((currentTime / 1000 / 60) << 0)}:${(
                    '0' + Math.floor((currentTime / 1000) % 60)
                  ).slice(-2)}`}{' '}
                  /{' '}
                  {`${Math.floor((duration / 1000 / 60) << 0)}:${(
                    '0' + Math.floor((duration / 1000) % 60)
                  ).slice(-2)}`}
                </div>
              </>
            </Grid>
          </Grid>
        </div>
      )}
      {tour.context.videoFilePoi && (
        <div style={{display: 'none'}}>
          <ReactPlayer
            url={tour.context.videoFilePoi}
            onDuration={onDuration}
          />
        </div>
      )}
    </>
  )
}
