import Insel1_1 from './14473_Atrium_E1_Insel1_1_roteTafel_1000x2430mm_RZ.pdf'
import Insel2_1 from './14473_Atrium_E1_Insel2_1_roteTafel_1000x2430mm_RZ.pdf'
import Insel3_1 from './14473_Atrium_E1_Insel3_1_roteTafel_1000x2430mm_RZ.pdf'
import Insel4_1 from './14473_Atrium_E1_Insel4_1_roteTafel_1000x2430mm_RZ.pdf'
import Insel5_1 from './14473_Atrium_E1_Insel5_1_roteTafel_1000x2480mm_RZ.pdf'

import Enery_Experience_1 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 1.pdf'
import Enery_Experience_2 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 2.pdf'
import Enery_Experience_3 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 3.pdf'
import Enery_Experience_4 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 4.pdf'
import Enery_Experience_5 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 5.pdf'
import Enery_Experience_6 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 6.pdf'
import Enery_Experience_7 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 7.pdf'
import Enery_Experience_8 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 8.pdf'
import Enery_Experience_9 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 9.pdf'
import Enery_Experience_10 from './13473_RZ_Energy_Experience_Outdoorstelen_A11 10.pdf'

const pdfs = {
  '14473_Atrium_E1_Insel1_1_roteTafel_1000x2430mm_RZ.pdf': Insel1_1,
  '14473_Atrium_E1_Insel2_1_roteTafel_1000x2430mm_RZ.pdf': Insel2_1,
  '14473_Atrium_E1_Insel3_1_roteTafel_1000x2430mm_RZ.pdf': Insel3_1,
  '14473_Atrium_E1_Insel4_1_roteTafel_1000x2430mm_RZ.pdf': Insel4_1,
  '14473_Atrium_E1_Insel5_1_roteTafel_1000x2480mm_RZ.pdf': Insel5_1,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 1.pdf': Enery_Experience_1,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 2.pdf': Enery_Experience_2,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 3.pdf': Enery_Experience_3,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 4.pdf': Enery_Experience_4,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 5.pdf': Enery_Experience_5,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 6.pdf': Enery_Experience_6,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 7.pdf': Enery_Experience_7,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 8.pdf': Enery_Experience_8,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 9.pdf': Enery_Experience_9,
  '13473_RZ_Energy_Experience_Outdoorstelen_A11 10.pdf': Enery_Experience_10,
}

export default pdfs
