import {useEffect, useState, useMemo, useRef} from 'react'
import Cookies from "js-cookie";
import axios from 'axios'
import { useIntl } from 'react-intl';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {v4} from 'uuid'
import { useAtomValue } from 'jotai/utils'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {useScroll} from 'react-use';
import { equals } from 'ramda'
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  useDroppable
} from '@dnd-kit/core';
import {
  arrayMove,
} from "@dnd-kit/sortable";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { sidebarAtom } from 'components/jotai';
import {sidebarWitdh} from 'components/utils'
import {CloseIcon} from 'components/Icons'
import VisibilityIcon from '@mui/icons-material/Visibility';
import IosShareIcon from '@mui/icons-material/IosShare';
import SaveIcon from '@mui/icons-material/Save';
import LogoutIcon from '@mui/icons-material/Logout';
import Droppable from './dropabble'
import DroppableLast from './addLast'
import Draggable from './draggable'
import useMessages from './useMessages'
import ShareDialog from 'components/ShareDialog'
import { getIsLoggedIn, invalidateUser } from 'components/user/duck';
import { getLanguage } from 'components/Language/duck'

const IndividuelleTour = () => {
  const { id } = useParams()

  const navigate = useNavigate()
  const messages = useMessages()
  const dispatch = useDispatch()
  const intl = useIntl()
  const scrollRef = useRef(null);
  const dragContainerScroll = useScroll(scrollRef);
  const [activeId, setActiveId] = useState();
  const [shareUrl, setShareUrl] = useState(null)
  const [currentTour, setCurrentTour] = useState(null)
  const [individuellePlaylistItems, setIndividuellePlaylistItems] = useState([])
  const [name, setName] = useState(intl.formatMessage(messages.defaultName))
  const [editName, setEditName] = useState(false)
  const drawerOpen = useAtomValue(sidebarAtom)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isLoggedIn = useSelector(getIsLoggedIn)
  const language = useSelector(getLanguage)
  const queryClient = useQueryClient()
  
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/backend')
    }
  
  }, [isLoggedIn])
  
  const playlistItemsProps = useQuery({
    queryKey: ['playlistItems'],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/playlistItems`,
      }).then((res) => {
        return res?.data || []
      }),
      select: (data) => data.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {})
  })

  const mappedPlaylistItems = playlistItemsProps.data || {} 

  const toursProps = useQuery({
    queryKey: ['tours'],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/tours`,
      }).then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          if (!res.data.find(el => el.id === currentTour)) {
            setCurrentTour(res.data[0]?.id)
          }
        }
        return res?.data || []
      }),
      select: (tours) => [...tours.filter(tour => !tour.id.includes('bje')), {id: 'bje', name: 'Lüdenscheid', subPlaces: tours.filter(tour => tour.id.includes('bje'))}]
  })

  const places = toursProps.data || []
  
  const individuelleToursProps = useQuery({
    queryKey: ['individuelleTour', id],
    queryFn: () =>
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/individuelleTour/${id}`,
      }).then((res) => {
        const tour = JSON.parse(res.data.tour)?.map(id => `${id}##${v4()}`) || []
        setName(res.data?.name || intl.formatMessage(messages.defaultName))
        setIndividuellePlaylistItems(tour)
        return res.data
      }),
    enabled: !!(isLoggedIn && id && id !== 'nav'),
  })

  const saveMutation = useMutation({
    mutationFn: (data) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/individuelleTour`,
        data: data
      }).then(res => res.data)
    },
    onSuccess: (data) => {
      navigate(`/backend/${data}`)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['individuelleTours'] })
    },
  })

  const activePlaylistItem = useMemo(() => {
    
    if (activeId) {
      const splits = activeId.split('##')
      return mappedPlaylistItems[splits[0].replace('drag_', '').replace('sort_', '')]
    }
    return null
  }, [mappedPlaylistItems, activeId])

  const visibleKeys = useMemo(() => {
    return Object.keys(mappedPlaylistItems).filter(id => {
      const playlistItem = mappedPlaylistItems[id]
      let possible = playlistItem.tourId === currentTour && id !== ''
      
      if (possible) {
        possible = !individuellePlaylistItems.find(id2 => {
          const splits = id2.split('##')
          return splits[0] === id
        })
      }
            
      return possible
    }).sort((a, b) => {
      const {index: indexA} = mappedPlaylistItems[a]
      const {index: indexB} = mappedPlaylistItems[b]
      return indexA - indexB
    })
  }, [mappedPlaylistItems, individuellePlaylistItems, currentTour])
  
  const usedKeys = useMemo(() => {
    return Object.keys(mappedPlaylistItems).filter(id => {
      const playlistItem = mappedPlaylistItems[id]
      let possible = playlistItem.tourId === currentTour && id !== ''
      
      if (possible) {
        possible = individuellePlaylistItems.find(id2 => {
          const splits = id2.split('##')
          return splits[0] === id
        })
      }
            
      return possible
    })
  }, [mappedPlaylistItems, individuellePlaylistItems, currentTour])

  const onDragStart = (event) => {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  const onDragOver = (event) => {
    //handleUpdateIndividuellePlaylistItems(event)
  }

  const onDragEnd = (event) => {
    setActiveId(null);
    const {active, over} = event;
    
    if (active && over) {
      const container = over?.id?.replace('drag_', '').replace('sort_', '') ?? "";
      const id = active.data.current?.id ?? "";
      
      const activeIndex = active?.data?.current?.sortable ? active.data.current.sortable.index : 0;
      const overIndex = over?.data?.current?.sortable ? over.data.current?.sortable.index : 0 //over.data.current?.sortable.index || 0;

      let newItems = []
      if (!active.id.includes('drag_')) {
        newItems = arrayMove(
          individuellePlaylistItems,
          activeIndex,
          overIndex
        );
      } else if (container && id !== "") {
        const elementId = `${id}##${v4()}`
        if (overIndex === 0 && (container === 'individuelleTour' || container === 'addLast')) {
          newItems = [...individuellePlaylistItems, elementId]
        } else if (overIndex === 0) {
          newItems = [elementId , ...individuellePlaylistItems]
        } else {
          newItems = [...individuellePlaylistItems.slice(0, overIndex), elementId, ...individuellePlaylistItems.slice(overIndex)]
        }
      }
      setIndividuellePlaylistItems(newItems);
    }
    
    
  }
  
  const onDragCancel = (event) => {
    setActiveId(null);
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
    /*useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })*/
  );

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    dispatch(invalidateUser());
    const host = window.location.host
    window.location.href = `${process.env.REACT_APP_MYABB_URL}/${language}/user/sso-logout?sso_logout_uri=&sso_host=${host}&client_id=${process.env.REACT_APP_SSOCLIENTID}`
  }

  const handleDelete = (id) => {
    setIndividuellePlaylistItems(arr => arr.filter(el => {
      const splits = el.split('##')
      return splits[0] !== id
    }))
  }

  const handleAddLast = (id) => {
    setIndividuellePlaylistItems(arr => [...arr, id])
  }

  const handleShare = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const url = `${window.origin}/#/tour/individuelleTour/${id}`
    let shareData = {
      title: "Factory-Tour Individuelle Tour",
      text: name,
      url: url,
    };

    if (!navigator.canShare) {
      setShareUrl(url)
    } else if (navigator.canShare(shareData)) {
      navigator.share(shareData)
    } else {
      setShareUrl(url)
    }
  }

  const handleSave = () => {
    saveMutation.mutate({
      id: id ? id : null,
      name,
      enabled: individuelleToursProps.data ? individuelleToursProps.data.enabled : true,
      tour: JSON.stringify(
        individuellePlaylistItems.map(id => {
          const splits = id.split('##')
          return splits[0]
        })
      )
    })
  }

  const handleBack = () => {
    navigate(`/backend`)
  }

  return (
    <div style={{overflow: 'auto', height: '100vh'}}>
      <DndContext 
        autoScroll={true}
        sensors={sensors}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragCancel={onDragCancel}
      >
        <Grid container style={{minHeight: '100vh'}}>
          <Grid item xs={12} md={8} style={{background: '#FEFEFE'}}>
            <div style={{padding: 20, height: 'calc(100% - 40px)'}}>
              <Box flexDirection="column" p={4} display="flex" style={{height: 'calc(100% - 64px)'}} >
                <Box p={1} flexGrow={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                      {isLoggedIn && matches &&
                        <Button
                          variant="text"
                          onClick={handleLogout}
                          startIcon={<LogoutIcon />}
                        >
                          <Typography>
                            {intl.formatMessage(messages.logout)}
                          </Typography>
                        </Button>
                      }
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="text"
                        onClick={handleBack}
                        startIcon={<ArrowBackIcon />}
                      >
                        <Typography>
                          {intl.formatMessage(messages.back)}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={3} align="center">
                      {id && 
                        <Button
                          variant="text"
                          onClick={handleShare}
                          startIcon={<IosShareIcon />}
                        >
                          <Typography>
                            {intl.formatMessage(messages.share)}
                          </Typography>
                        </Button>
                      }
                    </Grid>
                    <Grid item xs={3} align="center">
                      {id && 
                        <Button
                          color="primary"
                          href={`${window.location.origin}/#/tour/individuelleTour/${id}`}
                          target="_blank"
                          startIcon={<VisibilityIcon />}
                        >
                          <Typography>
                            {intl.formatMessage(messages.view)}
                          </Typography>
                        </Button>
                      }
                    </Grid>
                    <Grid item xs={3} align="right">
                      <Button
                        disabled={equals(individuelleToursProps.data || [], individuellePlaylistItems)}
                        variant="text"
                        onClick={handleSave}
                        startIcon={<SaveIcon />}
                      >
                        <Typography>
                          {intl.formatMessage(id ? messages.save : messages.create)}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {!editName &&
                        <>
                          <Typography variant="h2"  sx={{display: 'inline-block'}}>
                            {name}  
                          </Typography>
                          <Typography color="primary" variant="span">
                            <Button variant="text" onClick={() => setEditName(true)} startIcon={<EditIcon />}>
                              Edit
                            </Button>
                          </Typography>
                        </>
                      }
                      {editName &&
                        <>
                          <TextField
                            id="name"
                            value={name}
                            onChange={(event) => {
                              setName(event.target.value);
                            }}
                            onKeyDown={(event) => {
                              if(event.keyCode == 13){
                                setEditName(false)
                              }
                            }}
                            InputProps={{
                              sx: {fontSize: '3.75rem'},
                              endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => setEditName(false)}>
                                  <SaveIcon />
                                </IconButton>
                              </InputAdornment>,
                            }}
                            variant="standard"
                          />
                          {/*<TextField
                            variant="standard"
                            value={name}
                            onChange={(event) => {
                              setName(event.target.value);
                            }}
                            
                            inputProps={{
                              sx: {fontSize: '3.75rem'},
                              endAdornment: <InputAdornment position='end'><IconButton onClick={() => setEditName(false)}>
                                <SaveIcon />
                              </IconButton></InputAdornment>,
                            }}
                          />*/}
                          
                        </>
                      }
                    </Grid>
                  </Grid>
                </Box>
                <Box p={1} flexGrow={0}>
                  <DroppableLast />
                </Box>
                <Box p={1} flexGrow={1} style={{position: 'relative'}}>
                    <Droppable
                      id="individuelleTour"
                      items={individuellePlaylistItems}
                      playlistItems={mappedPlaylistItems}
                      isPublicDragging={!!activeId}
                      handleDelete={handleDelete}
                    />
                  <DragOverlay>
                    {activeId && 
                      <Card>
                        <CardContent>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                              <img src={activePlaylistItem?.thumbnailUrl} style={{maxWidth: '100%'}} />
                            </Grid>
                            <Grid item xs={8} align="left">
                              {activePlaylistItem?.name}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    }
                  </DragOverlay>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={4} style={matches ? {background: '#F0F0F0'} : {background: '#F0F0F0'}}>
            <Box flexDirection="column" p={4} display="flex" style={{height: 'calc(100% - 64px)'}} >
              <Box p={1} flexGrow={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{textAlign: 'right'}}>
                    {isLoggedIn && !matches &&
                      <Button
                        variant="text"
                        onClick={handleLogout}
                        startIcon={<LogoutIcon />}
                      >
                        <Typography>
                          {intl.formatMessage(messages.logout)}
                        </Typography>
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <h2>{intl.formatMessage(messages.overview)}</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {places.map(place => {
                        const handleSelect = () => {
                          if (place.id === 'abb' || place.id === 'striebel') {
                            setCurrentTour(place.id)
                          } else if (place.id === 'bje') {
                            setCurrentTour(place.subPlaces[0]?.id)
                          }
                        }
                        const active = place.id === currentTour || place.id === 'bje' && currentTour?.includes('bje')
                        return (
                          <Grid item onClick={handleSelect}>
                            <Typography
                              sx={{
                                color: active ? 'primary.main' : '#000',
                                cursor: 'pointer'
                              }}
                            >
                              {place.name}
                            </Typography>
                          </Grid>
                        )
                      })}    
                    </Grid>
                  </Grid>
                  {
                    currentTour?.includes('bje') &&
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          {places.find(el => el.id === 'bje').subPlaces.map(place => {
                            const handleSelect = () => {
                              setCurrentTour(place.id)
                            }
                            const active = place.id === currentTour
                            return (
                              <Grid item onClick={handleSelect}>
                                <Typography
                                  sx={{
                                    color: active ? 'primary.main' : '#000',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {place.name}
                                </Typography>
                              </Grid>
                            )
                          })}    
                        </Grid>
                      </Grid>
                  }
                </Grid>
              </Box>
              <Box p={1} flexGrow={1} style={{position: 'relative'}}>
                  <Grid container spacing={2}>
                    {visibleKeys.map(id => {
                      const playlistItem = mappedPlaylistItems[id]
                      return (
                        <Grid item xs={12} align="center">    
                          <Draggable
                            enabled={true}
                            element={playlistItem}
                            playlistItems={mappedPlaylistItems}
                            scroll={dragContainerScroll}
                            handleAddLast={handleAddLast}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
              </Box>
              <Box p={1} flexGrow={0}>
                <Typography>
                  {intl.formatMessage(messages.alreadyUsed)}
                </Typography>
              </Box>
              <Box p={1} flexGrow={1} style={{position: 'relative'}}>
                  <Grid container spacing={2}>
                    {usedKeys.map(id => {
                      const playlistItem = mappedPlaylistItems[id]
                      return (
                        <Grid item xs={12} align="center">    
                          <Draggable
                            enabled={false}
                            element={playlistItem}
                            playlistItems={mappedPlaylistItems}
                            scroll={dragContainerScroll}
                            handleAddLast={handleAddLast}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DndContext>
      <ShareDialog
        url={shareUrl}
        open={!!shareUrl}
        handleClose={() => setShareUrl(null)}
      />
    </div>
  )
}

export default IndividuelleTour