import * as React from 'react'
import {useAtom} from 'jotai'
import {useAtomValue} from 'jotai/utils'
import {useActor} from '@xstate/react'
import {useSelector} from 'react-redux'
import Tippy from '@tippyjs/react'
import {useClickAway} from 'react-use'
import IconButton from '@mui/material/IconButton'
import ErrorBoundary from 'components/ErrorBoundary'
import TooltipContent from 'components/PlanTooltipContent'
import {showSitePlanAtom, tourMachineAtom, sidebarAtom} from 'components/jotai'
import {CloseIcon} from 'components/Icons'
import {getFilteredVisited} from 'components/visited/duck'

export default function BjeLogisticPlan({onClose}) {
  const [showSitePlan, setShowSitePlan] = useAtom(showSitePlanAtom)
  const sidebarOpen = useAtomValue(sidebarAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const [tour] = useActor(tourService)
  const visited = useSelector(getFilteredVisited)

  const primaryColor = '#ff000f'
  const activeStyle = {fill: primaryColor, cursor: 'pointer'}
  const visitedStyle = {border: `1 px solid ${primaryColor}`, cursor: 'pointer'}
  const defaultStyle = {fill: '#9b9b9a', cursor: 'pointer'}

  const ref = React.useRef(null)
  useClickAway(ref, () => {
    setShowSitePlan(false)
  })

  const handleOpen = e => {
    setShowSitePlan(false)
    if (e?.target?.id) {
      const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
        e.target.id,
      )

      if (playlistItem) {
        window?.blazeIT?.navigateToPlayListItem(playlistItem)
      }
    }
  }

  const handleClose = () => {
    setShowSitePlan(false)
  }

  if (!showSitePlan) {
    return null
  }

  return (
    <ErrorBoundary>
      <div
        ref={ref}
        style={{
          zIndex: 1000,
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxHeight: '90vh',
          maxWidth: 'calc(100% - 200px)',
          marginLeft: sidebarOpen ? 44 : 0,
          webkitBoxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
          boxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
        }}
      >
        <div style={{height: '100%', width: '100%', position: 'relative'}}>
          <IconButton
            sx={{position: 'absolute', top: 0, right: 0}}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 843.31 602.36"
            style={{
              enableBackground: 'new 0 0 843.1 603',
              webkitBoxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
              boxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
              width: '100%',
              maxHeight: '90vh',
            }}
            xmlSpace="preserve"
          >
            <defs>
              <clipPath id="b">
                <path
                  style={{
                    fill: 'none',
                  }}
                  d="M40.75 393.6h63.89v24.42H40.75z"
                />
              </clipPath>
              <clipPath id="c">
                <path
                  style={{
                    fill: 'none',
                  }}
                  d="M40.75 393.6h63.89v24.42H40.75z"
                />
              </clipPath>
              <filter id="a" filterUnits="userSpaceOnUse">
                <feOffset dy={20} />
                <feGaussianBlur result="blur" stdDeviation={40} />
                <feFlood floodColor="#010101" floodOpacity={0.15} />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
              </filter>
              <filter id="shadow" colorInterpolationFilters="sRGB">
                <feDropShadow
                  dx="2"
                  dy="2"
                  stdDeviation="4"
                  floodOpacity="0.5"
                />
              </filter>
            </defs>
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M-.3-.11h843.91v602.58H-.3z"
            />
            <path
              style={{
                fill: '#fff',
                filter: 'url(#shadow)',
                stroke: '#fff',
                strokeMiterlimit: 10,
                strokeWidth: 4,
              }}
              d="M754.68 27.79v339.32h-128.2v78.83H208.69v-78.83H72.13V27.79h682.55z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M571.67 44.33v73.69l-328.69-.08V44.1l328.69.23zM349.56 389.38H242.98V159.06h89.57l-.24 201.66h17.25v28.66z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M352.17 244.62h-19.62l.02-57.66.62-1.53h19.05l-.07 59.19z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M516.31 389.38h-89.25V159.06h89.53l-.28 230.32zM585.19 388.73h-43.24V159.7H601.38v70.45h-16.1l-.09 158.58z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M242.98 440.88v-24.11l350.96.03v24.16l-350.96-.08z"
            />
            <text
              transform="translate(269.05 258.78)"
              style={{
                fill: '#010101',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: '15.25px',
              }}
            >
              <tspan
                x={0}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'0'}
              </tspan>
              <tspan x={9.42} y={0}>
                {'3'}
              </tspan>
            </text>
            <text
              transform="translate(389.07 87.24)"
              style={{
                fill: '#010101',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: '15.25px',
              }}
            >
              <tspan
                x={0}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'0'}
              </tspan>
              <tspan x={9.42} y={0}>
                {'4'}
              </tspan>
            </text>
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M516.59 255.54v-44.62l24.7.04.66 1.4v43.32l-25.36-.14zM356.2 345.88h-23.65l.02-69.81.75-1.85h22.95l-.07 71.66zM402.96 314.29l.04-23.41h-22.48l-.73 1.15-.02 43.24h.02l-.02 35.83h33.53l.1-56.81h-10.44z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M413.27 293.38h-43.35l.05-43.36 1.36-1.16h42.07l-.13 44.52z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M352.17 255.91v-19.04l72.95.02 1.94.59v18.49l-74.89-.06z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M481.6 118.02h78.91l-.08 17.89-2.47.47h-76.61l.25-18.36z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M571.67 117.79V44.1l97.97.08v73.84l-97.97-.23zM669.64 131.72V58.03l83.09.08v73.84l-83.09-.23z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M734.8 255.68h-83.99V159.7h84.26l-.27 95.98z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M734.71 356.87H620.65V250.79l114.42-1.93-.36 108.01z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M369.92 237.92v-15.63l42.36.02 1.12.49v15.17l-43.48-.05zM396.28 222.29h-26.36l.03-42.35.83-1.13h25.58l-.08 43.48z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M369.92 186.05V159.7l42.36.03 1.12.82v25.59l-43.48-.09zM411.32 214.06h-13.18l.01-19.18.42-.51h12.79l-.04 19.69zM620.59 438.71h-19.21l.02-19.18.61-.51h18.64l-.06 19.69z"
            />
            <Tippy
              content={<TooltipContent id="AT002" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="AT002"
                onClick={handleOpen}
                cx={255.81}
                cy={171.87}
                r={4.96}
                style={
                  tour?.context?.szene === 'AT002'
                    ? activeStyle
                    : visited.find(el => el === 'AT002')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="AT001" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="AT001"
                onClick={handleOpen}
                s
                cx={234.12}
                cy={171.87}
                r={4.96}
                style={
                  tour?.context?.szene === 'AT001'
                    ? activeStyle
                    : visited.find(el => el === 'AT001')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="AT003" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="AT003"
                onClick={handleOpen}
                cx={272.08}
                cy={171.87}
                r={4.96}
                style={
                  tour?.context?.szene === 'AT003'
                    ? activeStyle
                    : visited.find(el => el === 'AT003')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="AT004" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="AT004"
                onClick={handleOpen}
                cx={272.08}
                cy={188.14}
                r={4.96}
                style={
                  tour?.context?.szene === 'AT004'
                    ? activeStyle
                    : visited.find(el => el === 'AT004')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1001" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1001"
                onClick={handleOpen}
                cx={315.47}
                cy={188.14}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1001'
                    ? activeStyle
                    : visited.find(el => el === 'H1001')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1005" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1005"
                onClick={handleOpen}
                cx={315.47}
                cy={207.12}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1005'
                    ? activeStyle
                    : visited.find(el => el === 'H1005')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1006" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1006"
                onClick={handleOpen}
                cx={315.47}
                cy={226.1}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1006'
                    ? activeStyle
                    : visited.find(el => el === 'H1006')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1007" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1007"
                onClick={handleOpen}
                cx={315.47}
                cy={245.08}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1007'
                    ? activeStyle
                    : visited.find(el => el === 'H1007')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1008" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1008"
                onClick={handleOpen}
                cx={315.47}
                cy={264.07}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1008'
                    ? activeStyle
                    : visited.find(el => el === 'H1008')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1009" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1009"
                onClick={handleOpen}
                cx={315.47}
                cy={283.05}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1009'
                    ? activeStyle
                    : visited.find(el => el === 'H1009')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1015" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1015"
                onClick={handleOpen}
                cx={315.47}
                cy={302.03}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1015'
                    ? activeStyle
                    : visited.find(el => el === 'H1015')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1016" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1016"
                onClick={handleOpen}
                cx={272.08}
                cy={329.15}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1016'
                    ? activeStyle
                    : visited.find(el => el === 'H1016')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              disabled={true}
              content={<TooltipContent id="H1018" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H1018"
                onClick={handleOpen}
                cx={272.08}
                cy={358.97}
                r={4.96}
                style={
                  tour?.context?.szene === 'H1018'
                    ? activeStyle
                    : visited.find(el => el === 'H1018')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M213.52 358.97H91.37l.08-199.27h122.07V358.97z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M110.39 160.26h86.6l-.09 24.71-2.71.65h-84.07l.27-25.36zM112.99 42.96v76.44l-21.06-.08-.56-2.39V42.72l21.62.24z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M213.14 119.4H91.41l.12-73.35 3.82-1.95h118.17l-.38 75.3z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M213.19 119.4H91.43l.08-21.45h121.68V119.4z"
            />
            <path
              style={{
                fill: '#e8e8e8',
              }}
              d="M91.37 116.93V44.1l21.53.05v72.78H91.37z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M107.58 286.35v-7.73l71.98.01 1.92.24v7.5l-73.9-.02z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M102.19 202.3h7.73l-.01 146.06-.24 3.88h-7.5l.02-149.94z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M204.73 344.52v7.73l-99.9-.01-2.65-.24v-7.5l102.55.02z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M197.02 208.23h7.72l-.01 140.29-.24 3.73h-7.5l.03-144.02zM149.42 180.7h7.72v162.42l-.25 4.32h-7.5l.03-166.74zM173.75 208.27h7.73l-.01 71.72-.24 1.91h-7.51l.03-73.63z"
            />
            <path
              style={{
                fill: '#f3f3f3',
              }}
              d="M204.74 208.23v7.73l-96.14-.01-2.56-.24v-7.5l98.7.02zM110.97 182.84l7.09 3.09-8.57 19.57-.45.43-6.85-3.63 8.78-19.46z"
            />
            <Tippy
              content={<TooltipContent id="MS001" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="MS001"
                onClick={handleOpen}
                cx={149.08}
                cy={171.87}
                r={4.96}
                style={
                  tour?.context?.szene === 'MS001'
                    ? activeStyle
                    : visited.find(el => el === 'MS001')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="MS002" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="MS002"
                onClick={handleOpen}
                cx={120.73}
                cy={171.87}
                r={4.96}
                style={
                  tour?.context?.szene === 'MS002'
                    ? activeStyle
                    : visited.find(el => el === 'MS002')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <text
              transform="translate(184.74 89.34)"
              style={{
                fill: '#010101',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: '15.25px',
              }}
            >
              <tspan
                x={0}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'0'}
              </tspan>
              <tspan x={9.48} y={0}>
                {'1'}
              </tspan>
            </text>
            <text
              transform="translate(555.39 281.45)"
              style={{
                fill: '#010101',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: '15.25px',
              }}
            >
              <tspan
                x={0}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'0'}
              </tspan>
              <tspan x={9.42} y={0}>
                {'5'}
              </tspan>
            </text>
            <text
              transform="translate(166.34 177.09)"
              style={{
                fill: '#010101',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: '15.25px',
              }}
            >
              <tspan x={0} y={0}>
                {'02'}
              </tspan>
            </text>
            <Tippy
              content={<TooltipContent id="PL001" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="PL001"
                onClick={handleOpen}
                cx={570.58}
                cy={302.03}
                r={4.96}
                style={
                  tour?.context?.szene === 'PL001'
                    ? activeStyle
                    : visited.find(el => el === 'PL001')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4011" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4011"
                onClick={handleOpen}
                cx={501.34}
                cy={60.29}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4011'
                    ? activeStyle
                    : visited.find(el => el === 'H4011')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4010" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4010"
                onClick={handleOpen}
                cx={501.34}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4010'
                    ? activeStyle
                    : visited.find(el => el === 'H4010')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4009" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4009"
                onClick={handleOpen}
                cx={458.82}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4009'
                    ? activeStyle
                    : visited.find(el => el === 'H4009')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4008" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4008"
                onClick={handleOpen}
                cx={402.13}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4008'
                    ? activeStyle
                    : visited.find(el => el === 'H4008')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4006" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4006"
                onClick={handleOpen}
                cx={348.27}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4006'
                    ? activeStyle
                    : visited.find(el => el === 'H4006')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4005" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4005"
                onClick={handleOpen}
                cx={319.93}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4005'
                    ? activeStyle
                    : visited.find(el => el === 'H4005')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4004" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4004"
                onClick={handleOpen}
                cx={291.58}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4004'
                    ? activeStyle
                    : visited.find(el => el === 'H4004')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4001" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4001"
                onClick={handleOpen}
                cx={260.4}
                cy={102.81}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4001'
                    ? activeStyle
                    : visited.find(el => el === 'H4001')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4003" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4003"
                onClick={handleOpen}
                cx={260.4}
                cy={82.97}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4003'
                    ? activeStyle
                    : visited.find(el => el === 'H4003')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4002" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4002"
                onClick={handleOpen}
                cx={260.4}
                cy={68.79}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4002'
                    ? activeStyle
                    : visited.find(el => el === 'H4002')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="H4007" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="H4007"
                onClick={handleOpen}
                cx={373.79}
                cy={77.3}
                r={4.96}
                style={
                  tour?.context?.szene === 'H4007'
                    ? activeStyle
                    : visited.find(el => el === 'H4007')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="MS00101" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="MS00101"
                onClick={handleOpen}
                cx={134.91}
                cy={174.7}
                r={4.96}
                style={
                  tour?.context?.szene === 'MS00101'
                    ? activeStyle
                    : visited.find(el => el === 'MS00101')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <Tippy
              content={<TooltipContent id="A001" />}
              arrow={false}
              delay={[0, 0]}
              followCursor={true}
            >
              <circle
                id="A001"
                onClick={handleOpen}
                cx={228.45}
                cy={200.22}
                r={4.96}
                style={
                  tour?.context?.szene === 'A001'
                    ? activeStyle
                    : visited.find(el => el === 'A001')
                    ? visitedStyle
                    : defaultStyle
                }
              />
            </Tippy>
            <text
              transform="translate(42.54 481.59)"
              style={{
                fill: '#010101',
                fontSize: 11,
              }}
            >
              <tspan
                x={0}
                y={0}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                  letterSpacing: 0,
                }}
              >
                {'0'}
              </tspan>
              <tspan
                x={6.84}
                y={0}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                }}
              >
                {'1'}
              </tspan>
              <tspan
                x={11.33}
                y={0}
                xmlSpace="preserve"
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {' Anmeldung            '}
              </tspan>
              <tspan
                x={0}
                y={20}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                }}
              >
                {'02'}
              </tspan>
              <tspan
                x={12.73}
                y={20}
                xmlSpace="preserve"
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {' Musem und Schulungs'}
              </tspan>
              <tspan
                x={134.38}
                y={20}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                  letterSpacing: '-.02em',
                }}
              >
                {'z'}
              </tspan>
              <tspan
                x={139.31}
                y={20}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {'entrum'}
              </tspan>
              <tspan
                x={0}
                y={40}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                  letterSpacing: '-.01em',
                }}
              >
                {'0'}
              </tspan>
              <tspan
                x={6.8}
                y={40}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                }}
              >
                {'3'}
              </tspan>
              <tspan
                x={12.76}
                y={40}
                xmlSpace="preserve"
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {' P'}
              </tspan>
              <tspan
                x={22.5}
                y={40}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                  letterSpacing: '-.02em',
                }}
              >
                {'r'}
              </tspan>
              <tspan
                x={26.2}
                y={40}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {'oduk'}
              </tspan>
              <tspan
                x={51.34}
                y={40}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                  letterSpacing: 0,
                }}
              >
                {'t'}
              </tspan>
              <tspan
                x={55.27}
                y={40}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {'ion Halle 3'}
              </tspan>
              <tspan
                x={0}
                y={60}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                  letterSpacing: '-.01em',
                }}
              >
                {'0'}
              </tspan>
              <tspan
                x={6.8}
                y={60}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                }}
              >
                {'4'}
              </tspan>
              <tspan
                x={13.09}
                y={60}
                xmlSpace="preserve"
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {' P'}
              </tspan>
              <tspan
                x={22.83}
                y={60}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                  letterSpacing: '-.02em',
                }}
              >
                {'r'}
              </tspan>
              <tspan
                x={26.53}
                y={60}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {'oduk'}
              </tspan>
              <tspan
                x={51.67}
                y={60}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                  letterSpacing: 0,
                }}
              >
                {'t'}
              </tspan>
              <tspan
                x={55.6}
                y={60}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {'ion Halle 4'}
              </tspan>
              <tspan
                x={0}
                y={80}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                  letterSpacing: '-.01em',
                }}
              >
                {'0'}
              </tspan>
              <tspan
                x={6.8}
                y={80}
                style={{
                  fontFamily: "Averta-Bold,'Averta'",
                }}
              >
                {'5 '}
              </tspan>
              <tspan
                x={15.7}
                y={80}
                style={{
                  fontFamily: "Averta-Regular,'Averta'",
                }}
              >
                {'Pr\xFCflabor'}
              </tspan>
            </text>
            <text
              transform="translate(41.72 461.58)"
              style={{
                fill: '#010101',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: 14,
              }}
            >
              <tspan x={0} y={0}>
                {'P'}
              </tspan>
              <tspan
                x={8.71}
                y={0}
                style={{
                  letterSpacing: '-.01em',
                }}
              >
                {'r'}
              </tspan>
              <tspan x={13.73} y={0}>
                {'oduk'}
              </tspan>
              <tspan
                x={46.2}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'t'}
              </tspan>
              <tspan x={51.32} y={0}>
                {'ion'}
              </tspan>
            </text>
            <g
              style={{
                clipPath: 'url(#b)',
              }}
            >
              <g
                style={{
                  clipPath: 'url(#c)',
                }}
              >
                <path
                  d="M103.07 405.44c-.76-1.01-1.73-1.85-2.88-2.4a5.17 5.17 0 0 0 2.23-4.26c0-2.95-2.01-5.18-5.7-5.18h-1.46v11.84h7.8ZM87.38 393.6h7.15v11.84h-7.15zM87.38 406.19h7.15v11.84h-7.15zM95.27 406.19v11.84h1.46c4.85 0 7.92-3.43 7.92-7.89 0-1.44-.4-2.79-1.08-3.95h-8.29ZM60.73 405.44l-4.19-11.84h-3.21v11.84h7.4zM52.59 405.44V393.6h-3.2l-4.19 11.84h7.39zM52.59 406.19h-7.65l-4.19 11.84h6.17l1.73-4.94h3.94v-6.9zM53.33 406.19v6.9h3.95l1.73 4.94h6.16l-4.18-11.84h-7.66zM82.84 405.44c-.76-1.01-1.73-1.85-2.88-2.4a5.17 5.17 0 0 0 2.23-4.26c0-2.95-2.01-5.18-5.7-5.18h-1.46v11.84h7.8ZM67.15 393.6h7.15v11.84h-7.15zM67.15 406.19h7.15v11.84h-7.15zM75.04 406.19v11.84h1.46c4.85 0 7.92-3.43 7.92-7.89 0-1.44-.4-2.79-1.08-3.95h-8.29Z"
                  style={{
                    fill: '#e3051b',
                  }}
                />
              </g>
            </g>
            <text
              transform="translate(40.42 433.46)"
              style={{
                fill: '#1d1d1b',
                fontFamily: "Averta-Bold,'Averta'",
                fontSize: 12,
              }}
            >
              <tspan
                x={0}
                y={0}
                style={{
                  letterSpacing: '-.01em',
                }}
              >
                {'S'}
              </tspan>
              <tspan
                x={6.86}
                y={0}
                style={{
                  letterSpacing: '-.05em',
                }}
              >
                {'TO'}
              </tspan>
              <tspan
                x={22.67}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'T'}
              </tspan>
              <tspan
                x={29.72}
                y={0}
                style={{
                  letterSpacing: '-.04em',
                }}
              >
                {'Z'}
              </tspan>
              <tspan x={36.7} y={0}>
                {'-'}
              </tspan>
              <tspan
                x={41.46}
                y={0}
                style={{
                  letterSpacing: '-.05em',
                }}
              >
                {'K'}
              </tspan>
              <tspan x={48.83} y={0}>
                {'ON'}
              </tspan>
              <tspan
                x={67.69}
                y={0}
                style={{
                  letterSpacing: '-.07em',
                }}
              >
                {'T'}
              </tspan>
              <tspan x={73.87} y={0}>
                {'A'}
              </tspan>
              <tspan
                x={82.2}
                y={0}
                style={{
                  letterSpacing: 0,
                }}
              >
                {'K'}
              </tspan>
              <tspan x={90.11} y={0}>
                {'T'}
              </tspan>
            </text>
          </svg>
        </div>
      </div>
    </ErrorBoundary>
  )
}
