import React, {useState, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ChatBot from 'react-simple-chatbot'
import {useActor} from '@xstate/react'

import {useAtomValue} from 'jotai/utils'
import {v4} from 'uuid'
import useIsPoi from 'components/Hooks/useIsPoi'

import {
  dimensionsAtom,
  stepsChatbotAtom,
  audioTimeAtom,
  audioTimePoiAtom,
  tourMachineAtom,
  shouldPlaySoundAtom,
  shouldPlaySoundPoiAtom
} from 'components/jotai'

import {styled} from '@mui/material/styles'

import Header from 'components/Chatbot/components/ChatbotHeader'

import laura from 'assets/img/laura.png'

import {
  setOpen,
  getOpen,
  getFullSize,
  setFullSize,
  getCalculatedFontSize,
} from 'components/TourControls/duck'

import {getNavigationValue, getBrand} from 'components/xstate/NavigationMachine'

const Container = styled('div')(({theme}) => ({
  webkitBoxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
  boxShadow: 'rgb(0 0 0 / 15%) 0px 20px 40px 0px',
  background: '#FFF',
  borderRadius: '20px',
  position: 'relative',
  '&&:after': {
    content: '""',
    position: 'absolute',
    width: '18px',
    height: '18px',
    background: '#FFF',
    transform: 'rotate(45deg)',
    bottom: '-9px',
    right: '27px',
    boxShadow: '0 0 4px 0 #424242',
  },
}))

const ContainerFullSize = styled('div')(({theme}) => ({
  borderRadius: 0,
  height: '100vh',
  position: 'relative',
  background: 'rgba(255, 255, 255, 0.95)',
}))

export default function Sidebar() {
  const dispatch = useDispatch()
  const open = useSelector(getOpen)
  const fullSize = useSelector(getFullSize)
  const fontSize = useSelector(getCalculatedFontSize)
  const naviValue = useSelector(getNavigationValue)
  const brand = useSelector(getBrand)

  const steps = useAtomValue(stepsChatbotAtom)
  const isPoi = useIsPoi()

  const {playing} = useAtomValue(isPoi ? audioTimePoiAtom : audioTimeAtom)
  const {width} = useAtomValue(dimensionsAtom)
  const [id, setId] = useState(v4())
  const tourService = useAtomValue(tourMachineAtom)
  const [tour,] = useActor(tourService)
  const shouldPlaySound = useAtomValue(isPoi ? shouldPlaySoundPoiAtom : shouldPlaySoundAtom)
   
  useEffect(() => {
    if (width <= 600) {
      dispatch(setFullSize(true))
    }
  }, [width, dispatch])

  useEffect(() => {
    setId(v4())
  }, [steps])

  const ContainerElement = fullSize ? ContainerFullSize : Container

  return (
    <div
      style={{
        position: 'relative',
        width: width > 600 ? (open && fullSize ? 510 : 90) : '100%',
        height: '82px',
      }}
    >
      {(playing || tour.context.soundTitle && !shouldPlaySound) && (
        <svg
          width="82"
          height="82"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="lauraSpeech"
        >
          <defs>
            <linearGradient id="test">
              <stop
                offset="0%"
                stopColor={
                  brand === 'abbRed'
                    ? '#b6000a'
                    : brand === 'striebel'
                    ? '#005925'
                    : '#006288'
                }
              />
              <stop
                offset="50%"
                stopColor={
                  brand === 'abbRed'
                    ? '#ff000f'
                    : brand === 'striebel'
                    ? '#046927'
                    : '#00acec'
                }
              />
              <stop
                offset="100%"
                stopColor={
                  brand === 'abbRed'
                    ? '#ffe1e3'
                    : brand === 'striebel'
                    ? '#c2e3d0'
                    : '#f7fcfd'
                }
              />
            </linearGradient>
          </defs>
          <circle cx="41" cy="41" r="37" fill="#FFF" />
          <circle
            cx="41"
            cy="41"
            r="37"
            fill="none"
            stroke="url(#test)"
            strokeWidth="6"
          />
        </svg>
      )}
      <img
        src={brand === 'abbRed' ? laura : brand === 'striebel' ? laura : laura}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: -4,
          right: 12,
          zIndex: 1202,
          width: 74,
          height: 74,
          pointerEvents: 'auto',
        }}
        alt="laura"
        onClick={() => dispatch(setOpen(!open))}
      />
      {open && (
        <div
          style={{
            position: fullSize ? 'fixed' : 'absolute',
            bottom: fullSize ? 0 : 110,
            right: fullSize ? 0 : 4,
            width: fullSize ? (width > 600 ? 510 : 250) : 400,
            zIndex: 1202,
          }}
        >
          <ContainerElement>
            <ChatBot
              key={id}
              steps={steps}
              className={
                naviValue.toLowerCase()?.includes('luedenscheid')
                  ? 'chatbotBje'
                  : naviValue.value?.toLowerCase()?.includes('sasbach')
                  ? 'chatbotStriebel'
                  : 'chatbotAbb'
              }
              headerComponent={<Header />}
              hideBotAvatar={true}
              hideUserAvatar={true}
              footerStyle={{
                display: 'none',
              }}
              style={{
                background: fullSize ? 'transparent' : '#FFF',
                fontFamily: 'ABBVoice',
                borderRadius: '20px',
                boxShadow: 'none',
                fontSize: fontSize,
                pointerEvents: 'auto',
              }}
              bubbleStyle={{
                fontFamily: 'ABBVoice',
                fontSize: fontSize,
              }}
              bubbleOptionStyle={{
                fontFamily: 'ABBVoice',
                fontSize: fontSize,
              }}
              botDelay={200}
              customDelay={400}
            />
          </ContainerElement>
        </div>
      )}
    </div>
  )
}
