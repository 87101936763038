import {
  useDroppable,
} from '@dnd-kit/core';

const DroppableLast = () => {
  const { setNodeRef, isOver } = useDroppable({ id: 'addLast' });

  return (
    <div
      ref={setNodeRef}
      style={{width: '100%', padding: '20px 0', textAlign: 'center', border:  `2px dashed ${isOver ? 'red' : 'black'}`}}
    >
      Am Ende einfügen   
    </div>
  )
};

export default DroppableLast