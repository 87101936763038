import {Machine, assign} from 'xstate'

export const navigationMachine = Machine({
  id: 'navigation',
  initial: 'home',
  context: {
    brand: undefined,
    stateKey: undefined,
  },
  states: {
    home: {
      on: {
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return undefined
          },
          stateKey: (_, e) => {
            return undefined
          },
        }),
      ],
    },
    '/tour/luedenscheid_logistik': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            return 'bjeLogistik'
          },
        }),
      ],
    },
    '/tour/luedenscheid_produktion': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            return 'bjeProduktion'
          },
        }),
      ],
    },
    '/tour/luedenscheid_free': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            return 'bjeFree'
          },
        }),
      ],
    },
    '/tour/luedenscheid_schulungszentrum': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            return 'bjeSchulungszentrum'
          },
        }),
      ],
    },
    '/tour/luedenscheid_aussengelaende': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            return 'bjeAussengelaende'
          },
        }),
      ],
    },
    '/tour/luedenscheid_empfang': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            return 'bjeEmpfang'
          },
        }),
      ],
    },
    '/tour/heidelberg': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG2: '/tour/heidelberg2',
        SASBACH: '/tour/sasbach',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'abbRed'
          },
          stateKey: (_, e) => {
            return 'abb'
          },
        }),
      ],
    },
    '/tour/heidelberg2': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        SASBACH: '/tour/sasbach',
        HEIDELBERG: '/tour/heidelberg',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'abbRed'
          },
          stateKey: (_, e) => {
            return 'abb'
          },
        }),
      ],
    },
    '/tour/sasbach': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'striebel'
          },
          stateKey: (_, e) => {
            return 'striebel'
          },
        }),
      ],
    },
    'individuell': {
      on: {
        HOME: 'home',
        LUEDENSCHEID_LOGISTIK: '/tour/luedenscheid_logistik',
        LUEDENSCHEID_PRODUKTION: '/tour/luedenscheid_produktion',
        LUEDENSCHEID_FREE: '/tour/luedenscheid_free',
        LUEDENSCHEID_SCHULUNGSZENTRUM: '/tour/luedenscheid_schulungszentrum',
        LUEDENSCHEID_AUSSENGELAENDE: '/tour/luedenscheid_aussengelaende',
        LUEDENSCHEID_EMPFANG: '/tour/luedenscheid_empfang',
        HEIDELBERG: '/tour/heidelberg',
        HEIDELBERG2: '/tour/heidelberg2',
        INDIVIDUELL: 'individuell',
      },
      entry: [
        assign({
          brand: (_, e) => {
            return 'bje'
          },
          stateKey: (_, e) => {
            const splits = window.location.hash.split('/')
            return splits[splits.length - 1]
          },
        }),
      ],
    },
  },
})

function navigationReducer(state = navigationMachine.initialState, event) {
  return navigationMachine.transition(state, event)
}

export const getNavigationValue = state => state.navigationState?.value
export const getNavigationContext = state => state.navigationState?.context
export const getBrand = state => state.navigationState?.context?.brand

export default navigationReducer
