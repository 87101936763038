import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Grid from "@mui/material/Grid";
import SasbachTimelineElement from "./element";
import VisibilitySensor from "react-visibility-sensor";

/* Images aus neuer Timeline */
import img1958 from "../../assets/img/timeline/1958.jpg";
import img1963 from "../../assets/img/timeline/1963.jpg";
import img1964 from "../../assets/img/timeline/1964.jpg";
import img1968 from "../../assets/img/timeline/1968.jpg";
import img1973 from "../../assets/img/timeline/1973.jpg";
import img1977 from "../../assets/img/timeline/1977.jpg";
import img1984 from "../../assets/img/timeline/1984.jpg";
import img1985 from "../../assets/img/timeline/1985.jpg";
import img1988 from "../../assets/img/timeline/1988.jpg";
import img1993 from "../../assets/img/timeline/1993.jpg";
import img1995 from "../../assets/img/timeline/1995.jpg";
import img1996 from "../../assets/img/timeline/1996.jpg";
import img2000 from "../../assets/img/timeline/2000.jpg";
import img2001 from "../../assets/img/timeline/2001.jpg";
import img2003 from "../../assets/img/timeline/2003.jpg";
import img2004 from "../../assets/img/timeline/2004.jpg";
import img2008_1 from "../../assets/img/timeline/2008-1.jpg";
import img2008_2 from "../../assets/img/timeline/2008-1.jpg";
import img2010 from "../../assets/img/timeline/2010.jpg";
import img2012 from "../../assets/img/timeline/2012.jpg";
import img2013 from "../../assets/img/timeline/2013.jpg";
import img2015 from "../../assets/img/timeline/2015.jpg";
import img2017 from "../../assets/img/timeline/2017.jpg";
import img2018_1 from "../../assets/img/timeline/2018-1.jpg";
import img2018_2 from "../../assets/img/timeline/2018-2.jpg";
import img2020_1 from "../../assets/img/timeline/2020-1.jpg";
import img2020_2 from "../../assets/img/timeline/2020-2.jpg";
import img2021 from "../../assets/img/timeline/2021.jpg";
import img2022_1 from "../../assets/img/timeline/2022-1.jpg";
import img2022_2 from "../../assets/img/timeline/2022-2.jpg";

/* Images aus alter Timeline
import FranzStriebelPortrait from "../../assets/img/1958-franz-striebel.jpg";
import HannoverMesse from "../../assets/img/1968-hannover-messe.jpg";
import Polyrastsystem from "../../assets/img/1974-polyrasersystem.png";
import WandUndStandverteiler from "../../assets/img/1984-wand-und-standverteiler.png";
import Schnellmontagesystem from "../../assets/img/1988-schnellmontagesystem.jpg";
import Versandtzentrum from "../../assets/img/1995-versandtzentrum.png";
import TrilineRSchaltSchranksystem from "../../assets/img/2003-triline-r-schalt-schranksystem.png";
import CD from "../../assets/img/cd.png";
import PlanoDiskette from "../../assets/img/plano-diskette.png";
import ReihenschalterschrankProgramm from "../../assets/img/reihenschalterschrank-programm.png";
import Jubilaeum25 from "../../assets/img/1983-25-jahre-striebel-und-john-logo.png";
import Jubilaeum50 from "../../assets/img/2008-50-jahre-striebel-john.png";
*/

import "./styles.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SasbachTimeline() {
  const toStart = 10;
  const [openImageBox, setOpenImageBox] = useState(false);
  const [image, setImage] = useState(null);
  const [scrollPos, setScrollPos] = useState(toStart);
  const main = React.createRef();

  const elements = [
    {
      year: 1958,
      text: "Gründung von Striebel & John – Firmengründer: Franz Striebel",
      img: img1958,
    },
    {
      year: 1963,
      text: "Bau einer 400 m² großen Halle in der Schulstraße in Obersasbach",
      img: img1963,
    },
    {
      year: 1964,
      text: "Entwicklung der Zähler- und Verteilerschränke",
      img: img1964,
    },
    {
      year: 1968,
      text: "Bau des ersten Gebäudes im Klammsbosch in Obersasbach",
      img: img1968,
    },
    {
      year: 1973,
      text: "Markteinführung des UK Stromverteilers für zwei Einbautiefen",
      img: img1973,
    },
    {
      year: 1977,
      text: "Fertigung der Reihenschaltschränke RSN",
      img: img1977,
    },
    {
      year: 1984,
      text: "Markteinführung der Wand- und Standschränke",
      img: img1984,
    },
    {
      year: 1985,
      text: "Vorstellung der Planungs- und Kalkulationssoftware",
      img: img1985,
    },
    {
      year: 1988,
      text: "Entwicklung des EDF-Schnellmontagesystems",
      img: img1988,
    },
    {
      year: 1993,
      text: "Beteiligung der ABB AG Mannheim mit 51% an Striebel & John",
      img: img1993,
    },
    {
      year: 1996,
      text:
        "Gründung der ABB STOTZ-KONTAKT / STRIEBEL & JOHN Vertriebsgesellschaft",
      img: img1996,
    },
    {
      year: 2000,
      text: "Markteinführung Kleinverteiler",
      img: img2000,
    },
    {
      year: 2001,
      text: "Bezug des Schulungs- und Verwaltungszentrums",
      img: img2001,
    },
    {
      year: 2003,
      text: "Markteinführung Reihenschaltschränke TriLine",
      img: img2003,
    },
    {
      year: 2004,
      text: "Markteinführung Brandschutzgehäuse",
      img: img2004,
    },
    {
      year: 2008,
      text: "Jubiläum: 50 Jahre STRIEBEL & JOHN",
      img: img2008_1,
    },
    {
      year: 2008,
      text: "Markteinführung Innenausbausystem CombiLine",
      img: img2008_2,
    },
    {
      year: 2010,
      text: "Markteinführung PowerModule TriLine",
      img: img2010,
    },
    {
      year: 2012,
      text: "Markteinführung Wand- und Standschränke TwinLine",
      img: img2012,
    },
    {
      year: 2013,
      text: "Markteinführung Stromkreisverteiler Mistral 65",
      img: img2013,
    },
    {
      year: 2015,
      text: "ABB erhöht Beteiligung auf 100% an STRIEBEL & JOHN",
      img: img2015,
    },
    {
      year: 2017,
      text: "Markteinführung Standschränke TwinLine N 44",
      img: img2017,
    },
    {
      year: 2018,
      text: "Jubiläum: 60 Jahre ABB STRIEBEL & JOHN",
      img: img2018_1,
    },
    {
      year: 2018,
      text: "Markteinführung Stromkreisverteiler UK600",
      img: img2018_2,
    },
    {
      year: 2020,
      text:
        "Markteinführung Kleinverteiler, Zähler- und Wandschränke ComfortLine",
      img: img2020_1,
    },
    {
      year: 2020,
      text: "Vorstellung Zählerplatz-Konfigurator ComfortPlaner",
      img: img2020_2,
    },
    {
      year: 2021,
      text: "Markteinführung anreihbare Standschränke TwinLine-X",
      img: img2021,
    },
    {
      year: 2022,
      text: "Markteinführung Innenausbausystem ComfortLine",
      img: img2022_1,
    },
    {
      year: 2022,
      text: "Markteinführung Reihenschaltschränke System pro E power",
      img: img2022_2,
    },
  ];
  /*
  const oldElements = [
    {
      year: 1958,
      titel: "Gründung",
      text: (
        <>
          von Striebel & John
          <br />
          Firmengründer: Franz Striebel
        </>
      ),
      img: FranzStriebelPortrait,
    },
    {
      year: 1959,
      text:
        "Striebel & John liefert fabrikfertige Stromkreisverteiler mit geschlossenem Putzkasten und herausnehmbarem Geräteträger",
    },
    {
      year: 1963,
      text:
        "Entwicklung der Berührungsschutzkappe für E27 E33 Elemente - Bau einer 400 qm großen Halle",
    },
    {
      year: 1965,
      text:
        "Der Vertrieb wird erstmals über die Landesgrenzen Baden-Württembergs ausgedehnt",
    },
    {
      year: 1966,
      text: "Entwicklung des wegweisenden Normfeldprogramms",
    },
    {
      year: 1968,
      text: "Striebel & John stellt zum ersten Mal auf der Hannover Messe aus",
      img: HannoverMesse,
    },
    {
      year: 1969,
      text: "Bau des ersten Gebäudes, im Klammsbosch",
    },
    {
      year: 1973,
      text: (
        <>
          Einführung des UK Stromverteilers für zwei Einbautiefen
          <br />
          (1970 - 1980 Markführer)
          <br />
          <br />
          Am Standort Sasbach wird die Oberflächentechnik in Betrieb genommen
        </>
      ),
    },
    {
      year: 1974,
      text:
        "Das Polyrastsystem, ein stabiles Polyestergehäuseprogramm, ergänzt die Striebel & John Produktpalette",
      img: Polyrastsystem,
    },
    {
      year: 1977,
      text:
        "Reihenschaltschrankprogramm RSN-System wird in die Fertigung aufgenommen",
    },
    {
      year: 1983,
      titel: "25 Jahre Striebel & John",
      text: "230 Mitarbeiter",
      img: Jubilaeum25,
    },
    {
      year: 1984,
      text:
        "Wand- und Standverteilerreihe FS, C und G werden auf dem Markt angeboten",
      img: WandUndStandverteiler,
    },
    {
      year: 1985,
      text: (
        <>
          Vorstellung der neuen Stromkreisverteiler
          <br />
          Reihe UK200
        </>
      ),
    },
    {
      year: 1986,
      text:
        "Der Anreihverteiler erhält eine Auszeichnung für gutes Design – das IF-Zeichen",
    },
    {
      text:
        "Entwicklung des Reihenschrank-Programms XA geerdet und XS schutzisoliert",
      img: ReihenschalterschrankProgramm,
    },
    {
      year: 1988,
      text: "Erster Hersteller von EDF-Schnellmontagesystemen",
      img: Schnellmontagesystem,
    },
    {
      text:
        "Vorstellung der ersten Planungs- und Projektierungssoftware – Plano",
      img: PlanoDiskette,
    },
    {
      year: 1990,
      text: "Vorstellung der neuen Stromkreisverteiler Reihe UK400",
    },
    {
      year: 1992,
      text: (
        <>
          Der Produktionsstandort wurde um 2500 qm erweitert und weitere
          flexible Fertigungseinrichtungen angeschafft
          <br />
          <br />
          Gründung eines modernen Kunststoffspritzbetriebs
        </>
      ),
    },
    {
      year: 1993,
      text: "Striebel & John wird in die ABB Firmengruppe aufgenommen",
    },
    {
      year: 1995,
      text: (
        <>
          Das hochmoderne Lager und Versandzentrum wird in Betrieb genommen
          <br />
          <br />
          Zertifizierung gemäß internationaler Maßstäbe nach DIN EN ISO 9001
        </>
      ),
      img: Versandtzentrum,
    },
    {
      year: 1996,
      text:
        "Gründung der ASJ, ABB STOTZ-KONTAKT / Striebel & John Vertriebsgesellschaft mbH (ASJ)",
    },
    {
      year: 1998,
      text:
        "Vorstellung der neuen Stromkreisverteiler der Reihe UK500 zur Hannover Messe",
    },
    {
      year: 1999,
      text: "Vorstellung des neuen Haubenverteilers A300",
    },
    {
      year: 2000,
      text: "Markteinführung der neuen Kleinverteiler und TSK-Verteilersysteme",
    },
    {
      year: 2001,
      text: "Bezug des neuen Schulungs- und Verwaltungszentrums am Fuchsgraben",
    },
    {
      year: 2002,
      text:
        "Markteinführung der N/PE Quick-Steckschiene zur Light+Building, Frankfurt",
    },
    {
      year: 2003,
      text: "Markteinführung des TriLine-R Reihen-Schaltschranksystems",
      img: TrilineRSchaltSchranksystem,
    },
    {
      year: 2004,
      text:
        "Brandschutzverteiler als Wand- und Standverteiler werden im Produktsortiment vorgestellt",
    },
    {
      year: 2005,
      text: (
        <>
          StriePlan 3.0 Planungs- und Kalkulationssoftware
          <br />
          Neu: mit Stromlaufplan SLP
        </>
      ),
      img: CD,
    },
    {
      year: 2006,
      text:
        "Typgeprüfte Reihenschaltschränke bis 4000 A (TSK) und die neuen eHZ-Zählerschränke werden auf der Light+Building vorgestellt",
    },
    {
      year: 2008,
      titel: (
        <>
          50 Jahre
          <br />
          Striebel & John
        </>
      ),
      img: Jubilaeum50,
    },
  ];
  */

  const handleShow = (e) => {
    setOpenImageBox(true);
    setImage(e.target.src);
  };
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenImageBox(false);
  };

  const updateLineHeight = (e) => {
    const realScrollPercentage = (
      ((100 - toStart) * main.current.scrollTop) /
        (main.current.scrollHeight - main.current.clientHeight) +
      toStart
    ).toFixed(2);
    const scrollPercentage = realScrollPercentage * 1.2;

    if (scrollPercentage > 0 && scrollPercentage < 50)
      setScrollPos(scrollPercentage);
    else if (realScrollPercentage > 80)
      setScrollPos(
        realScrollPercentage - 30 + (realScrollPercentage - 80) * 1.5
      );
  };

  return (
    <>
      <Dialog
        open={openImageBox}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="lg"
        fullScreen={true}
        PaperProps={{
          sx: { background: "transparent" },
        }}
      >
        <DialogTitle style={{ cursor: "pointer", paddingTop: 25 }}>
          <Typography onClick={handleClose}>
            <FormattedMessage
              id="striebel.timeline.image.close"
              defaultMessage="Bild schließen"
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClose}
          >
            <img
              src={image}
              style={{ maxWidth: "100%", maxHeight: "80%" }}
              alt={""}
            />
          </div>
        </DialogContent>
      </Dialog>

      <main
        id="timelineScroll"
        style={{ height: "100vh", overflow: "auto", backgroundColor: "white" }}
        onScroll={updateLineHeight}
        ref={main}
      >
        <Container>
          <div
            className="scrollIndicator"
            style={{
              height: scrollPos + "vh",
            }}
          ></div>
          <section className="timeline">
            <Grid container>
              <Grid item xs={12}>
                <ul className="timeline-list">
                  {elements.map((e, i) => {
                    return (
                      <>
                        <VisibilitySensor key={i}>
                          {({ isVisible }) => (
                            <>
                              <SasbachTimelineElement
                                e={e}
                                i={i}
                                visible={isVisible}
                                handleShow={handleShow}
                              />
                            </>
                          )}
                        </VisibilitySensor>
                      </>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
          </section>
        </Container>
      </main>
    </>
  );
}
