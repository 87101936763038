import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useAtom} from 'jotai'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import AbbIcon from 'components/Icons/AbbIcon'
import BjeIcon from 'components/Icons/BjeIcon'
import StriebelIcon from 'components/Icons/StriebelIcon'
import Settings from 'components/Chatbot/components/Settings'
import {CloseIcon, SettingsIcon, ResizeIcon} from 'components/Icons'
import {
  dimensionsAtom,
  stepsChatbotAtom,
  tempStepsChatbotAtom,
} from 'components/jotai'
import {getNavigationValue} from 'components/xstate/NavigationMachine'

import ErrorBoundary from 'components/ErrorBoundary'
import {getFullSize, setFullSize, setOpen} from 'components/TourControls/duck'
import useConfig from 'components/Chatbot/useConfig'

export default function Header() {
  const dispatch = useDispatch()
  const fullSize = useSelector(getFullSize)
  const naviValue = useSelector(getNavigationValue)
  const [steps, setSteps] = useAtom(stepsChatbotAtom)
  const setTempSteps = useUpdateAtom(tempStepsChatbotAtom)
  const {width} = useAtomValue(dimensionsAtom)
  const defaultStep = useConfig('default')
  const empfangStep = useConfig('empfang')

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    if (
      ['SelectTour', 'SelectPlace', 'SelectWhere', 'SelectHighlights'].find(
        el => steps[0]?.id && steps[0]?.id === el,
      )
    ) {
      setSteps(defaultStep)
    }

    if (steps[0]?.id === 'SelectPlaceEmpfang') {
      setSteps(empfangStep)
    }

    dispatch(setOpen(false))
  }

  const handleResize = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(setFullSize(!fullSize))
  }

  const handleSettings = e => {
    e.stopPropagation()
    e.preventDefault()
    setTempSteps(steps)
    setSteps([
      {
        id: 'settings',
        component: <Settings />,
        end: true,
      },
    ])
  }

  return (
    <ErrorBoundary>
      <Box sx={{background: 'transparent', padding: '19px 29px'}}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            {naviValue.toLowerCase()?.includes('heidelberg') && (
              <AbbIcon width={48} height={18} />
            )}
            {naviValue.toLowerCase()?.includes('luedenscheid') && (
              <BjeIcon width={101} height={28} />
            )}
            {naviValue.toLowerCase()?.includes('sasbach') && (
              <StriebelIcon width={140} height={23} />
            )}
          </Grid>
          <Grid item xs={6} align="right">
            <Grid container justifyContent="flex-end">
              <Grid item>
                <IconButton onClick={handleSettings}>
                  <SettingsIcon />
                </IconButton>
              </Grid>
              {width > 600 && (
                <Grid item>
                  <IconButton onClick={handleResize}>
                    <ResizeIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ErrorBoundary>
  )
}
