import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useAtom} from 'jotai'
import {useAtomValue, useUpdateAtom} from 'jotai/utils'
import {useMeasure} from 'react-use'
import Tippy from '@tippyjs/react/headless'

import {styled} from '@mui/material/styles'
import {
  audioControlsAtom,
  showPlaybackBarAtom,
  tourMachineAtom,
  isEndVideoAtom,
  sidebarAtom,
  isPausedAtom,
  showPlaybackBarPoiAtom,
  currentTimeAtom,
  audioTimeAtom,
  globalSoundEnabledAtom,
  stepsChatbotAtom,
  shouldPlaySoundAtom
} from 'components/jotai'
import {
  getSound,
} from 'components/TourControls/duck'
import {useActor} from '@xstate/react'

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import ReactPlayer from 'react-player'
import useConfig from 'components/Chatbot/useConfig'

import {setOpen} from 'components/TourControls/duck'
import {isPanorama, getMediaData} from 'components/Navigation/duck'
import useIsIndividualTour from 'components/IndividuelleTour/useIsIndividualTour'

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 16,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))

const Timeslot = styled('div')(({theme}) => ({
  position: 'absolute',
  bottom: 0,
  width: 20,
  height: 20,
  zIndex: 1201,
  cursor: 'pointer',
}))

export default function PlaybackBar() {
  const dispatch = useDispatch()
  const isIndividualTour = useIsIndividualTour()
  const showPlaybackBar = useAtomValue(showPlaybackBarAtom)
  const soundControls = useAtomValue(audioControlsAtom)
  const {paused, finished} = useAtomValue(audioTimeAtom)
  const tourService = useAtomValue(tourMachineAtom)
  const sidebarOpen = useAtomValue(sidebarAtom)
  const [stepsChatbot, setStepsChatbot] = useAtom(stepsChatbotAtom)
  const [tour, sendTour] = useActor(tourService)
  const [globalSoundEnabled, setGlobalSoundEnabledAtom] = useAtom(globalSoundEnabledAtom)
  const shouldPlaySound = useAtomValue(shouldPlaySoundAtom)
  const duration = tour?.context?.duration ? tour.context.duration * 1000 : 0
  const timeSlots = tour?.context?.timeSlots || []
  const [isVideo, setIsVideo] = useState(false)
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom)
  const [loop, setLoop] = useState(null)
  const [ref, {width}] = useMeasure()
  const sound = useSelector(getSound)
  const enableSoundStep = useConfig('enableGlobalSounding')

  const setIsEndVideo = useUpdateAtom(isEndVideoAtom)
  const [isPaused, setIsPaused] = useAtom(isPausedAtom)
  const showPlaybackBarPoi = useAtomValue(showPlaybackBarPoiAtom)

  useEffect(() => {
    if (tour.context.szene) {
      clearTimeout(loop)
      const playlistItem = window?.blazeIT?.getPlayListItemByMediaName(
        tour.context.szene,
      )
      if (playlistItem) {
        setIsVideo(!isPanorama(playlistItem))
        setCurrentTime(0)
        if (!isPanorama(playlistItem)) {
        }
        setLoop(
          setInterval(() => {
            setCurrentTime(
              window?.blazeIT?.getCurrentMedia().get('currentTime') * 1000,
            )
          }, 200),
        )
      } else {
        setIsVideo(false)
        setCurrentTime(0)
      }
    }
    return () => clearTimeout(loop)
  }, [tour.context.szene, setCurrentTime]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = e => {
    if (duration > 0) {
      setIsEndVideo(false)
      const newTime = Math.min(
        (e.pageX /
          document.getElementById('playbackBar')?.getBoundingClientRect()
            ?.width) *
          duration,
        duration - 200,
      )
      window?.blazeIT?.setComponentAttributes(window?.blazeIT?.getCurrentMedia(), {
        currentTime: newTime / 1000,
      })
      window.tour.MainViewerPanoramaPlayer.play()
      window.tour.MainViewerVideoPlayer.play()
      setIsPaused(false)

      if (!finished) {
        soundControls?.seek(newTime / 1000)
      }
    }
  }

  const handleClickTimeslot = time => {
    setIsEndVideo(false)
    window?.blazeIT?.setComponentAttributes(window?.blazeIT?.getCurrentMedia(), {
      currentTime: time,
    })
    if (!finished) {
      soundControls?.seek(time)
    }
  }

  const onDuration = e => {
    sendTour({type: 'ENTER_DELAYED', duration: e})
  }

  useEffect(() => {
    const shouldShow = !(Array.isArray(stepsChatbot) && stepsChatbot.length > 0 && stepsChatbot[0].id === enableSoundStep[0].id)
    if (currentTime > 1000 && !globalSoundEnabled && shouldShow && sound && shouldPlaySound && tour.context.soundFile) {
      if (paused) {
        dispatch(setOpen(true))
        setStepsChatbot(enableSoundStep)
      } else {
        setGlobalSoundEnabledAtom(true)
      }
    }
    
    
    if (
      currentTime > 0 &&
      currentTime < duration &&
      !isPaused &&
      paused &&
      !finished
    ) {
      soundControls?.play()
    }

    if (isIndividualTour && currentTime >= duration - 500) {
      if (
        getMediaData(window?.blazeIT?.getCurrentPlayListItem(), "label")?.includes('.')
        && window?.blazeIT?.getComponentAttributes('Main Viewer')
          ?.playbackState === 'playing'
      ) {
        window?.blazeIT?.getComponent('Main Viewer')?.pause()
      }
    }

    
  }, [isPaused, currentTime, soundControls, paused, finished, duration])

  return (
    <>
      {showPlaybackBar && isVideo && !showPlaybackBarPoi && (
        <>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 1100,
            }}
            ref={ref}
            onClick={handleClick}
            id="playbackBar"
          >
            <BorderLinearProgress
              variant="determinate"
              value={
                (Math.min(currentTime, duration) / Math.max(1, duration)) * 100
              }
            />
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: sidebarOpen ? 93 : 5,
              zIndex: 1101,
              color: '#000',
            }}
          >
            {`${Math.floor((currentTime / 1000 / 60) << 0)}:${(
              '0' + Math.floor((currentTime / 1000) % 60)
            ).slice(-2)}`}{' '}
            /{' '}
            {`${Math.floor((duration / 1000 / 60) << 0)}:${(
              '0' + Math.floor((duration / 1000) % 60)
            ).slice(-2)}`}
          </div>
          {timeSlots.map((el, index) => {
            if (width > 0) {
              const position = (width / duration) * (el.position * 1000)
              const handleClick = () => {
                handleClickTimeslot(el.position)
              }
              return (
                <Tippy
                  key={`timeSlot-${index}`}
                  disabled={true}
                  render={attrs => (
                    <div
                      className="box"
                      style={{
                        backgroundColor: '#FFF',
                        padding: '2px 4px',
                      }}
                      tabIndex="-1"
                      {...attrs}
                    >
                      {el.label}
                    </div>
                  )}
                >
                  <Timeslot
                    onClick={handleClick}
                    style={{
                      left: position - 10,
                    }}
                    key={`timeSlot${index}`}
                  >
                    <div
                      style={{
                        width: 4,
                        height: '100%',
                        marginLeft: 8,
                        background: '#000',
                      }}
                    ></div>
                  </Timeslot>
                </Tippy>
              )
            }
            return null
          })}
        </>
      )}
      {tour.context.videoFile && (
        <div style={{display: 'none'}}>
          <ReactPlayer url={tour.context.videoFile} onDuration={onDuration} />
        </div>
      )}
    </>
  )
}
