import React from 'react'
import {useEffectOnce} from 'react-use'
import {FormattedMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {useUpdateAtom} from 'jotai/utils'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {getCalculatedFontSize} from 'components/TourControls/duck'
import useConfig from 'components/Chatbot/useConfig'
import {stepsChatbotAtom, showSitePlanAtom} from 'components/jotai'

export const WhereBack = () => {
  const defaultStep = useConfig('default')
  const fontSize = useSelector(getCalculatedFontSize)

  const setStepsChatbot = useUpdateAtom(stepsChatbotAtom)
  const handleOpen = id => {
    setStepsChatbot(defaultStep)
  }

  return (
    <Box sx={{background: 'transparent', padding: '19px 29px', witdh: '100%'}}>
      <Grid container alignItems="center">
        <Grid item xs={6} onClick={() => handleOpen('back')}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} sx={{alignSelf: 'center', textAlign: 'right'}}>
          <Typography style={{fontSize: fontSize}}>
            <FormattedMessage
              id="chatbot.tour.where.header"
              defaultMessage="Wo bin ich?"
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export const SelectWhere = () => {
  const showSitePlan = useUpdateAtom(showSitePlanAtom)

  useEffectOnce(() => {
    showSitePlan(true)
  })

  return null
}
